// import CKEditor from 'ckeditor4';

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";

import useAxiosFile from "../../actions/useaxios";
// import AxiosFile from "../../../../../src/axios/axios"
import { CKEditor } from "ckeditor4-react";
import {
  DecryptData,
  isEmpty,
  LoadToast,
  updateToast,
} from "../../actions/common";
// import { useParams } from "react-router";
import config from "../../actions/config";
import Sidebar from "../../layouts/sidebar";
import Header from "../../layouts/header";
import { useLocation } from "react-router";
import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";

import noimage from "../../assests/images/noimage.jpg";
import { useSelector } from "react-redux";

function Cmsadd() {
  const { toggle } = useSelector((state) => state.admin_panel.User);

  let data = useLocation();
  console.log("dfghb", data);
  const search = data.search.split("=")[1];
  console.log("axiosFile", data.search.split("=")[1]);

  const axiosFile = useAxiosFile();
  const navigate = useNavigate();
  const [file, setFile] = useState([]);

  const [input, setInput] = useState("");
  const [edit, setEdit] = useState({});
  const [errors, setError] = useState({});

  console.log("inputdasta0", input);
  useEffect(() => {
    idverify(search);
  }, []);

  const idverify = async (search) => {
    console.log("search", search !== "" || search !== undefined);
    if (search !== "" && search !== undefined) {
      var senddata = {
        _id: search,
      };
      var data = await axiosFile.idverify(senddata);
      console.log("CMS EDIT", data, data.data.Image);
      if (data.status == true) {
        setInput({ type: data.data.type });
        setInput({ linkurl: data.data.linkurl });
        setInput({ page: data.data.page });
        setInput({ name: data.data.name });
        setInput({ question: data.data.question });
        setEdit({ answer: data.data.answer });
        setInput({
          name: data.data.name,
          page: data.data.page,
          type: data.data.type,
          answer: data.data.answer,
          question: data.data.question,
          linkurl: data.data.linkurl,
          Image: data.data.Image,
        });
        setFile(data.data.Image);
      }
      console.log("iiiii", data.data.Images);

      // setFinddata(data?.data)
    }
  };
  const changefn = (e) => {
    setError("");

    console.log("sadfgjhadfasdf", e.target.value);
    var { name, value } = e.target;
    setInput((input) => ({ ...input, [name]: value }));
    // setInput({ ...input, ...{ [name] : value}})
  };

  async function handleChange(event) {
    setError("");

    console.log("sergreg", event.target.files[0]);

    // setFile(event.target.files[0])
    var file = event.target.files;
    var error = {};
    console.log("filesimg", file);
    if (file.length <= 2) {
      var height = input?.type;
      console.log("tyrsr", height);
      var width = input?.type;
      console.log("yrju", height, width);

      console.log("fucfgufct", file, Object.keys(file), Object.values(file));
      await Promise.all(
        Object.values(file).map(async (val, index) => {
          console.log("gxdfth", val);
          console.log("rthg", index);
          const img = new Image();
          img.src = URL.createObjectURL(val);
          console.log("immmmmm", img.src);
          img.onload = () => {
            console.log("height", img.height);
            console.log("width", img.width);
            if (img.height != height || img.width != width) {
              error.file = `please file upload size in ${input?.type}`;
            }
            console.log(
              "dfgdfsg",
              error,
              index == Object.values(file).length - 1
            );
            if (index == Object.values(file).length - 1) {
              if (isEmpty(error)) {
                setFile((filesimg) => [...filesimg, ...file]);
              } else {
                setError(error);
              }
            }

            console.log("lkdjfkdlkjfdfinnmapppp", error);
          };
        })
      );
      console.log("asdbhkfsagdfha", error);
    } else {
      console.log("sdgfdgdfghbfhfdhgdfh");
      error.file = "please file upload 2 files";
      setError(error);
    }
    // setError(error)
    // const img = new Image();
    // img.src = createObjectURL(file);
    // var file = event.target.files
    // img.onload = () => {
    //     console.log(img.height);
    //     console.log(img.width);
    //   };
    // setInput({...input, ...{ Images : event.target.files[0]}})
  }

  const clickSubmit = async (e) => {
    e.preventDefault();
    let id = LoadToast();

    var value = cmsvalidation();

    if (!isEmpty(value)) {
      console.log("value", value);
      setError(value);
      updateToast(id, "fix all validation", "error");
    } else {
      console.log("search_data", search);
      let inputdata;
      if (search != undefined) {
        console.log("asdgfhasdfasdf", file);
        inputdata = {
          ...input,
          ...{
            _id: search,
            Images: typeof file == "object" ? file : [file],
            action: "update",
          },
        };
        console.log("poiuuiy", inputdata);
        // formData.append('_id', search)
        // formData.append('type', input.type)
        // formData.append('link', input.linkurl)
        // formData.append('question', input.question)
        // formData.append('answer', input.answer)
        // formData.append('file', file);
        // // formData.append('file1', file1);
        // formData.append('action', "update");
      } else {
        inputdata = { ...input, ...{ Images: file, action: "add" } };
        // formData.append('type', input.type)
        // formData.append('link', input.linkurl)
        // formData.append('question', input.question)
        // formData.append('answer', input.answer)
        // formData.append('file', file);
        // // formData.append('file1', file1);
        // formData.append('action', "add");
      }

      // console.log("file_data", file, "file1_data", file1);
      var data = await axiosFile.Cmsadd(inputdata);
      console.log("dddd", data);
      updateToast(id, data.msg, data.success);

      if (data.success == "success") {
        console.log("statusdata", data.status);
        navigate("/listcms");
      }
    }
  };

  const ckeditfn = (evt) => {
    console.log("event", evt);
    setError("");
    var edit = evt.editor.getData();
    var name = evt.editor.name;
    console.log("edit_data", name, edit);
    // setEdit((editor) => ({ ...editor, [name]: edit }))
    setInput((editor) => ({ ...editor, [name]: edit }));
  };

  const cmsvalidation = () => {
    var action = search ? "update" : "add";
    console.log("dbhjbnjhbasjhd", input?.answer, edit?.answer);
    const linkurl =
      /^((https?|http?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
    var errors = {};
    console.log("rtdujhryt", input?.question);
    if (input?.page !== "privacy") {
      if (isEmpty(input?.name)) {
        errors.name = "name field is required";
      }
      if (isEmpty(input?.page)) {
        errors.page = "page field is required";
      }
      if (isEmpty(input?.type)) {
        errors.type = "type field is required";
      }
      if (isEmpty(input?.linkurl)) {
        errors.linkurl = "link field is required";
      } else if (!linkurl.test(input?.linkurl)) {
        errors.linkurl = "please valid link";
      }
      if (isEmpty(input?.question)) {
        errors.question = "question field is required";
      }
      if (isEmpty(input?.answer)) {
        errors.answer = "answer field is required";
      }
      if (action == "update") {
        console.log("nnnnn", file, isEmpty(file));
        if (isEmpty(file)) {
          errors.file = "Please choose file";
        }
      }
    } else {
      if (isEmpty(input?.page)) {
        errors.page = "page field is required";
      }
      if (isEmpty(input?.question)) {
        errors.question = "question field is required";
      }
      if (isEmpty(input?.answer)) {
        errors.answer = "answer field is required";
      }
    }
    console.log("urtdyr", isEmpty(input?.question));

    // if (isEmpty(file1?.name || file1?.Image1)) {

    //     console.log("nnnnn", file1?.name, file1?.Image1);
    //     errors.file1 = "Please choose file1";
    // }

    return errors;
  };
  return (
    <>
      <div>
        <div className="innerpages">
          <div className="orders_pages">
            {console.log("lkdjfkdlkjfdf", errors)}
            <Header />
            <div className="container custom_container px-lg-0">
              <div className="row mx-auto">
                <div className="innerpages_wid px-0">
                  <Sidebar />
                  <div
                    className={
                      toggle
                        ? "content-sec-inner right_widthset content_without_sidebar"
                        : "content-sec-inner  content_with_sidebar"
                    }
                  >
                    <section id="content-wrapper">
                      <div className="top_title_sec">
                        <div>
                          <p className="page_title">
                            {search !== undefined ? "Edit cms" : "Add cms"}
                          </p>
                        </div>
                      </div>
                      <label>
                        name
                        <input
                          className="mdv_cmninput mb-3"
                          name="name"
                          type="text"
                          id="name"
                          placeholder="Enter the name"
                          value={input?.name}
                          onChange={(e) => changefn(e)}
                        />
                        <span id="page-error" className="error-msg">
                          {errors && errors.name}
                        </span>
                      </label>
                      <br />

                      <label>
                        type
                        <input
                          className="mdv_cmninput mb-3"
                          name="type"
                          disabled={search !== undefined}
                          type="text"
                          id="type"
                          placeholder="Enter the type"
                          value={input?.type}
                          onChange={(e) => changefn(e)}
                        />
                        <span id="subject-error" className="error-msg">
                          {errors && errors.type}
                        </span>
                      </label>
                      <br />

                      <label>
                        link
                        <input
                          className="mdv_cmninput mb-3"
                          name="linkurl"
                          type="text"
                          id="linkurl"
                          placeholder="Enter the link"
                          value={input?.linkurl}
                          onChange={(e) => changefn(e)}
                        />
                        <span id="subject-error" className="error-msg">
                          {errors && errors.linkurl}
                        </span>
                      </label>
                      <br />
                      <label>
                        page
                        <input
                          className="mdv_cmninput mb-3"
                          name="page"
                          disabled={search !== undefined}
                          type="text"
                          id="page"
                          placeholder="Enter the page"
                          value={input?.page}
                          onChange={(e) => changefn(e)}
                        />
                        <span id="page-error" className="error-msg">
                          {errors && errors.page}
                        </span>
                      </label>
                      <br />
                      <label>
                        question
                        {search == undefined ? (
                          <input
                            className="mdv_cmninput mb-3"
                            name="question"
                            type="text"
                            id="question"
                            placeholder="Enter the question"
                            value={input?.question}
                            onChange={(e) => changefn(e)}
                          />
                        ) : (
                          <input
                            className="mdv_cmninput mb-3"
                            name="question"
                            disabled={search !== undefined}
                            type="text"
                            id="question"
                            placeholder="Enter the question"
                            value={input?.question}
                            onChange={(e) => changefn(e)}
                          />
                        )}
                        <span id="question-error" className="error-msg">
                          {errors && errors.question}
                        </span>
                      </label>

                      {search ? (
                        <>
                          {edit.answer && (
                            <CKEditor
                              name="answer"
                              initData={edit.answer}
                              onChange={(e) => ckeditfn(e)}
                            />
                          )}
                        </>
                      ) : (
                        <CKEditor
                          name="answer"
                          initData={[]}
                          onChange={(e) => ckeditfn(e)}
                        />
                      )}

                      {/* //     :
                    //    (!id && 
                    //     <CKEditor
                    //     name="answer"
                   
                    //     onChange={(e) => ckeditfn(e)}
                    //     initData={edit?.answer}
                    //   /> )
                       
                       
                 
                    //  } */}

                      <span id="answer-error" className="error-msg">
                        {errors && errors.answer}
                      </span>
                      <br />
                      {/* <div>? */}
                      {/* Files:
                   
                    <br /><br/>
                    <button type="submit" onClick={(e) => { Upload(e) }}>submit</button>
                    {typeof file == "object" ? <img height="100" width="100" src={URL.createObjectURL(file)} /> : <></>}

                    <span id="file-error" >{errors && errors.file}</span>

                    <br> */}
                      {/* </br> */}

                      {/* </div> */}
                      <input
                        type="file"
                        name="Images"
                        multiple
                        accept="image/*"
                        onChange={handleChange}
                      />

                      <br />
                      <br />
                      <span id="file-error" className="error-msg">
                        {errors && errors.file}
                      </span>
                      {console.log(
                        "search !== undefined",
                        search !== undefined,
                        search
                      )}
                      {console.log("fileImage ", file)}
                      {file.map((val) => {
                        console.log("valdasdfasdf", val);
                        return typeof val == "object" ? (
                          <img
                            height="100"
                            width="100"
                            src={
                              val
                                ? URL.createObjectURL(val)
                                : search !== undefined && val !== ""
                                ? URL.createObjectURL(val)
                                : noimage
                            }
                          />
                        ) : (
                          <img
                            height="100"
                            width="100"
                            src={
                              val
                                ? `http://localhost:2000/cms_images/${val}`
                                : noimage
                            }
                          />
                        );
                      })}

                      {/* <input type="file" name="file1" onChange={handleChange} /> */}
                      <br />

                      <br />

                      <button

                        className="blue_btn add_deliveryprsn dp_btn_hover"

                        onClick={(e) => clickSubmit(e)}
                      >
                        {search !== undefined ? "update" : "add"}
                      </button>

                      {/* {typeof file == "object" ? <img height="100" width="100" src={(file?.name) ? URL.createObjectURL(file.name) : (search !== undefined && file?.name !== "") ? URL.createObjectURL(file) : noimage} /> : <></>} */}

                      {/* {typeof file1 == "object" ? <img height="100" width="100" src={(file1) ? URL.createObjectURL(file1) : (search !== undefined && file1?.name !== "") ? URL.createObjectURL(file1) : noimage} /> : <></>}
                                            <span id="file1-error" className="error-msg">{errors && errors.file1}</span> */}
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cmsadd;
