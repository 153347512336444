import React, { useEffect, useState } from "react";
import Sidebar from "../../layouts/sidebar";
import Headers from "../../layouts/header";

import "react-datetime/css/react-datetime.css";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import useAxiosFile from "../../actions/useaxios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { LoadToast, updateToast } from "../../actions/common";
import noimage from "../../assests/images/noimage.jpg";

function listcms() {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const { toggle } = useSelector((state) => state.admin_panel.User);
  const axiosFile = useAxiosFile();

  useEffect(() => {
    listcms();
  }, []);

  const listcms = async () => {
    var data = await axiosFile.listcms();
    console.log("vvvv", data);
    setList(data?.data);
  };

  const submitbtn = async (cell) => {
    console.log("ccc", cell);
    navigate(`/cmsadd/?id=${cell._id}`);
  };

  //   const clickbtn = async (cell) => {

  //     const id= LoadToast()
  //     console.log("ccc", cell);
  //     const updatedata = {
  //       _id: cell._id,
  //       action: "statusupdate",
  //       status: true
  //     }
  //     var data = await axiosFile.cmsnewinsert(updatedata)
  //     console.log("ttttttttrue", data);
  //     updateToast(id,data.msg,data.success)
  //     if(data?.success== "success"){
  //         listcms()
  //     }
  //   }
  const columns = [
    {
      dataField: "page",
      text: "page",
      sort: true,
      formatter: (cell, row) => (
        <div dangerouslySetInnerHTML={{ __html: cell }}></div>
      ),
    },
    {
      dataField: "type",
      text: "type",
      sort: true,
      formatter: (cell, row) => (
        <div dangerouslySetInnerHTML={{ __html: cell }}></div>
      ),
    },
    {
      dataField: "linkurl",
      text: "linkurl",
      sort: true,
      formatter: (cell, row) => (
        <a href={cell} target="_blank">
          {cell}
        </a>
      ),
    },
    {
      dataField: "question",
      text: "question",
      sort: true,
      formatter: (cell, row) => <div>{cell}</div>,
    },
    {
      dataField: "answer",
      text: "answer",
      sort: true,
      formatter: (cell, row) => (
        <div dangerouslySetInnerHTML={{ __html: cell }}></div>
      ),
    },
    {
      dataField: "Image",
      text: "Image",
      sort: true,
      formatter: (cell, val) => (
        <div>
          {console.log("vallll", cell)}
          {cell.map((imgshow) => {
            console.log("imgsjhow", imgshow);
            //   return( <img height="100" width="100" src={`http://localhost:2000/cms_images/${imgshow}`} />  )
            // })}
            return imgshow ? (
              <img
                height="100"
                width="100"
                src={`http://localhost:2000/cms_images/${imgshow}`}
              />
            ) : (
              <img height="100" width="100" src={noimage} />
            );
          })}
        </div>
      ),
    },

    {
      dataField: "hideshow",
      text: "Edit",
      formatter: (cell, row) => (
        <button
          class="btn table_viewdrop"
          type="button"
          onClick={() => submitbtn(row)}
        >
          edit
        </button>
      ),
    },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {},
    onSizePerPageChange: function (page, sizePerPage) {},
  });

  return (
    <div>
      {console.log("sssssss", list)}

      <div className="innerpages">
        <div className="orders_pages">
          <Headers />
          <div className="container custom_container px-lg-0">
            <div className="row mx-auto">
              <div className="innerpages_wid px-0">
                <Sidebar />
                <div
                  className={
                    toggle
                      ? "content-sec-inner right_widthset content_without_sidebar"
                      : "content-sec-inner  content_with_sidebar"
                  }
                >
                  <section id="content-wrapper">
                    <div class="row">
                      <div className="top_title_sec">
                        <div>
                          <p className="page_title">Cms lists</p>
                        </div>
                        {/* <div className="rightsec">
                          <button
                            type="button"
                            className="blue_btn add_deliveryprsn"
                            onClick={() => navigate("/cmsadd")}
                          >
                            Add
                          </button>
                        </div> */}
                      </div>
                    </div>
                    <div className="row mx-auto mt-4">
                      <div className="sec_border">
                        <div className="row">
                          {
                            <div className="ffd_table mar_bot">
                              <BootstrapTable
                                bootstrap4
                                keyField="id"
                                columns={columns}
                                data={list}
                                pagination={pagination}
                              />
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default listcms;
