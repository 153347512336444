import React, { useEffect, useState } from "react";
import Sidebar from "../../../layouts/sidebar";
import Headers from "../../../layouts/header";
import Select from "react-select";
import Datetime from "react-datetime";
import moment from "moment";
import { Nav, Tab, Row, Col } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-datetime/css/react-datetime.css";

import { useSelector } from "react-redux";

import { useLocation, useNavigate } from "react-router";
import {
  DecryptData,
  MinuteHourFormat,
  isEmpty,
} from "../../../actions/common";
import useAxiosFile from "../../../actions/useaxios";
import config from "../../../actions/config";
import { toast } from "react-toastify";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input";

const initname = {
  FirstName: "First Name",
  LastName: "Last Name",
  EmailId: "Email-Id",
  Mobile: "Mobile Number",
  Password: "Password",
  ConfirmPassword: "Confirm-Password",
  VehicleType: "Vehicle Type",
  VehicleCount: "Vehicle Count",
  DeliveryZones: "Delivery Zone",
  Street: "Street Number",
  Street2: "Street Name",
  Street: "Street Number",
  ZipCode: "Zip Code",
  BankName: "Bank Name",
  AccountType: "Account Type",
  AccountHolderName: "Account Holder Name",
  AccountNumber: "Bank Account Number",
  RoutingNumber: "Routing Number",
};

const initfile = {
  License: "",
  Profile: "",
};

const gender = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "others", label: "Others" },
];

const city = [
  { value: "Chicago", label: "Chicago" },
  { value: "Austin", label: "Austin" },
  { value: "Seattle", label: "Seattle" },
  { value: "San Francisco", label: "San Francisco" },
  { value: "Portland", label: "Portland" },
  { value: "New York", label: "New York" },
  { value: "Denver", label: "Denver" },
];

const state = [
  { value: "California", label: "California" },
  { value: "Texas", label: "Texas" },
  { value: "Florida", label: "Florida" },
  { value: "Ohio", label: "Ohio" },
  { value: "Hawaii", label: "Hawaii" },
  { value: "Alaska", label: "Alaska" },
  { value: "New Jersey", label: "New Jersey" },
];

const country = [{ value: "USA", label: "USA" }];

const deliveryzones = [
  { value: "Chicago", label: "Chicago" },
  { value: "Austin", label: "Austin" },
  { value: "Seattle", label: "Seattle" },
  { value: "San Francisco", label: "San Francisco" },
  { value: "Portland", label: "Portland" },
  { value: "New York", label: "New York" },
  { value: "Denver", label: "Denver" },
];

const driveroptions = [
  { value: "idle", label: "Idle" },
  { value: "intransit", label: "In Transit" },
  { value: "offline", label: "Offline" },
  { value: "active", label: "Active" },
];
const vehicletype = [
  { value: "Two-Wheeler", label: "Two-Wheeler" },
  { value: "Four-Wheeler", label: "Four-Wheeler" },
];

function ManagementDriverView() {
  const [opacity, setOpacity] = useState("false");
  const [opacityEnd, setEndOpacity] = useState("false");

  const location = useLocation();
  const navigate = useNavigate();

  const axiosFile = useAxiosFile();

  const [selectedOption, setSelectedOption] = useState(null);
  const [activeTab, setActiveTab] = useState("profile");
  const [Id, Setid] = useState(DecryptData(location.search.split("?Id=")[1]));
  const [driverdata, SetDriverData] = useState({});
  const [displayname, SetDisplayName] = useState(initname);
  const [Error, SetError] = useState({});
  const [Files, SetFiles] = useState(initfile);
  const [DisplayDate, SetDisplayDate] = useState({ display: "", max: "" });

  useEffect(() => {
    if (location?.search) {
      let paramdata = location.search.split("?Id=")[1];

      Setid(
        isEmpty(DecryptData(paramdata)) ? paramdata : DecryptData(paramdata)
      );
    }
  }, [location.search]);

  useEffect(() => {
    if (!isEmpty(Id)) {
      GetDriverdetail();
    }
  }, [Id]);

  const GetDriverdetail = async () => {
    let Resp = await axiosFile.GetDriverDetail({ id: Id });

    Resp.data.disable = Resp?.data?.creatortype == "admin" ? false : true;
    SetDriverData(Resp.data);
    console.log("kfjdksjfdksfsdfsd", Resp?.data);
    let newdate = Resp?.data?.DateofBirth
      ? new Date(Resp?.data?.DateofBirth)
      : new Date(new Date().setFullYear(new Date().getFullYear() - 18));
    let display = `${MinuteHourFormat(
      newdate.getFullYear()
    )}-${MinuteHourFormat(newdate.getMonth() + 1)}-${MinuteHourFormat(
      newdate.getDate()
    )}`;
    let maxdate = new Date(
      new Date().setFullYear(new Date().getFullYear() - 18)
    );
    SetDisplayDate({
      ...DisplayDate,
      ...{
        display: display,
        max: `${MinuteHourFormat(maxdate.getFullYear())}-${MinuteHourFormat(
          maxdate.getMonth() + 1
        )}-${MinuteHourFormat(maxdate.getDate())}`,
      },
    });
  };

  const { toggle } = useSelector((state) => state.admin_panel.User);

  const columns = [
    { dataField: "orderid", text: "Order ID" },
    { dataField: "customerName", text: "Customer Name", sort: true },
    { dataField: "vendor", text: "Vendor", sort: true },
    { dataField: "payment", text: "Payment", sort: true },
    { dataField: "time", text: "Time", sort: true },
    { dataField: "tips", text: "Tips", sort: true },
    { dataField: "amount", text: "Amount", sort: true },
    { dataField: "action", text: "Action" },
  ];

  const data = [
    {
      orderid: "#1569",
      customerName: "John Doe",
      vendor: "John Doe",
      payment: <p className="table_green">Online</p>,
      time: "1/17/2022  8:20 PM",
      tips: "$1.00",
      amount: <p className="table_bluetxt">$5.00</p>,
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementdriverview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },

    {
      orderid: "#1569",
      customerName: "John Doe",
      vendor: "John Doe",
      payment: <p className="table_green">Online</p>,
      time: "1/17/2022  8:20 PM",
      tips: "$1.00",
      amount: <p className="table_bluetxt">$5.00</p>,
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementdriverview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      orderid: "#1569",
      customerName: "John Doe",
      vendor: "John Doe",
      payment: <p className="table_green">Online</p>,
      time: "1/17/2022  8:20 PM",
      tips: "$1.00",
      amount: <p className="table_bluetxt">$5.00</p>,
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementdriverview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      orderid: "#1569",
      customerName: "John Doe",
      vendor: "John Doe",
      payment: <p className="table_green">Online</p>,
      time: "1/17/2022  8:20 PM",
      tips: "$1.00",
      amount: <p className="table_bluetxt">$5.00</p>,
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementdriverview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      orderid: "#1569",
      customerName: "John Doe",
      vendor: "John Doe",
      payment: <p className="table_green">Online</p>,
      time: "1/17/2022  8:20 PM",
      tips: "$1.00",
      amount: <p className="table_bluetxt">$5.00</p>,
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementdriverview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  const reviewcolumns = [
    { dataField: "orderid", text: "Order ID" },
    { dataField: "customerid", text: "Customer ID", sort: true },
    { dataField: "customername", text: "Customer Name", sort: true },
    { dataField: "mobile", text: "Mobile Number", sort: true },
    { dataField: "rating", text: "Rating", sort: true },
    { dataField: "review", text: "Review", sort: true },
    { dataField: "date", text: "Date", sort: true },
    { dataField: "action", text: "Action" },
  ];

  const reviewdata = [
    {
      orderid: "#1569",
      customerid: 10,
      customerName: "John Doe",
      mobile: "515 516 0624",
      rating: (
        <div className="table_startxt">
          <p className="table_blurblue">2</p>
          <img src={require("../../../assests/images/Union.png")} />
        </div>
      ),
      review: "Ipsum is simply dummy text of the printing industry. ",
      date: "10/10/2022",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementdriverview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },

    {
      orderid: "#1569",
      customerid: 10,
      customerName: "John Doe",
      mobile: "515 516 0624",
      rating: (
        <div className="table_startxt">
          <p className="table_blurblue">2</p>
          <img src={require("../../../assests/images/Union.png")} />
        </div>
      ),
      review: "Ipsum is simply dummy text of the printing industry. ",
      date: "10/10/2022",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementdriverview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      orderid: "#1569",
      customerid: 10,
      customerName: "John Doe",
      mobile: "515 516 0624",
      rating: (
        <div className="table_startxt">
          <p className="table_blurblue">2</p>
          <img src={require("../../../assests/images/Union.png")} />
        </div>
      ),
      review: "Ipsum is simply dummy text of the printing industry. ",
      date: "10/10/2022",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementdriverview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      orderid: "#1569",
      customerid: 10,
      customerName: "John Doe",
      mobile: "515 516 0624",
      rating: (
        <div className="table_startxt">
          <p className="table_blurblue">2</p>
          <img src={require("../../../assests/images/Union.png")} />
        </div>
      ),
      review: "Ipsum is simply dummy text of the printing industry. ",
      date: "10/10/2022",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementdriverview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      orderid: "#1569",
      customerid: 10,
      customerName: "John Doe",
      mobile: "515 516 0624",
      rating: (
        <div className="table_startxt">
          <p className="table_blurblue">2</p>
          <img src={require("../../../assests/images/Union.png")} />
        </div>
      ),
      review: "Ipsum is simply dummy text of the printing industry. ",
      date: "10/10/2022",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementdriverview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  const gender = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "FeMale" },
    { value: "Others", label: "Others" },
  ];

  const driveroptions = [
    { value: "idle", label: "Idle" },
    { value: "intransit", label: "In Transit" },
    { value: "offline", label: "Offline" },
    { value: "active", label: "Active" },
  ];

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#F5F6F7"
        : isSelected
        ? "#F5F6F7"
        : isHovered
        ? "red"
        : "#F5F6F7",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#a71d24"
          : isSelected
          ? "#a71d24"
          : isFocused
          ? "#a71d24"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 20px",
      backgroundColor: "#fff ",
      border: "1px solid lightgray",
      borderRadius: 10,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#6C6A81",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const stylegreendrop = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#F5F6F7"
        : isSelected
        ? "#F5F6F7"
        : isHovered
        ? "red"
        : "#F5F6F7",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#a71d24"
          : isSelected
          ? "#a71d24"
          : isFocused
          ? "#a71d24"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 20px",
      backgroundColor: "#fff ",
      border: "1px solid #00B167",
      borderRadius: 10,
      fontSize: "13px",
      color: "#00B167",
      fontWeight: 700,
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#00B167",
      fontWeight: 700,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#00B167",
      fontWeight: 700,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {},
    onSizePerPageChange: function (page, sizePerPage) {},
  });

  const OnChange = (e) => {
    if (e?.target) {
      const { name, value, id, type } = e.target;

      if (!isEmpty(Error[id])) {
        SetError({ ...Error, ...{ [id]: "" } });
      }

      if (name == "Address" || name == "BankDetails") {
        if (!isEmpty(Error[id])) {
          SetError({ ...Error, ...{ [id]: "" } });
        }
        if (type == "text" && id != "Street2") {
          if (config.Alpabetsalone.test(value)) {
            SetDriverData({
              ...driverdata,
              ...{ [name]: { ...driverdata[name], ...{ [id]: value } } },
            });
          }
        } else {
          SetDriverData({
            ...driverdata,
            ...{ [name]: { ...driverdata[name], ...{ [id]: value } } },
          });
        }
        // SetDriverData({...driverdata,...{[name]:{...driverdata[name],...{[id]:value}}}})
      } else {
        if (["FirstName", "LastName", "EmailId", "Mobile"].includes(id)) {
          SetDriverData({
            ...driverdata,
            ...{
              [id]: value,
              ["Address"]: {
                ...driverdata["Address"],
                ...{
                  [id == "LastName" || id == "FirstName" ? "FullName" : id]:
                    id == "LastName"
                      ? driverdata.FirstName + " " + value
                      : id == "FirstName"
                      ? value + " " + driverdata.LastName
                      : value,
                },
              },
            },
          });
        } else {
          if (id == "DateofBirth") {
            let disp = new Date(value);
            SetDisplayDate({
              ...DisplayDate,
              ...{
                display: `${MinuteHourFormat(
                  disp.getFullYear()
                )}-${MinuteHourFormat(disp.getMonth() + 1)}-${MinuteHourFormat(
                  disp.getDate()
                )}`,
              },
            });
          }
          SetDriverData({ ...driverdata, ...{ [id]: value } });
        }
      }
    }
  };

  const SelectChange = (e, name, id) => {
    const { value } = e;
    if (name == "Address") {
      SetDriverData({
        ...driverdata,
        ...{ [name]: { ...driverdata[name], ...{ [id]: value } } },
      });
    } else if (name == "DeliveryZones") {
      if (driverdata?.DeliveryZones?.length > 0) {
        if (!driverdata?.DeliveryZones?.some((val) => val == value)) {
          SetDriverData({
            ...driverdata,
            ...{ [name]: [...driverdata[name], value] },
          });
        }
        return true;
      } else {
        SetDriverData({
          ...driverdata,
          ...{ [name]: [...driverdata[name], value] },
        });
      }
    } else {
      SetDriverData({ ...driverdata, ...{ [name]: value } });
    }
  };

  const OnFileChange = (e) => {
    SetFiles({ ...Files, ...{ [e.target.id]: e.target.files[0] } });
  };

  const Validation = () => {
    const {
      FirstName,
      LastName,
      EmailId,
      Mobile,
      Password,
      Gender,
      Address,
      BankDetails,
      VehicleType,
      DeliveryZones,
      VehicleCount,
    } = driverdata;
    let errors = {};
    Object.keys(driverdata).map((val) => {
      if (val !== "__v" && val !== "Password" && val !== "Profile") {
        if (isEmpty(driverdata[val])) {
          errors[val] =
            (displayname[val] ? displayname[val] : val) + " is Required";
        } else {
          if (val == "Address" || val == "BankDetails") {
            Object.keys(driverdata[val]).map((inval) => {
              if (isEmpty(driverdata[val][inval])) {
                errors[inval] =
                  (initname[inval] ? initname[inval] : inval) + " is Required";
              }
            });
          }
          if (val == "Mobile") {
            if (!isValidPhoneNumber(driverdata[val])) {
              errors[val] = "Enter Valid Mobile-Number";
            }
          }
          if (val == "EmailId") {
            if (!config.EMAIL.test(driverdata[val])) {
              errors[val] = "Enter Valid EmailId";
            }
          }
          if (val == "Password") {
            if (!config.PASSWORD.test(driverdata[val])) {
              errors[val] =
                "Password Must Contain one small letter, one capital letter, one number and one special character";
            }
          }
        }
      }
    });
    Object.keys(Files).map((val) => {});

    return errors;
  };

  const Submit = async () => {
    if (driverdata?.disable) {
      let senddata = driverdata;
      senddata.type = "edit";
      senddata.from = "Admin";
      let Resp = await axiosFile.DriverAction(senddata);
      if (Resp?.success == "success") {
        navigate("/managementdriver");
        toast.success("Added Successfully", 1000);
      } else {
        toast.error("Try-Again", 1000);
      }
    } else {
      let validate = Validation();
      console.log("kldflkdsjfklgjsg", validate);
      if (isEmpty(validate)) {
        let senddata = driverdata;
        senddata.DeliveryZones = JSON.stringify(senddata.DeliveryZones);
        if (Object.keys(Files).length > 0) {
          Object.keys(Files).map((val) => {
            if (!isEmpty(Files[val])) {
              senddata[val] = Files[val];
            }
          });
        }

        senddata.type = "edit";
        senddata.from = "Admin";

        let Resp = await axiosFile.DriverAction(senddata);
        console.log("ksjfksjkf", Resp);
        if (Resp?.success == "success") {
          navigate("/managementdriver");
          toast.success("Added Successfully", 1000);
        } else {
          toast.error(Resp.msg, 1000);
        }
      } else {
        SetError(validate);
        toast.error("Fix all mistakes and try-again", 1000);
      }
    }
  };

  const Removedeliveryzone = (index) => {
    let array = driverdata.DeliveryZones;
    array.splice(index, 1);
    SetDriverData({ ...driverdata, ...{ DeliveryZones: array } });
  };

  return (
    <>
      <div>
        <div className="innerpages">
          <div className="orders_pages">
            <Headers />
            <div className="container custom_container px-lg-0">
              <div className="row mx-auto px-0">
                <div className="innerpages_wid">
                  <Sidebar />
                  <div
                    className={
                      toggle
                        ? "content-sec-inner right_widthset content_without_sidebar"
                        : "content-sec-inner  content_with_sidebar"
                    }
                  >
                    <section id="content-wrapper">
                      <div class="row">
                        <div className="top_title_sec">
                          <div>
                            <p className="page_title">
                              {driverdata?.FirstName && driverdata?.LastName
                                ? driverdata.FirstName +
                                  " " +
                                  driverdata?.LastName
                                : "e.g. Rino Piccolo"}
                            </p>
                          </div>
                          {/* <div className='rightsec'>
        <p>Accepting Orders
        <div className='togswit'>
  <input type="checkbox" id="toggle-btn"/>
  <label for="toggle-btn"></label>
</div>
        </p>
      </div> */}
                        </div>
                      </div>
                      {/* profile details */}
                      <div className="mt-4">
                        <div className="mdvsec_border sec_border">
                          <div className="row mdv_driverdtls_whole">
                            <div className="col-xxl-4 col-xl-4 col-lg-3 col-md-3 col-sm-12 col-12">
                              <div className="mdv_driverdtls">
                                {console.log("kjdkljgjslgs", driverdata)}
                                <img
                                  className="tab_dummyimg me-3"
                                  src={
                                    isEmpty(Files?.Profile?.type) &&
                                    isEmpty(driverdata?.Profile)
                                      ? require("../../../assests/images/dummyimg.png")
                                      : isEmpty(Files?.Profile?.type)
                                      ? `${config.IMG_URL}/Driver/Profile/${driverdata?.Profile}`
                                      : URL.createObjectURL(Files.Profile)
                                  }
                                />
                                <div>
                                  <p className="mdv_drivername">
                                    {driverdata?.FirstName &&
                                    driverdata?.LastName
                                      ? driverdata.FirstName +
                                        " " +
                                        driverdata?.LastName
                                      : "e.g. Rino Piccolo"}
                                  </p>
                                  <div className="mdv_locationtext">
                                    <img
                                      className="mdv_location"
                                      src={require("../../../assests/images/location.png")}
                                    />
                                    <p>
                                      {driverdata?.Address?.State
                                        ? driverdata?.Address?.State
                                        : "e.g. Pasadena"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-xxl-8 col-xl-8 col-lg-9 col-md-9 col-sm-12 col-12">
                              {activeTab == "rating" ? (
                                <div className="rightside">
                                  <div className="whole_searchbar white_bg">
                                    <img
                                      className="search_glass"
                                      src={require("../../../assests/images/Search.png")}
                                    />
                                    <input placeholder="Search..." />
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}

                              {/* {activeTab == "profile" ? 
        <div className='rightside'>
        <div className='whole_searchbar white_bg'>
   <img className='search_glass' src={require('../../../assests/images/Search.png')}/>
  <input placeholder="Search..."/>
   </div>
        <div className='rightside_select'>
        <Select
                            options={driveroptions}
                            defaultValue={selectedOption}
                            styles={stylegreendrop}
                            onChange={setSelectedOption}
                            className="border_blue_select"
                            classNamePrefix="react-select"
                            placeholder='Status'
                            disabled={driverdata?.disable}
                       
                          />
                          </div>


        </div> : <></>} */}

                              {activeTab == "delivered" ||
                              activeTab == "rejected" ? (
                                <div className="rightside">
                                  <div className="rightside_select">
                                    <Select
                                      options={options}
                                      defaultValue={selectedOption}
                                      styles={stylesgraybg}
                                      onChange={setSelectedOption}
                                      className="border_blue_select"
                                      classNamePrefix="react-select"
                                      placeholder="Type"
                                      disabled={driverdata?.disable}
                                    />
                                  </div>

                                  <div className="order_calendar_div">
                                    <p
                                      className={
                                        opacity == "true" ? "hiding_caltxt" : ""
                                      }
                                    >
                                      Start Date
                                    </p>
                                    <img
                                      className="order_calendar_ic"
                                      src={require("../../../assests/images/calendar.png")}
                                    />
                                    <Datetime
                                      onChange={() => {
                                        setOpacity("true");
                                      }}
                                      className="order_datepick"
                                    />
                                  </div>

                                  <p className="order_totxt">To</p>
                                  <div className="order_calendar_div">
                                    <p
                                      className={
                                        opacityEnd == "true"
                                          ? "hiding_calendtxt"
                                          : ""
                                      }
                                    >
                                      End Date
                                    </p>
                                    <img
                                      className="order_calendar_ic"
                                      src={require("../../../assests/images/calendar.png")}
                                    />
                                    <Datetime
                                      onChange={() => {
                                        setEndOpacity("true");
                                      }}
                                      className="order_datepick"
                                    />
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                          {/* <div className='mdv_drivertab'>
        <p className={activeTab == "profile" ? 'mdv_tabtext active' : 'mdv_tabtext'} onClick={() => setActiveTab("profile")}>Profile</p>
        <p className={activeTab == "delivered" ? 'mdv_tabtext active' : 'mdv_tabtext'} onClick={() => setActiveTab("delivered")}>Delivered</p>
        <p className={activeTab == "rejected" ? 'mdv_tabtext active' : 'mdv_tabtext'} onClick={() => setActiveTab("rejected")}>Rejected</p>
        <p className={activeTab == "rating" ? 'mdv_tabtext active' : 'mdv_tabtext'} onClick={() => setActiveTab("rating")}>Ratings & Reviews</p>
        <p className={activeTab == "changepw" ? 'mdv_tabtext active' : 'mdv_tabtext'} onClick={() => setActiveTab("changepw")}>Change Password</p>

      </div> */}
                        </div>

                        {/* profile details */}
                        {activeTab == "profile" && (
                          <>
                            <div className="mdv_profiledtls">
                              <div className="mdv_border_div">
                                <p className="mdv_profilettl">
                                  Personal Information
                                </p>
                              </div>

                              <Row className="mdv_inputrow">
                                <Col
                                  xxl={4}
                                  xl={4}
                                  lg={4}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <p className="mdv_inplabel">First Name</p>
                                  <input
                                    className="mdv_cmninput"
                                    type="text"
                                    placeholder="John"
                                    value={driverdata?.FirstName || ""}
                                    disabled={driverdata?.disable}
                                    id="FirstName"
                                    onChange={(e) => OnChange(e)}
                                  />
                                  {Error?.FirstName && (
                                    <span className="error-msg">
                                      {Error?.FirstName}
                                    </span>
                                  )}
                                </Col>
                                <Col
                                  xxl={4}
                                  xl={4}
                                  lg={4}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <p className="mdv_inplabel">Last Name</p>
                                  <input
                                    className="mdv_cmninput"
                                    type="text"
                                    placeholder="Doe"
                                    value={driverdata?.LastName || ""}
                                    disabled={driverdata?.disable}
                                    id="LastName"
                                    onChange={(e) => OnChange(e)}
                                  />
                                  {Error?.LastName && (
                                    <span className="error-msg">
                                      {Error?.LastName}
                                    </span>
                                  )}
                                </Col>
                                <Col
                                  xxl={4}
                                  xl={4}
                                  lg={4}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <p className="mdv_inplabel">Email Address</p>
                                  <input
                                    className="mdv_cmninput"
                                    type="email"
                                    placeholder="john@jamkse.com"
                                    value={driverdata?.EmailId || ""}
                                    disabled={driverdata?.disable}
                                    id="EmailId"
                                    onChange={(e) => OnChange(e)}
                                  />
                                  {Error?.EmailId && (
                                    <span className="error-msg">
                                      {Error?.EmailId}
                                    </span>
                                  )}
                                </Col>

                                <Col
                                  xxl={4}
                                  xl={4}
                                  lg={4}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <p className="mdv_inplabel">Phone Number</p>
                                  <PhoneInput
                                    className="sigin_inputs new_rephoneinp"
                                    placeholder="Enter phone number"
                                    id="Mobile"
                                    onChange={(e) => OnChange(e, "Mobile")}
                                    defaultCountry="US"
                                    country="US"
                                    countries={["US","IN"]}
                                    // onCountryChange={(e)=>OnChange(e,"Mobile","country")}
                                  />
                                  {/* <input className='mdv_cmninput' type="number" placeholder="+1 515 516 0624" value={driverdata?.Mobile || ''} disabled={driverdata?.disable} id='Mobile' onChange={(e)=>OnChange(e)}/> */}
                                  {Error?.Mobile && (
                                    <span className="error-msg">
                                      {Error?.Mobile}
                                    </span>
                                  )}
                                </Col>
                                <Col
                                  xxl={4}
                                  xl={4}
                                  lg={4}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <p className="mdv_inplabel">Date Of Birth</p>
                                  <input
                                    className="mdv_cmninput"
                                    type="date"
                                    placeholder="Doe"
                                    value={DisplayDate?.display || ""}
                                    disabled={driverdata?.disable}
                                    id="DateofBirth"
                                    onChange={(e) => OnChange(e)}
                                    max={DisplayDate?.max}
                                  />
                                  {Error?.DateofBirth && (
                                    <span className="error-msg">
                                      {Error?.DateofBirth}
                                    </span>
                                  )}
                                </Col>
                                <Col
                                  xxl={4}
                                  xl={4}
                                  lg={4}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <p className="mdv_inplabel">Gender</p>
                                  {/* <input className='mdv_cmninput' type="email" placeholder="john@jamkse.com"/> */}
                                  {console.log(
                                    "kjdhfkjdhjkfhds",
                                    driverdata?.Gender
                                  )}
                                  <Select
                                    options={gender}
                                    defaultValue={selectedOption}
                                    styles={stylesgraybg}
                                    onChange={(e) =>
                                      SetDriverData({
                                        ...driverdata,
                                        ...{ ["Gender"]: e.value },
                                      })
                                    }
                                    className="border_blue_select"
                                    classNamePrefix="react-select"
                                    placeholder="Gender"
                                    value={
                                      driverdata?.Gender
                                        ? {
                                            label: driverdata?.Gender,
                                            value: driverdata?.Gender,
                                          }
                                        : ""
                                    }
                                    disabled={driverdata?.disable}
                                  />
                                  {Error?.Gender && (
                                    <span className="error-msg">
                                      {Error?.Gender}
                                    </span>
                                  )}
                                </Col>
                                {/* <Col xxl={4} xl={4} lg={4} md={6} sm={6} xs={12} className="mb-3">
            <p className='mdv_inplabel'>Choose Profile</p>
                <input className='mdv_cmninput' type="file" placeholder="Doe" id='Profile' onChange={(e)=>OnFileChange(e)}/>
            </Col> */}
                                {/* <Col xxl={4} xl={4} lg={4} md={6} sm={6} xs={12} className="mb-3">
            <p className='mdv_inplabel'>Enter Password</p>
                <input className='mdv_cmninput' type="text" placeholder="Doe" id='Password' onChange={(e)=>OnChange(e)}/>
                { Error?.Password && <span className='error-msg'>{Error?.Password}</span>}
            </Col> */}
                              </Row>
                            </div>

                            <div className="mdv_profiledtls">
                              <div className="mdv_border_div">
                                <p className="mdv_profilettl">Address</p>
                              </div>
                              <Row className="mdv_inputrow">
                                <Col
                                  xxl={4}
                                  xl={4}
                                  lg={4}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <p className="mdv_inplabel">Street Number</p>
                                  <input
                                    className="mdv_cmninput"
                                    type="number"
                                    placeholder="485"
                                    value={driverdata?.Address?.Street || ""}
                                    disabled={driverdata?.disable}
                                    id="Street"
                                    name="Address"
                                    onChange={(e) => OnChange(e)}
                                  />
                                  {Error?.Street && (
                                    <span className="error-msg">
                                      {Error?.Street}
                                    </span>
                                  )}
                                </Col>
                                <Col
                                  xxl={4}
                                  xl={4}
                                  lg={4}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <p className="mdv_inplabel">Street Name</p>
                                  <input
                                    className="mdv_cmninput"
                                    type="text"
                                    placeholder="6th avenue"
                                    value={driverdata?.Address?.Street2 || ""}
                                    disabled={driverdata?.disable}
                                    id="Street2"
                                    name="Address"
                                    onChange={(e) => OnChange(e)}
                                  />
                                  {Error?.Street2 && (
                                    <span className="error-msg">
                                      {Error?.Street2}
                                    </span>
                                  )}
                                </Col>
                                <Col
                                  xxl={4}
                                  xl={4}
                                  lg={4}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <p className="mdv_inplabel">Area</p>
                                  <input
                                    className="mdv_cmninput"
                                    type="text"
                                    placeholder="delpick"
                                    value={driverdata?.Address?.Area || ""}
                                    disabled={driverdata?.disable}
                                    id="Area"
                                    name="Address"
                                    onChange={(e) => OnChange(e)}
                                  />
                                  {Error?.Area && (
                                    <span className="error-msg">
                                      {Error?.Area}
                                    </span>
                                  )}
                                </Col>

                                <Col
                                  xxl={3}
                                  xl={3}
                                  lg={3}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <p className="mdv_inplabel">City</p>
                                  {/* <input className='mdv_cmninput' type="number" placeholder="+1 515 516 0624"/> */}
                                  <Select
                                    options={city}
                                    defaultValue={selectedOption}
                                    styles={stylesgraybg}
                                    onChange={(e) =>
                                      SelectChange(e, "Address", "City")
                                    }
                                    className="border_blue_select"
                                    classNamePrefix="react-select"
                                    placeholder="Manhattan"
                                    value={
                                      driverdata?.Address?.City
                                        ? {
                                            label: driverdata?.Address?.City,
                                            value: driverdata?.Address?.City,
                                          }
                                        : ""
                                    }
                                    id="City"
                                    name="Address"
                                    disabled={driverdata?.disable}
                                  />
                                  {Error?.City && (
                                    <span className="error-msg">
                                      {Error?.City}
                                    </span>
                                  )}
                                </Col>
                                <Col
                                  xxl={3}
                                  xl={3}
                                  lg={3}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <p className="mdv_inplabel">State</p>
                                  {/* <input className='mdv_cmninput' type="text" placeholder="Doe"/> */}

                                  <Select
                                    options={state}
                                    defaultValue={selectedOption}
                                    styles={stylesgraybg}
                                    onChange={(e) =>
                                      SelectChange(e, "Address", "State")
                                    }
                                    className="border_blue_select"
                                    classNamePrefix="react-select"
                                    placeholder="New York"
                                    value={
                                      driverdata?.Address?.State
                                        ? {
                                            label: driverdata?.Address?.State,
                                            value: driverdata?.Address?.State,
                                          }
                                        : ""
                                    }
                                    id="State"
                                    name="Address"
                                    disabled={driverdata?.disable}
                                  />
                                  {Error?.State && (
                                    <span className="error-msg">
                                      {Error?.State}
                                    </span>
                                  )}
                                </Col>
                                <Col
                                  xxl={3}
                                  xl={3}
                                  lg={3}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <p className="mdv_inplabel">Country</p>
                                  {/* <input className='mdv_cmninput' type="text" placeholder="john@jamkse.com"/> */}

                                  <Select
                                    options={country}
                                    defaultValue={selectedOption}
                                    styles={stylesgraybg}
                                    onChange={(e) =>
                                      SelectChange(e, "Address", "Country")
                                    }
                                    className="border_blue_select"
                                    classNamePrefix="react-select"
                                    placeholder="USA"
                                    value={
                                      driverdata?.Address?.Country
                                        ? {
                                            label: driverdata?.Address?.Country,
                                            value: driverdata?.Address?.Country,
                                          }
                                        : ""
                                    }
                                    id="Country"
                                    name="Address"
                                    disabled={driverdata?.disable}
                                  />
                                  {Error?.Country && (
                                    <span className="error-msg">
                                      {Error?.Country}
                                    </span>
                                  )}
                                </Col>
                                <Col
                                  xxl={3}
                                  xl={3}
                                  lg={3}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <p className="mdv_inplabel">Zip Code</p>
                                  <input
                                    className="mdv_cmninput"
                                    type="number"
                                    placeholder="10101"
                                    value={driverdata?.Address?.ZipCode || ""}
                                    disabled={driverdata?.disable}
                                    id="ZipCode"
                                    name="Address"
                                    onChange={(e) => OnChange(e)}
                                  />
                                  {Error?.ZipCode && (
                                    <span className="error-msg">
                                      {Error?.ZipCode}
                                    </span>
                                  )}
                                </Col>
                              </Row>
                            </div>

                            <div className="mdv_profiledtls">
                              <div className="mdv_border_div">
                                <p className="mdv_profilettl">Bank Details</p>
                              </div>
                              <Row className="mdv_inputrow">
                                <Col
                                  xxl={4}
                                  xl={4}
                                  lg={4}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <p className="mdv_inplabel">Bank Name</p>
                                  <input
                                    className="mdv_cmninput"
                                    type="text"
                                    placeholder="Bank Of America"
                                    value={
                                      driverdata?.BankDetails?.BankName || ""
                                    }
                                    disabled={driverdata?.disable}
                                    id="BankName"
                                    name="BankDetails"
                                    onChange={(e) => OnChange(e)}
                                  />
                                  {Error?.BankName && (
                                    <span className="error-msg">
                                      {Error?.BankName}
                                    </span>
                                  )}
                                </Col>
                                <Col
                                  xxl={4}
                                  xl={4}
                                  lg={4}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <p className="mdv_inplabel">Account Type</p>
                                  <input
                                    className="mdv_cmninput"
                                    type="text"
                                    placeholder="Business"
                                    value={
                                      driverdata?.BankDetails?.AccountType || ""
                                    }
                                    disabled={driverdata?.disable}
                                    id="AccountType"
                                    name="BankDetails"
                                    onChange={(e) => OnChange(e)}
                                  />
                                  {Error?.AccountType && (
                                    <span className="error-msg">
                                      {Error?.AccountType}
                                    </span>
                                  )}
                                </Col>
                                <Col
                                  xxl={4}
                                  xl={4}
                                  lg={4}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <p className="mdv_inplabel">
                                    Account Holder Name
                                  </p>
                                  <input
                                    className="mdv_cmninput"
                                    type="text"
                                    placeholder="Sweet green"
                                    value={
                                      driverdata?.BankDetails
                                        ?.AccountHolderName || ""
                                    }
                                    disabled={driverdata?.disable}
                                    id="AccountHolderName"
                                    name="BankDetails"
                                    onChange={(e) => OnChange(e)}
                                  />
                                  {Error?.AccountHolderName && (
                                    <span className="error-msg">
                                      {Error?.AccountHolderName}
                                    </span>
                                  )}
                                </Col>
                                <Col
                                  xxl={4}
                                  xl={4}
                                  lg={4}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <p className="mdv_inplabel">
                                    Bank Account Number
                                  </p>
                                  <input
                                    className="mdv_cmninput"
                                    type="number"
                                    placeholder="5894 58967 6984"
                                    value={
                                      driverdata?.BankDetails?.AccountNumber ||
                                      ""
                                    }
                                    disabled={driverdata?.disable}
                                    id="AccountNumber"
                                    name="BankDetails"
                                    onChange={(e) => OnChange(e)}
                                  />
                                  {Error?.AccountNumber && (
                                    <span className="error-msg">
                                      {Error?.AccountNumber}
                                    </span>
                                  )}
                                </Col>
                                <Col
                                  xxl={4}
                                  xl={4}
                                  lg={4}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <p className="mdv_inplabel">Routing Number</p>
                                  <input
                                    className="mdv_cmninput"
                                    type="number"
                                    placeholder="1562 87952"
                                    value={
                                      driverdata?.BankDetails?.RoutingNumber ||
                                      ""
                                    }
                                    disabled={driverdata?.disable}
                                    id="RoutingNumber"
                                    name="BankDetails"
                                    onChange={(e) => OnChange(e)}
                                  />
                                  {Error?.RoutingNumber && (
                                    <span className="error-msg">
                                      {Error?.RoutingNumber}
                                    </span>
                                  )}
                                </Col>
                              </Row>
                            </div>

                            <div className="mdv_profiledtls">
                              <div className="mdv_border_div">
                                <p className="mdv_profilettl">
                                  Vehicle & Delivery Details
                                </p>
                              </div>
                              <Row className="mdv_inputrow">
                                <Col
                                  xxl={6}
                                  xl={6}
                                  lg={6}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <Row>
                                    <Col
                                      xxl={6}
                                      xl={6}
                                      lg={6}
                                      md={6}
                                      sm={6}
                                      xs={12}
                                      className="mb-3"
                                    >
                                      <p className="mdv_inplabel">
                                        Vehicle Type
                                      </p>
                                      {/* <input className='mdv_cmninput' type="text" placeholder="Doe"/> */}
                                      <Select
                                        options={vehicletype}
                                        defaultValue={selectedOption}
                                        styles={stylesgraybg}
                                        onChange={(e) =>
                                          SelectChange(
                                            e,
                                            "VehicleType",
                                            undefined
                                          )
                                        }
                                        className="border_blue_select"
                                        classNamePrefix="react-select"
                                        placeholder="Car"
                                        value={
                                          driverdata?.VehicleType
                                            ? {
                                                label: driverdata.VehicleType,
                                                value: driverdata.VehicleType,
                                              }
                                            : ""
                                        }
                                        disabled={driverdata?.disable}
                                      />
                                    </Col>
                                    <Col
                                      xxl={6}
                                      xl={6}
                                      lg={6}
                                      md={6}
                                      sm={6}
                                      xs={12}
                                      className="mb-3"
                                    >
                                      <p className="mdv_inplabel">Vehicles</p>
                                      <input
                                        className="mdv_cmninput"
                                        type="text"
                                        placeholder="2"
                                        value={driverdata?.VehicleCount || ""}
                                        disabled={driverdata?.disable}
                                        id="VehicleCount"
                                        onChange={(e) => OnChange(e)}
                                      />
                                    </Col>
                                    <Col
                                      xxl={12}
                                      xl={12}
                                      lg={12}
                                      md={12}
                                      sm={12}
                                      xs={12}
                                      className="mb-3"
                                    >
                                      <p className="mdv_inplabel">
                                        Delivery Zones
                                      </p>
                                      {/* <input className='mdv_cmninput' type="text" placeholder="2"/> */}

                                      <Select
                                        options={deliveryzones}
                                        defaultValue={selectedOption}
                                        styles={stylesgraybg}
                                        onChange={(e) =>
                                          SelectChange(
                                            e,
                                            "DeliveryZones",
                                            undefined
                                          )
                                        }
                                        className="border_blue_select"
                                        classNamePrefix="react-select"
                                        placeholder="Pasadena"
                                        disabled={driverdata?.disable}
                                      />
                                    </Col>
                                    {driverdata.DeliveryZones?.length > 0 &&
                                      !isEmpty(driverdata.DeliveryZones) && (
                                        <Col
                                          xxl={12}
                                          xl={12}
                                          lg={12}
                                          md={12}
                                          sm={12}
                                          xs={12}
                                          className="mb-3"
                                        >
                                          <div className="mdv_locationlists">
                                            {driverdata.DeliveryZones?.map(
                                              (val, ind) => (
                                                <div className="mdv_singleloclist">
                                                  <p>{val}</p>
                                                  {!driverdata?.disable && (
                                                    <img
                                                      src={require("../../../assests/images/close_small.png")}
                                                      onClick={() =>
                                                        Removedeliveryzone(ind)
                                                      }
                                                    />
                                                  )}
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </Col>
                                      )}

                                    <div className="mdv_update_dualbtn">
                                      <button
                                        className="blue_btn mdv_update"
                                        type="button"
                                        onClick={() => Submit()}
                                      >
                                        Update
                                      </button>
                                      <button className="seconday_btn">
                                        Cancel
                                      </button>
                                    </div>
                                  </Row>
                                </Col>

                                <Col
                                  xxl={6}
                                  xl={6}
                                  lg={6}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <Row>
                                    <Col
                                      xxl={6}
                                      xl={6}
                                      lg={6}
                                      md={6}
                                      sm={6}
                                      xs={12}
                                      className="mb-3"
                                    >
                                      <p className="mdv_inplabel">
                                        National Identity Document
                                      </p>
                                      {isEmpty(Files?.License?.type) &&
                                      isEmpty(driverdata?.Documents?.length) ? (
                                        <img
                                          className="mdv_license img-fluid"
                                          src={require("../../../assests/images/license.png")}
                                        />
                                      ) : isEmpty(Files?.License?.type) ? (
                                        <img
                                          className="mdv_license img-fluid"
                                          src={`${config.IMG_URL}/Driver/${driverdata?.Documents[0]?.Image}`}
                                        />
                                      ) : (
                                        <img
                                          className="mdv_license img-fluid"
                                          src={URL.createObjectURL(
                                            Files.License
                                          )}
                                        />
                                      )}
                                    </Col>
                                    <Col
                                      xxl={6}
                                      xl={6}
                                      lg={6}
                                      md={6}
                                      sm={6}
                                      xs={12}
                                      className="mb-3"
                                    >
                                      <div className="mdv_uploadfile">
                                        <img
                                          className="mdv_license img-fluid"
                                          src={require("../../../assests/images/cloud.png")}
                                        />
                                        <p className="mdv_uploadfile_txt">
                                          Upload File
                                        </p>
                                        <input
                                          type="file"
                                          className="mdv_fileupload"
                                          id="License"
                                          onChange={(e) => OnFileChange(e)}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </div>
                          </>
                        )}

                        {/* end of profile details */}

                        {/* delivered details */}
                        {activeTab == "delivered" && (
                          <div className="mdvsec_border ">
                            <div className="mdv_driverdtls_whole">
                              <div className="ffd_table">
                                <BootstrapTable
                                  bootstrap4
                                  keyField="id"
                                  columns={columns}
                                  data={data}
                                  pagination={pagination}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        {/* end of delivered details */}

                        {/* rejected details */}
                        {activeTab == "rejected" && (
                          <div className="">
                            <div className="mdvsec_border">
                              <div className=" mdv_driverdtls_whole">
                                <div className="ffd_table">
                                  <BootstrapTable
                                    bootstrap4
                                    keyField="id"
                                    columns={columns}
                                    data={data}
                                    pagination={pagination}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {/* end of rejected details */}

                        {/* ratings & reviews details */}

                        {activeTab == "rating" && (
                          <div className="row">
                            <div className="mdvsec_border ">
                              <div className="mdv_driverdtls_whole">
                                <div className="ffd_table">
                                  <BootstrapTable
                                    bootstrap4
                                    keyField="id"
                                    columns={reviewcolumns}
                                    data={reviewdata}
                                    pagination={pagination}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* end of ratings & reviews details */}

                        {/* change password details */}

                        {activeTab == "changepw" && (
                          <div className="mdv_profiledtls">
                            <div className="mdv_border_div">
                              <p className="mdv_profilettl">Change Password</p>
                            </div>
                            <Row className="mdv_inputrow">
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">New Password</p>
                                <input
                                  className="mdv_cmninput"
                                  type="password"
                                  placeholder="******"
                                />
                              </Col>
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">Confirm Password</p>
                                <input
                                  className="mdv_cmninput"
                                  type="password"
                                  placeholder="******"
                                />
                              </Col>

                              <div className="mdv_update_dualbtn">
                                <button className="blue_btn mdv_update">
                                  Update
                                </button>
                                <button className="seconday_btn">Cancel</button>
                              </div>
                            </Row>
                          </div>
                        )}
                      </div>

                      {/* end of change password details */}
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManagementDriverView;
