import React from "react";
import { DateTimeForm, isEmpty, isToday } from "../actions/common";

function OrderStatus(props) {
  const { Orderdata, OrderAction } = props;
  return (
    <>
      {Orderdata?.Time?.ApprovedBySeller && (
        <>
          <div className="rv_rowborder">
            <div className="ov_pendingbtn">
              <button className="blue_btn" disabled={true}>
                Accepted
              </button>
            </div>
            <p className="ov_shippingttl mt-2">Shipping Method</p>
            <div className="ov_rightdtls accepted">
              <div>
                <p className="ov_address">
                  {isToday(new Date(Orderdata?.Time?.ApprovedBySeller))
                    ? "Today"
                    : DateTimeForm(new Date(Orderdata?.DeliveryTime), true)}
                </p>
                <p className="ov_address mt-2">
                  {DateTimeForm(
                    new Date(Orderdata?.Time?.ApprovedBySeller),
                    undefined,
                    true,
                    true
                  )}
                </p>
              </div>
              <p className="value_datetime greentxt">{Orderdata?.OrderType}</p>
            </div>
            {Orderdata?.OrderType?.toLocaleLowerCase() == "delivery" && (
              <>
                <div className="">
                  <p className="ov_shippingttl mt-2">Delivery Address</p>
                  <p className="ov_shippingttl mt-2">
                    {Orderdata?.DeliveryAddress?.FullName}
                  </p>
                  {
                    isEmpty(Orderdata?.Address?.FullAddress)
                    ?
<p className="ov_address mt-1">
                    {Orderdata?.DeliveryAddress?.Doornumber},{" "}
                    {Orderdata?.DeliveryAddress?.Street},{" "}
                    {Orderdata?.DeliveryAddress?.City}{" "}
                    {Orderdata?.DeliveryAddress?.Zipcode},{" "}
                    {Orderdata?.DeliveryAddress?.Area}
                  </p>
                  :
                  <p className="ov_address mt-1">
                    {Orderdata?.Address?.FullAddress}
                  </p>

                  }
                  
                </div>
              </>
            )}
          </div>
          <br />
        </>
      )}

      {isEmpty(Orderdata?.Time?.ApprovedBySeller) &&
        isEmpty(Orderdata?.Time?.cancelbyseller) && (
          <div className="rv_rowborder">
            <div className="ov_pendingbtn">
              <button className="orange_btn">Approve Pending</button>
            </div>
            <p className="ov_shippingttl mt-2">Shipping Method</p>
            <div className="ov_rightdtls">
              <div>
                <p className="ov_address">Today</p>
                <p className="ov_address mt-2">8:30 PM - 9:00 PM</p>
              </div>
              <p className="value_datetime greentxt">Pick Up</p>
            </div>
          </div>
        )}

      {!isEmpty(Orderdata?.Time?.preparing) &&
        isEmpty(Orderdata?.Time?.cancelbyseller) && (
          <>
            {/* Orderdata?.Time?.cancelbyseller */}
            <div>
              <div className="rv_rowborder">
                <div className="ov_pendingbtn">
                  <button
                    className={
                      isEmpty(Orderdata?.Time?.Dispatch)
                        ? "orange_btn"
                        : "blue_btn"
                    }
                    disabled={isEmpty(Orderdata?.Time?.Dispatch) ? false : true}
                  >
                    {!isEmpty(Orderdata?.Time?.Dispatch)
                      ? "Order is Ready"
                      : "Preparing"}
                  </button>
                </div>
                <p className="ov_shippingttl mt-2">Shipping Method</p>
                <div className="ov_rightdtls accepted">
                  <div>
                    <p className="ov_address">Today</p>
                    <p className="ov_address mt-2">
                      {DateTimeForm(
                        new Date(Orderdata?.Time?.preparing),
                        undefined,
                        true,
                        true
                      )}
                    </p>
                  </div>
                  <p className="value_datetime greentxt">
                    {Orderdata?.OrderType}
                  </p>
                </div>
                {Orderdata?.OrderType?.toLocaleLowerCase() == "delivery" && (
                  <>
                    <p className="ov_shippingttl mt-2">Delpick Driver</p>
                    <div className="os_driverdtls">
                      <div className="os_drivernames">
                        {/* <img className='os_driverimg' src={require('../assests/images/drivers.png')}/> */}
                        <div>
                          <p className="ov_shippingttl m-0">
                            {Orderdata?.Driver?.FirstName}{" "}
                            {Orderdata?.Driver?.LastName}
                          </p>
                          <p className="ov_address">
                            {Orderdata?.Driver?.Mobile}
                          </p>
                        </div>
                      </div>
                      {/* <img className='ov_chatlogo' src={require('../assests/images/Chat.png')}/> */}
                    </div>
                  </>
                )}
                <div className="">
                  {Orderdata?.OrderType?.toLocaleLowerCase() == "delivery" && (
                    <>
                      <p className="ov_shippingttl mt-2">Delivery Address</p>
                      <p className="ov_shippingttl mt-2">
                        {Orderdata?.DeliveryAddress?.FullName}
                      </p>
                      <p className="ov_address mt-1">
                        {Orderdata?.DeliveryAddress?.Doornumber},{" "}
                        {Orderdata?.DeliveryAddress?.Street},{" "}
                        {Orderdata?.DeliveryAddress?.City}{" "}
                        {Orderdata?.DeliveryAddress?.Zipcode},{" "}
                        {Orderdata?.DeliveryAddress?.Area}
                      </p>
                    </>
                  )}

                  <div className="os_greentick_txt mt-1">
                    {/* <img className='me-2' src={require('../assests/images/greentick.png')}/> */}
                    <p className="ov_address">
                      Order will be{" "}
                      {Orderdata?.OrderType?.toLocaleLowerCase() == "delivery"
                        ? "delivered"
                        : "pickup"}{" "}
                      at{" "}
                      {DateTimeForm(
                        Orderdata?.DeliveryTime,
                        undefined,
                        true,
                        true
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </>
        )}

      {Orderdata?.Time?.cancelbyseller && (
        <>
          <div className="rv_rowborder">
            <p className="ov_shippingttl mt-2">Shipping Method</p>
            <div className="ov_rightdtls accepted">
              <div>
                <p className="ov_address">
                  {isToday(
                    new Date(Orderdata?.Time?.cancelbyseller),
                    undefined,
                    true,
                    true
                  )
                    ? "Today"
                    : DateTimeForm(
                        new Date(Orderdata?.Time?.DeliveryTime),
                        true
                      )}
                </p>
                <p className="ov_address mt-2">
                  {DateTimeForm(
                    new Date(Orderdata?.Time?.cancelbyseller),
                    undefined,
                    true,
                    true
                  )}
                </p>
              </div>
              <p className="value_datetime greentxt">{Orderdata?.OrderType}</p>
            </div>
            {Orderdata?.OrderType?.toLocaleLowerCase() == "delivery" && (
              <>
                <div className="">
                  <p className="ov_shippingttl mt-2">Delivery Address</p>
                  <p className="ov_shippingttl mt-2">
                    {Orderdata?.DeliveryAddress?.FullName}
                  </p>
                  <p className="ov_address mt-1">
                    {Orderdata?.DeliveryAddress?.Doornumber},{" "}
                    {Orderdata?.DeliveryAddress?.Street},{" "}
                    {Orderdata?.DeliveryAddress?.City}{" "}
                    {Orderdata?.DeliveryAddress?.Zipcode},{" "}
                    {Orderdata?.DeliveryAddress?.Area}
                  </p>
                </div>
              </>
            )}
            <div className="ov_pendingbtn">
              <button className="blue_btn" disabled={true}>
                Cancelled
              </button>
            </div>
          </div>
          <br />
        </>
      )}

      {((Orderdata?.Time?.Pickup &&
        Orderdata?.OrderType?.toLocaleLowerCase() == "delivery") ||
        Orderdata?.Time?.completed ||
        Orderdata?.Time?.returned) && (
        <>
          <div className="rv_rowborder">
            {Orderdata?.Status != "completed" ? (
              <div className="ov_pendingbtn">
                <button className="orange_btn">
                  {" "}
                  {Orderdata?.Status == "returned" ? "Returned" : "In transit"}
                </button>
              </div>
            ) : (
              <div className="ov_pendingbtn">
                <button className="green_btn">Completed</button>
              </div>
            )}
            <p className="ov_shippingttl mt-2">Shipping Method</p>
            <div className="ov_rightdtls accepted">
              <div>
                <p className="ov_address">
                  {Orderdata?.Time?.completed
                    ? "Delivered"
                    : Orderdata?.Time?.returned
                    ? "Returned"
                    : "Picked"}{" "}
                  {isToday(
                    new Date(
                      Orderdata?.Time?.completed
                        ? Orderdata?.Time?.completed
                        : Orderdata?.Time?.returned
                        ? Orderdata?.Time?.returned
                        : Orderdata?.Time?.Pickup
                    )
                  )
                    ? "Today"
                    : DateTimeForm(
                        new Date(
                          Orderdata?.Time?.completed
                            ? Orderdata?.Time?.completed
                            : Orderdata?.Time?.returned
                            ? Orderdata?.Time?.returned
                            : Orderdata?.Time?.Pickup
                        ),
                        true,
                        undefined,
                        undefined
                      )}
                </p>
                <p className="ov_address mt-2">
                  {DateTimeForm(
                    new Date(
                      Orderdata?.Time?.completed
                        ? Orderdata?.Time?.completed
                        : Orderdata?.Time?.returned
                        ? Orderdata?.Time?.returned
                        : Orderdata?.Time?.Pickup
                    ),
                    undefined,
                    true,
                    true
                  )}
                </p>
              </div>
              <p className="value_datetime greentxt">{Orderdata?.OrderType}</p>
            </div>

            <p className="ov_shippingttl mt-2">Delpick Driver</p>
            <div className="os_driverdtls">
              <div className="os_drivernames">
                {/* <img className='os_driverimg' src={require('../assests/images/drivers.png')}/> */}
                <div>
                  <p className="ov_shippingttl m-0">
                    {Orderdata?.Driver?.FirstName} {Orderdata?.Driver?.LastName}
                  </p>
                  <p className="ov_address">{Orderdata?.Driver?.Mobile}</p>
                </div>
              </div>
              {/* <img className='ov_chatlogo' src={require('../assests/images/Chat.png')}/> */}
            </div>
            <div className="">
              {Orderdata?.OrderType == "delivery" && (
                <>
                  <p className="ov_shippingttl mt-2">Delivery Address</p>
                  <p className="ov_shippingttl mt-2">
                    {Orderdata?.DeliveryAddress?.FullName}
                  </p>
                  <p className="ov_address mt-1">
                    {Orderdata?.DeliveryAddress?.Doornumber},{" "}
                    {Orderdata?.DeliveryAddress?.Street},{" "}
                    {Orderdata?.DeliveryAddress?.City}{" "}
                    {Orderdata?.DeliveryAddress?.Zipcode},{" "}
                    {Orderdata?.DeliveryAddress?.Area}
                  </p>
                </>
              )}
              <div className="os_greentick_txt mt-1">
                <p className="ov_address">
                  {Orderdata.Status == "completed" ||
                  Orderdata.Status == "returned" ? (
                    <>
                      Order{" "}
                      {Orderdata.Status == "returned"
                        ? "returned"
                        : Orderdata?.OrderType?.toLocaleLowerCase() ==
                          "delivery"
                        ? "delivered"
                        : "pickup"}{" "}
                      at{" "}
                      {DateTimeForm(
                        Orderdata?.Time?.completed
                          ? Orderdata?.Time?.completed
                          : Orderdata?.Time?.returned,
                        undefined,
                        true,
                        true
                      )}
                    </>
                  ) : (
                    <>
                      Order will be{" "}
                      {Orderdata?.OrderType?.toLocaleLowerCase() == "delivery"
                        ? "delivered"
                        : "pickup"}{" "}
                      at{" "}
                      {DateTimeForm(
                        Orderdata?.DeliveryTime,
                        undefined,
                        true,
                        true
                      )}
                    </>
                  )}
                </p>
              </div>
              {/* <div className="os_greentick_txt mt-1">
                
                <p className="ov_address">
                  Order will be{" "}
                  {Orderdata?.OrderType == "Delivery" ? "delivered" : "pickup"}{" "}
                  at{" "}
                  {DateTimeForm(Orderdata?.DeliveryTime,undefined,true,true)}
                </p>
              </div> */}
            </div>
          </div>
          <br />
        </>
      )}

      {/* { ((Orderdata?.Time?.Pickup && Orderdata?.OrderType == "Delivery") || Orderdata?.Time?.completed ) && (
        <>
          <div className="rv_rowborder">
            <div className="ov_pendingbtn">
              <button className="green_btn">Completed</button>
            </div>
            <p className="ov_shippingttl mt-2">Shipping Method</p>
            <div className="ov_rightdtls accepted">
              <div>
                <p className="ov_address">
                  {isToday(new Date(Orderdata?.Time?.completed))
                    ? "Today"
                    : DateTimeForm(Orderdata?.Time?.completed)}
                </p>
                <p className="ov_address mt-2">
                  {DateTimeForm(new Date(Orderdata?.Time?.completed),undefined,true,true)}
                </p>
              </div>
              <p className="value_datetime greentxt">{Orderdata?.OrderType}</p>
            </div>
            {
              Orderdata?.Driver?._id &&
              <>
              <p className="ov_shippingttl mt-2">Jamske Driver</p>
            <div className="os_driverdtls">
              <div className="os_drivernames">
                <div>
                  <p className="ov_shippingttl m-0">{Orderdata?.Driver?.FirstName} {Orderdata?.Driver?.LastName}</p>
                  <p className="ov_address">{Orderdata?.Driver?.Mobile}</p>
                </div>
              </div>
            </div>
              </>
            }
            
            <div className="">
              {Orderdata?.OrderType == "delivery" && (
                <>
                  <p className="ov_shippingttl mt-2">Delivery Address</p>
                  <p className="ov_shippingttl mt-2">
                    {Orderdata?.DeliveryAddress?.FullName}
                  </p>
                  <p className="ov_address mt-1">
                    {Orderdata?.DeliveryAddress?.FullName}
                  </p>
                </>
              )}
              <div className="os_greentick_txt mt-1">
                <p className="ov_address">
                  Order{" "}
                  {Orderdata?.OrderType == "Delivery" ? "delivered" : "pickup"}{" "}
                  at{" "}
                  {DateTimeForm(Orderdata?.Time?.completed,undefined,true,true)}
                </p>
              </div>
            </div>
          </div>
        </>
      )} */}

      {/* <div className='rv_rowborder'>
                <div className='ov_pendingbtn'>
                    <button className='orange_btn'>Pending</button>
                </div>
                <p className='ov_shippingttl mt-2'>Shipping Method</p>
                <div className='ov_rightdtls'>
                    <div>
                        <p className='ov_address'>Today</p>
                        <p className='ov_address mt-2'>8:30 PM - 9:00 PM</p>
                    </div>
                    <p className='value_datetime greentxt'>Pick Up</p>

                </div>
                </div>

                <div className='rv_rowborder'>
                <div className='ov_pendingbtn'>
                    <button className='blue_btn'>Accepted</button>
                </div>
                <p className='ov_shippingttl mt-2'>Shipping Method</p>
                <div className='ov_rightdtls accepted'>
                    <div>
                        <p className='ov_address'>Today</p>
                        <p className='ov_address mt-2'>8:30 PM - 9:00 PM</p>
                    </div>
                    <p className='value_datetime greentxt'>Pick Up</p>

                </div>
                <div className=''>
                <p className='ov_shippingttl mt-2'>Delivery Address</p>
                <p className='ov_shippingttl mt-2'>John Doe</p>
                <p className='ov_address mt-1'>1180 6th Ave, New York, NY 10036, USA</p>

                </div>
                </div>




                <div>

                <div className='rv_rowborder'>
                <div className='ov_pendingbtn'>
                    <button className='lightblue_btn'>Order is Ready</button>
                </div>
                <p className='ov_shippingttl mt-2'>Shipping Method</p>
                <div className='ov_rightdtls accepted'>
                    <div>
                        <p className='ov_address'>Today</p>
                        <p className='ov_address mt-2'>8:30 PM - 9:00 PM</p>
                    </div>
                    <p className='value_datetime greentxt'>Pick Up</p>

                </div>
                
                <p className='ov_shippingttl mt-2'>Jamske Driver</p>
                <div className='os_driverdtls'>
                    <div className='os_drivernames'>
                <img className='os_driverimg' src={require('../assests/images/drivers.png')}/>
                <div>
                <p className='ov_shippingttl m-0'>Harry P. Mills</p>
                <p className='ov_address'>+1 321 654 9870</p>
                </div>
                </div>
                <img className='ov_chatlogo' src={require('../assests/images/Chat.png')}/>
                </div>
                <div className=''>
                <p className='ov_shippingttl mt-2'>Delivery Address</p>
                <p className='ov_shippingttl mt-2'>John Doe</p>
                <p className='ov_address mt-1'>1180 6th Ave, New York, NY 10036, USA</p>

                <div className='os_greentick_txt mt-1'>
                    <img className='me-2' src={require('../assests/images/greentick.png')}/>
                    <p className='ov_address'>Order will be deliverd at 10th june, 8:20PM</p>
                </div>

                </div>
                </div>

                <div className='rv_rowborder mt-3'>
                <p className='ov_shippingttl'>Order Package Number</p>

                <div className='os_greendrids'>
                    <div className='os_gridgreendtl'>
                        <p>58426</p>
                    </div>
                    <div className='os_gridgreendtl'>
                        <p>58426</p>
                    </div>
                    <div className='os_gridgreendtl'>
                        <p>58426</p>
                    </div>
                    <div className='os_gridgreendtl'>
                        <p>58426</p>
                    </div>
                </div>
                <p className='ov_shippingttl mt-2'>Seal Pack Packages Images</p>
                <div className='os_greendrids'>
                    <img className='os_packetimage' src={require('../assests/images/packet1.png')} />
                    <img className='os_packetimage' src={require('../assests/images/packet1.png')} />
                    <img className='os_packetimage' src={require('../assests/images/packet1.png')} />
                    <img className='os_packetimage' src={require('../assests/images/packet1.png')} />
                </div>
                </div>



                </div>




                <div>

                <div className='rv_rowborder'>
                <div className='ov_pendingbtn'>
                    <button className='orange_btn'>In transit</button>
                </div>
                <p className='ov_shippingttl mt-2'>Shipping Method</p>
                <div className='ov_rightdtls accepted'>
                    <div>
                        <p className='ov_address'>Today</p>
                        <p className='ov_address mt-2'>8:30 PM - 9:00 PM</p>
                    </div>
                    <p className='value_datetime greentxt'>Pick Up</p>

                </div>
                
                <p className='ov_shippingttl mt-2'>Jamske Driver</p>
                <div className='os_driverdtls'>
                    <div className='os_drivernames'>
                <img className='os_driverimg' src={require('../assests/images/drivers.png')}/>
                <div>
                <p className='ov_shippingttl m-0'>Harry P. Mills</p>
                <p className='ov_address'>+1 321 654 9870</p>
                </div>
                </div>
                <img className='ov_chatlogo' src={require('../assests/images/Chat.png')}/>
                </div>
                <div className=''>
                <p className='ov_shippingttl mt-2'>Delivery Address</p>
                <p className='ov_shippingttl mt-2'>John Doe</p>
                <p className='ov_address mt-1'>1180 6th Ave, New York, NY 10036, USA</p>

                <div className='os_greentick_txt mt-1'>
                    <img className='me-2' src={require('../assests/images/greentick.png')}/>
                    <p className='ov_address'>Order will be deliverd at 10th june, 8:20PM</p>
                </div>

                </div>
                </div>

                <div className='rv_rowborder mt-3'>
                <p className='ov_shippingttl'>Order Package Number</p>

                <div className='os_greendrids'>
                    <div className='os_gridgreendtl'>
                        <p>58426</p>
                    </div>
                    <div className='os_gridgreendtl'>
                        <p>58426</p>
                    </div>
                    <div className='os_gridgreendtl'>
                        <p>58426</p>
                    </div>
                    <div className='os_gridgreendtl'>
                        <p>58426</p>
                    </div>
                </div>
                <p className='ov_shippingttl mt-2'>Seal Pack Packages Images by Himalayan’s Cafe</p>
                <div className='os_greendrids'>
                    <img className='os_packetimage' src={require('../assests/images/packet1.png')} />
                    <img className='os_packetimage' src={require('../assests/images/packet1.png')} />
                    <img className='os_packetimage' src={require('../assests/images/packet1.png')} />
                    <img className='os_packetimage' src={require('../assests/images/packet1.png')} />
                </div>

                <p className='ov_shippingttl mt-2'>Seal Pack Packages Images by John malalla doe</p>
                <div className='os_greendrids'>
                    <img className='os_packetimage' src={require('../assests/images/packet1.png')} />
                    <img className='os_packetimage' src={require('../assests/images/packet1.png')} />
                    <img className='os_packetimage' src={require('../assests/images/packet1.png')} />
                    <img className='os_packetimage' src={require('../assests/images/packet1.png')} />
                </div>
                </div>



                </div>




                <div>

                <div className='rv_rowborder'>
                <div className='ov_pendingbtn'>
                    <button className='green_btn'>Completed</button>
                </div>
                <p className='ov_shippingttl mt-2'>Shipping Method</p>
                <div className='ov_rightdtls accepted'>
                    <div>
                        <p className='ov_address'>Today</p>
                        <p className='ov_address mt-2'>8:30 PM - 9:00 PM</p>
                    </div>
                    <p className='value_datetime greentxt'>Pick Up</p>

                </div>
                
                <p className='ov_shippingttl mt-2'>Jamske Driver</p>
                <div className='os_driverdtls'>
                    <div className='os_drivernames'>
                <img className='os_driverimg' src={require('../assests/images/drivers.png')}/>
                <div>
                <p className='ov_shippingttl m-0'>Harry P. Mills</p>
                <p className='ov_address'>+1 321 654 9870</p>
                </div>
                </div>
                <img className='ov_chatlogo' src={require('../assests/images/Chat.png')}/>
                </div>
                <div className=''>
                <p className='ov_shippingttl mt-2'>Delivery Address</p>
                <p className='ov_shippingttl mt-2'>John Doe</p>
                <p className='ov_address mt-1'>1180 6th Ave, New York, NY 10036, USA</p>

                <div className='os_greentick_txt mt-1'>
                    <img className='me-2' src={require('../assests/images/greentick.png')}/>
                    <p className='ov_address'>Order will be deliverd at 10th june, 8:20PM</p>
                </div>

                </div>
                </div>

                <div className='rv_rowborder mt-3'>
                <p className='ov_shippingttl'>Order Package Number</p>

                <div className='os_greendrids'>
                    <div className='os_gridgreendtl'>
                        <p>58426</p>
                    </div>
                    <div className='os_gridgreendtl'>
                        <p>58426</p>
                    </div>
                    <div className='os_gridgreendtl'>
                        <p>58426</p>
                    </div>
                    <div className='os_gridgreendtl'>
                        <p>58426</p>
                    </div>
                </div>
                <p className='ov_shippingttl mt-2'>Seal Pack Packages Images by Himalayan’s Cafe</p>
                <div className='os_greendrids'>
                    <img className='os_packetimage' src={require('../assests/images/packet1.png')} />
                    <img className='os_packetimage' src={require('../assests/images/packet1.png')} />
                    <img className='os_packetimage' src={require('../assests/images/packet1.png')} />
                    <img className='os_packetimage' src={require('../assests/images/packet1.png')} />
                </div>

                <p className='ov_shippingttl mt-2'>Seal Pack Packages Images by John malalla doe</p>
                <div className='os_greendrids'>
                    <img className='os_packetimage' src={require('../assests/images/packet1.png')} />
                    <img className='os_packetimage' src={require('../assests/images/packet1.png')} />
                    <img className='os_packetimage' src={require('../assests/images/packet1.png')} />
                    <img className='os_packetimage' src={require('../assests/images/packet1.png')} />
                </div>

                <p className='ov_shippingttl mt-2'>Seal Pack Packages Images by John Doe</p>
                <div className='os_greendrids'>
                    <img className='os_packetimage' src={require('../assests/images/packet1.png')} />
                    <img className='os_packetimage' src={require('../assests/images/packet1.png')} />
                    <img className='os_packetimage' src={require('../assests/images/packet1.png')} />
                    <img className='os_packetimage' src={require('../assests/images/packet1.png')} />
                </div>
                </div>



                </div> */}
    </>
  );
}

export default OrderStatus;
