import React, { useEffect, useState, useMemo } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "../src/assests/css/styles.css";

import { LoginRoutes } from "./routes/LoginRoutes";
import { PublicRoutes } from "./routes/PublicRoutes";
import useAxiosFile from "./actions/useaxios";
import { DecryptData, EncryptData, isEmpty } from "./actions/common";

function App() {
  const usertoken = ("; " + document.cookie)
    .split(`; token_a=`)
    .pop()
    .split(";")[0]
    ? ("; " + document.cookie).split(`; token_a=`).pop().split(";")[0]
    : "";

  const { admin_panel, payload, token } = useSelector(
    (state) => state.admin_panel.User
  );
  const dispatch = useDispatch();

  const axiosfile = useAxiosFile();

  const _id_a = localStorage.getItem("_id_a"),
    userAuth = localStorage.getItem("userAuth");
  const HavAccess = !isEmpty(_id_a)
    ? DecryptData(_id_a)
    : payload?._id
    ? payload?._id
    : DecryptData(_id_a);
  console.log("ksfjsfksjkfs", _id_a, HavAccess);

  const [Auth, SetAuth] = useState(usertoken ? usertoken : false);
  useEffect(() => {
    SetAuth(usertoken ? usertoken : token);
    console.log("lsjfklsfsfsf", HavAccess);
    if (!isEmpty(HavAccess) && HavAccess != "undefined") {
      AlreadyExits(HavAccess);
    } else if (HavAccess == "undefined") {
      localStorage.clear();
      document.cookie =
        "token_a" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    } else {
      dispatch({
        type: "Login",
        Register_Section: { App_Inside: true },
      });
    }
  }, [token]);

  const AlreadyExits = async (HavAccess) => {
    console.log("kjlkfdsfd", HavAccess, DecryptData(HavAccess));
    await axiosfile.AlreadyExits({ data: EncryptData({ _id: HavAccess }) });
  };
  console.log("Auth&&token", Auth, "============", token);
  return useMemo(() => {
    return (
      <BrowserRouter basename="/">
        <Routes>
          {LoginRoutes(Auth)} {PublicRoutes(Auth, token)}
        </Routes>
      </BrowserRouter>
    );
  }, [Auth, token]);
}

export default App;
