import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Dropdown from "react-bootstrap/Dropdown";
import { HiOutlineBell } from "@react-icons/all-files/hi/HiOutlineBell";
import pf_img from "../assests/images/profile.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import starImg from "../assests/images/Union.png";
import deliveryPersonOne from "../assests/images/deliveryPersonOne.png";
import badgeOne from "../assests/images/badgeFirst.png";

import delpick from "../assests/images/delpick.png";

export default function Header() {
  const navigate = useNavigate();

  const [smShow, setSmShow] = useState(false);

  const { token, payload } = useSelector((state) => state.admin_panel.User);
  const dispatch = useDispatch();

  const logout = () => {
    document.cookie = "token_a" + "=" + "" + ";" + ";path=/";
    localStorage.removeItem("Login");
    dispatch({
      type: "Logout",
    });
    navigate("/");
  };

  console.log("kdjsjhfsfs", token, payload);

  return (
    <>
      <div className="headers_pages">
        <Navbar expand="lg" className="bg-body-tertiary">
          <Container className="custom-container">
            {/* <Navbar.Brand to="/"> */}
            <Link to="/dashboard">
              {/* <img
                src={require("../assests/images/logo_admin.png")}
                alt="logo"
                width="100%"
              /> */}
              <img src={delpick} alt="logo " className="delpick_logo" />
            </Link>
            {/* </Navbar.Brand> */}
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                {/* <div class="search-input">
                <span class="fa fa-search"></span>
                <input placeholder="Search term" />
              </div> */}

                <div className="d-flex flex-column flex-lg-row mt-sm-4  mt-lg-0 gap-3">
                  <div className="d-flex align-items-center justify-content-between mt-3 mt-lg-0">
                    <div className="whole_searchbar   search_border_none">
                      <img
                        className="search_glass"
                        src={require("../assests/images/Search.png")}
                      />
                      <input placeholder="Search term" />
                    </div>
                    {/* <Dropdown className="">
                    <Dropdown.Toggle id="dropdown-basic" className="noti_btn">
                      <HiOutlineBell />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">
                        Another action
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Something else
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}

                    <div
                      className="noti_btn d-flex align-items-center justify-content-center ms-3"
                      onClick={() => setSmShow(true)}
                    >
                      <HiOutlineBell className="fs-5" />
                    </div>
                  </div>

                  <div className="pf_drop">
                    <div className="d-flex align-items-center">
                      <img src={pf_img} className="pf_img" alt="pf_img" />
                    </div>
                    <NavDropdown
                      title={payload?.username}
                      id="basic-nav-dropdown"
                      className="logout_wrap"
                    >
                      <NavDropdown.Item onClick={() => logout("token")}>
                        Logout
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

      <div>
        {/* <Button  className="me-2">
          Small modal
        </Button> */}
        <Modal
          size="md"
          show={smShow}
          onHide={() => setSmShow(false)}
          aria-labelledby="example-modal-sizes-title-sm"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm d-flex align-items-center justify-content-between">
              <p className="modal_tle">Top Delivery Boy</p>
              <NavLink to="#" className="modal_lnk">
                See All
              </NavLink>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal_body pt-0">
            <div className="row mx-auto dlvry_row pb-3 pt-3">
              <div className="col-2 ps-0 position-relative">
                <div className="dlvry_person_dls ">
                  <img
                    src={deliveryPersonOne}
                    alt="rank1"
                    className="img-fluid"
                  />
                </div>
                <img
                  src={badgeOne}
                  alt="badge First"
                  className="img-fluid position-absolute badge_img"
                />
              </div>
              <div className="col-10 pe-0">
                <div className="row mx-auto ">
                  <div className="col-5">
                    <p className="mb-0 p-0 fw-bold dlvry_person_name">
                      John Doe
                    </p>
                  </div>
                  <div className="col-7">
                    <div className="d-flex align-items-center justify-content-end dlvry_person_rvw">
                      <img src={starImg} alt="review" className="img-fluid" />
                      <p className="mb-0 p-0 ms-2 fw-bold">
                        4.5 <span>(165)</span>
                      </p>
                    </div>
                  </div>
                  <div className="col-5 dlvry_person_exp mt-2">
                    <p className="mb-0 p-0 ">Experience</p>
                  </div>
                  <div className="col-7 text-end mt-2 dlvry_person_exp">
                    <p className="mb-0 p-0">Price Range(last 24M)</p>
                  </div>
                  <div className="col-5 mt-2 dlvry_person_exp_dls">
                    <p className="mb-0 p-0">20 years</p>
                  </div>
                  <div className="col-7 text-end mt-2 dlvry_person_exp_dls">
                    <p className="mb-0 p-0">$225k - $4M</p>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mx-auto dlvry_row pb-3 pt-3">
              <div className="col-2 ps-0 position-relative">
                <div className="dlvry_person_dls ">
                  <img
                    src={deliveryPersonOne}
                    alt="rank1"
                    className="img-fluid"
                  />
                </div>
                <img
                  src={badgeOne}
                  alt="badge First"
                  className="img-fluid position-absolute badge_img"
                />
              </div>
              <div className="col-10 pe-0">
                <div className="row mx-auto ">
                  <div className="col-5">
                    <p className="mb-0 p-0 fw-bold dlvry_person_name">
                      John Doe
                    </p>
                  </div>
                  <div className="col-7">
                    <div className="d-flex align-items-center justify-content-end dlvry_person_rvw">
                      <img src={starImg} alt="review" className="img-fluid" />
                      <p className="mb-0 p-0 ms-2 fw-bold">
                        4.5 <span>(165)</span>
                      </p>
                    </div>
                  </div>
                  <div className="col-5 dlvry_person_exp mt-2">
                    <p className="mb-0 p-0 ">Experience</p>
                  </div>
                  <div className="col-7 text-end mt-2 dlvry_person_exp">
                    <p className="mb-0 p-0">Price Range(last 24M)</p>
                  </div>
                  <div className="col-5 mt-2 dlvry_person_exp_dls">
                    <p className="mb-0 p-0">20 years</p>
                  </div>
                  <div className="col-7 text-end mt-2 dlvry_person_exp_dls">
                    <p className="mb-0 p-0">$225k - $4M</p>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mx-auto dlvry_row pb-3 pt-3">
              <div className="col-2 ps-0 position-relative">
                <div className="dlvry_person_dls ">
                  <img
                    src={deliveryPersonOne}
                    alt="rank1"
                    className="img-fluid"
                  />
                </div>
                <img
                  src={badgeOne}
                  alt="badge First"
                  className="img-fluid position-absolute badge_img"
                />
              </div>
              <div className="col-10 pe-0">
                <div className="row mx-auto ">
                  <div className="col-5">
                    <p className="mb-0 p-0 fw-bold dlvry_person_name">
                      John Doe
                    </p>
                  </div>
                  <div className="col-7">
                    <div className="d-flex align-items-center justify-content-end dlvry_person_rvw">
                      <img src={starImg} alt="review" className="img-fluid" />
                      <p className="mb-0 p-0 ms-2 fw-bold">
                        4.5 <span>(165)</span>
                      </p>
                    </div>
                  </div>
                  <div className="col-5 dlvry_person_exp mt-2">
                    <p className="mb-0 p-0 ">Experience</p>
                  </div>
                  <div className="col-7 text-end mt-2 dlvry_person_exp">
                    <p className="mb-0 p-0">Price Range(last 24M)</p>
                  </div>
                  <div className="col-5 mt-2 dlvry_person_exp_dls">
                    <p className="mb-0 p-0">20 years</p>
                  </div>
                  <div className="col-7 text-end mt-2 dlvry_person_exp_dls">
                    <p className="mb-0 p-0">$225k - $4M</p>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mx-auto dlvry_row pb-3 pt-3">
              <div className="col-2 ps-0 position-relative">
                <div className="dlvry_person_dls ">
                  <img
                    src={deliveryPersonOne}
                    alt="rank1"
                    className="img-fluid"
                  />
                </div>
                <img
                  src={badgeOne}
                  alt="badge First"
                  className="img-fluid position-absolute badge_img"
                />
              </div>
              <div className="col-10 pe-0">
                <div className="row mx-auto ">
                  <div className="col-5">
                    <p className="mb-0 p-0 fw-bold dlvry_person_name">
                      John Doe
                    </p>
                  </div>
                  <div className="col-7">
                    <div className="d-flex align-items-center justify-content-end dlvry_person_rvw">
                      <img src={starImg} alt="review" className="img-fluid" />
                      <p className="mb-0 p-0 ms-2 fw-bold">
                        4.5 <span>(165)</span>
                      </p>
                    </div>
                  </div>
                  <div className="col-5 dlvry_person_exp mt-2">
                    <p className="mb-0 p-0 ">Experience</p>
                  </div>
                  <div className="col-7 text-end mt-2 dlvry_person_exp">
                    <p className="mb-0 p-0">Price Range(last 24M)</p>
                  </div>
                  <div className="col-5 mt-2 dlvry_person_exp_dls">
                    <p className="mb-0 p-0">20 years</p>
                  </div>
                  <div className="col-7 text-end mt-2 dlvry_person_exp_dls">
                    <p className="mb-0 p-0">$225k - $4M</p>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mx-auto dlvry_row pb-3 pt-3">
              <div className="col-2 ps-0 position-relative">
                <div className="dlvry_person_dls ">
                  <img
                    src={deliveryPersonOne}
                    alt="rank1"
                    className="img-fluid"
                  />
                </div>
                <img
                  src={badgeOne}
                  alt="badge First"
                  className="img-fluid position-absolute badge_img"
                />
              </div>
              <div className="col-10 pe-0">
                <div className="row mx-auto ">
                  <div className="col-5">
                    <p className="mb-0 p-0 fw-bold dlvry_person_name">
                      John Doe
                    </p>
                  </div>
                  <div className="col-7">
                    <div className="d-flex align-items-center justify-content-end dlvry_person_rvw">
                      <img src={starImg} alt="review" className="img-fluid" />
                      <p className="mb-0 p-0 ms-2 fw-bold">
                        4.5 <span>(165)</span>
                      </p>
                    </div>
                  </div>
                  <div className="col-5 dlvry_person_exp mt-2">
                    <p className="mb-0 p-0 ">Experience</p>
                  </div>
                  <div className="col-7 text-end mt-2 dlvry_person_exp">
                    <p className="mb-0 p-0">Price Range(last 24M)</p>
                  </div>
                  <div className="col-5 mt-2 dlvry_person_exp_dls">
                    <p className="mb-0 p-0">20 years</p>
                  </div>
                  <div className="col-7 text-end mt-2 dlvry_person_exp_dls">
                    <p className="mb-0 p-0">$225k - $4M</p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
