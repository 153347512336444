import { combineReducers } from 'redux';
export  const Login = 'Login';
export  const Logout = 'Logout';

const initial_login = {
    User:{
        token : '',
        payload : null,
        logincheck: false,
        toggle: false,
    },
    App_Inside:false,
}



//
function admin_panel(state=initial_login, action) {
    
    switch (action.type) {
        case Login: {
            //

            return {
                ...state,
                ...action.Register_Section
            }
        };
        case Logout:
            return {
                ...state,
                ...initial_login
            };
        default: {
            //
            return state;

        }
  }
}
const birdApp = combineReducers({
    admin_panel
      });
      
      export default birdApp;