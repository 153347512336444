import React, { useEffect, useState, useCallback } from "react";
import Sidebar from '../../../layouts/sidebar'
import Headers from '../../../layouts/header'
import { Nav, Tab, Row, Col } from "react-bootstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-datetime/css/react-datetime.css";
import { useNavigate } from "react-router-dom";
import useAxiosFile from "../../../actions/useaxios";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {  useHistory } from "react-router-dom";
import { isEmpty } from "../../../actions/common";
import { useParams, useLocation } from "react-router-dom";
import config from "../../../actions/config";
import { DecryptData, EncryptData } from '../../../actions/common';
import { useSelector } from "react-redux";

function CmslistEdit() {


  const { toggle } = useSelector((state) => state.admin_panel.User);

    const axiosFile = useAxiosFile();
    const navigate = useNavigate();
    const initialState = {
      Categoryname: "",
      CategoryImage: "",
    };
    const [formValue, setFormValue] = useState(initialState);
    const [ValidateError, SetValidateError] = useState("");
    
    const [imageVal, setImageVal] = useState("");
    const [onchangeimg, setOnchangeimg] = useState("");
    // const { Id } = useParams();
    const [Id, Setid] = useState()
    const location = useLocation();
    console.log("xcvxcvxcv", Id);
    const { Categoryname, CategoryImage } = formValue;
    
  
   
  
    const geteditcat = async () => {
      var sendata = {
        id: isEmpty(DecryptData(Id)) ? Id : DecryptData(Id),
      };
      var resp = await axiosFile.getcat(sendata);
      
      setFormValue({ ...formValue, ...resp?.data });
    };
    useEffect(() => {
      if (Id) geteditcat();
    }, [Id]);
    const onChange = (e) => {
      SetValidateError({});
      const { value, id } = e.target;
      var formData = { ...formValue, ...{ [id]: value } };
      setFormValue(formData);
    };
  
    useEffect(() => {
      console.log('kjsfsjfs', location.search.split('?Id='))
      if (location?.search) {
          let paramdata = location.search.split('?Id=')[1];
          console.log('llsflklfk', DecryptData(paramdata))
          Setid(isEmpty(DecryptData(paramdata)) ? paramdata : DecryptData(paramdata))
      }
  }, [location?.search])
  
    const Validation = useCallback(
      (data) => {
        // console("validation Checking", data)
        
        
        // 
        let ValidateError = {};
        if (!data.CategoryImage)
          ValidateError.CategoryImage = "CategoryImage  Required";
        if (!data.Categoryname)
          ValidateError.Categoryname = "CategoryName Required";
        SetValidateError(ValidateError);
        return ValidateError;
      },
      [ValidateError]
    );
  
    const AddMainCategory = async () => {
      let error = Validation(formValue);
      if (!isEmpty(error)) {
        SetValidateError(error);
      }
      if (Id) {
        var SendData = {
          Categoryname: formValue.Categoryname,
          CategoryImage: formValue.CategoryImage,
          CategoryId: Id,
          action: "edit",
        };
        var resp = await axiosFile.AddMainCategory(SendData);
        
        if (resp) {
          navigate("/Categorylist");
          toast.success(resp?.msg)
        }
      } else {
        var SendData = {
          Categoryname: formValue.Categoryname,
          CategoryImage: formValue.CategoryImage,
          action: "add",
        };
        var resp = await axiosFile.AddMainCategory(SendData);
        
        // setoder(resp)
        if (resp) {
          navigate("/Categorylist");
          toast.success(resp?.msg)
        }
      }
    };
  
  
    const handleFile = (event) => {
      event.preventDefault();
      var reader = new FileReader();
      
      const { id, files } = event.target;
      
      if (event.target.files && event.target.files[0]) {
        var file = event.target.files[0];
        
        setImageVal(file);
        var url = reader.readAsDataURL(file);
        reader.onloadend = function (e) {
          
          if (reader.result) {
            setOnchangeimg(reader.result);
          }
        };
      }
      let formData = { ...formValue, ...{ [id]: files[0] } };
      setFormValue(formData);
    };
  return (
    <>

    <div>

        <div className='innerpages'>
            <div className='orders_pages'>
                <Headers />
                <div className='container custom_container px-lg-0'>
                    <div className='row'>
                        <div className='innerpages_wid px-0'>
                            <Sidebar />
                              <div
                                className={
                                  toggle
                                  ? "content-sec-inner right_widthset content_without_sidebar"
                                  : "content-sec-inner  content_with_sidebar"
                                }
                              >
                                <section id="content-wrapper">

                                    <div className='top_title_sec'>
                                        <div>
                                            <p className='page_title'>Edit CMS</p>
                                        </div>
                                        {/* <div className='rightsec'>


<button type="button" className='btn primary-style export_csv'>
<Exportcsv csvData={exportdata} fileName={"Category"}/>
</button>



</div> */}
                                    </div>
                                    <div className='sec_border'>
                                    <Row className="mdv_inputrow">
        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12} className="mb-3">
          <p className="mdv_inplabel"> CategoryName</p>
          <input
            className="mdv_cmninput"
            id="Categoryname"
            value={Categoryname}
            type="text"
            placeholder="John"
            onChange={onChange}
          />
          {ValidateError && ValidateError.Categoryname && (
            <span  className='error-msg'>{ValidateError.Categoryname}</span>
          )}
        </Col>

        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12} className="mb-3">
                                                        <p className='mdv_inplabel'>CategoryImage</p>

                                                        <div className='flat_upload_img'>
                                                            <img className='flat_cloudimg' src={require('../../../assests/images/cloud.png')} />
                                                            <p className='flat_uploadtxt'>Upload Image</p>
                                                            <input className="flat_uploadfile" id="CategoryImage" onChange={(e) => handleFile(e)} type='file' />
                                                        </div>

                                                    </Col>
        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12} className="mb-3">
          
          {onchangeimg ? (
            <img
              className="flat_uploadedimg"
              src={
                onchangeimg
                  ? onchangeimg
                  : require("../../../assests/images/cloud.png")
              }
            />
          ) : (
            <img
              className="flat_uploadedimg"
              src={
                CategoryImage
                  ? `${config.IMG_URL}/admin/Category/${CategoryImage}`
                  : require("../../../assests/images/cloud.png")
              }
            />
          )}
          {/* <input
            type="file"
            id="CategoryImage"
            className=""
            
          /> */}
          {ValidateError && ValidateError.CategoryImage && (
            <span className='error-msg'>{ValidateError.CategoryImage}</span>
          )}
        </Col>
        {/* <Col xxl={4} xl={4} lg={4} md={6} sm={6} xs={12} className="mb-3">
                <div className='mvv_uploadfile_dtl'>
            <img className='img-fluid' src={require('../../../assests/images/cloud.png')}/>
            <p className='mvv_cloudtext'>Upload File</p>
            <input type='file' id="StoreImages" className='mvv_fileupload' onChange={(e) => handleFile(e)}/>
            </div>
            </Col> */}
      </Row>
      <div className="mdv_update_dualbtn mt-3">
        <button
          className="blue_btn mdv_update"
          onClick={() => AddMainCategory()}
        >
          save
        </button>
      </div>
                                    </div>





                                </section>
                            </div>
                        </div>

                    </div>
                </div>




            </div>
        </div>

    </div>

</>
  )
}

export default CmslistEdit