import React, { useEffect, useState } from "react";
import Sidebar from "../../layouts/sidebar";
import Headers from "../../layouts/header";

import "react-datetime/css/react-datetime.css";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import useAxiosFile from "../../actions/useaxios";
import { useSelector } from "react-redux";

import { useNavigate } from "react-router";
import { LoadToast, updateToast } from "../../actions/common";

function detaillist() {
  const navigate = useNavigate();
  const [dtlists, setDtlists] = useState([]);
  const { toggle } = useSelector((state) => state.admin_panel.User);
  const axiosFile = useAxiosFile();

  useEffect(() => {
    showdata();
  }, []);

  const showdata = async () => {
    console.log("ddddd");
    var data = await axiosFile.showdata();
    console.log("vvvv", data);
    setDtlists(data?.data);
  };

  const submitbtn = async (cell) => {
    console.log("rtg", cell);
    navigate(`/detailadd/?id=${cell._id}`);
  };

  const clickbtn = async (cell) => {
    const id = LoadToast();
    console.log("ccc", cell);
    const updatedata = {
      _id: cell._id,
      action: "statusupdate",
      status: true,
    };
    var data = await axiosFile.detailinsert(updatedata);
    console.log("ttttttttrue", data);
    updateToast(id, data.msg, data.success);
    if (data?.success == "success") {
      showdata();
    }
  };
  const columns = [
    {
      dataField: "type",
      text: "type",
      sort: true,
      formatter: (cell, row) => (
        <div dangerouslySetInnerHTML={{ __html: cell }}></div>
      ),
    },
    {
      dataField: "subject",
      text: "subject",
      sort: true,
      formatter: (cell, row) => <div>{cell}</div>,
    },
    {
      dataField: "answer",
      text: "answer",
      sort: true,
      formatter: (cell, row) => (
        <div dangerouslySetInnerHTML={{ __html: cell }}></div>
      ),
    },
    {
      dataField: "hideshow",
      text: "Edit",
      formatter: (cell, row) => (
        <button
          class="btn table_viewdrop"
          type="button"
          onClick={() => submitbtn(row)}
        >
          edit
        </button>
      ),
    },
    {
      dataField: "hideshow",
      text: "Delete",
      formatter: (cell, row) => (
        <button
          class="btn table_viewdrop"
          type="button"
          onClick={() => clickbtn(row)}
        >
          delete
        </button>
      ),
    },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {},
    onSizePerPageChange: function (page, sizePerPage) {},
  });

  return (
    <div>
      {console.log("dftgdf", dtlists)}

      <div className="innerpages">
        <div className="orders_pages">
          <Headers />
          <div className="container custom_container px-lg-0">
            <div className="row mx-auto">
              <div className="innerpages_wid px-0">
                <Sidebar />
                <div
                  className={
                    toggle
                      ? "content-sec-inner right_widthset content_without_sidebar"
                      : "content-sec-inner  content_with_sidebar"
                  }
                >
                  <section id="content-wrapper">
                    <div class="row">
                      <div className="top_title_sec">
                        <div>
                          <p className="page_title">EmailTemplate List</p>
                        </div>
                        <div className="rightsec">
                          <button
                            type="button"
                            className="blue_btn add_deliveryprsn dp_btn_hover"
                            onClick={() => navigate("/detailadd")}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4 mx-auto">
                      <div className="sec_border">
                        <div className="row">
                          {
                            dtlists?.length>0 &&
                            <div className="ffd_table mar_bot">
                              <BootstrapTable
                                bootstrap4
                                keyField="id"
                                columns={columns}
                                data={dtlists}
                                pagination={pagination}
                              />
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default detaillist;
