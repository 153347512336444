import React, { useEffect, useState } from "react";
// import Sidebar from "../../../layouts/sidebar";
import Sidebar from "../../layouts/sidebar";
import Headers from "../../layouts/header";
import Select from "react-select";
import Datetime from "react-datetime";
import moment from "moment";
import "react-datetime/css/react-datetime.css";
import { Nav, Tab, Row, Col } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
// import useAxiosFile from "../../../actions/useaxios";
import useAxiosFile from "../../actions/useaxios";
// import config from "../../../actions/config";
import config from "../../actions/config";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
// import Exportcsv from "./Exportcsv";
import Exportcsv from "../loginpages/Managementpages/Exportcsv";
// import { DecryptData, EncryptData, isEmpty } from "../../../actions/common";
import { DecryptData, EncryptData, isEmpty } from "../../actions/common";

function Locations() {
  const [selectedOption, setSelectedOption] = useState(null);
  const { toggle } = useSelector((state) => state.admin_panel.User);

  const navigate = useNavigate();

  const axiosFile = useAxiosFile();

  const [seller, setseller] = useState();
  const [exportdata, setExportdata] = useState([]);

  useEffect(() => {
    getvendor();
  }, []);

  const getvendor = async () => {
    var resp = await axiosFile.getvendorList();
    console.log("dasdasdsadsa", resp);
    if (resp?.data?.length > 0) {
      // (resp?.data||[])?.map((val, idx) => {

      //   val.hash = <img className='tab_dummyimg' src={val.BrandImage ? `${config.IMG_URL}/shop/${val?.BrandImage}` : require('../../../assests/images/dummyimg.png')} />
      //   val.action = <div class="dropdown table_viewdropdown">
      //     <button class="btn table_viewdrop dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
      //       <i class="fa-solid fa-ellipsis" />
      //     </button>
      //     <ul class="dropdown-menu">
      //       <li><Link class="dropdown-item table_viewborbot" to={`/managementvendorsview/?Id=${String(EncryptData((val?.SellerId)))}`}>View</Link></li>
      //       {/* <li><a class="dropdown-item table_viewborbot">Edit</a></li>
      //       <li><a class="dropdown-item">Delete</a></li> */}
      //     </ul>
      //   </div>

      // })
      var ddd =
        resp.length > 0
          ? (resp || [])?.map(({ _id, _v, ...rest }) => {
              return rest;
            })
          : [];
      setExportdata(ddd);
    }

    setseller(resp?.data);
  };

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {},
    onSizePerPageChange: function (page, sizePerPage) {},
  });

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const driveroptions = [
    { value: "idle", label: "Idle" },
    { value: "intransit", label: "In Transit" },
    { value: "offline", label: "Offline" },
  ];

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#F5F6F7"
        : isSelected
        ? "#F5F6F7"
        : isHovered
        ? "red"
        : "#F5F6F7",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#a71d24"
          : isSelected
          ? "#a71d24"
          : isFocused
          ? "#a71d24"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 20px",
      backgroundColor: "#fff ",
      border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 10,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#6C6A81",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const columns = [
    {
      dataField: "hash",
      text: "#",
      formatter: (cell, row, rowindex) => (
        <>
          <p>{rowindex + 1}</p>
        </>
      ),
    },

    // {
    //   dataField: "VendorName",
    //   text: "Vendor Name",
    //   sort: true,
    //   formatter: (cell, row, rowindex) => (
    //     <>
    //       <p>{row?.FirstName + row?.LastName}</p>
    //     </>
    //   ),
    // },
    {
      dataField: "countryName",
      text: "Country Name",
      sort: true,
      formatter: (cell, row, rowindex) => (
        <>
          <p>USA</p>
        </>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: (cell, row, rowindex) => (
        <>
          <p className="table_grnavtivebtn cont_width mx-auto">Active</p>
        </>
      ),
    },
    {
      dataField: "action",
      text: "Action",
      sort: true,
      formatter: (cell, row, rowindex) => (
        <>
          <div className="d-flex align-items-center justify-content-center gap-3">
            <i class="fa-solid fa-pen curs_poin" aria-hidden="true"></i>
            <i class="fa-solid fa-eye curs_poin" aria-hidden="true"></i>
          </div>
        </>
      ),
    },
    // {dataField:"Address",text:"Location"},
    // {dataField:"Category",text:"Category",sort:true},
    // {dataField:"status",text:"Status",sort:true},
    // {
    //   dataField: "action",
    //   text: "Action",
    //   formatter: (cell, val) => (
    //     <div class="dropdown table_viewdropdown">
    //       <button
    //         class="btn table_viewdrop dropdown-toggle"
    //         type="button"
    //         data-bs-toggle="dropdown"
    //         aria-expanded="false"
    //       >
    //         <i class="fa-solid fa-ellipsis" />
    //       </button>
    //       <ul class="dropdown-menu">
    //         <li>
    //           <Link
    //             class="dropdown-item table_viewborbot"
    //             to={`/managementvendorsview/?Id=${String(
    //               EncryptData(val?._id)
    //             )}`}
    //           >
    //             View
    //           </Link>
    //         </li>
    //         {/* <li><a class="dropdown-item table_viewborbot">Edit</a></li>
    //          <li><a class="dropdown-item">Delete</a></li> */}
    //       </ul>
    //     </div>
    //   ),
    // },
  ];

  const data = [
    {
      hash: (
        // <img
        //   className="tab_dummyimg"
        //   src={require("../../../assests/images/dummyimg.png")}
        // />
        <img
          className="tab_dummyimg"
          src={require("../../assests/images/dummyimg.png")}
        />
      ),
      vendorname: "John Doe",
      location: "354 Allen Ave, Pasadena, CA 91106, USA",
      category: "Resturant, Italian",
      status: <button className="table_grnavtivebtn">Available</button>,

      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementvendorsview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot drpdw_redtxt">
                Inactive
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Order Aceepting Off</a>
            </li>
            <li>
              <a class="dropdown-item">Settlement</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      hash: (
        <img
          className="tab_dummyimg"
          src={require("../../assests/images/dummyimg.png")}
        />
      ),
      vendorname: "John Doe",
      location: "354 Allen Ave, Pasadena, CA 91106, USA",
      category: "Resturant, Italian",
      status: <button className="table_redinactbtn">Not Available</button>,
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementusersview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot drpdw_greentxt">
                Active
              </a>
            </li>
            <li>
              <a class="dropdown-item">Settlement</a>
            </li>
          </ul>
        </div>
      ),
    },

    {
      hash: (
        <img
          className="tab_dummyimg"
          src={require("../../assests/images/dummyimg.png")}
        />
      ),
      vendorname: "John Doe",
      vendorId: "#1562",
      location: "354 Allen Ave, Pasadena, CA 91106, USA",
      category: "Resturant, Italian",
      status: (
        <button className="table_sandalactbtn">Waiting for approval</button>
      ),
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementusersview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot drpdw_greentxt">
                Active
              </a>
            </li>
            <li>
              <a class="dropdown-item drpdw_redtxt">Inactive</a>
            </li>
          </ul>
        </div>
      ),
    },

    {
      hash: (
        <img
          className="tab_dummyimg"
          src={require("../../assests/images/dummyimg.png")}
        />
      ),
      vendorname: "John Doe",
      vendorId: "#1562",
      location: "354 Allen Ave, Pasadena, CA 91106, USA",
      category: "Resturant, Italian",
      status: <button className="table_grnavtivebtn">Active</button>,
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementvendorsview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot drpdw_redtxt">
                Inactive
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Order Aceepting Off</a>
            </li>
            <li>
              <a class="dropdown-item">Settlement</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      hash: (
        <img
          className="tab_dummyimg"
          src={require("../../assests/images/dummyimg.png")}
        />
      ),
      vendorname: "John Doe",
      vendorId: "#1562",
      location: "354 Allen Ave, Pasadena, CA 91106, USA",
      category: "Resturant, Italian",
      status: <button className="table_grnavtivebtn">Active</button>,
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementvendorsview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot drpdw_redtxt">
                Inactive
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Order Aceepting Off</a>
            </li>
            <li>
              <a class="dropdown-item">Settlement</a>
            </li>
          </ul>
        </div>
      ),
    },
  ];
  // var header=csvData.map((item)=>
  //     [JSON.stringify(item)]
  //     )

  // const csvReport = {
  //     data: csvData,
  //     filename: fileName
  //   };

  return (
    <>
      <div>
        <div className="innerpages">
          <div className="orders_pages">
            <Headers />
            <div className="container custom_container px-lg-0">
              <div className="row mx-auto">
                <div className="innerpages_wid px-0">
                  <Sidebar />
                  <div
                    className={
                      toggle
                        ? "content-sec-inner right_widthset content_without_sidebar"
                        : "content-sec-inner  content_with_sidebar"
                    }
                  >
                    <section id="content-wrapper">
                      <div class="row">
                        <div className="top_title_sec">
                          <div>
                            <p className="page_title mb-0">Location</p>
                          </div>
                          <div className="rightside just_end ">
                            <button
                              type="button"
                              className="blue_btn add_deliveryprsn dp_btn_hover"
                              onClick={() => navigate("/addseller")}
                            >
                              Add Locations
                            </button>

                            {/* {exportdata && (
                              <Exportcsv
                                csvData={exportdata}
                                fileName={"users"}
                              />
                            )} */}

                            {/* <CSVLink className="downloadbtn" filename="my-file.csv" data={csvData}>
        Export to CSV
      </CSVLink> */}
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4 mx-auto">
                        <div className="sec_border">
                          <div className="row">
                            <div className="col-xxl-4 col-xl-4 col-lg-3 col-md-3 col-sm-6 col-12">
                              <div className="whole_searchbar white_bg search_mx_ht">
                                {/* <img
                                  className="search_glass"
                                  src={require("../../../assests/images/Search.png")}
                                /> */}
                                <img
                                  className="search_glass"
                                  src={require("../../assests/images/Search.png")}
                                />
                                <input placeholder="Search term" />
                              </div>
                            </div>

                            <div className="col-xxl-8 col-xl-8 col-lg-9 col-md-9 col-sm-6 col-12">
                              <div className="rightside  mrgn_unst">
                                <div className="rightside_select">
                                  <Select
                                    options={options}
                                    defaultValue={selectedOption}
                                    styles={stylesgraybg}
                                    onChange={setSelectedOption}
                                    className="border_blue_select"
                                    classNamePrefix="react-select"
                                    placeholder="Type"
                                    // menuIsOpen={true}
                                  />
                                </div>

                                <div className="rightside_select">
                                  <Select
                                    options={driveroptions}
                                    defaultValue={selectedOption}
                                    styles={stylesgraybg}
                                    onChange={setSelectedOption}
                                    className="border_blue_select"
                                    classNamePrefix="react-select"
                                    placeholder="Status"
                                  />
                                </div>
                              </div>
                            </div>
                            {console.log("ldjkfdljfdsfa", seller)}
                            {seller && (
                              <div className="ffd_table mar_bot">
                                <BootstrapTable
                                  bootstrap4
                                  keyField="id"
                                  columns={columns}
                                  data={seller}
                                  pagination={pagination}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Locations;
