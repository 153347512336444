import React, { useEffect, useState } from "react";
import Sidebar from "../../layouts/sidebar";
import Headers from "../../layouts/header";
import Select from "react-select";
import "react-datetime/css/react-datetime.css";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import Datetime from "react-datetime";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Exportcsv from "../loginpages/Managementpages/Exportcsv";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
// import { DecryptData, EncryptData } from '../../../actions/common';

function FinanceVendors() {
  const [opacity, setOpacity] = useState("false");

  const [selectedOption, setSelectedOption] = useState(null);
  const { toggle } = useSelector((state) => state.admin_panel.User);

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {},
    onSizePerPageChange: function (page, sizePerPage) {},
  });

  const columns = [
    { dataField: "month", text: "Month", sort: true },
    { dataField: "orders", text: "Orders" },
    { dataField: "subtotal", text: "Subtotal" },
    { dataField: "taxes", text: "Taxes" },
    { dataField: "clienttips", text: "Client Tips" },
    { dataField: "discount", text: "Discount" },
    { dataField: "totalamount", text: "Total Amount" },
    { dataField: "commission", text: "Commission Paid" },
    { dataField: "allearning", text: "All Earning" },
    { dataField: "action", text: "Action" },
  ];

  const data = [
    {
      month: "January 2022",
      orders: <p className="table_secondarytxt">1968</p>,
      subtotal: "$10,000",
      taxes: <p className="table_secondarytxt">$1,100</p>,
      clienttips: <p className="table_secondarytxt">$1,100</p>,
      discount: <p className="table_secondarytxt">$1,100</p>,
      totalamount: <p className="table_blurblue">$10,000</p>,
      commission: <p className="table_secondarytxt">$1,100</p>,
      allearning: <p className="table_blurblue">$10,000</p>,
      action: (
        <Link to="/financevendorview">
          <i class="fa-solid fa-eye text-dark" />
        </Link>
      ),
    },
    {
      month: "January 2022",
      orders: <p className="table_secondarytxt">1968</p>,
      subtotal: "$10,000",
      taxes: <p className="table_secondarytxt">$1,100</p>,
      clienttips: <p className="table_secondarytxt">$1,100</p>,
      discount: <p className="table_secondarytxt">$1,100</p>,
      totalamount: <p className="table_blurblue">$10,000</p>,
      commission: <p className="table_secondarytxt">$1,100</p>,
      allearning: <p className="table_blurblue">$10,000</p>,
      action: (
        <Link to="/financevendorview">
          <i class="fa-solid fa-eye text-dark" />
        </Link>
      ),
    },
    {
      month: "January 2022",
      orders: <p className="table_secondarytxt">1968</p>,
      subtotal: "$10,000",
      taxes: <p className="table_secondarytxt">$1,100</p>,
      clienttips: <p className="table_secondarytxt">$1,100</p>,
      discount: <p className="table_secondarytxt">$1,100</p>,
      totalamount: <p className="table_blurblue">$10,000</p>,
      commission: <p className="table_secondarytxt">$1,100</p>,
      allearning: <p className="table_blurblue">$10,000</p>,
      action: (
        <Link to="/financevendorview">
          <i class="fa-solid fa-eye text-dark" />
        </Link>
      ),
    },
    {
      month: "January 2022",
      orders: <p className="table_secondarytxt">1968</p>,
      subtotal: "$10,000",
      taxes: <p className="table_secondarytxt">$1,100</p>,
      clienttips: <p className="table_secondarytxt">$1,100</p>,
      discount: <p className="table_secondarytxt">$1,100</p>,
      totalamount: <p className="table_blurblue">$10,000</p>,
      commission: <p className="table_secondarytxt">$1,100</p>,
      allearning: <p className="table_blurblue">$10,000</p>,
      action: (
        <Link to="/financevendorview">
          <i class="fa-solid fa-eye text-dark" />
        </Link>
      ),
    },
  ];

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#F5F6F7"
        : isSelected
        ? "#F5F6F7"
        : isHovered
        ? "red"
        : "#F5F6F7",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#a71d24"
          : isSelected
          ? "#a71d24"
          : isFocused
          ? "#a71d24"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "35px",
      padding: "0 20px",
      backgroundColor: "#fff ",
      border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 10,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "35px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "35px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#6C6A81",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  return (
    <div>
      <div className="innerpages">
        <div className="orders_pages">
          <Headers />
          <div className="container custom_container">
            <div className="row">
              <div className="innerpages_wid">
                <Sidebar />
                <div
                  className={
                    toggle
                      ? "content-sec-inner right_widthset"
                      : "content-sec-inner"
                  }
                >
                  <section id="content-wrapper">
                    <div class="row">
                      <div className="top_title_sec mt-4">
                        <div>
                          <p className="page_title">Monthly Report</p>
                        </div>
                        <div className="rightside">
                          <div className="rightside_select">
                            <Select
                              options={options}
                              defaultValue={selectedOption}
                              styles={stylesgraybg}
                              onChange={setSelectedOption}
                              className="border_blue_select"
                              classNamePrefix="react-select"
                              placeholder="Type"
                            />
                          </div>

                          <div className="order_calendar_div finance_order_calendar_div">
                            <p
                              className={
                                opacity == "true" ? "hiding_caltxt" : ""
                              }
                            >
                              2022
                            </p>
                            <img
                              className="order_calendar_ic"
                              src={require("../../assests/images/calendar.png")}
                            />
                            <Datetime
                              onChange={() => {
                                setOpacity("true");
                              }}
                              className="order_datepick"
                            />
                          </div>

                          <button
                            type="button"
                            className="btn primary-style padding_lessbtn"
                          >
                            <Exportcsv
                              className="cmn_exportcsvs"
                              //  csvData={exportdata}
                              fileName={"SubCategory"}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className=" mt-4">
                      <div className="ffd_table finance_ffdtable">
                        <BootstrapTable
                          bootstrap4
                          keyField="id"
                          columns={columns}
                          data={data}
                          pagination={pagination}
                        />
                      </div>

                      {/* <div className='sec_border'>

      <div className='row'>
        <div className='col-xxl-4 col-xl-4 col-lg-3 col-md-3 col-sm-12 col-12'>
        <div className='whole_searchbar white_bg'>
   <img className='search_glass' src={require('../../assests/images/Search.png')}/>
  <input placeholder="Search term"/>
   </div>
        </div>

        <div className='col-xxl-8 col-xl-8 col-lg-9 col-md-9 col-sm-12 col-12'>
        <div className='rightside'>

<div className='rightside_select'>
        <Select
                            // options={options}
                            // defaultValue={selectedOption}
                            // styles={stylesgraybg}
                            // onChange={setSelectedOption}
                            className="border_blue_select"
                            classNamePrefix="react-select"
                            placeholder='Type'

                          />
                          </div>

                          <div className='rightside_select'>
        <Select
                            // options={driveroptions}
                            // defaultValue={selectedOption}
                            // styles={stylesgraybg}
                            // onChange={setSelectedOption}
                            className="border_blue_select"
                            classNamePrefix="react-select"
                            placeholder='Status'

                          />
                          </div>

        </div>

        </div>

      </div>

    </div> */}
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FinanceVendors;
