import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

import Dash_img from "../assests/images/dashboard.png";
import Over_img from "../assests/images/overview.png";
import order_img from "../assests/images/orders.png";
import { useDispatch, useSelector } from "react-redux";

import overviewSvg from "../assests/svg/overview.svg";

export default function Sidebar() {
  const dispatch = useDispatch();
  const { token, payload } = useSelector((state) => state.admin_panel.User);

  const [isToggled, setIsToggled] = useState(false);
  // var content = document.getElementById("content-wrapper")
  // alert(content)
  const handleToggle = () => {
    setIsToggled(!isToggled);
    console.log("djhfkdfsside", isToggled);
    //   if (isToggled == "true"){
    //     content.classList.add("preview1");
    // } else{
    //     content.classList.add("preview");
    // }
    dispatch({
      type: "Login",
      Register_Section: {
        User: {
          payload: payload,
          token: token,
          toggle: !isToggled,
        },
      },
    });
  };

  return (
    <div className={isToggled ? "toggled" : ""} id="wrapper">
      <aside id="sidebar-wrapper" className="min_ht">
        <ul class="sidebar-nav">
          <li>
            <div
              class="accordion accordion-flush side_dahsboard_accord"
              id="accordionFlushExample"
            >
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button side_dashaccord_headbtn collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    <img
                      className="sidebar_logo"
                      src={Dash_img}
                      alt="dashboard"
                    />
                    Dashboard
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    <ul className="">
                      <li>
                        <NavLink to="/dashboard">
                          {/* <img
                            className="sidebar_logo"
                            src={Over_img}
                            alt="dashboard"
                          /> */}
                          <img
                            className="sidebar_logo"
                            src={overviewSvg}
                            alt="dashboard"
                          />
                          Overview
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/orders">
                          <img
                            className="sidebar_logo"
                            src={order_img}
                            alt="dashboard"
                          />
                          Orders
                        </NavLink>
                      </li>
                      {/* <li>
          <NavLink to='/orders'><img className='sidebar_logo' src={order_img} alt="dashboard"/>Orders</NavLink>
          </li>
          <li>
          <NavLink to='/ordersdriver'><img className='sidebar_logo' src={require('../assests/images/driver.png')} alt="dashboard"/>Drivers</NavLink>
          </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </li>

          <li>
            <div
              class="accordion accordion-flush side_dahsboard_accord"
              id="management"
            >
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button side_dashaccord_headbtn collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#management-collapse"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    <img
                      className="sidebar_logo"
                      src={
                        require("../assests/images/Speakerphone.svg").default
                      }
                      alt="dashboard"
                    />
                    Management
                  </button>
                </h2>
                <div
                  id="management-collapse"
                  class="accordion-collapse collapse"
                  data-bs-parent="#management"
                >
                  <div class="accordion-body">
                    <ul className="">
                      <li>
                        <NavLink to="/managementvendors">
                          <img
                            className="sidebar_logo"
                            src={
                              require("../assests/images/Library.svg").default
                            }
                            alt="dashboard"
                          />
                          Vendors
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/managementdriver">
                          <img
                            className="sidebar_logo"
                            src={
                              require("../assests/images/Identification.svg")
                                .default
                            }
                            alt="dashboard"
                          />
                          Drivers
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/managementusers">
                          <img
                            className="sidebar_logo"
                            src={
                              require("../assests/images/User_group.svg")
                                .default
                            }
                            alt="dashboard"
                          />
                          Users
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/menumanager">
                          <img
                            className="sidebar_logo"
                            src={require("../assests/images/shopping_bag.png")}
                            alt="dashboard"
                          />
                          Menu Manager
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </li>
          {/* <li>
            <div
              class="accordion accordion-flush side_dahsboard_accord"
              id="locations"
            >
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button side_dashaccord_headbtn collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#locations-collapse"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    <img
                      className="sidebar_logo"
                      src={require("../assests/images/Globe.svg").default}
                      alt="dashboard"
                    />
                    Locations
                  </button>
                </h2>
                <div
                  id="locations-collapse"
                  class="accordion-collapse collapse"
                  data-bs-parent="#locations"
                >
                  <div class="accordion-body">
                    <ul className="">
                      <li>
                        <NavLink to="/locations">
                          <img
                            className="sidebar_logo"
                            src={require("../assests/images/location.png")}
                            alt="location"
                          />
                          Locations
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/deliveryzone">
                          <img
                            className="sidebar_logo"
                            src={require("../assests/images/zones.png")}
                            alt="delivery zones"
                          />
                          Delivery Zones
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </li> */}

          {/* <li>
            <div
              class="accordion accordion-flush side_dahsboard_accord"
              id="location"
            >
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button side_dashaccord_headbtn collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#location-collapse"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    <img
                      className="sidebar_logo"
                      src={require("../assests/images/Globe.svg").default}
                      alt="dashboard"
                    />
                    Location
                  </button>
                </h2>
                <div
                  id="location-collapse"
                  class="accordion-collapse collapse"
                  data-bs-parent="#location"
                >
                  <div class="accordion-body">
                    <ul className="">
                      <li>
                        <NavLink to="/locations">
                          <img
                            className="sidebar_logo"
                            src={require("../assests/images/location.png")}
                            alt="dashboard"
                          />
                          Locations
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/deliveryzone">
                          <img
                            className="sidebar_logo"
                            src={require("../assests/images/zones.png")}
                            alt="dashboard"
                          />
                          Delivery Zones
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </li>

          <li>
            <div
              class="accordion accordion-flush side_dahsboard_accord"
              id="promotion"
            >
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button side_dashaccord_headbtn collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#promotion-collapse"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    <img
                      className="sidebar_logo"
                      src={
                        require("../assests/images/Speakerphone.svg").default
                      }
                      alt="dashboard"
                    />
                    Promotions
                  </button>
                </h2>
                <div
                  id="promotion-collapse"
                  class="accordion-collapse collapse"
                  data-bs-parent="#promotion"
                >
                  <div class="accordion-body">
                    <ul className="">
                      <li>
                        <NavLink to="">
                          <img
                            className="sidebar_logo"
                            src={require("../assests/images/Receipttax.png")}
                            alt="dashboard"
                          />
                          Promotion Code
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="">
                          <img
                            className="sidebar_logo"
                            src={require("../assests/images/Photograph.png")}
                            alt="dashboard"
                          />
                          Discount Banners
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </li>


          <li>
            <div
              class="accordion accordion-flush side_dahsboard_accord"
              id="finance"
            >
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button side_dashaccord_headbtn collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#finance-collapse"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    <img
                      className="sidebar_logo"
                      src={require("../assests/images/roundgrid.png")}
                      alt="dashboard"
                    />
                    Finance
                  </button>
                </h2>
                <div
                  id="finance-collapse"
                  class="accordion-collapse collapse"
                  data-bs-parent="#finance"
                >
                  <div class="accordion-body">
                    <ul className="">
                      <li>
                        <NavLink to="/financesales">
                          <img
                            className="sidebar_logo"
                            src={require("../assests/images/Chartpie.png")}
                            alt="dashboard"
                          />
                          Sales
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/financevendor">
                          <img
                            className="sidebar_logo"
                            src={require("../assests/images/storefront.png")}
                            alt="dashboard"
                          />
                          Vendor
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/financedrivers">
                          <img
                            className="sidebar_logo"
                            src={require("../assests/images/Truck.png")}
                            alt="dashboard"
                          />
                          Driver
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/financereports">
                          <img
                            className="sidebar_logo"
                            src={require("../assests/images/Collection.png")}
                            alt="dashboard"
                          />
                          Report
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </li> */}
          {/* Finance end */}
          <li>
            <NavLink to="/Categorylist" className="sidebar_faq">
              <img
                className="sidebar_logo"
                src={require("../assests/images/Bookmarkalt.png")}
                alt="dashboard"
              />
              Category
            </NavLink>
          </li>

          <li>
            <NavLink to="/subcategorylist" className="sidebar_faq">
              <img
                className="sidebar_logo"
                src={require("../assests/images/Bookmarkalt.png")}
                alt="dashboard"
              />
              Sub Category
            </NavLink>
          </li>
          <li>
            <NavLink to="/Faqlist" className="sidebar_faq">
              <img
                className="sidebar_logo"
                src={require("../assests/images/Bookmarkalt.png")}
                alt="dashboard"
              />
              Faq List
            </NavLink>
          </li>

          {/* setting page start*/}

          {/* <li>
            <div
              class="accordion accordion-flush side_dahsboard_accord"
              id="settings"
            >
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button side_dashaccord_headbtn collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#settings-collapse"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    <img
                      className="sidebar_logo"
                      src={require("../assests/images/nut.png")}
                      alt="settings"
                    />
                    Settings
                  </button>
                </h2>
                <div
                  id="settings-collapse"
                  class="accordion-collapse collapse"
                  data-bs-parent="#settings"
                >
                  <div class="accordion-body">
                    <ul className="">
                      <li>
                        <NavLink to="#">
                          <img
                            className="sidebar_logo"
                            src={require("../assests/images/Briefcase.png")}
                            alt="configurations"
                          />
                          Configurations
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/useraccess">
                          <img
                            className="sidebar_logo"
                            src={require("../assests/images/Briefcase.png")}
                            alt="useraccess"
                          />
                          Users & Access
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="#">
                          <img
                            className="sidebar_logo"
                            src={require("../assests/images/Clock.png")}
                            alt="terminology"
                          />
                          Terminology
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </li> */}
          {/* setting page end*/}

          {/* <li>
            <NavLink to="/couponslist" className="sidebar_faq">
              <img
                className="sidebar_logo"
                src={require("../assests/images/Bookmarkalt.png")}
                alt="dashboard"
              />{" "}
              Coupons
            </NavLink>
          </li>

          <li></li> */}
          {/* <li>
            <NavLink to="/lists" className="sidebar_faq">
              <img
                className="sidebar_logo"
                src={require("../assests/images/Bookmarkalt.png")}
                alt="dashboard"
              />
              SocialLinks
            </NavLink>
          </li> */}

          {/* <li>
            <NavLink to="/datalists" className="sidebar_faq">
              <img
                className="sidebar_logo"
                src={require("../assests/images/Bookmarkalt.png")}
                alt="dashboard"
              />
              EmailTemplate
            </NavLink>
          </li> */}

          <li>
            <NavLink to="/subscribelists" className="sidebar_faq">
              <img
                className="sidebar_logo"
                src={require("../assests/images/Bookmarkalt.png")}
                alt="dashboard"
              />
              Subscribe
            </NavLink>
          </li>

          {/* <li>
            <NavLink to="/listcms" className="sidebar_faq">
              <img
                className="sidebar_logo"
                src={require("../assests/images/Bookmarkalt.png")}
                alt="dashboard"
              />
              Cms
            </NavLink>
          </li> */}
          <li>
            <NavLink to="/deliverytimecharge" className="sidebar_faq">
              <img
                className="sidebar_logo"
                src={require("../assests/images/Bookmarkalt.png")}
                alt="dashboard"
              />
              Delivery Time Charge
            </NavLink>
          </li>
        </ul>
      </aside>

      <div id="navbar-wrapper">
        <nav class="navbar navbar-inverse">
          <div class="container-fluid">
            <div class="navbar-header">
              <a
                href="#"
                class="navbar-brand"
                id="sidebar-toggle"
                onClick={handleToggle}
              >
                <i class="fa fa-bars"></i>
              </a>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}
