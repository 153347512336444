import axios from "axios";
import config from "./config";
import { DecryptData, EncryptData, isEmpty } from "./common";
import { useSelector, useDispatch } from "react-redux";
import { handleCallback } from "./errorHandler";

const useAxiosFile = () => {
  const usertoken = ("; " + document.cookie)
    .split(`; token_a=`)
    .pop()
    .split(";")[0];
  const authToken = useSelector((state) => state.LoginReducer?.User?.token);

  var token = usertoken ? usertoken : authToken;

  axios.defaults.baseURL = config.BACK_URL;
  axios.defaults.headers.post["Content-Type"] = "application/json";
  axios.defaults.headers.post["Authorization"] = token;
  const dispatch = useDispatch();

  const axiosFunc = async (data) => {
    console.log("hderrgser", data);
    try {
      let Resp = await axios(data);
      console.log(
        "Resp?.data",
        Resp,
        Resp?.data,
        DecryptData(Resp.data),
        isEmpty(DecryptData(Resp.data))
      );
      if (Resp?.data) {
        Resp.data = Resp.data
          ? isEmpty(DecryptData(Resp.data))
            ? Resp.data
            : DecryptData(Resp.data)
          : Resp.data;
      }

      return Resp.data;
    } catch (e) {
      console.log("error", e);
      return { success: "error", msg: null };
    }
  };

  const AppenData = (data) => {
    var formdata = new FormData();
    var SendDta = Object.entries(data).map((item) => {
      if (Array.isArray(item[1])) {
        var come = item[1].map((data) => {
          if (data?.type && data?.size) {
            formdata.append(item[0], data);
          } else {
            formdata.append(item[0], EncryptData(data));
          }
          return formdata;
        });
        return come;
      } else {
        console.log("kshjskfdfsmappp", item[0], item[1]);
        if (item[1]?.type && item[1]?.size) {
          formdata.append(item[0], item[1]);
        } else {
          formdata.append(item[0], EncryptData(item[1]));
        }
        return formdata;
      }
    });
    return SendDta;
  };

  const LoginReg = handleCallback(async (data) => {
    var formdata = AppenData(data);
    var senddata = {
      method: "post",
      url: `/admin/adminlogin`,
      data: formdata[0],
    };
    var Resp = await axiosFunc(senddata);

    let _id = Array.isArray(Resp.data) ? Resp.data[0]?._id : Resp.data?._id,
      type = data.Type;
    console.log("ksdhjfkhsfsfs", Resp, _id);
    if (Resp?.token) {
      document.cookie = "token_a" + "=" + Resp?.token + ";" + ";path=/";
    }

    if (_id) {
      dispatch({
        type: "Login",
        Register_Section: {
          User: {
            payload: Array.isArray(Resp.data) ? Resp.data[0] : Resp.data,
            token: Resp?.token ? Resp?.token : token,
          },
          App_Inside: true,
        },
      });
      localStorage.setItem("_id_a", EncryptData(_id));
    }

    return Resp;
  });

  const AlreadyExits = handleCallback(async (data) => {
    console.log("AlreadyExitsdataa", data, token);
    var senddata = {
      method: "get",
      url: `/admin/AlreadyExits`,
      params: data,
      headers: { Authorization: token ? token : "skip" },
    };

    let Resp = await axiosFunc(senddata);
    console.log("kjhsafjdskfds", Resp);
    if (Resp?.token) {
      document.cookie = "token_a" + "=" + Resp?.token + ";" + ";path=/";
      localStorage.setItem("_id_a", EncryptData(Resp?.data[0]?._id));
    }
    dispatch({
      type: "Login",
      Register_Section: {
        User: {
          payload: Resp?.data?.[0],
          token: Resp?.token ? Resp?.token : token,
        },
        App_Inside: true,
      },
    });
    console.log("lsjfklsshiii");
  });

  const getusers = async (data) => {
    try {
      var senddata = {
        method: "GET",
        url: `/admin/getusers`,
        params: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {}
  };

  const getuserview = async (data) => {
    try {
      var senddata = {
        method: "get",
        url: `/admin/getuserview`,
        params: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {}
  };

  const getvendorList = async () => {
    try {
      var senddata = {
        method: "get",
        url: `/admin/getvendors`,
      };
      let Resp = await axiosFunc(senddata);

      return Resp;
    } catch (err) {}
  };
  const getvendorListview = async (data) => {
    try {
      var senddata = {
        method: "get",
        url: `/admin/getvendorsview`,
        params: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {}
  };
  const getodercom = async (data) => {
    try {
      var senddata = {
        method: "get",
        url: `/admin/getuseroder`,
        params: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {}
  };
  const getoderreview = async (data) => {
    try {
      var senddata = {
        method: "get",
        url: `/admin/getoderreview`,
        params: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {}
  };
  const AddMainCategory = async (data) => {
    var formdata = AppenData(data);

    try {
      var senddata = {
        method: "POST",
        url: `/admin/mainCategory`,
        data: formdata[0],
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {}
  };
  const getcatList = async (data) => {
    try {
      var senddata = {
        method: "get",
        url: `/admin/getcategory`,
        params: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {}
  };
  const getFaqList = async () => {
    try {
      var senddata = {
        method: "get",
        url: `/admin/getFaqList`,
      };
      var resp = await axiosFunc(senddata);
      return resp;
    } catch (err) {
      console.log("jiol", err);
    }
  };
  const getCmsList = async () => {
    try {
      var senddata = {
        method: "get",
        url: `/admin/getCmsList`,
      };
      var resp = await axiosFunc(senddata);
      return resp;
    } catch (err) {
      console.log("tyuol", err);
    }
  };

  const getCouponList = async (data) => {
    try {
      var formdata = AppenData(data);
      var senddata = {
        method: "Post",
        url: `/admin/getCouponList`,
        headers: {
          "content-type": "multipart/form-data", // do not forget this
        },
        data: formdata[0],
      };

      var resp = await axiosFunc(senddata);
      return resp;
    } catch (err) {
      console.log("dfgfb", err);
    }
  };

  const getcat = async (data) => {
    try {
      var senddata = {
        method: "get",
        url: `/admin/getcat`,
        params: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {}
  };

  const getFaq = async (data) => {
    try {
      var senddata = {
        method: "get",
        url: `admin/getfaq`,
        params: { data: EncryptData(data) },
      };
      var resp = await axiosFunc(senddata);
      return resp;
    } catch (err) {
      console.log(err);
    }
  };
  const getCms = async (data) => {
    try {
      var senddata = {
        method: "get",
        url: "admin/getcms",
        params: { data: EncryptData(data) },
      };
      var resp = await axiosFunc(senddata);
      return resp;
    } catch (err) {
      console.log(err);
    }
  };
  const CouponList = async (data) => {
    try {
      var senddata = {
        method: "get",
        url: `/admin/getCouponLists`,
        params: data,
      };
      var resp = await axiosFunc(senddata);
      return resp;
    } catch (err) {
      console.log(err);
    }
  };

  const DriverAction = async (data) => {
    console.log("erghza", data);
    try {
      var formdata = AppenData(data);
      var senddata = {
        method: "post",
        url: `/driver/driveraction`,
        headers: {
          "content-type": "multipart/form-data", // do not forget this
        },
        data: formdata[0],
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {}
  };

  const AddSubCategory = async (data) => {
    var formdata = AppenData(data);

    try {
      var senddata = {
        method: "POST",
        url: `/admin/subCategory`,
        data: formdata[0],
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {
      return false;
    }
  };

  const GetDriver = async (data) => {
    try {
      var senddata = {
        method: "get",
        url: `/driver/getdriver`,
        params: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {}
  };
  const GetCoupons = async (data) => {
    try {
      var senddata = {
        method: "get",
        url: "/admin/getcoupons",
      };
      var resp = await axiosFunc(senddata);
      return resp;
    } catch (err) {
      console.log(err);
    }
  };
  const getsubcatList = async () => {
    try {
      var senddata = {
        method: "get",
        url: `/admin/getsubcategory`,
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {}
  };

  const GetDriverDetail = async (data) => {
    try {
      var senddata = {
        method: "get",

        url: `/driver/getdriverdetail`,
        params: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {}
  };

  const GetCouponDetail = async (data) => {
    try {
      var senddata = {
        method: "get",
        url: `/admin/getCouponDetail`,
        params: { data: EncryptData(data) },
      };
      var resp = await axiosFunc(senddata);
      return resp;
    } catch (err) {
      console.log(err);
    }
  };
  const getsubcat = async (data) => {
    try {
      var senddata = {
        method: "get",
        url: `/admin/getsubcat`,
        params: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {}
  };

  const GetDashBoardDatas = async (data) => {
    try {
      var senddata = {
        method: "get",
        url: `/admin/dashboard`,
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {}
  };
  const hideShowCat = async (data) => {
    try {
      var resp = {
        method: "POST",
        url: `/admin/hideshowcategory`,
        data: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(resp);

      return Resp;
    } catch (err) {}
  };
  const hideShowsubCat = async (data) => {
    try {
      var resp = {
        method: "POST",
        url: `/admin/hideshowsubcategory`,
        data: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(resp);
      return Resp;
    } catch (err) {}
  };

  const addFaq = async (data) => {
    var formData = AppenData(data);
    var senddata = {
      method: "POST",
      url: `admin/addFaq`,
      data: formData[0],
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    var resp = await axiosFunc(senddata);
    return resp;
  };

  const addCms = async (data) => {
    console.log("dataadf", formData);
    var formData = AppenData(data);
    var sendData = {
      method: "POST",
      url: `admin/addCms`,
      data: formData[0],
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    var resp = await axiosFunc(sendData);
    return resp;
  };

  const hideshowfaq = async (data) => {
    try {
      var resp = {
        method: "POST",
        url: `admin/hideshowfaq`,
        data: { data: EncryptData(data) },
      };
      var resp = await axiosFunc(resp);
      return resp;
    } catch (err) {
      console.log(err);
    }
  };

  const hideshowcms = async (data) => {
    try {
      var resp = {
        method: "POST",
        url: `admin/hideshowcms`,
        data: { data: EncryptData(data) },
      };
      var resp = await axiosFunc(resp);
      return resp;
    } catch (err) {
      console.log(err);
    }
  };

  const TestApi = async (data) => {
    var encdata = EncryptData(JSON.stringify(data));
    var senddata = {
      method: "post",
      url: `/user/test`,
      data: { data: encdata },
    };
    let Resp = await axiosFunc(senddata);
    return Resp.data;
  };

  const getProduct = async (data) => {
    var encdata = EncryptData(JSON.stringify(data));
    var senddata = {
      method: "get",
      url: `/admin/getProduct`,
      data: { data: encdata },
    };
    let Resp = await axiosFunc(senddata);

    return Resp.data;
  };

  const GetOrders = async (data) => {
    var encdata = EncryptData(JSON.stringify(data));
    var senddata = {
      method: "get",
      url: `/admin/GetOrders`,
      params: { data: encdata },
    };
    let Resp = await axiosFunc(senddata);

    return Resp;
  };

  const GetShop = async (data) => {
    var encdata = EncryptData(JSON.stringify(data));
    var senddata = {
      method: "get",
      url: `/admin/GetShops`,
      params: { data: encdata },
    };
    let Resp = await axiosFunc(senddata);

    return Resp;
  };

  const OrderStatusUpdate = async (data) => {
    var resp = {
      method: "post",
      url: `/admin/orderaction`,
      data: { data: EncryptData(data) },
    };
    let Resp = await axiosFunc(resp);
    console.log("gfgfg", Resp);
    return Resp;
  };

  //first--------------->
  const addinsert = async (data) => {
    console.log("data", data);
    try {
      console.log("add_data", data);
      var adddata = {
        method: "post",
        url: `/admin/Addinsert`,
        data: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(adddata);
      console.log("aaaaaa", Resp);
      return Resp;
    } catch (err) {}
  };

  const datalist = async (data) => {
    try {
      console.log("list_data", data);
      var listdata = {
        method: "get",
        url: `/admin/datalist`,
        data: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(listdata);
      console.log("listtt", Resp);
      return Resp;
    } catch (err) {}
  };

  const alldata = async (data) => {
    try {
      console.log("list_data", data);
      var trigdata = {
        method: "get",
        url: `/admin/alldata`,
        params: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(trigdata);
      console.log("listtt", Resp);
      return Resp;
    } catch (err) {}
  };

  //new------------>

  const detailinsert = async (data) => {
    console.log("data", data);
    try {
      console.log("add_datasss", data);
      var adddata = {
        method: "post",
        url: `/admin/detailinsert`,
        data: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(adddata);
      console.log("assssss", Resp);
      return Resp;
    } catch (err) {}
  };

  const viewdata = async (data) => {
    try {
      console.log("list_data", data);
      var listdata = {
        method: "get",
        url: `/admin/viewdata`,
        params: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(listdata);
      console.log("listtt", Resp);
      return Resp;
    } catch (err) {}
  };

  const showdata = async (data) => {
    try {
      console.log("zsgzsg", data);
      var emaildata = {
        method: "get",
        url: `/admin/showdata`,
        params: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(emaildata);
      console.log("drgds", Resp);
      return Resp;
    } catch (err) {}
  };

  //emailsend
  const newinsert = async (data) => {
    console.log("data", data);
    try {
      console.log("add_datasss", data);
      var adddata = {
        method: "post",
        url: `/admin/newinsert`,
        data: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(adddata);
      console.log("assssss", Resp);
      return Resp;
    } catch (err) {}
  };

  const showinglists = async (data) => {
    try {
      console.log("zsgzsg", data);
      var emaildata = {
        method: "get",
        url: `/admin/showinglists`,
        params: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(emaildata);
      console.log("drgds", Resp);
      return Resp;
    } catch (err) {}
  };

  const Adddetails = async (data) => {
    try {
      console.log("list_data", data);
      var listdata = {
        method: "get",
        url: `/admin/Adddetails`,
        params: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(listdata);
      console.log("listtt", Resp);
      return Resp;
    } catch (err) {}
  };

  const emailsend = async (data) => {
    try {
      console.log("list_data", data);
      var listdata = {
        method: "post",
        url: `/admin/emailsend`,
        data: data,
      };
      let Resp = await axiosFunc(listdata);
      console.log("listtt", Resp);
      return Resp;
    } catch (err) {}
  };

  //cms
  const Cmsadd = async (data) => {
    var formData = AppenData(data);
    var sendData = {
      method: "post",
      url: `admin/Cmsadd`,
      data: formData[0],
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    var resp = await axiosFunc(sendData);
    return resp;
  };

  const idverify = async (data) => {
    try {
      console.log("list_data", data);
      var trigdata = {
        method: "get",
        url: `/admin/idverify`,
        params: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(trigdata);
      console.log("listtt", Resp);
      return Resp;
    } catch (err) {}
  };

  //  const cmsnewinsert = (async(data)=>{
  //     console.log("data",data);
  //     try {
  //         console.log('add_data', data)
  //         var adddata = {
  //             method: 'post',
  //             url: `/admin/cmsnewinsert`,
  //             data:{data:EncryptData(data)},

  //         }
  //         let Resp = await axiosFunc(adddata);
  //         console.log("aaaaaa",Resp);
  //         return Resp;
  //     }
  //     catch (err) { }
  //  });

  const listcms = async (data) => {
    try {
      console.log("list_data", data);
      var listdata = {
        method: "get",
        url: `/admin/listcms`,
        params: data,
      };
      let Resp = await axiosFunc(listdata);
      console.log("listtt", Resp);
      return Resp;
    } catch (err) {}
  };

  const showuserpanel = async (data) => {
    try {
      console.log("gfwehjre", data);
      var userlistdata = {
        method: "get",
        url: `/admin/showuserpanel`,
        params: data,
      };
      let Resp = await axiosFunc(userlistdata);
      console.log("listtt", Resp);
      return Resp;
    } catch (err) {}
  };

  const couponsend = async (data) => {
    try {
      var sendData = {
        method: "post",
        url: `admin/couponsend`,
        data: data,
      };
      console.log("dataaaaas", data);
      var resp = await axiosFunc(sendData);

      console.log("sedmdd", resp);
      return resp;
    } catch (err) {
      console.log("error", err);
    }
  };

  const OrderDetail = handleCallback(async (data) => {
    var resp = {
      method: "get",
      url: `/seller/orderdetail`,
      params: { data: EncryptData(data) },
    };
    let Resp = await axiosFunc(resp);
    console.log("gfgfg", Resp);
    return Resp;
  });

  const AssignDriver = handleCallback(async (data) => {
    var adddata = {
      method: "post",
      url: `/admin/assigndriver`,
      data: { data: EncryptData(data) },
    };
    let Resp = await axiosFunc(adddata);
    return Resp;
  });

  const DeliveryTimeChargeAction = async (data) => {
    var adddata = {
      method: "post",
      url: `/admin/deliverytimecharge`,
      data: { data: EncryptData(data) },
    };
    let Resp = await axiosFunc(adddata);
    return Resp;
  };

  const SellerAction = async (data) => {
    var formdata = AppenData(data);
    console.log("kshjskfdfs", data, formdata[0]);
    var adddata = {
      method: "post",
      url: `/admin/selleraction`,
      data: formdata[0],
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    let Resp = await axiosFunc(adddata);
    return Resp;
  };

  const ShopAction = async (data) => {
    var formdata = AppenData(data);
    var adddata = {
      method: "post",
      url: `/admin/shopaction`,
      data: formdata[0],
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    let Resp = await axiosFunc(adddata);
    return Resp;
  };

  const getsellercatList = async (data) => {
    console.log("gdgdgdg");
    try {
      var senddata = {
        method: "get",
        params: { data: EncryptData(data) },
        url: `/seller/getsellercategory`,
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {
      console.log("subcat", err);
    }
  };

  const menudetail = handleCallback(async (data) => {
    var resp = {
      method: "post",
      url: `/seller/menudetail`,
      data: { data: EncryptData(data) },
    };
    let Resp = await axiosFunc(resp);
    console.log("gfgfg", Resp);
    return Resp;
  });

  const getproduct = async (data) => {
    try {
      var senddata = {
        method: "get",
        url: `/seller/getprodut`,
        params: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {
      console.log("getcat", err);
    }
  };

  const AddProduct = async (data) => {
    console.log("AddProductaaaaa", data);
    var formdata = AppenData(data);
    try {
      var senddata = {
        method: "POST",
        url: `/seller/addproduct`,
        data: formdata[0],
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {
      console.log("AddProduct error", err);
      return false;
    }
  };

  const addsellercat = async (data) => {
    console.log("addoffer", data);

    try {
      var senddata = {
        method: "POST",
        url: `/seller/categoryaction`,
        data: { data: EncryptData(data) },
      };
      let Resp = await axiosFunc(senddata);
      return Resp;
    } catch (err) {
      console.log("Addoffer error", err);
      return false;
    }
  };

  return {
    LoginReg,
    AlreadyExits,
    getusers,
    getuserview,
    getvendorList,
    TestApi,
    addFaq,
    addCms,
    getFaqList,
    getCmsList,
    CouponList,
    getCouponList,
    getFaq,
    getCms,
    addinsert,
    datalist,
    alldata,
    detailinsert,
    showdata,
    viewdata,
    newinsert,
    showinglists,
    Adddetails,
    emailsend,
    Cmsadd,
    idverify,
    showuserpanel,
    listcms,
    couponsend,

    getvendorListview,
    getodercom,
    getoderreview,
    GetCouponDetail,
    AddMainCategory,
    getcatList,
    getcat,
    getsubcatList,
    AddSubCategory,
    getsubcat,
    hideShowCat,
    hideshowfaq,
    hideshowcms,
    hideShowsubCat,
    DriverAction,
    GetDriver,
    GetDriverDetail,
    GetDashBoardDatas,
    getProduct,
    GetOrders,
    GetShop,
    OrderStatusUpdate,
    OrderDetail,
    AssignDriver,
    DeliveryTimeChargeAction,
    SellerAction,
    ShopAction,
    getsellercatList,
    menudetail,
    getproduct,
    AddProduct,
    addsellercat,
  };
};

export default useAxiosFile;
