import React, { useEffect, useState } from "react";
import Sidebar from "../../../layouts/sidebar";
import Headers from "../../../layouts/header";
import Select from "react-select";
import Datetime from "react-datetime";
import moment from "moment";
import { Nav, Tab, Row, Col } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-datetime/css/react-datetime.css";
import useAxiosFile from "../../../actions/useaxios";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DecryptData, EncryptData, isEmpty } from "../../../actions/common";
// import useAxiosFile from '../../../actions/useaxios';
import { useParams, useHistory } from "react-router-dom";
function ManagementUserView() {
  const [opacity, setOpacity] = useState("false");
  const [opacityEnd, setEndOpacity] = useState("false");
  const { token, payload } = useSelector((state) => state.admin_panel.User);
  const [Id, Setid] = useState();
  const { toggle } = useSelector((state) => state.admin_panel.User);
  console.log("ffd", payload);
  const location = useLocation();
  const axiosFile = useAxiosFile();

  // const { Id } = useParams()

  const [user, setuserlist] = useState({});
  const [order, setoder] = useState();

  const [review, setreview] = useState();

  const [selectedOption, setSelectedOption] = useState(null);
  const [activeTab, setActiveTab] = useState("profile");
  useEffect(() => {
    if (Id) getuserListview();
  }, [Id]);

  const getuserListview = async () => {
    var SendData = {
      userId: isEmpty(DecryptData(Id)) ? Id : DecryptData(Id),
    };
    var resp = await axiosFile.getuserview(SendData);

    resp.data.map((val) => {
      setuserlist(val);
    });
  };

  const getodercom = async (data) => {
    var SendData = {
      userId: isEmpty(DecryptData(Id)) ? Id : DecryptData(Id),
      tabname: data,
    };
    var resp = await axiosFile.getodercom(SendData);
    console.log("oder", resp);
    if (resp) {
      resp?.map((val, idx) => {
        val.dataField = idx + 1;
      });
      setoder(resp);
    }
  };

  const getuserview = async () => {
    var SendData = {
      userId: isEmpty(DecryptData(Id)) ? Id : DecryptData(Id),
    };
    var resp = await axiosFile.getoderreview(SendData);

    setreview(resp);
  };

  useEffect(() => {
    console.log("kjsfsjfs", location.search.split("?Id="));
    if (location?.search) {
      let paramdata = location.search.split("?Id=")[1];
      console.log("llsflklfk", DecryptData(paramdata));
      Setid(
        isEmpty(DecryptData(paramdata)) ? paramdata : DecryptData(paramdata)
      );
    }
  }, [location.search]);

  const columns = [
    { dataField: "dataField", text: "No" },
    { dataField: "_id", text: "Order ID" },
    { dataField: "ShopId.BrandName", text: "Vendor Name", sort: true },
    { dataField: "UserId.FirstName", text: "Customer Name", sort: true },
    // {dataField:"PaymentType",text:"Type",sort:true},
    { dataField: "PaymentType", text: "Payment", sort: true },
    { dataField: "DeliveryTime", text: "Time", sort: true },
    { dataField: "Price", text: "Amount", sort: true },
    // {dataField:"action",text:"Action"},
  ];

  const data = [
    {
      no: "1",
      orderid: "#1569",
      vendorName: "MC Donlad’s",
      customerName: "John Doe",
      type: <p className="table_blurblue">Delivery</p>,
      payment: <p className="table_green">Online</p>,
      time: "1/17/2022  8:20 PM",
      tips: "$1.00",
      amount: <p className="table_bluetxt">$5.00</p>,

      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item table_viewborbot">View</a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },

    {
      no: "1",
      orderid: "#1569",
      vendorName: "MC Donlad’s",
      customerName: "John Doe",
      type: <p className="table_blurblue">Delivery</p>,
      payment: <p className="table_green">Online</p>,
      time: "1/17/2022  8:20 PM",
      tips: "$1.00",
      amount: <p className="table_bluetxt">$5.00</p>,
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item table_viewborbot">View</a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      no: "1",
      orderid: "#1569",
      vendorName: "MC Donlad’s",
      customerName: "John Doe",
      type: <p className="table_blurblue">Delivery</p>,
      payment: <p className="table_green">Online</p>,
      time: "1/17/2022  8:20 PM",
      tips: "$1.00",
      amount: <p className="table_bluetxt">$5.00</p>,
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item table_viewborbot">View</a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      no: "1",
      orderid: "#1569",
      vendorName: "MC Donlad’s",
      customerName: "John Doe",
      type: <p className="table_blurblue">Delivery</p>,
      payment: <p className="table_green">Online</p>,
      time: "1/17/2022  8:20 PM",
      tips: "$1.00",
      amount: <p className="table_bluetxt">$5.00</p>,
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item table_viewborbot">View</a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      no: "1",
      orderid: "#1569",
      vendorName: "MC Donlad’s",
      customerName: "John Doe",
      type: <p className="table_blurblue">Delivery</p>,
      payment: <p className="table_green">Online</p>,
      time: "1/17/2022  8:20 PM",
      tips: "$1.00",
      amount: <p className="table_bluetxt">$5.00</p>,
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item table_viewborbot">View</a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  const reviewcolumns = [
    { dataField: "_id", text: "Order ID" },
    { dataField: "ShopId.BrandName", text: "Vendor Name", sort: true },
    // {dataField:"address",text:"Address",sort:true},
    { dataField: "Rating", text: "Rating", sort: true },
    { dataField: "Comments", text: "Review", sort: true },
    { dataField: "createdAt", text: "Date", sort: true },
    // {dataField:"action",text:"Action"},
  ];

  const reviewdata = [
    {
      orderid: "#1569",
      vendorname: "John Doe",
      address: "515 516 0624",
      rating: (
        <div className="table_startxt">
          <p className="table_blurblue">2</p>
          <img src={require("../../../assests/images/Union.png")} />
        </div>
      ),
      review: "Ipsum is simply dummy text of the printing industry. ",
      date: "10/10/2022",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item table_viewborbot">View</a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      orderid: "#1569",
      vendorname: "John Doe",
      address: "515 516 0624",
      rating: (
        <div className="table_startxt">
          <p className="table_blurblue">2</p>
          <img src={require("../../../assests/images/Union.png")} />
        </div>
      ),
      review: "Ipsum is simply dummy text of the printing industry. ",
      date: "10/10/2022",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item table_viewborbot">View</a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      orderid: "#1569",
      vendorname: "John Doe",
      address: "515 516 0624",
      rating: (
        <div className="table_startxt">
          <p className="table_blurblue">2</p>
          <img src={require("../../../assests/images/Union.png")} />
        </div>
      ),
      review: "Ipsum is simply dummy text of the printing industry. ",
      date: "10/10/2022",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item table_viewborbot">View</a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      orderid: "#1569",
      vendorname: "John Doe",
      address: "515 516 0624",
      rating: (
        <div className="table_startxt">
          <p className="table_blurblue">2</p>
          <img src={require("../../../assests/images/Union.png")} />
        </div>
      ),
      review: "Ipsum is simply dummy text of the printing industry. ",
      date: "10/10/2022",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item table_viewborbot">View</a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      orderid: "#1569",
      vendorname: "John Doe",
      address: "515 516 0624",
      rating: (
        <div className="table_startxt">
          <p className="table_blurblue">2</p>
          <img src={require("../../../assests/images/Union.png")} />
        </div>
      ),
      review: "Ipsum is simply dummy text of the printing industry. ",
      date: "10/10/2022",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item table_viewborbot">View</a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  const gender = [
    { value: "male", label: "Male" },
    { value: "female", label: "FeMale" },
    { value: "others", label: "Others" },
  ];

  const driveroptions = [
    { value: "idle", label: "Idle" },
    { value: "intransit", label: "In Transit" },
    { value: "offline", label: "Offline" },
    { value: "active", label: "Active" },
  ];

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#F5F6F7"
        : isSelected
        ? "#F5F6F7"
        : isHovered
        ? "red"
        : "#F5F6F7",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#a71d24"
          : isSelected
          ? "#a71d24"
          : isFocused
          ? "#a71d24"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 20px",
      backgroundColor: "#fff ",
      border: "1px solid lightgray",
      borderRadius: 10,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#6C6A81",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const stylegreendrop = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#F5F6F7"
        : isSelected
        ? "#F5F6F7"
        : isHovered
        ? "red"
        : "#F5F6F7",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#a71d24"
          : isSelected
          ? "#a71d24"
          : isFocused
          ? "#a71d24"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 20px",
      backgroundColor: "#fff ",
      border: "1px solid #00B167",
      borderRadius: 10,
      fontSize: "13px",
      color: "#00B167",
      fontWeight: 700,
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#00B167",
      fontWeight: 700,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#00B167",
      fontWeight: 700,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {},
    onSizePerPageChange: function (page, sizePerPage) {},
  });
  return (
    <>
      <div>
        <div className="innerpages">
          <div className="orders_pages">
            <Headers />
            <div className="container custom_container px-lg-0">
              <div className="row mx-auto">
                <div className="innerpages_wid px-0">
                  <Sidebar />
                  <div
                    className={
                      toggle
                        ? "content-sec-inner right_widthset content_without_sidebar"
                        : "content-sec-inner  content_with_sidebar"
                    }
                  >
                    <section id="content-wrapper">
                      <div class="row">
                        <div className="top_title_sec">
                          <div>
                            <p className="page_title">{user?.FirstName} </p>
                          </div>
                          {/* <div className='rightsec'>
        <p>Accepting Orders
        <div className='togswit'>
  <input type="checkbox" id="toggle-btn"/>
  <label for="toggle-btn"></label>
</div>
        </p>
      </div> */}
                        </div>
                      </div>
                      {/* profile details */}
                      <div className="mt-4">
                        <div className="mdvsec_border sec_border">
                          <div className="row mdv_driverdtls_whole">
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                              <div className="mdv_driverdtls">
                                {/* <img className='tab_dummyimg me-3' src={require('../../../assests/images/dummyimg.png')}/> */}
                                <div>
                                  <p className="mdv_drivername">
                                    {user?.FirstName}{" "}
                                  </p>
                                  <div className="mdv_locationtext">
                                    {/* <img className='mdv_location' src={require('../../../assests/images/location.png')}/> */}
                                    {/* <p>{user?.FirstName}</p> */}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                              {activeTab == "rating" ? (
                                <div className="rightside userview_rightside">
                                  <div className="whole_searchbar white_bg">
                                    <img
                                      className="search_glass"
                                      src={require("../../../assests/images/Search.png")}
                                    />
                                    <input placeholder="Search..." />
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}

                              {/* {activeTab == "profile" ? 
        <div className='rightside'>
        <div className='whole_searchbar white_bg'>
   <img className='search_glass' src={require('../../../assests/images/Search.png')}/>
  <input placeholder="Search..."/>
   </div>
        <div className='rightside_select'>
        <Select
                            options={driveroptions}
                            defaultValue={selectedOption}
                            styles={stylegreendrop}
                            onChange={setSelectedOption}
                            className="border_blue_select"
                            classNamePrefix="react-select"
                            placeholder='Status'
                       
                          />
                          </div>


        </div> : <></>} */}

                              {activeTab == "delivered" ||
                              activeTab == "rejected" ? (
                                <div className="rightside userview_rightside">
                                  <div className="rightside_select">
                                    <Select
                                      options={options}
                                      defaultValue={selectedOption}
                                      styles={stylesgraybg}
                                      onChange={setSelectedOption}
                                      className="border_blue_select"
                                      classNamePrefix="react-select"
                                      placeholder="Type"
                                    />
                                  </div>

                                  <div className="order_calendar_div">
                                    <p
                                      className={
                                        opacity == "true" ? "hiding_caltxt" : ""
                                      }
                                    >
                                      Start Date
                                    </p>
                                    <img
                                      className="order_calendar_ic"
                                      src={require("../../../assests/images/calendar.png")}
                                    />
                                    <Datetime
                                      onChange={() => {
                                        setOpacity("true");
                                      }}
                                      className="order_datepick"
                                    />
                                  </div>

                                  <p className="order_totxt">To</p>
                                  <div className="order_calendar_div">
                                    <p
                                      className={
                                        opacityEnd == "true"
                                          ? "hiding_calendtxt"
                                          : ""
                                      }
                                    >
                                      End Date
                                    </p>
                                    <img
                                      className="order_calendar_ic"
                                      src={require("../../../assests/images/calendar.png")}
                                    />
                                    <Datetime
                                      onChange={() => {
                                        setEndOpacity("true");
                                      }}
                                      className="order_datepick"
                                    />
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                          <div className="mdv_drivertab">
                            <p
                              className={
                                activeTab == "profile"
                                  ? "mdv_tabtext active"
                                  : "mdv_tabtext"
                              }
                              onClick={() => setActiveTab("profile")}
                            >
                              Profile
                            </p>
                            {/* <p className={activeTab == "delivered" ? 'mdv_tabtext active' : 'mdv_tabtext'} onClick={() => {setActiveTab("delivered");getodercom("completed")}}>Completed Orders</p>
        <p className={activeTab == "rejected" ? 'mdv_tabtext active' : 'mdv_tabtext'} onClick={() => {setActiveTab("rejected");getodercom("cancel")}}>Canceled Orders</p>
        <p className={activeTab == "rating" ? 'mdv_tabtext active' : 'mdv_tabtext'} onClick={() => {setActiveTab("rating");getuserview()}}>Ratings & Reviews</p> */}
                            {/* <p className={activeTab == "changepw" ? 'mdv_tabtext active' : 'mdv_tabtext'} onClick={() => setActiveTab("changepw")}>Change Password</p> */}
                          </div>
                        </div>
                        {console.log("ksgskljgklsjgls", user)}
                        {/* profile details */}
                        {activeTab == "profile" && (
                          <>
                            <div className="mdv_profiledtls">
                              <div className="mdv_border_div">
                                <p className="mdv_profilettl">
                                  Personal Information
                                </p>
                              </div>
                              <Row className="mdv_inputrow">
                                <Col
                                  xxl={4}
                                  xl={4}
                                  lg={4}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <p className="mdv_inplabel">First Name</p>
                                  <input
                                    className="mdv_cmninput"
                                    type="text"
                                    placeholder=""
                                    value={user?.FirstName}
                                    disabled={true}
                                  />
                                </Col>
                                <Col
                                  xxl={4}
                                  xl={4}
                                  lg={4}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <p className="mdv_inplabel">Last Name</p>
                                  <input
                                    className="mdv_cmninput"
                                    type="text"
                                    placeholder=""
                                    value={user?.LastName}
                                    disabled={true}
                                  />
                                </Col>
                                <Col
                                  xxl={4}
                                  xl={4}
                                  lg={4}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <p className="mdv_inplabel">Email Address</p>
                                  <input
                                    className="mdv_cmninput"
                                    type="email"
                                    value={user.EmailId}
                                    placeholder=""
                                    disabled={true}
                                  />
                                </Col>

                                <Col
                                  xxl={4}
                                  xl={4}
                                  lg={4}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  {console.log("kfjjfhsdjkhsdg", user)}
                                  <p className="mdv_inplabel">Phone Number</p>
                                  <input
                                    className="mdv_cmninput"
                                    type="text"
                                    value={user.Mobile}
                                    placeholder=""
                                    disabled={true}
                                  />
                                </Col>
                                <Col
                                  xxl={4}
                                  xl={4}
                                  lg={4}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <p className="mdv_inplabel">Date Of Birth</p>
                                  <input
                                    className="mdv_cmninput"
                                    type="text"
                                    value={user.DateofBirth}
                                    placeholder=""
                                    disabled={true}
                                  />
                                </Col>
                                <Col
                                  xxl={4}
                                  xl={4}
                                  lg={4}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <p className="mdv_inplabel">Gender</p>

                                  <input
                                    className="mdv_cmninput"
                                    type="email"
                                    value={user.Gender}
                                    placeholder=""
                                    disabled={true}
                                  />
                                </Col>
                              </Row>
                            </div>

                            {/* <div className="mdv_profiledtls">
                              <div className="mdv_border_div">
                                <p className="mdv_profilettl">Location</p>
                              </div>
                              <Row className="mdv_inputrow">
                                <Col
                                  xxl={4}
                                  xl={4}
                                  lg={4}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <p className="mdv_inplabel">Street Number</p>
                                  <input
                                    className="mdv_cmninput"
                                    type="text"
                                    placeholder=""
                                    value={user?.Address?.StreetNumber}
                                    disabled={true}
                                  />
                                </Col>
                                <Col
                                  xxl={4}
                                  xl={4}
                                  lg={4}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <p className="mdv_inplabel">Street Name</p>
                                  <input
                                    className="mdv_cmninput"
                                    type="text"
                                    placeholder=""
                                    value={user?.Address?.StreetName}
                                    disabled={true}
                                  />
                                </Col>
                                <Col
                                  xxl={4}
                                  xl={4}
                                  lg={4}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <p className="mdv_inplabel">Unit</p>
                                  <input
                                    className="mdv_cmninput"
                                    type="text"
                                    placeholder=""
                                    value={user?.Address?.Unit}
                                    disabled={true}
                                  />
                                </Col>

                                <Col
                                  xxl={3}
                                  xl={3}
                                  lg={3}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <p className="mdv_inplabel">City</p>
                                  <input
                                    className="mdv_cmninput"
                                    type="text"
                                    placeholder=""
                                    value={user?.Address?.City}
                                    disabled={true}
                                  />
                                </Col>
                                <Col
                                  xxl={3}
                                  xl={3}
                                  lg={3}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <p className="mdv_inplabel">State</p>
                                  <input
                                    className="mdv_cmninput"
                                    type="text"
                                    placeholder=""
                                    value={user?.Address?.State}
                                    disabled={true}
                                  />
                                </Col>
                                <Col
                                  xxl={3}
                                  xl={3}
                                  lg={3}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <p className="mdv_inplabel">Country</p>
                                  <input
                                    className="mdv_cmninput"
                                    type="text"
                                    placeholder=""
                                    value={user?.Address?.Country}
                                    disabled={true}
                                  />
                                </Col>
                                <Col
                                  xxl={3}
                                  xl={3}
                                  lg={3}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <p className="mdv_inplabel">Zip Code</p>
                                  <input
                                    className="mdv_cmninput"
                                    type="text"
                                    placeholder=""
                                    value={user?.Address?.ZipCode}
                                    disabled={true}
                                  />
                                </Col>
                              </Row>
                            </div> */}
                          </>
                        )}
                      </div>

                      {/* end of profile details */}

                      {/* delivered details */}
                      {activeTab == "delivered" && (
                        <div className="mdvsec_border">
                          <div className="mdv_driverdtls_whole">
                            {order && order && (
                              <div className="ffd_table">
                                <BootstrapTable
                                  bootstrap4
                                  keyField="id"
                                  columns={columns}
                                  data={order}
                                  pagination={pagination}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {/* end of delivered details */}

                      {/* rejected details */}
                      {activeTab == "rejected" && (
                        <div className="mdvsec_border ">
                          <div className="mdv_driverdtls_whole">
                            {order && order && (
                              <div className="ffd_table">
                                <BootstrapTable
                                  bootstrap4
                                  keyField="id"
                                  columns={columns}
                                  data={order}
                                  pagination={pagination}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {/* end of rejected details */}

                      {/* ratings & reviews details */}

                      {activeTab == "rating" && (
                        <div className="mdvsec_border">
                          <div className=" mdv_driverdtls_whole">
                            {review && review && (
                              <div className="ffd_table">
                                <BootstrapTable
                                  bootstrap4
                                  keyField="id"
                                  columns={reviewcolumns}
                                  data={review}
                                  pagination={pagination}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      {/* end of ratings & reviews details */}

                      {/* change password details */}

                      {activeTab == "changepw" && (
                        <div className="mdv_profiledtls">
                          <div className="mdv_border_div">
                            <p className="mdv_profilettl">Change Password</p>
                          </div>
                          <Row className="mdv_inputrow">
                            <Col
                              xxl={4}
                              xl={4}
                              lg={4}
                              md={6}
                              sm={6}
                              xs={12}
                              className="mb-3"
                            >
                              <p className="mdv_inplabel">New Password</p>
                              <input
                                className="mdv_cmninput"
                                type="password"
                                placeholder="******"
                              />
                            </Col>
                            <Col
                              xxl={4}
                              xl={4}
                              lg={4}
                              md={6}
                              sm={6}
                              xs={12}
                              className="mb-3"
                            >
                              <p className="mdv_inplabel">Confirm Password</p>
                              <input
                                className="mdv_cmninput"
                                type="password"
                                placeholder="******"
                              />
                            </Col>

                            <div className="mdv_update_dualbtn">
                              <button className="blue_btn mdv_update">
                                Update
                              </button>
                              <button className="seconday_btn">Cacnel</button>
                            </div>
                          </Row>
                        </div>
                      )}

                      {/* end of change password details */}
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManagementUserView;
