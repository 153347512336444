import React, { useEffect, useState } from "react";
import Sidebar from "../../../layouts/sidebar";
import Headers from "../../../layouts/header";
import Select from "react-select";
import { toast } from "react-toastify";
import { Nav, Tab, Row, Col, Toast } from "react-bootstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-datetime/css/react-datetime.css";

import { useSelector } from "react-redux";

import { useLocation, useNavigate } from "react-router";
import { DecryptData, isEmpty } from "../../../actions/common";
import useAxiosFile from "../../../actions/useaxios";
import config from "../../../actions/config";
import { data } from "jquery";
const Currencies = [
  { value: "usd", label: "USD" },
  { value: "INR", label: "INR" },
  { value: "EURO", label: "EURO" },
];

const initname = {
  title: "",
  CouponDescription: "",
  StartTime: "",
  ExpireTime: "",
  MinimumBuy: "",
  CurrencyType: "",
  OfferCode: "",
  offersType: "", // offersType - All , BuyGet , Discount ,Cashback
  DiscountInPercentange: "",
  DiscountInPrice: "",
  ProductId: [],
  UserId: "",
  Creator: "ADMIN",
  offerImage: "",
  BuyProductId: [],
  GetProductId: [],
  Count: "",
};

const offersTypeOptions = [
  { value: "BuyGet", label: "Buy Get" },
  { value: "Discount", label: "Discount" },
  { value: "Price", label: "Price" },
];

const initfile = {
  License: {},
};

function Coupons() {
  const location = useLocation();
  const navigate = useNavigate();

  const axiosFile = useAxiosFile();
  // console.log("adsd",location.state.data);
  const [Id, Setid] = useState(DecryptData(location.search.split("?Id=")[1]));
  const [driverdata, SetDriverData] = useState({});
  const [displayname, SetDisplayName] = useState(initname);
  const [Error, SetError] = useState({});
  // const[ValidateError,SetValidateError] = useSta
  const [Files, SetFiles] = useState(initfile);
  const [Formdata, SetFormdata] = useState(initname);
  const [AllProduct, SetAllProduct] = useState(initname);
  const [daata, setDaata] = useState();

  useEffect(() => {
    getProduct();
    GetDriverdetail();
  }, []);
  console.log("sdahfjhshfjksd", Formdata);
  const getProduct = async () => {
    let Resp = await axiosFile.getProduct();
    setDaata(Resp);
    var data = Resp.map((items) => {
      return {
        value: items._id,
        label: items.ProductName,
      };
    });
    console.log("mvbn", data);

    SetAllProduct(data);
  };
  const GetDriverdetail = async () => {
    if (Id) {
      let Resp = await axiosFile.GetCouponDetail({ id: Id });
      console.log("ghfd", Resp);
      Resp.data.disable = Resp.data.creatortype == "admin" ? false : true;
      SetFormdata(Resp.data);
    }
  };

  const { toggle } = useSelector((state) => state.admin_panel.User);

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#F5F6F7"
        : isSelected
        ? "#F5F6F7"
        : isHovered
        ? "red"
        : "#F5F6F7",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#a71d24"
          : isSelected
          ? "#a71d24"
          : isFocused
          ? "#a71d24"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 20px",
      backgroundColor: "#fff ",
      border: "1px solid lightgray",
      borderRadius: 10,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#6C6A81",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  console.log("dsfg", Formdata);
  const OnChange = (e, ids) => {
    console.log("jn", e, ids);
    var { id, value, files, label } = e.target ?? e;
    id = id ?? ids;

    SetFormdata({
      ...Formdata,
      ...{
        [id]: files?.length > 0 ? files[0] : value,
      },
    });
  };

  const Validation = () => {
    console.log("jjuhju", Formdata);
    var errors = {};
    if (isEmpty(Formdata.title)) errors.title = "Title is required";
    if (isEmpty(Formdata.CouponDescription))
      errors.CouponDescription = "Description is required";
    if (isEmpty(Formdata.StartTime)) errors.StartTime = "StartTime is required";
    if (isEmpty(Formdata.ExpireTime))
      errors.ExpireTime = "ExpreTime is required";
    if (new Date(Formdata.StartTime) >= new Date(Formdata.ExpireTime))
      errors.ExpireTime = "Set the Correct ExpireTime";
    console.log(
      "sdfjkvn ",
      Date.now(StartTime) >= Date.now(ExpireTime),
      Date.now(StartTime),
      Date.now(ExpireTime),
      StartTime,
      ExpireTime
    );
    if (isEmpty(Formdata.MinimumBuy))
      errors.MinimumBuy = "Minimum  is required";
    // if(Formdata?.MinimumBuy && isEmpty(Formdata.CurrencyType)) errors.CurrencyType = "Currency Type is required"
    if (isEmpty(Formdata.OfferCode)) errors.OfferCode = "OfferCode is required";
    if (isEmpty(Formdata.offersType))
      errors.offersType = "Offers Type is required";
    if (Formdata.offersType == "BuyGet" && isEmpty(Formdata.BuyProductId))
      errors.BuyProductId = "Buy ProductId is required";
    if (Formdata.offersType == "BuyGet" && isEmpty(Formdata.GetProductId))
      errors.GetProductId = "Get ProductId is required";
    if (
      Formdata.offersType == "Discount" &&
      isEmpty(Formdata.DiscountInPercentange)
    )
      errors.DiscountInPercentange = "Discount In Percentange is required";
    if (Formdata.offersType == "Price" && isEmpty(Formdata.DiscountInPrice))
      errors.DiscountInPrice = "Discount In Price is required";
    if (isEmpty(Formdata.Count)) errors.Count = "Total is required";
    if (!Formdata.offerImage) errors.offerImage = "Image is required";
    if (isEmpty(Formdata.ProductId)) errors.ProductName = "Product is required";

    console.log("jkdnf", errors);
    return errors;
  };

  const Submit = async () => {
    // let id = LoadToast()

    let validate = Validation();
    console.log("iuy", validate, isEmpty(validate));

    if (isEmpty(validate)) {
      let senddata = Formdata;

      console.log("dfg", senddata, Formdata);

      if (Id) {
        senddata.action = "edit";
      } else {
        senddata.action = "add";
      }
      senddata.from = "Admin";

      // let Resp = await axiosFile.DriverAction(senddata);

      let Resp = await axiosFile.getCouponList(senddata);

      if (Resp?.success == "success") {
        navigate("/couponslist", { state: senddata });
        toast.success("Added Successfully", 1000);
      } else {
        toast.error("Try-Again", 1000);
      }
    } else {
      // updateToast(id, "fix all validation", "error")

      SetError(validate);
      toast.error("Fix all Validations", 1000);
    }
  };

  return (
    <>
      <div>
        <div className="innerpages">
          <div className="orders_pages">
            <Headers />
            <div className="container custom_container px-lg-0">
              <div className="row mx-auto">
                <div className="innerpages_wid px-0">
                  <Sidebar />
                  <div
                    className={
                      toggle
                        ? "content-sec-inner right_widthset content_without_sidebar"
                        : "content-sec-inner  content_with_sidebar"
                    }
                  >
                    <section id="content-wrapper">
                      <div class="row">
                        <div className="top_title_sec">
                          <div>
                            <p className="page_title">{"Add Coupons"}</p>
                          </div>
                        </div>
                      </div>
                      {/* profile details */}
                      <div className="mt-4">
                        <div className="mdv_profiledtls">
                          <Row className="mdv_inputrow">
                            <Col
                              xxl={4}
                              xl={4}
                              lg={4}
                              md={6}
                              sm={6}
                              xs={12}
                              className="mb-3"
                            >
                              <p className="mdv_inplabel">Title</p>
                              <input
                                className="mdv_cmninput"
                                type="text"
                                placeholder="John"
                                id="title"
                                value={Formdata?.title || ""}
                                // disabled={Formdata?.title}
                                onChange={(e) => OnChange(e)}
                              />
                              {Error?.title && (
                                <span className="error-msg">
                                  {Error?.title}
                                </span>
                              )}
                            </Col>
                            <Col
                              xxl={4}
                              xl={4}
                              lg={4}
                              md={6}
                              sm={6}
                              xs={12}
                              className="mb-3"
                            >
                              <p className="mdv_inplabel">Description</p>
                              <input
                                className="mdv_cmninput"
                                type="text"
                                placeholder="John"
                                value={Formdata?.CouponDescription || ""}
                                // disabled={Formdata?.CouponDescription}
                                id="CouponDescription"
                                onChange={(e) => OnChange(e)}
                              />
                              {Error?.CouponDescription && (
                                <span className="error-msg">
                                  {Error?.CouponDescription}
                                </span>
                              )}
                            </Col>
                            <Col
                              xxl={4}
                              xl={4}
                              lg={4}
                              md={6}
                              sm={6}
                              xs={12}
                              className="mb-3"
                            >
                              <p className="mdv_inplabel">Start Time</p>
                              <input
                                className="mdv_cmninput"
                                type="datetime-local"
                                placeholder="Doe"
                                value={Formdata.StartTime || ""}
                                // disabled={Formdata.StartTime}
                                id="StartTime"
                                onChange={(e) => OnChange(e)}
                              />
                              {Error?.StartTime && (
                                <span className="error-msg">
                                  {Error?.StartTime}
                                </span>
                              )}
                            </Col>
                            <Col
                              xxl={4}
                              xl={4}
                              lg={4}
                              md={6}
                              sm={6}
                              xs={12}
                              className="mb-3"
                            >
                              <p className="mdv_inplabel">Expire Time</p>
                              <input
                                className="mdv_cmninput"
                                type="datetime-local"
                                placeholder="john@jamkse.com"
                                value={Formdata.ExpireTime || ""}
                                // disabled={Formdata.ExpireTime}
                                id="ExpireTime"
                                onChange={(e) => OnChange(e)}
                              />
                              {Error?.ExpireTime && (
                                <span className="error-msg">
                                  {Error.ExpireTime}
                                </span>
                              )}
                            </Col>

                            <Col
                              xxl={4}
                              xl={4}
                              lg={4}
                              md={6}
                              sm={6}
                              xs={12}
                              className="mb-3"
                            >
                              <p className="mdv_inplabel">Minimum Buy Amount</p>
                              <input
                                className="mdv_cmninput"
                                type="number"
                                placeholder="123"
                                value={Formdata.MinimumBuy || ""}
                                // disabled={Formdata.MinimumBuy}
                                id="MinimumBuy"
                                onChange={(e) => OnChange(e)}
                              />
                              {Error?.MinimumBuy && (
                                <span className="error-msg">
                                  {Error?.MinimumBuy}
                                </span>
                              )}
                            </Col>

                            {/* <Col
                              xxl={4}
                              xl={4}
                              lg={4}
                              md={6}
                              sm={6}
                              xs={12}
                              className="mb-3"
                            >
                              <p className="mdv_inplabel">Currency Type</p>
                              <Select
                                options={Currencies}
                                styles={stylesgraybg}
                                onChange={(e) => OnChange(e,"CurrencyType")}
                                className="border_blue_select"
                                classNamePrefix="react-select"
                                placeholder="Select Option"
                                id="CurrencyType"
                                value={
                                  Formdata.CurrencyType
                                    ? {
                                        label: Formdata.CurrencyType,
                                        value: Formdata.CurrencyType,
                                      }
                                    : ""
                                }
                                // disabled={Formdata.CurrencyType}
                              />
                            </Col> */}

                            <Col
                              xxl={4}
                              xl={4}
                              lg={4}
                              md={6}
                              sm={6}
                              xs={12}
                              className="mb-3"
                            >
                              <p className="mdv_inplabel">Offer Code</p>
                              {/* <input className='mdv_cmninput' type="email" placeholder="john@jamkse.com"/> */}

                              <input
                                className="mdv_cmninput"
                                type="text"
                                placeholder="Offer Codes"
                                id="OfferCode"
                                value={Formdata.OfferCode}
                                onChange={(e) => OnChange(e)}
                              />

                              {Error?.OfferCode && (
                                <span className="error-msg">
                                  {Error?.OfferCode}
                                </span>
                              )}
                            </Col>

                            <Col
                              xxl={4}
                              xl={4}
                              lg={4}
                              md={6}
                              sm={6}
                              xs={12}
                              className="mb-3"
                            >
                              <p className="mdv_inplabel">offers Type</p>
                              <Select
                                options={offersTypeOptions}
                                styles={stylesgraybg}
                                onChange={(e) => OnChange(e, "offersType")}
                                className="border_blue_select"
                                classNamePrefix="react-select"
                                placeholder="Select Options"
                                value={
                                  Formdata.offersType
                                    ? {
                                        label: Formdata.offersType,
                                        value: Formdata.offersType,
                                      }
                                    : ""
                                }
                                // disabled={Formdata.offersType}
                              />

                              {Error?.offersType && (
                                <span className="error-msg">
                                  {Error?.offersType}
                                </span>
                              )}
                            </Col>
                            {Formdata?.offersType == "Discount" ? (
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">
                                  Discount In Percentange
                                </p>
                                <input
                                  className="mdv_cmninput"
                                  type="text"
                                  placeholder="Doe"
                                  id="DiscountInPercentange"
                                  value={Formdata.DiscountInPercentange}
                                  onChange={(e) => OnChange(e)}
                                />
                                {Error?.DiscountInPercentange && (
                                  <span className="error-msg">
                                    {Error?.DiscountInPercentange}
                                  </span>
                                )}
                              </Col>
                            ) : Formdata?.offersType == "Price" ? (
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">
                                  Buy Product By Id
                                </p>
                                <input
                                  className="mdv_cmninput"
                                  type="text"
                                  placeholder="Doe"
                                  id="DiscountInPrice"
                                  onChange={(e) => OnChange(e)}
                                />
                                {Error?.DiscountInPrice && (
                                  <span className="error-msg">
                                    {Error?.DiscountInPrice}
                                  </span>
                                )}
                              </Col>
                            ) : (
                              Formdata?.offersType == "BuyGet" && (
                                <>
                                  <Col
                                    xxl={4}
                                    xl={4}
                                    lg={4}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="mb-3"
                                  >
                                    <p className="mdv_inplabel">
                                      Add and Find Buy Products
                                    </p>
                                    <Select
                                      isMulti
                                      options={AllProduct}
                                      styles={stylesgraybg}
                                      onChange={(e) =>
                                        OnChange(e, "BuyProductId")
                                      }
                                      className="border_blue_select"
                                      classNamePrefix="react-select"
                                      placeholder="A...."

                                      // disabled={Formdata.BuyProductId.length>0}
                                    />
                                    {Error?.BuyProductId && (
                                      <span className="error-msg">
                                        {Error?.BuyProductId}
                                      </span>
                                    )}
                                  </Col>
                                  <Col
                                    xxl={4}
                                    xl={4}
                                    lg={4}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="mb-3"
                                  >
                                    <p className="mdv_inplabel">
                                      Add and Find Get Products
                                    </p>
                                    <Select
                                      isMulti
                                      options={AllProduct}
                                      styles={stylesgraybg}
                                      onChange={(e) =>
                                        OnChange(e, "GetProductId")
                                      }
                                      className="border_blue_select"
                                      classNamePrefix="react-select"
                                      placeholder="A..."

                                      // disabled={Formdata.BuyProductId.length>0}
                                    />
                                    {Error?.GetProductId && (
                                      <span className="error-msg">
                                        {Error?.GetProductId}
                                      </span>
                                    )}
                                  </Col>
                                </>
                              )
                            )}
                            {(Formdata?.offersType == "Discount" ||
                              Formdata?.offersType == "Price") && (
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel"> Products </p>
                                <Select
                                  isMulti
                                  options={AllProduct}
                                  styles={stylesgraybg}
                                  value={daata?.map((item) => {
                                    if (
                                      Formdata?.ProductId?.includes(item._id)
                                    ) {
                                      return {
                                        value: item._id,
                                        label: item.ProductName,
                                      };
                                    }
                                  })}
                                  // onChange={(e) => OnChange(e,"ProductId")}
                                  //  value = {data.getProduct.filter(item)=>item.id.includes(id)
                                  //   {value:item.id,
                                  //   label:item.ProductName}}
                                  // value={
                                  //   data.getProduct.filter(item=>item.Id.includes(Id))
                                  //      {
                                  //         label: item.ProductName,
                                  //         value: Formdata.item.id,
                                  //       }
                                  onChange={(e) => {
                                    let ProductId = [];
                                    e.map((val, i) => {
                                      ProductId.push(val.value);
                                    });
                                    SetFormdata({
                                      ...Formdata,
                                      ...{ ["ProductId"]: ProductId },
                                    });
                                    console.log(
                                      "jkkmnj",
                                      e,
                                      e.target,
                                      Formdata["ProductId"],
                                      e,
                                      ProductId
                                    );
                                  }}
                                  className="border_blue_select"
                                  classNamePrefix="react-select"
                                  placeholder="A...."

                                  // disabled={Formdata.BuyProductId.length>0}
                                />

                                {Error?.Count && (
                                  <span className="error-msg">
                                    {Error?.ProductName}
                                  </span>
                                )}
                              </Col>
                            )}
                            <Col
                              xxl={4}
                              xl={4}
                              lg={4}
                              md={6}
                              sm={6}
                              xs={12}
                              className="mb-3"
                            >
                              <p className="mdv_inplabel">Total</p>
                              <input
                                className="mdv_cmninput"
                                type="text"
                                placeholder="Doe"
                                id="Count"
                                value={Formdata.Count}
                                onChange={(e) => OnChange(e)}
                              />
                              {Error?.Count && (
                                <span className="error-msg">
                                  {Error?.Count}
                                </span>
                              )}
                            </Col>

                            <Col
                              xxl={4}
                              xl={4}
                              lg={4}
                              md={6}
                              sm={6}
                              xs={12}
                              className="mb-3"
                            >
                              <p className="mdv_inplabel">offerImage</p>
                              <input
                                className="mdv_cmninput"
                                type="file"
                                placeholder="Doe"
                                id="offerImage"
                                // value={Formdata.offerImage}

                                onChange={(e) => OnChange(e)}
                              />
                              {Id && (
                                <img
                                  src={`${config.IMG_URL}/coupon/ADMIN/${Formdata.offerImage}`}
                                  width={50}
                                />
                              )}
                              {Error?.offerImage && (
                                <span className="error-msg">
                                  {Error?.offerImage}
                                </span>
                              )}
                            </Col>
                            <div className="mdv_update_dualbtn">
                              <button
                                className="blue_btn mdv_update"
                                type="button"
                                onClick={() => Submit()}
                              >
                                Update
                              </button>
                              <button
                                className="seconday_btn"
                                onClick={() => SetFormdata(initname)}
                              >
                                Clear
                              </button>
                            </div>
                          </Row>
                        </div>
                      </div>

                      {/* end of change password details */}
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Coupons;
