import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";

import useAxiosFile from "../../../actions/useaxios";
import { CKEditor } from 'ckeditor4-react';
import { isEmpty } from "../../../actions/common";
import { useParams } from "react-router";
import config from '../../../actions/config'


function addCms()
{
  const axiosFile = useAxiosFile()
  const [data, setData] = useState({
    question: '',
    answer: null,
    image: ''
  });
   
  const [onchangeimg, setOnchangeimg] = useState('');
  // const[validate,setValidate] = useState('');
  const [img, setImg] = useState('');
  const navigate = useNavigate();
  const { Id } = useParams()
  console.log("hjgj", Id);



  useEffect(() => {
    if (Id)
      geteditcms();
      else
      setData({...data,...{answer:''}})
  }, [])

  const geteditcms = async () => {
    var senddata = {
      id: Id
    }
    var resp = await axiosFile.getCms(senddata)
    console.log("", resp.data);
    setData(resp?.data )


    setOnchangeimg(`${config.IMG_URL}/Cms/${resp.data.Image}`)
  }
  console.log("dasjkdasjkhfsdhf",data)

  const handle = async (event) => {
    const { id, value } = event.target;
    var datas = { ...data, ...{ ['question']: value ,answer:data.answer} }
    setData(datas)
  }

  const onEditorChange = (event) => {
    console.log("lllll", event);
    var datas = event.editor.getData()
    setData(prev=>({
      ...prev,
      ...{answer: datas}
    }))

  }


  const handleFile = (event) => {
    event.preventDefault();
    var reader = new FileReader()
    console.log("resdf", reader);
    const { id, files } = event.target;
    console.log("profile id", id)
    if (event.target.files && event.target.files[0]) {
      var file = event.target.files[0];
      console.log("rt", file);
      setImg(file)
      var url = reader.readAsDataURL(file);
      console.log(url);
      reader.onloadend = function (e) {
        if (reader.result) {
          setOnchangeimg(reader.result);
        }
      }
    }
    var formData = { ...data, ...{ [id]: files[0] } };
    console.log("jkhgk", formData);
    setData(formData);
  }

const Addata = async () => {
    // var error = validation(data)
    // if(!isEmpty(error))
    // {
    //   setValidate(error)
    // }

    if (Id) {
      var sendData = {
        question: data.question,
        answer: data.answer,
        image: img,
        CmsId: Id,
        action: "edit"
      }
      console.log("dwe",sendData);
      var resp = await axiosFile.addCms(sendData)
      if (resp) {
        navigate('/Cmslist')
      }
    }
    else {
      var sendData = {
        question: data.question,
        answer: data.answer,
        image: img,
        action: "add"
      }
      console.log("dfc", sendData);
      var resp = await axiosFile.addCms(sendData)
      console.log("safsd", resp);
      if (resp) {
        navigate('/Cmslist')
      }
    }

  }


  const { answer } = data






  return (
    <div>
      <h1>CMS Page</h1>
      <input type="text" id="question" placeholder="Enter the question" value={data.question} onChange={(event) => handle(event)} />
      {
        onchangeimg ? <img src={onchangeimg ? onchangeimg : require('../../../assests/images/cloud.png')} width={50} /> :

          <img src={img ? `${config.IMG_URL}/Cms/${img}` : require('../../../assests/images/cloud.png')} />
      }



      <input type="file" id="image" onChange={handleFile} />
      {answer !== null  &&
        <CKEditor
          initData={answer}

          onChange={(evt) => onEditorChange(evt)}

        />}
   


      <button onClick={() => Addata()}>Add</button>
    </div>
  )
}

export default addCms