import React, { useState } from "react";
import Sidebar from "../layouts/sidebar";
import Headers from "../layouts/header";
import { Link, NavLink } from "react-router-dom";
import Datetime from "react-datetime";
import { Nav, Row, Col } from "react-bootstrap";
import pf_cover from "../assests/images/pf_cover.png";

import { useSelector } from "react-redux";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import Form from "react-bootstrap/Form";

import cameraUpload from "../assests/svg/camera.svg";

export default function Profiledetail() {
  const { toggle } = useSelector((state) => state.admin_panel.User);

  const [notify, setNotify] = useState(false);

  return (
    <div>
      <div className="innerpages">
        <div className="profile_pages">
          <Headers />
          <div className="container custom_container px-lg-0">
            <div className="row mx-auto">
              <div className="innerpages_wid px-0">
                <Sidebar />
                <div
                  className={
                    toggle
                      ? "content-sec-inner right_widthset content_without_sidebar"
                      : "content-sec-inner  content_with_sidebar"
                  }
                >
                  <section id="content-wrapper">
                    <div class="banner_pf position-relative">
                      <img
                        src={pf_cover}
                        alt="pf_cover"
                        className="img-fluid pf_cover"
                      />
                      <div className="pf_img_sec position-absolute ">
                        <img
                          src={require("../assests/images/loginleft.png")}
                          alt="profile"
                          className="img-fluid"
                        />
                        <div className="position-relative upl_cam">
                          <div className="upl_cam_abs position-absolute">
                            {/* <img
                              src={require("../assests/images/camUpload.png")}
                              alt=""
                            /> */}
                            <img src={cameraUpload} alt="profile" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4 mx-auto">
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="first"
                      >
                        {/* <div className="container"> */}

                        <div className="sec_border pfl_dtl_border">
                          <Nav variant="pills" className="pfl_nav">
                            <Nav.Item className="pfl_nav_item me-3  me-lg-4 me-xl-5">
                              <Nav.Link
                                eventKey="first"
                                className="pfl_nav_link"
                              >
                                Profile
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="pfl_nav_item ms-sm-5 ms-0">
                              <Nav.Link
                                eventKey="second"
                                className="pfl_nav_link "
                                onClick={() => setNotify(true)}
                              >
                                Notification Settings
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </div>

                        <div className="pfl_bor mt-4 p-0">
                          <Tab.Content>
                            <Tab.Pane eventKey="first">
                              <div>
                                <div className="pfl_tab_tit sec_border">
                                  <p className="mb-0 fw_600">
                                    Personal Information
                                  </p>
                                </div>
                                <div>
                                  <div className="row  mx-auto pfl_tab_tit sec_border">
                                    <div className="col-12 col-md-6 col-lg-4 ps-0 pe-0 pe-md-3">
                                      <Form>
                                        <Form.Group
                                          className="mb-3"
                                          controlId="formBasicEmail"
                                        >
                                          <Form.Label className="frm_lbl">
                                            First Name
                                          </Form.Label>
                                          <Form.Control
                                            className="frm_ctrl"
                                            type="text"
                                            placeholder="Jone"
                                          />
                                        </Form.Group>
                                      </Form>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4 pe-0 ps-0 ps-md-3 pe-lg-3">
                                      <Form>
                                        <Form.Group
                                          className="mb-3"
                                          controlId="formBasicEmail"
                                        >
                                          <Form.Label className="frm_lbl">
                                            Last Name
                                          </Form.Label>
                                          <Form.Control
                                            className="frm_ctrl"
                                            type="text"
                                            placeholder="Doe"
                                          />
                                        </Form.Group>
                                      </Form>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4 pe-0 ps-0  pe-md-3 pe-lg-0 ps-lg-3">
                                      <Form>
                                        <Form.Group
                                          className="mb-3"
                                          controlId="formBasicEmail"
                                        >
                                          <Form.Label className="frm_lbl">
                                            Seller Name
                                          </Form.Label>
                                          <Form.Control
                                            className="frm_ctrl"
                                            type="text"
                                            placeholder="delpick"
                                          />
                                        </Form.Group>
                                      </Form>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4 ps-0 pe-0 ps-md-3 ps-lg-0 pe-lg-3">
                                      <Form>
                                        <Form.Group
                                          className="mb-3"
                                          controlId="formBasicEmail"
                                        >
                                          <Form.Label className="frm_lbl">
                                            Seller Name
                                          </Form.Label>
                                          <Form.Control
                                            className="frm_ctrl"
                                            type="email"
                                            placeholder="john@delpick.com"
                                          />
                                        </Form.Group>
                                      </Form>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4 pe-0 ps-0 pe-md-3 ps-lg-3">
                                      <Form>
                                        <Form.Group
                                          className="mb-3"
                                          controlId="formBasicEmail"
                                        >
                                          <Form.Label className="frm_lbl">
                                            Contact Number
                                          </Form.Label>
                                          <Form.Control
                                            className="frm_ctrl"
                                            type="number"
                                            placeholder="+1 515 516 0624"
                                          />
                                        </Form.Group>
                                      </Form>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4 pe-0 ps-0 ps-md-3">
                                      <Form>
                                        <Form.Group
                                          className="mb-3"
                                          controlId="formBasicEmail"
                                        >
                                          <Form.Label className="frm_lbl">
                                            Store Category
                                          </Form.Label>
                                          <div className="frm_ctrl  slt_ctg d-flex align-items-center ">
                                            <p className=" mb-0">Restaurant</p>
                                          </div>
                                        </Form.Group>
                                      </Form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                              <div className="row">
                                <div className="col-xl-10 col-xxl-9">
                                  <div className="act_not_top mb-4">
                                    <p className="mb-0 fw_600">Activity</p>
                                    <div className="d-flex align-items-center mt-4">
                                      <div className="d-flex align-items-center">
                                        {" "}
                                        {/* <label for="switch" class="toggle"></label> */}
                                        <label class="switch">
                                          <input type="checkbox" />
                                          <span class="slider round"></span>
                                        </label>
                                      </div>
                                      <p className="mb-0 ms-3 act_p">
                                        Lorem Ipsum has been the industry's
                                        standard dummy text ever since the
                                        1500s.
                                      </p>
                                    </div>
                                    <div className="d-flex align-items-center mt-4">
                                      <div className="d-flex align-items-center">
                                        {" "}
                                        {/* <label for="switch" class="toggle"></label> */}
                                        <label class="switch">
                                          <input type="checkbox" />
                                          <span class="slider round"></span>
                                        </label>
                                      </div>
                                      <p className="mb-0 ms-3 act_p">
                                        Lorem Ipsum has been the industry's
                                        standard dummy text ever since the
                                        1500s.
                                      </p>
                                    </div>
                                    <div className="d-flex align-items-center mt-4 mb-4">
                                      <div className="d-flex align-items-center">
                                        {" "}
                                        {/* <label for="switch" class="toggle"></label> */}
                                        <label class="switch">
                                          <input type="checkbox" />
                                          <span class="slider round"></span>
                                        </label>
                                      </div>
                                      <p className="mb-0 ms-3 act_p">
                                        Lorem Ipsum has been the industry's
                                        standard dummy text ever since the
                                        1500s.
                                      </p>
                                    </div>
                                  </div>
                                  <div className="act_not_bot">
                                    <p className="mb-0 fw_600">System</p>
                                    <div className="d-flex align-items-center mt-4">
                                      <div className="d-flex align-items-center">
                                        {" "}
                                        {/* <label for="switch" class="toggle"></label> */}
                                        <label class="switch">
                                          <input type="checkbox" />
                                          <span class="slider round"></span>
                                        </label>
                                      </div>
                                      <p className="mb-0 ms-3 act_p">
                                        Lorem Ipsum has been the industry's
                                        standard dummy text ever since the
                                        1500s.
                                      </p>
                                    </div>
                                    <div className="d-flex align-items-center mt-4">
                                      <div className="d-flex align-items-center">
                                        {" "}
                                        {/* <label for="switch" class="toggle"></label> */}
                                        <label class="switch">
                                          <input type="checkbox" />
                                          <span class="slider round"></span>
                                        </label>
                                      </div>
                                      <p className="mb-0 ms-3 act_p">
                                        Lorem Ipsum has been the industry's
                                        standard dummy text ever since the
                                        1500s.
                                      </p>
                                    </div>
                                    <div className="d-flex align-items-center mt-4">
                                      <div className="d-flex align-items-center">
                                        {" "}
                                        <label class="switch">
                                          <input type="checkbox" />
                                          <span class="slider round"></span>
                                        </label>
                                      </div>
                                      <p className="mb-0 ms-3 act_p">
                                        Lorem Ipsum has been the industry's
                                        standard dummy text ever since the
                                        1500s.
                                      </p>
                                    </div>
                                    <div className="d-flex align-items-center mt-4">
                                      <div className="d-flex align-items-center">
                                        {" "}
                                        <label class="switch">
                                          <input type="checkbox" />
                                          <span class="slider round"></span>
                                        </label>
                                      </div>
                                      <p className="mb-0 ms-3 act_p">
                                        Lorem Ipsum has been the industry's
                                        standard dummy text ever since the
                                        1500s.
                                      </p>
                                    </div>
                                  </div>

                                  <div className="blue_btn add_deliveryprsn not_sav_btn mt-4 dp_btn_hover">
                                    <NavLink to="#">Save Changes</NavLink>
                                  </div>
                                </div>
                              </div>
                            </Tab.Pane>
                          </Tab.Content>
                        </div>
                      </Tab.Container>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
