import React, { useState } from "react";
import Sidebar from "../layouts/sidebar";
import Headers from "../layouts/header";
import { Link, NavLink } from "react-router-dom";
import Select from "react-select";
import Datetime from "react-datetime";
import moment from "moment";
import "react-datetime/css/react-datetime.css";
import { Nav, Tab, Row, Col } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";

function customCheckbox(theme) {
  return {
    "& .MuiCheckbox-root svg": {
      width: 16,
      height: 16,
      backgroundColor: "transparent",
      border: `1px solid ${
        theme.palette.mode === "light" ? "#d9d9d9" : "rgb(67, 67, 67)"
      }`,
      borderRadius: 2,
    },
    "& .MuiCheckbox-root svg path": {
      display: "none",
    },
    "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
      backgroundColor: "#e50914",
      borderColor: "#e50914",
    },
    "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
      position: "absolute",
      display: "table",
      border: "2px solid #fff",
      borderTop: 0,
      borderLeft: 0,
      transform: "rotate(45deg) translate(-50%,-50%)",
      opacity: 1,
      transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
      content: '""',
      top: "50%",
      left: "39%",
      width: 5.71428571,
      height: 9.14285714,
    },
    "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after":
      {
        width: 8,
        height: 8,
        backgroundColor: "#62626214",
        transform: "none",
        top: "39%",
        border: 0,
      },
  };
}
function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

export default function Drivers() {
  const [opacity, setOpacity] = useState("false");
  const [opacityEnd, setEndOpacity] = useState("false");
  const [selectedOption, setSelectedOption] = useState(null);

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    border: 0,
    color:
      theme.palette.mode === "light"
        ? "rgba(0,0,0,.85)"
        : "rgba(255,255,255,0.85)",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    WebkitFontSmoothing: "auto",
    letterSpacing: "normal",
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    // '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
    //   borderRight: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
    //     }`,
    // },
    // '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    //   borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
    //     }`,
    // },
    "& .MuiDataGrid-cell": {
      color:
        theme.palette.mode === "light"
          ? "rgba(0,0,0,.85)"
          : "rgba(255,255,255,0.65)",
    },
    "& .MuiPaginationItem-root": {
      borderRadius: 0,
    },
    "& .MuiDataGrid-footerContainer": {
      display: "flex",
      justifyContent: "center",
    },

    ...customCheckbox(theme),
  }));

  const columns = [
    { field: "id", headerName: "No", width: 90 },
    {
      field: "deliverypersonName",
      headerName: "Delivery Person Name",
      width: 250,
      editable: true,
    },
    {
      field: "deliverypersonId",
      headerName: "Delivery Person ID",
      width: 250,
      editable: true,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",

      width: 200,
      editable: true,
    },
    {
      field: "deliveryZone",
      headerName: "Delivery Zone",
      // description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 250,
      // valueGetter: (params) =>
      //   `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    },
    {
      field: "activeOrders",
      headerName: "Active Orders",
      width: 250,
      editable: true,
    },
  ];

  const scheduledColumns = [
    { field: "id", headerName: "No", width: 90 },
    {
      field: "deliverypersonName",
      headerName: "Delivery Person Name",
      width: 140,
      editable: true,
    },
    {
      field: "deliverypersonId",
      headerName: "Delivery Person ID",
      width: 140,
      editable: true,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",

      width: 140,
      editable: true,
    },
    {
      field: "deliveryZone",
      headerName: "Delivery Zone",
      // description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 140,
      // valueGetter: (params) =>
      //   `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    },
    {
      field: "activeOrders",
      headerName: "Active Orders",
      width: 140,
      editable: true,
    },
  ];

  const scheduledrows = [
    {
      id: "1",
      deliverypersonName: "Rino Piccolo",
      deliverypersonId: "#10",
      phoneNumber: "3107285597",
      deliveryZone: "Admin Pasadena",
      activeOrders: "#15045, #15050, #15070",
    },
    {
      id: "2",
      deliverypersonName: "Lucas",
      deliverypersonId: "#12",
      phoneNumber: "3107285597",
      deliveryZone: "Admin Pasadena",
      activeOrders: "#15045, #15050, #15070",
    },
    {
      id: "3",
      deliverypersonName: "Alexander",
      deliverypersonId: "#13",
      phoneNumber: "3107285597",
      deliveryZone: "Admin Pasadena",
      activeOrders: "#15045, #15050, #15070",
    },
    {
      id: "4",
      deliverypersonName: "Noah",
      deliverypersonId: "#15",
      phoneNumber: "3107285597",
      deliveryZone: "Admin Pasadena",
      activeOrders: "#15045, #15050, #15070",
    },
    {
      id: "5",
      deliverypersonName: "Oliver",
      deliverypersonId: "#18",
      phoneNumber: "3107285597",
      deliveryZone: "Admin Pasadena",
      activeOrders: "#15045, #15050, #15070",
    },
    {
      id: "6",
      deliverypersonName: "Isabella",
      deliverypersonId: "#19",
      phoneNumber: "3107285597",
      deliveryZone: "Admin Pasadena",
      activeOrders: "#15045, #15050, #15070",
    },
  ];

  const rows = [
    {
      id: "1",
      deliverypersonName: "Rino Piccolo",
      deliverypersonId: "#10",
      phoneNumber: "3107285597",
      deliveryZone: "Admin Pasadena",
      activeOrders: "#15045, #15050, #15070",
    },
    {
      id: "2",
      deliverypersonName: "Lucas",
      deliverypersonId: "#12",
      phoneNumber: "3107285597",
      deliveryZone: "Admin Pasadena",
      activeOrders: "#15045, #15050, #15070",
    },
    {
      id: "3",
      deliverypersonName: "Alexander",
      deliverypersonId: "#13",
      phoneNumber: "3107285597",
      deliveryZone: "Admin Pasadena",
      activeOrders: "#15045, #15050, #15070",
    },
    {
      id: "4",
      deliverypersonName: "Noah",
      deliverypersonId: "#15",
      phoneNumber: "3107285597",
      deliveryZone: "Admin Pasadena",
      activeOrders: "#15045, #15050, #15070",
    },
    {
      id: "5",
      deliverypersonName: "Oliver",
      deliverypersonId: "#18",
      phoneNumber: "3107285597",
      deliveryZone: "Admin Pasadena",
      activeOrders: "#15045, #15050, #15070",
    },
    {
      id: "6",
      deliverypersonName: "Isabella",
      deliverypersonId: "#19",
      phoneNumber: "3107285597",
      deliveryZone: "Admin Pasadena",
      activeOrders: "#15045, #15050, #15070",
    },
  ];

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#F5F6F7"
        : isSelected
        ? "#F5F6F7"
        : isHovered
        ? "red"
        : "#F5F6F7",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#a71d24"
          : isSelected
          ? "#a71d24"
          : isFocused
          ? "#a71d24"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 20px",
      backgroundColor: "#fff ",
      border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 10,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#6C6A81",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  return (
    <div>
      <div className="innerpages">
        <div className="orders_pages">
          <Headers />
          <div className="container custom_container">
            <div className="row">
              <div className="innerpages_wid">
                <Sidebar />
                <div className="content-sec-inner">
                  <section id="content-wrapper">
                    <div class="row">
                      <div className="top_title_sec">
                        <div>
                          <p className="page_title">Orders</p>
                        </div>
                        <div className="rightsec">
                          <Exportcsv
                            className="cmn_exportcsv"
                            csvData={exportdata}
                            fileName={"drivers"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="sec_border">
                        <div className="row">
                          <div className="col-xxl-4 col-xl-4 col-lg-3 col-md-3 col-sm-12 col-12">
                            <div className="whole_searchbar">
                              <img
                                className="search_glass"
                                src={require("../assests/images/Search.png")}
                              />
                              <input placeholder="Search term" />
                            </div>
                          </div>

                          <div className="col-xxl-8 col-xl-8 col-lg-9 col-md-9 col-sm-12 col-12">
                            <div className="rightside">
                              <div className="rightside_select">
                                <Select
                                  options={options}
                                  defaultValue={selectedOption}
                                  styles={stylesgraybg}
                                  onChange={setSelectedOption}
                                  className="border_blue_select"
                                  classNamePrefix="react-select"
                                  placeholder="Type"
                                />
                              </div>

                              <div className="order_calendar_div">
                                <p
                                  className={
                                    opacity == "true" ? "hiding_caltxt" : ""
                                  }
                                >
                                  Start Date
                                </p>
                                <img
                                  className="order_calendar_ic"
                                  src={require("../assests/images/calendar.png")}
                                />
                                <Datetime
                                  onChange={() => {
                                    setOpacity("true");
                                  }}
                                  className="order_datepick"
                                />
                              </div>

                              <p className="order_totxt">To</p>
                              <div className="order_calendar_div">
                                <p
                                  className={
                                    opacityEnd == "true"
                                      ? "hiding_calendtxt"
                                      : ""
                                  }
                                >
                                  End Date
                                </p>
                                <img
                                  className="order_calendar_ic"
                                  src={require("../assests/images/calendar.png")}
                                />
                                <Datetime
                                  onChange={() => {
                                    setEndOpacity("true");
                                  }}
                                  className="order_datepick"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="orders_tabs">
                            <Tab.Container
                              id="left-tabs-example"
                              defaultActiveKey="first"
                            >
                              <Row>
                                <Col sm={12}>
                                  <Nav
                                    variant="pills"
                                    className="flex orders_tabs"
                                  >
                                    <Nav.Item>
                                      <Nav.Link
                                        className="order_bluetabbig"
                                        eventKey="first"
                                      >
                                        Busy With Delivery
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link
                                        className="order_bluetabbig"
                                        eventKey="second"
                                      >
                                        Free For Delivery
                                      </Nav.Link>
                                    </Nav.Item>
                                  </Nav>
                                </Col>
                                <Col sm={12}>
                                  <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                      <div className="order_tables_cmn">
                                        <StyledDataGrid
                                          pageSize={5}
                                          rowsPerPageOptions={[5]}
                                          rows={rows}
                                          columns={columns}
                                          components={{
                                            Pagination: CustomPagination,
                                          }}
                                          className="campaign_muitbl"
                                        />
                                      </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                      <div className="order_tables_cmn">
                                        <StyledDataGrid
                                          pageSize={5}
                                          rowsPerPageOptions={[5]}
                                          rows={scheduledrows}
                                          columns={scheduledColumns}
                                          components={{
                                            Pagination: CustomPagination,
                                          }}
                                          className="campaign_muitbl"
                                        />
                                      </div>
                                    </Tab.Pane>
                                  </Tab.Content>
                                </Col>
                              </Row>
                            </Tab.Container>
                          </div>
                        </div>
                        {/* <div className='row top_filter_sec'>

          <div className='whole_searchbar'>
     <img className='search_glass' src={require('../assests/images/Search.png')}/>
    <input placeholder="Search term"/>
     </div>
          <div className='rightside'>
          <Select
        defaultValue={selectedOption}
        onChange={setSelectedOption}
        options={options}
        classNamePrefix="react-select"
      />
       <LocalizationProvider dateAdapter={AdapterDayjs} classNamePrefix="date_picker">
      <DemoContainer components={['DatePicker']}>
        <DatePicker label="start date" />
      </DemoContainer>
    </LocalizationProvider>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker label="End date" />
      </DemoContainer>
    </LocalizationProvider>
          </div>
        </div> */}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
