import React,{useState} from 'react'
import Sidebar from '../layouts/sidebar'
import Headers from '../layouts/header'
import Select from 'react-select';
import Datetime from 'react-datetime';

  import {Nav,Tab,Row,Col} from 'react-bootstrap';
  import BootstrapTable from 'react-bootstrap-table-next';
  import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css'
  import paginationFactory from 'react-bootstrap-table2-paginator';
  import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import { useSelector } from 'react-redux';


function OrderDrivers() {
  const [opacity,setOpacity] = useState("false")  
  const [opacityEnd,setEndOpacity] = useState("false") 

  const { toggle } = useSelector((state)=>state.admin_panel.User)


    const pagination = paginationFactory({
        page:1,
        sizePerPage:5,
        lastPageText:">>",
        firstPageText:"<<",
        nextPageText:">",
        prePageText:"<",
        showTotal:true,
        alwaysShowAllBtns:true,
        onPageChange: function (page,sizePerPage){
            
            
        },
        onSizePerPageChange: function (page,sizePerPage){
            
            
        },

    });

    const columns = [
        {dataField:"id",text:"Id"},
        {dataField:"deliverypersonname",text:"Delivery Person Name"},
        {dataField:"deliverypersonid",text:"Delivery Person ID",sort:true},
        {dataField:"phonenumber",text:"Phone Number",sort:true},
        {dataField:"deliveryzone",text:"Delivery Zone",sort:true},
        {dataField:"activeorder",text:"Actove Prder",sort:true},
    ]

    const data = [
        {id:"1",deliverypersonname:"John Doe", deliverypersonid:"John",phonenumber:"johndoe@gmail.com",deliveryzone:"Admin Pasadena",activeorder:"#15045, #15050, #15070"},
        {id:"1",deliverypersonname:"John Doe", deliverypersonid:"John",phonenumber:"johndoe@gmail.com",deliveryzone:"Admin Pasadena",activeorder:"#15045, #15050, #15070"},
        {id:"1",deliverypersonname:"John Doe", deliverypersonid:"John",phonenumber:"johndoe@gmail.com",deliveryzone:"Admin Pasadena",activeorder:"#15045, #15050, #15070"},
        {id:"1",deliverypersonname:"John Doe", deliverypersonid:"John",phonenumber:"johndoe@gmail.com",deliveryzone:"Admin Pasadena",activeorder:"#15045, #15050, #15070"},
        {id:"1",deliverypersonname:"John Doe", deliverypersonid:"John",phonenumber:"johndoe@gmail.com",deliveryzone:"Admin Pasadena",activeorder:"#15045, #15050, #15070"},
        {id:"1",deliverypersonname:"John Doe", deliverypersonid:"John",phonenumber:"johndoe@gmail.com",deliveryzone:"Admin Pasadena",activeorder:"#15045, #15050, #15070"},
        {id:"1",deliverypersonname:"John Doe", deliverypersonid:"John",phonenumber:"johndoe@gmail.com",deliveryzone:"Admin Pasadena",activeorder:"#15045, #15050, #15070"},
        {id:"1",deliverypersonname:"John Doe", deliverypersonid:"John",phonenumber:"johndoe@gmail.com",deliveryzone:"Admin Pasadena",activeorder:"#15045, #15050, #15070"},
        {id:"1",deliverypersonname:"John Doe", deliverypersonid:"John",phonenumber:"johndoe@gmail.com",deliveryzone:"Admin Pasadena",activeorder:"#15045, #15050, #15070"},
        {id:"1",deliverypersonname:"John Doe", deliverypersonid:"John",phonenumber:"johndoe@gmail.com",deliveryzone:"Admin Pasadena",activeorder:"#15045, #15050, #15070"},
        {id:"1",deliverypersonname:"John Doe", deliverypersonid:"John",phonenumber:"johndoe@gmail.com",deliveryzone:"Admin Pasadena",activeorder:"#15045, #15050, #15070"},
        {id:"1",deliverypersonname:"John Doe", deliverypersonid:"John",phonenumber:"johndoe@gmail.com",deliveryzone:"Admin Pasadena",activeorder:"#15045, #15050, #15070"},
        {id:"1",deliverypersonname:"John Doe", deliverypersonid:"John",phonenumber:"johndoe@gmail.com",deliveryzone:"Admin Pasadena",activeorder:"#15045, #15050, #15070"},
        {id:"1",deliverypersonname:"John Doe", deliverypersonid:"John",phonenumber:"johndoe@gmail.com",deliveryzone:"Admin Pasadena",activeorder:"#15045, #15050, #15070"},
        {id:"1",deliverypersonname:"John Doe", deliverypersonid:"John",phonenumber:"johndoe@gmail.com",deliveryzone:"Admin Pasadena",activeorder:"#15045, #15050, #15070"},
        {id:"1",deliverypersonname:"John Doe", deliverypersonid:"John",phonenumber:"johndoe@gmail.com",deliveryzone:"Admin Pasadena",activeorder:"#15045, #15050, #15070"},
    ]



    const freedata = [
        {id:"1",deliverypersonname:"John Doe", deliverypersonid:"John",phonenumber:"johndoe@gmail.com",deliveryzone:"Admin Pasadena",activeorder: 
            <div class="dropdown table_viewdropdown">
        <button class="btn table_viewdrop dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="fa-solid fa-ellipsis"/>
        </button>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item">View</a></li>
        </ul>
      </div>},
        {id:"1",deliverypersonname:"John Doe", deliverypersonid:"John",phonenumber:"johndoe@gmail.com",deliveryzone:"Admin Pasadena",activeorder:
     <div class="dropdown table_viewdropdown">
        <button class="btn table_viewdrop dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="fa-solid fa-ellipsis"/>
        </button>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item">View</a></li>
        </ul>
      </div>},
        {id:"1",deliverypersonname:"John Doe", deliverypersonid:"John",phonenumber:"johndoe@gmail.com",deliveryzone:"Admin Pasadena",activeorder:
     <div class="dropdown table_viewdropdown">
        <button class="btn table_viewdrop dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="fa-solid fa-ellipsis"/>
        </button>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item">View</a></li>
        </ul>
      </div>},
        {id:"1",deliverypersonname:"John Doe", deliverypersonid:"John",phonenumber:"johndoe@gmail.com",deliveryzone:"Admin Pasadena",activeorder:
     <div class="dropdown table_viewdropdown">
        <button class="btn table_viewdrop dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="fa-solid fa-ellipsis"/>
        </button>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item">View</a></li>
        </ul>
      </div>},
        {id:"1",deliverypersonname:"John Doe", deliverypersonid:"John",phonenumber:"johndoe@gmail.com",deliveryzone:"Admin Pasadena",activeorder:
     <div class="dropdown table_viewdropdown">
        <button class="btn table_viewdrop dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="fa-solid fa-ellipsis"/>
        </button>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item">View</a></li>
        </ul>
      </div>},
        {id:"1",deliverypersonname:"John Doe", deliverypersonid:"John",phonenumber:"johndoe@gmail.com",deliveryzone:"Admin Pasadena",activeorder:
     <div class="dropdown table_viewdropdown">
        <button class="btn table_viewdrop dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="fa-solid fa-ellipsis"/>
        </button>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item">View</a></li>
        </ul>
      </div>},
        {id:"1",deliverypersonname:"John Doe", deliverypersonid:"John",phonenumber:"johndoe@gmail.com",deliveryzone:"Admin Pasadena",activeorder:
     <div class="dropdown table_viewdropdown">
        <button class="btn table_viewdrop dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="fa-solid fa-ellipsis"/>
        </button>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item">View</a></li>
        </ul>
      </div>},
        {id:"1",deliverypersonname:"John Doe", deliverypersonid:"John",phonenumber:"johndoe@gmail.com",deliveryzone:"Admin Pasadena",activeorder:
     <div class="dropdown table_viewdropdown">
        <button class="btn table_viewdrop dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="fa-solid fa-ellipsis"/>
        </button>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item">View</a></li>
        </ul>
      </div>},
        {id:"1",deliverypersonname:"John Doe", deliverypersonid:"John",phonenumber:"johndoe@gmail.com",deliveryzone:"Admin Pasadena",activeorder:
     <div class="dropdown table_viewdropdown">
        <button class="btn table_viewdrop dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="fa-solid fa-ellipsis"/>
        </button>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item">View</a></li>
        </ul>
      </div>},
        {id:"1",deliverypersonname:"John Doe", deliverypersonid:"John",phonenumber:"johndoe@gmail.com",deliveryzone:"Admin Pasadena",activeorder:
     <div class="dropdown table_viewdropdown">
        <button class="btn table_viewdrop dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="fa-solid fa-ellipsis"/>
        </button>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item">View</a></li>
        </ul>
      </div>},
        {id:"1",deliverypersonname:"John Doe", deliverypersonid:"John",phonenumber:"johndoe@gmail.com",deliveryzone:"Admin Pasadena",activeorder:
     <div class="dropdown table_viewdropdown">
        <button class="btn table_viewdrop dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="fa-solid fa-ellipsis"/>
        </button>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item">View</a></li>
        </ul>
      </div>},
        {id:"1",deliverypersonname:"John Doe", deliverypersonid:"John",phonenumber:"johndoe@gmail.com",deliveryzone:"Admin Pasadena",activeorder:
     <div class="dropdown table_viewdropdown">
        <button class="btn table_viewdrop dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="fa-solid fa-ellipsis"/>
        </button>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item">View</a></li>
        </ul>
      </div>},
        {id:"1",deliverypersonname:"John Doe", deliverypersonid:"John",phonenumber:"johndoe@gmail.com",deliveryzone:"Admin Pasadena",activeorder:
     <div class="dropdown table_viewdropdown">
        <button class="btn table_viewdrop dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="fa-solid fa-ellipsis"/>
        </button>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item">View</a></li>
        </ul>
      </div>},
        {id:"1",deliverypersonname:"John Doe", deliverypersonid:"John",phonenumber:"johndoe@gmail.com",deliveryzone:"Admin Pasadena",activeorder:
     <div class="dropdown table_viewdropdown">
        <button class="btn table_viewdrop dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="fa-solid fa-ellipsis"/>
        </button>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item">View</a></li>
        </ul>
      </div>},
        {id:"1",deliverypersonname:"John Doe", deliverypersonid:"John",phonenumber:"johndoe@gmail.com",deliveryzone:"Admin Pasadena",activeorder:
     <div class="dropdown table_viewdropdown">
        <button class="btn table_viewdrop dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="fa-solid fa-ellipsis"/>
        </button>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item">View</a></li>
        </ul>
      </div>},
        {id:"1",deliverypersonname:"John Doe", deliverypersonid:"John",phonenumber:"johndoe@gmail.com",deliveryzone:"Admin Pasadena",activeorder:
     <div class="dropdown table_viewdropdown">
        <button class="btn table_viewdrop dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="fa-solid fa-ellipsis"/>
        </button>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item">View</a></li>
        </ul>
      </div>},
    ]

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
      ];

      const [selectedOption, setSelectedOption] = useState(null);

 

      const stylesgraybg = {
        option: (styles, { isFocused, isSelected,isHovered }) => ({
          ...styles,
          color: "#6C6A81",
          background: isFocused ? "#F5F6F7" : isSelected ? "#F5F6F7" : isHovered ? "red" : "#F5F6F7",
      
          zIndex: 1,
          cursor:"pointer",
          fontSize:"13px"
        }),
      
        option: (styles, {isFocused, isSelected,isHovered }) => {
          // const color = chroma(data.color);
          
          
          return {
            ...styles,
            backgroundColor: isHovered ? "#06038D" : isSelected ? "#06038D" : isFocused ? "#06038D" :"",
            cursor:"pointer",
            color: isHovered ? "#fff" : isSelected ? "#fff" : isFocused ? "#fff" :"",
            fontSize:"13px"
           
          };
        },
        valueContainer: (provided, state) => ({
          ...provided,
          height: "40px",
          padding: "0 20px",
          backgroundColor: "#fff ",
          border: "1px solid rgba(34, 34, 34, 0.32)",
          borderRadius: 10,
          fontSize:"13px"
          
          
        }),
        control: (provided, state) => ({
          ...provided,
          height: "40px",
          borderRadius: 10,
          backgroundColor: "#fff",
          border:"none",
          outline:"none",
          boxShadow:"none"
          
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          height: "40px",
          position: "absolute",
          right: 0,
          top: 0,
          color: "#6C6A81",
          
        }),
        singleValue: (provided, state) => ({
          ...provided,
          color: "#6C6A81",
         
        }),
        menuList: (base) => ({
          ...base,
          // kill the white space on first and last option
          padding: 0,
        }),
      };

  return (
    <>
    <div>
      
      <div className='innerpages'>
        <div className='orders_pages'>
      <Headers/>
      <div className='container custom_container'>
        <div className='row'>
          <div className='innerpages_wid'>
              <Sidebar/>
            <div className={ toggle ? 'content-sec-inner right_widthset' : 'content-sec-inner'}>
            <section id="content-wrapper">
  <div class="row">
    <div className='top_title_sec'>
    <div>
      <p className='page_title'>Orders</p>
    </div>
    <div className='rightsec'>
     <Exportcsv className='cmn_exportcsv' csvData={exportdata} fileName={"drivers"}/>
    </div>
    </div>
  </div>
  <div className='row mt-4'>
    <div className='sec_border'>

      <div className='row'>
        <div className='col-xxl-4 col-xl-4 col-lg-3 col-md-3 col-sm-12 col-12'>
        <div className='whole_searchbar white_bg'>
   <img className='search_glass' src={require('../assests/images/Search.png')}/>
  <input placeholder="Search term"/>
   </div>
        </div>

        <div className='col-xxl-8 col-xl-8 col-lg-9 col-md-9 col-sm-12 col-12'>
        <div className='rightside'>

<div className='rightside_select'>
        <Select
                            options={options}
                            defaultValue={selectedOption}
                            styles={stylesgraybg}
                            onChange={setSelectedOption}
                            className="border_blue_select"
                            classNamePrefix="react-select"
                            placeholder='Type'
                       
                          />
                          </div>

                          <div className='order_calendar_div'>
                              <p className={opacity == "true" ? "hiding_caltxt" : ""}>Start Date</p>
                              <img className='order_calendar_ic' src={require('../assests/images/calendar.png')}/>
                              <Datetime onChange={()=>{setOpacity("true")}} className='order_datepick'/>
                            </div>

                            <p className='order_totxt'>To</p>
                            <div className='order_calendar_div'>
                              <p className={opacityEnd == "true" ? "hiding_calendtxt" : ""}>End Date</p>
                              <img className='order_calendar_ic' src={require('../assests/images/calendar.png')}/>
                              <Datetime onChange={()=>{setEndOpacity("true")}} className='order_datepick'/>
                            </div>
                          
 
     
  
        </div>

       
        </div>
        <div className='orders_tabs'>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
    <Row>
      <Col sm={12}>
        <Nav variant="pills" className="flex orders_tabs">
          <Nav.Item>
            <Nav.Link className='order_bluetab' eventKey="first">Busy With Delivery</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link className='order_bluetab' eventKey="second">Free For Delivery</Nav.Link>
          </Nav.Item>
          
         
        </Nav>
      </Col>
      <Col sm={12}>
        <Tab.Content>
          <Tab.Pane eventKey="first">
          <div className='ffd_table'>
          <BootstrapTable bootstrap4 keyField='id' resonsive="true" columns={columns} data={data} pagination={pagination}/>
          </div>
          </Tab.Pane>

          <Tab.Pane eventKey="second">
            <div className='ffd_table'>
          <BootstrapTable bootstrap4 keyField='id' columns={columns} data={freedata} pagination={pagination}/>
          </div>
          </Tab.Pane>
         
          
        </Tab.Content>
      </Col>
    </Row>
  </Tab.Container>


 
        </div>
      </div>
      
    </div>
  </div>






</section>
</div>
            </div>
              
          </div>
      </div>




      </div>
      </div>

  </div>
    </>
  )
}

export default OrderDrivers