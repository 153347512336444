import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { CKEditor } from "ckeditor4-react";

import useAxiosFile from "../../actions/useaxios";
import {
  DecryptData,
  LoadToast,
  isEmpty,
  updateToast,
} from "../../actions/common";
import Sidebar from "../../layouts/sidebar";
import Header from "../../layouts/header";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";

function subscribeadd() {
  let data = useLocation();
  console.log("tyyyy", data);
  const { toggle } = useSelector((state) => state.admin_panel.User);
  const search = data.search.split("=")[1];
  console.log("axios", data.search.split("=")[1]);
  const state = data?.state;

  const axiosFile = useAxiosFile();
  const navigate = useNavigate();

  const [subsdata, setSubsdata] = useState({});
  // const [disb, setDisb] = useState(false)

  const [edit, setEdit] = useState({});

  const [errors, setError] = useState({});
  // setDisb({search})
  console.log("dddd", subsdata);
  console.log("fffff", subsdata.subject, subsdata?.users);
  useEffect(() => {
    Adddetails(search);
  }, []);

  const Adddetails = async (search) => {
    console.log("search", search !== "" || search !== undefined);
    if (search !== "" && search !== undefined) {
      var senddata = {
        _id: search,
      };

      var data = await axiosFile.Adddetails(senddata);
      console.log("wwwwww", data);
      if (data?.success == true) {
        setSubsdata({
          subject: data?.data?.subject,
          users: data?.data?.users,
        });
        setEdit({ users: data?.data?.users });

        console.log("wefwera", data?.success);
      }
    }
  };
  const changefn = (e) => {
    setError("");

    console.log("sadfgjhadfasdf", e.target.value);
    var { name, value } = e.target;
    console.log("nv", name, value);

    setSubsdata({ ...subsdata, ...{ [e.target.name]: e.target.value } });
  };

  const clickSubmit = async (e) => {
    let id = LoadToast();

    var value = insertvalidation();

    if (!isEmpty(value)) {
      console.log("value", value);
      setError(value);
      updateToast(id, "fix all validation", "error");
    } else {
      let senddata;
      if (search != undefined) {
        senddata = { ...subsdata };
      } else {
        senddata = { ...subsdata };
      }
      console.log("search_data", senddata);
      let sendmail = await axiosFile.emailsend({
        EmailIds: state?.email,
        mail: subsdata,
      });
      // navigate(
      //     '/subscribelists',
      //     { state: subsdata }
      //   )
      updateToast(id, "success", "success");
    }
  };
  const ckeditfn = (evt) => {
    console.log("event", evt);
    setError("");
    var edit = evt.editor.getData();
    var name = evt.editor.name;
    console.log("edit_data", name, edit);
    setSubsdata((editor) => ({ ...editor, [name]: edit }));
  };
  const insertvalidation = () => {
    console.log("dbhjbnjhbasjhd", subsdata.subject, subsdata?.users);
    // const link = /^((https?|ftp|smtp):\/\/)?(https://www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
    let errors = {};
    if (isEmpty(subsdata?.subject)) {
      errors.subject = "subject field is required";
    }
    if (isEmpty(subsdata?.users)) {
      errors.users = "mail field is required";
    }

    return errors;
  };

  console.log("subsdata", subsdata);
  return (
    <>
      <div>
        <div className="innerpages">
          <div className="orders_pages">
            <Header />
            <div className="container custom_container px-lg-0">
              <div className="row mx-auto">
                <div className="innerpages_wid px-0">
                  <Sidebar />
                  <div
                    className={
                      toggle
                        ? "content-sec-inner right_widthset content_without_sidebar"
                        : "content-sec-inner  content_with_sidebar"
                    }
                  >
                    <section id="content-wrapper">
                      <div className="top_title_sec">
                        <div>
                          <p className="page_title">{"Template"}</p>
                        </div>
                      </div>
                      <label>
                        subject
                        <input
                          className="mdv_cmninput mb-3"
                          name="subject"
                          type="text"
                          id="subject"
                          placeholder="Enter the type"
                          value={subsdata?.subject}
                          onChange={(e) => changefn(e)}
                        />
                        <span id="subject-error" className="error-msg">
                          {errors && errors.subject}
                        </span>
                      </label>

                      <br />
                      {search ? (
                        <>
                          {edit.users && (
                            <CKEditor
                              name="users"
                              initData={edit.users}
                              onChange={(e) => ckeditfn(e)}
                            />
                          )}
                        </>
                      ) : (
                        <CKEditor
                          name="users"
                          initData={[]}
                          onChange={(e) => ckeditfn(e)}
                        />
                      )}
                      <span id="users-error" className="error-msg">
                        {errors && errors.users}
                      </span>

                      <br />

                      <link></link>
                      <button
                        className="blue_btn add_deliveryprsn dp_btn_hover"
                        onClick={() => clickSubmit()}
                      >
                        next
                      </button>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default subscribeadd;
