// import CKEditor from 'ckeditor4';

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";

import useAxiosFile from "../../../actions/useaxios";
import { CKEditor } from "ckeditor4-react";
import {
  DecryptData,
  isEmpty,
  LoadToast,
  updateToast,
} from "../../../actions/common";
import { useParams } from "react-router";
import config from "../../../actions/config";
import Sidebar from "../../../layouts/sidebar";
import Header from "../../../layouts/header";
import { useLocation } from "react-router";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";

function addfaq() {
  const location = useLocation();
  const navigate = useNavigate();
  const { toggle } = useSelector((state) => state.admin_panel.User);
  // const EditorRef = useRef(null)
  const axiosFile = useAxiosFile();

  const [data, setData] = useState({
    question: "",
    answer: null,
    image: "",
  });
  const [onchangeimg, setOnchangeimg] = useState("");
  // const[validate,setValidate] = useState('');
  const [img, setImg] = useState("");
  const [errors, setError] = useState({});

  const [Id, Setid] = useState(
    isEmpty(DecryptData(location.search.split("?Id=")[1]))
      ? location.search.split("?Id=")[1]
      : DecryptData(location.search.split("?Id=")[1])
  );

  console.log("hjgj", Id);

  // useEffect(()=>{
  // if(location?.search){
  //   // let paramdata = location.search.split('?Id=')[1];
  //   Setid()
  // }
  // },[location.search])

  useEffect(() => {
    if (Id) geteditfaq();
    else setData({ ...data, ...{ answer: "" } });
  }, [Id]);

  const geteditfaq = async () => {
    var senddata = {
      id: Id,
    };
    var resp = await axiosFile.getFaq(senddata);
    console.log("", resp.data);
    setData(resp?.data);

    setOnchangeimg(`${config.IMG_URL}/Faq/${resp.data.Image}`);
  };
  // console.log("dasjkdasjkhfsdhf",data)

  const handle = async (event) => {
    // console.log("++++++++++++++++")
    const { id, value } = event.target;
    var datas = { ...data, ...{ ["question"]: value, answer: data.answer } };
    setData(datas);
  };

  const onEditorChange = (event) => {
    console.log("lllll", event);
    var datas = event.editor.getData();
    setData((prev) => ({
      ...prev,
      ...{ answer: datas },
    }));
    // console.log("hjk",setData);
  };
  // console.log("jbgsd", data)
  const handleFile = (event) => {
    event.preventDefault();
    var reader = new FileReader();
    console.log("resdf", reader);
    const { id, files } = event.target;
    console.log("profile id", id);
    if (event.target.files && event.target.files[0]) {
      var file = event.target.files[0];
      console.log("rt", file);
      setImg(file);
      var url = reader.readAsDataURL(file);
      console.log(url);
      reader.onloadend = function (e) {
        if (reader.result) {
          setOnchangeimg(reader.result);
        }
      };
    }
    var formData = { ...data, ...{ [id]: files[0] } };
    console.log("jkhgk", formData);
    setData(formData);
  };

  const Addata = async () => {
    // var error = validation(data)
    // if(!isEmpty(error))
    // {
    //   setValidate(error)
    // }
    let id = LoadToast();
    var value = faqvalidation();

    if (!isEmpty(value)) {
      console.log("value", value);
      setError(value);
      updateToast(id, "fix all validation", "error");
    } else if (Id) {
      var sendData = {
        question: data.question,
        answer: data.answer,
        image: data.image,
        _id: Id,
        action: "edit",
      };
      var resp = await axiosFile.addFaq(sendData);
      console.log("yrtyt", resp);
      updateToast(id, resp.msg, resp.success);

      if (resp.success == "success") {
        navigate("/Faqlist");
      }
    } else {
      var sendData = {
        question: data.question,
        answer: data.answer,
        image: data.image,
        action: "add",
      };
      console.log("dfc", sendData);
      var resp = await axiosFile.addFaq(sendData);
      console.log("jikfyt", resp);

      updateToast(id, resp.msg, resp.success);

      console.log("safsd", resp);
      if (resp.success == "success") {
        navigate("/Faqlist");
      }
    }
  };

  const faqvalidation = () => {
    console.log(
      "dbhjbnjhbasjhdsdgsdfftsdsd",
      data,
      data?.question,
      data?.answer
    );
    var errors = {};

    if (isEmpty(data?.question)) {
      errors.question = "question field is required";
    }
    if (isEmpty(data?.answer)) {
      errors.answer = "answer field is required";
    }
    return errors;
  };

  const { answer } = data;

  return (
    <>
      <div>
        <div className="innerpages">
          <div className="orders_pages">
            <Header />
            <div className="container custom_container px-lg-0">
              <div className="row mx-auto">
                <div className="innerpages_wid px-0">
                  <Sidebar />
                  <div
                    className={
                      toggle
                        ? "content-sec-inner right_widthset content_without_sidebar"
                        : "content-sec-inner  content_with_sidebar"
                    }
                  >
                    <section id="content-wrapper">
                      <div className="top_title_sec">
                        <div>
                          <p className="page_title">
                            {Id ? "Edit Faq" : "Add Faq"}
                          </p>
                        </div>
                      </div>
                      <input
                        className="mdv_cmninput mb-3"
                        type="text"
                        id="question"
                        placeholder="Enter the question"
                        value={data.question}
                        onChange={(event) => handle(event)}
                      />
                      <span id="question-error" className="error-msg">
                        {errors && errors.question}
                      </span>

                      {answer !== null && (
                        <CKEditor
                          initData={answer}
                          onChange={(evt) => onEditorChange(evt)}
                        />
                      )}
                      <span id="answer-error" className="error-msg">
                        {errors && errors.answer}
                      </span>
                      <br />
                      {/* <Row className="mt-4">
                          <Col md={6}>
                            <div className="addfaq_sep_inpfile">
                              <p>Choose File...</p>
                            <input type="file" className="addfaq_sep_input" id="image" onChange={handleFile} />
                            </div>
                          </Col>
                          <Col md={6}>
                          {
                        onchangeimg ? <img src={onchangeimg ? onchangeimg : require('../../../assests/images/cloud.png')} width={50} /> :

                          <img src={img ? `${config.IMG_URL}/Faq/${img}` : require('../../../assests/images/cloud.png')} />
                      }
                          </Col>
                        </Row> */}

                      <button
                        className="blue_btn add_deliveryprsn dp_btn_hover"
                        onClick={() => Addata()}
                      >
                        {Id ? "Edit" : "Add"}
                      </button>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default addfaq;
