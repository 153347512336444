import React, { useState } from "react";
import Sidebar from "../layouts/sidebar";
import Headers from "../layouts/header";
import { Link, NavLink } from "react-router-dom";
import Select from "react-select";
import Datetime from "react-datetime";
import moment from "moment";
import "react-datetime/css/react-datetime.css";
import { Nav, Tab, Row, Col } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import { useSelector } from "react-redux";
import Exportcsv from "./loginpages/Managementpages/Exportcsv";
import { useEffect } from "react";
import useAxiosFile from "../actions/useaxios";
import { DateTimeForm, EncryptData, isEmpty } from "../actions/common";
import { toast } from "react-toastify";

export default function Orders() {
  const [opacity, setOpacity] = useState("false");
  const [opacityEnd, setEndOpacity] = useState("false");
  const [selectedOption, setSelectedOption] = useState(null);
  const { toggle, payload } = useSelector((state) => state.admin_panel.User);

  const axiosFile = useAxiosFile();

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {},
    onSizePerPageChange: function (page, sizePerPage) {},
  });

  const [TabName, SetTabName] = useState("neworder");
  const [Tabdatas, SetTabDatas] = useState({
    neworder: { page: 1, loader: true, data: [] },
  });
  const [shoplist, SetShopList] = useState([]);
  const [currentShop, SetCurrentShop] = useState({ label: "", value: "" });

  const columns = [
    { dataField: "OrderNumber", text: "Order Id" },
    // {dataField:"vendorName",text:"Vendor Name",sort:true},
    {
      dataField: "UserId",
      text: "Customer Name",
      sort: true,
      formatter: (cell, val) => (
        <div>
          {cell?.FirstName
            ? cell?.FirstName + " " + cell?.LastName
            : cell?.EmailId}
        </div>
      ),
    },
    // {dataField:"Quantity",text:"Items",sort:true},
    { dataField: "OrderType", text: "Type", sort: true },
    // {dataField:"operationtime",text:"Operation Time",sort:true},

    {
      dataField: "TransactionHistory",
      text: "Payment",
      sort: true,
      formatter: (cell, row) => (
        <div>
          {cell?.object?.payment_method_types?.[0]
            ? cell?.object?.payment_method_types?.[0]
            : "card"}
        </div>
      ),
    },
    {
      dataField: "DeliveryTime",
      text: "Delivery Time",
      sort: true,
      formatter: (cell, row) => (
        <div>{DateTimeForm(cell, null, null, true)}</div>
      ),
    },
    {
      dataField: "Price.Payable",
      text: "Amount",
      sort: true,
      formatter: (cell, val) => (
        <>
          <p>
            {val?.ShopId?.CurrencySymbol} {val?.Price?.PayAmount}
          </p>
        </>
      ),
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, val) => (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu men_indi_tab">
            <li>
              <Link
                class="dropdown-item"
                to={`/ordersview?id=${EncryptData(val?._id)}`}
              >
                View
              </Link>
            </li>
            {val.Status == "dispatch" && (
              <li onClick={() => OrderAction(val, "picked")}>
                <Link class="dropdown-item" to="">
                  Picked up for delivery
                </Link>
              </li>
            )}
            {val.Status == "shipping" && (
              <li onClick={() => OrderAction(val, "delivered")}>
                <Link class="dropdown-item" to="">
                  Delivered
                </Link>
              </li>
            )}
            {val.Status == "shipping" && (
              <li onClick={() => OrderAction(val, "returned")}>
                <Link class="dropdown-item" to="">
                  Returned
                </Link>
              </li>
            )}
          </ul>
        </div>
      ),
    },
  ];

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#F5F6F7"
        : isSelected
        ? "#F5F6F7"
        : isHovered
        ? "red"
        : "#F5F6F7",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#a71d24"
          : isSelected
          ? "#a71d24"
          : isFocused
          ? "#a71d24"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 20px",
      backgroundColor: "#fff ",
      border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 10,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#6C6A81",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  useEffect(() => {
    Tabdatas[TabName] = { page: 1, data: [], loader: false };
    SetTabDatas(Tabdatas);
    GetOrderList(1, TabName);
  }, [TabName]);
  console.log("djsdhsgfsj", Tabdatas);

  useEffect(() => {
    console.log("skjfskfs", currentShop);
    if (currentShop?.value) {
      GetOrderList();
    }
  }, [currentShop?.value]);

  useEffect(() => {
    console.log("sifisifosjkflsj", payload);
    if (payload?._id) {
      GetShoplist();
    }
  }, [payload?._id]);

  const GetOrderList = async (page, tab) => {
    let senddata = {
      page: (page ? page : Tabdatas[TabName]?.page) ?? 1,
      TabName: tab ? tab : TabName,
      limit: 500,
    };
    let Resp = await axiosFile.GetOrders(senddata);
    console.log("kkfldskfsd", Resp);
    if (Resp?.data?.length > 0) {
      // Resp?.data?.map((val) => {
      //   val.action = (
      //     <div class="dropdown table_viewdropdown">
      //       <button
      //         class="btn table_viewdrop dropdown-toggle"
      //         type="button"
      //         data-bs-toggle="dropdown"
      //         aria-expanded="false"
      //       >
      //         <i class="fa-solid fa-ellipsis" />
      //       </button>
      //       <ul class="dropdown-menu">
      //         {/* <li><Link class="dropdown-item" to={`/orderdetail?id=${EncryptData(val?._id)}`}>View</Link></li> */}
      //         {/* <li>
      //           <hr class="dropdown-divider" />
      //         </li> */}
      //         {val.Status == "dispatch" && (
      //           <li onClick={() => OrderAction(val, "shipping")}>
      //             <Link class="dropdown-item" to="">
      //               Picked up for delivery
      //             </Link>
      //           </li>
      //         )}
      //         {val.Status == "shipping" && (
      //           <li onClick={() => OrderAction(val, "delivered")}>
      //             <Link class="dropdown-item" to="">
      //               Delivered
      //             </Link>
      //           </li>
      //         )}
      //         {val.Status == "shipping" && (
      //           <li onClick={() => OrderAction(val, "returned")}>
      //             <Link class="dropdown-item" to="">
      //               Returned
      //             </Link>
      //           </li>
      //         )}
      //       </ul>
      //     </div>
      //   );
      // });
    }
    console.log("lfdkflkgjklsg", page);
    if (senddata.page == 1) {
      SetTabDatas({
        ...Tabdatas,
        ...{
          [TabName]: {
            data: Resp?.data,
            page: page,
            loader: Resp?.data?.length > 0 ? true : false,
          },
        },
      });
    } else {
      SetTabDatas({
        ...Tabdatas,
        ...{
          [TabName]: {
            data: [...Tabdatas[TabName].data, ...Resp?.data],
            page: page,
            loader: Resp?.data?.length > 0 ? true : false,
          },
        },
      });
    }
  };

  const OrderAction = async (val, action) => {
    console.log("kfsjgdflgdsf", val, action);

    if (action == "picked" && isEmpty(val?.Driver) && val?.OrderType == "Delivery") {
      return toast.error("Assign Delivery Partner for Pickup", 1000);
    }
    let senddata = {
      OrderId: val._id,
      Status: action,
    };
    let Resp = await axiosFile.OrderStatusUpdate(senddata);
    if (Resp?.success == "success") {
      toast.success("Status Updated Successfully");
    }
    GetOrderList(1, TabName);
  };

  const GetShoplist = async () => {
    let Resp = await axiosFile.GetShop({ from: "admin" });
    console.log("dfjkdjfhkdlshfd", Resp);
    SetShopList(Resp?.data);
    if (Resp?.data?.length > 0) {
      SetCurrentShop({
        label: Resp?.data[0]?.label,
        value: Resp?.data[0]?.value,
      });
    }
  };

  return (
    <div>
      <div className="innerpages">
        <div className="orders_pages">
          <Headers />
          <div className="container custom_container px-lg-0">
            <div className="row mx-auto">
              <div className="innerpages_wid px-0">
                <Sidebar />
                <div
                  className={
                    toggle
                      ? "content-sec-inner right_widthset content_without_sidebar"
                      : "content-sec-inner  content_with_sidebar"
                  }
                >
                  <section id="content-wrapper">
                    <div class="row mx-auto">
                      <div className="top_title_sec p-0">
                        <div>
                          <p className="page_title">Orders</p>
                        </div>
                        <div className="rightsec">
                          {/* <Exportcsv className='cmn_exportcsv' csvData={exportdata} fileName={"drivers"}/> */}
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4 mx-auto">
                      <div className="sec_border">
                        <div className="row mx-auto">
                          {/* <div className='col-xxl-4 col-xl-4 col-lg-3 col-md-3 col-sm-12 col-12'>
          <div className='whole_searchbar white_bg'>
     <img className='search_glass' src={require('../assests/images/Search.png')}/>
    <input placeholder="Search term"/>
     </div>
          </div> */}

                          {/* <div className='col-xxl-8 col-xl-8 col-lg-9 col-md-9 col-sm-12 col-12'>
          <div className='rightside'>

<div className='rightside_select'>
          <Select
                              options={options}
                              defaultValue={selectedOption}
                              styles={stylesgraybg}
                              onChange={setSelectedOption}
                              className="border_blue_select"
                              classNamePrefix="react-select"
                              placeholder='Type'
                         
                            />
                            </div>

                            <div className='order_calendar_div'>
                              <p className={opacity == "true" ? "hiding_caltxt" : ""}>Start Date</p>
                              <img className='order_calendar_ic' src={require('../assests/images/calendar.png')}/>
                              <Datetime onChange={()=>{setOpacity("true")}} className='order_datepick'/>
                            </div>

                            <p className='order_totxt'>To</p>
                            <div className='order_calendar_div'>
                              <p className={opacityEnd == "true" ? "hiding_calendtxt" : ""}>End Date</p>
                              <img className='order_calendar_ic' src={require('../assests/images/calendar.png')}/>
                              <Datetime onChange={()=>{setEndOpacity("true")}} className='order_datepick'/>
                            </div>
                            
   
       
    
          </div>

         
          </div> */}

                          <div className="orders_tabs">
                            <Tab.Container
                              id="left-tabs-example"
                              defaultActiveKey={TabName}
                            >
                              <Row>
                                <Col sm={12}>
                                  <Nav
                                    variant="pills"
                                    className="flex orders_tabs"
                                  >
                                    <Nav.Item>
                                      <Nav.Link
                                        className="order_orangetab"
                                        eventKey="neworder"
                                        onClick={() => SetTabName("neworder")}
                                      >
                                        New orders
                                        {/* <span className='orders_tabpop orange_tabbg'>5</span> */}
                                      </Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item>
              <Nav.Link className='order_violettab' eventKey="second">Scheduled <span className='orders_tabpop violet_tabbg'>5</span></Nav.Link>
            </Nav.Item> */}
                                    {/* <Nav.Item>
              <Nav.Link className='order_bluetab' eventKey="third">Accepted <span className='orders_tabpop blue_tabbg'>5</span></Nav.Link>
            </Nav.Item> */}
                                    <Nav.Item>
                                      <Nav.Link
                                        className="order_blurbluetab"
                                        eventKey="dispatch"
                                        onClick={() => SetTabName("dispatch")}
                                      >
                                        Order is ready
                                        {/* <span className='orders_tabpop blurblue_tabbg'>5</span>  */}
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link
                                        className="order_lightorangetab"
                                        eventKey="shipping"
                                        onClick={() => SetTabName("shipping")}
                                      >
                                        In transist
                                        {/* <span className='orders_tabpop lightorange_tabbg'>5</span> */}
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link
                                        className="order_greentab"
                                        eventKey="completed"
                                        onClick={() => SetTabName("completed")}
                                      >
                                        Completed
                                        {/* <span className='orders_tabpop green_tabbg'>5</span> */}
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link
                                        className="order_redtab"
                                        eventKey="cancelled"
                                        onClick={() => SetTabName("cancelled")}
                                      >
                                        Cancel
                                        {/* <span className='orders_tabpop red_tabbg'>5</span> */}
                                      </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link
                                        className="order_skybluetab"
                                        eventKey="returned"
                                        onClick={() => SetTabName("returned")}
                                      >
                                        Returned
                                        {/* <span className='orders_tabpop skyblue_tabbg'>5</span> */}
                                      </Nav.Link>
                                    </Nav.Item>
                                  </Nav>
                                </Col>
                                {console.log(
                                  "skjdskhfsjkhfs",
                                  Tabdatas[TabName]?.data
                                )}
                                <Col sm={12}>
                                  <Tab.Content>
                                    {Tabdatas?.[TabName]?.data?.length > 0 && (
                                      <Tab.Pane eventKey={TabName}>
                                        <div className="ffd_table row table_min_wid mar_bot">
                                          <BootstrapTable
                                            bootstrap4
                                            keyField="id"
                                            columns={columns}
                                            data={Tabdatas[TabName]?.data}
                                            pagination={pagination}
                                          />
                                        </div>
                                      </Tab.Pane>
                                    )}
                                    {/* <Tab.Pane eventKey="second">
            <div className='ffd_table'>
          <BootstrapTable bootstrap4 keyField='id' columns={schedulecolumns} data={data} pagination={pagination}/>
          </div>
            </Tab.Pane>
            <Tab.Pane eventKey="third">
            <div className='ffd_table'>
          <BootstrapTable bootstrap4 keyField='id' columns={schedulecolumns} data={data} pagination={pagination}/>
          </div> 
            </Tab.Pane>

            <Tab.Pane eventKey="fourth">
            <div className='ffd_table'>
          <BootstrapTable bootstrap4 keyField='id' columns={orderreadyecolumns} data={orderreadydata} pagination={pagination}/>
          </div> 
            </Tab.Pane>
            <Tab.Pane eventKey="fifth">
            <div className='ffd_table'>
          <BootstrapTable bootstrap4 keyField='id' columns={orderreadyecolumns} data={orderreadydata} pagination={pagination}/>
          </div> 
            </Tab.Pane>
            <Tab.Pane eventKey="sixth">
            <div className='ffd_table'>
          <BootstrapTable bootstrap4 keyField='id' columns={orderreadyecolumns} data={orderreadydata} pagination={pagination}/>
          </div> 
            </Tab.Pane>
            <Tab.Pane eventKey="seventh">
            <div className='ffd_table'>
          <BootstrapTable bootstrap4 keyField='id' columns={columns} data={data} pagination={pagination}/>
          </div>
            </Tab.Pane>
            <Tab.Pane eventKey="eight">
            <div className='ffd_table'>
          <BootstrapTable bootstrap4 keyField='id' columns={abandonecolumns} data={abandondata} pagination={pagination}/>
          </div>
            </Tab.Pane> */}
                                  </Tab.Content>
                                </Col>
                              </Row>
                            </Tab.Container>
                          </div>
                        </div>
                        {/* <div className='row top_filter_sec'>

          <div className='whole_searchbar'>
     <img className='search_glass' src={require('../assests/images/Search.png')}/>
    <input placeholder="Search term"/>
     </div>
          <div className='rightside'>
          <Select
        defaultValue={selectedOption}
        onChange={setSelectedOption}
        options={options}
        classNamePrefix="react-select"
      />
       <LocalizationProvider dateAdapter={AdapterDayjs} classNamePrefix="date_picker">
      <DemoContainer components={['DatePicker']}>
        <DatePicker label="start date" />
      </DemoContainer>
    </LocalizationProvider>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker label="End date" />
      </DemoContainer>
    </LocalizationProvider>
          </div>
        </div> */}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
