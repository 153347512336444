import React, { useEffect, useState } from "react";
import Sidebar from "../layouts/sidebar";
import Headers from "../layouts/header";
import { Link, NavLink } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import moment from "moment";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import { Nav, Tab, Row, Col } from "react-bootstrap";

import orders_img from "../assests/images/shopping_bag.png";
// import seller_img from "../assests/images/seller_image.png";
import star_img from "../assests/images/star.png";
import useAxiosFile from "../actions/useaxios";
import { useSelector } from "react-redux";

function createData(no, orderid, name, type, date, price) {
  return { no, orderid, name, type, date, price };
}

const latestorders = [
  createData(1, "#1593195485", "Joh Doe", "Pickup", "1/17/2022", "$23.67"),
  createData(2, "#1593195486", "Joh Doe", "Delivery", "1/17/2022", "$23.67"),
  createData(3, "#1593195487", "Joh Doe", "Pickup", "1/17/2022", "$23.67"),
  createData(4, "#1593195488", "Joh Doe", "Pickup", "1/17/2022", "$23.67"),
  createData(5, "#1593195489", "Joh Doe", "Pickup", "1/17/2022", "$23.67"),
];

function DashboardOverviewNew() {
  const [opacity, setOpacity] = useState("false");
  const [opacityEnd, setEndOpacity] = useState("false");
  const { toggle } = useSelector((state) => state.admin_panel.User);

  const axiosFile = useAxiosFile();

  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    GetDashBoardDatas();
  }, []);

  const GetDashBoardDatas = async () => {
    let Resp = await axiosFile.GetDashBoardDatas();
  };

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {},
    onSizePerPageChange: function (page, sizePerPage) {},
  });

  const columns = [
    { dataField: "id", text: "Order Id" },
    { dataField: "vendorName", text: "Vendor Name", sort: true },
    { dataField: "customerName", text: "Customer Name", sort: true },
    { dataField: "type", text: "Type", sort: true },
    { dataField: "payment", text: "Payment", sort: true },
    { dataField: "time", text: "Time", sort: true },
    { dataField: "amount", text: "Amount", sort: true },
    { dataField: "action", text: "Action" },
  ];
  const data = [
    {
      id: "1",
      vendorName: "John Doe",
      customerName: "John",
      type: <p className="table_blurblue">Delivery</p>,
      payment: <p className="table_green">Online</p>,
      time: "1/17/2022  8:20 PM",
      amount: "$5.00",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item">View</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: "1",
      vendorName: "John Doe",
      customerName: "John",
      type: <p className="table_blurblue">Delivery</p>,
      payment: <p className="table_green">Online</p>,
      time: "1/17/2022  8:20 PM",
      amount: "$5.00",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item">View</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: "1",
      vendorName: "John Doe",
      customerName: "John",
      type: <p className="table_blurblue">Delivery</p>,
      payment: <p className="table_green">Online</p>,
      time: "1/17/2022  8:20 PM",
      amount: "$5.00",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item">View</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: "1",
      vendorName: "John Doe",
      customerName: "John",
      type: <p className="table_blurblue">Delivery</p>,
      payment: <p className="table_green">Online</p>,
      time: "1/17/2022  8:20 PM",
      amount: "$5.00",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item">View</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: "1",
      vendorName: "John Doe",
      customerName: "John",
      type: <p className="table_blurblue">Delivery</p>,
      payment: <p className="table_green">Online</p>,
      time: "1/17/2022  8:20 PM",
      amount: "$5.00",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item">View</a>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  const schedulecolumns = [
    { dataField: "id", text: "Order Id" },
    { dataField: "vendorName", text: "Vendor Name", sort: true },
    { dataField: "customerName", text: "Customer Name", sort: true },
    { dataField: "type", text: "Type", sort: true },
    { dataField: "payment", text: "Payment", sort: true },
    { dataField: "time", text: "Schedule Time", sort: true },
    { dataField: "amount", text: "Amount", sort: true },
    { dataField: "action", text: "Action" },
  ];

  const orderreadyecolumns = [
    { dataField: "id", text: "Order Id" },
    { dataField: "vendorName", text: "Vendor Name", sort: true },
    { dataField: "customerName", text: "Customer Name", sort: true },
    { dataField: "type", text: "Type", sort: true },
    { dataField: "drivername", text: "Driver Name", sort: true },
    { dataField: "payment", text: "Payment", sort: true },
    { dataField: "time", text: "Schedule Time", sort: true },
    { dataField: "amount", text: "Amount", sort: true },
    { dataField: "action", text: "Action" },
  ];

  const orderreadydata = [
    {
      id: "1",
      vendorName: "John Doe",
      customerName: "John",
      type: <p className="table_blurblue">Delivery</p>,
      drivername: "John Doe",
      payment: <p className="table_green">Online</p>,
      time: "1/17/2022  8:20 PM",
      amount: "$5.00",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item">View</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: "1",
      vendorName: "John Doe",
      customerName: "John",
      type: <p className="table_blurblue">Delivery</p>,
      drivername: "John Doe",
      payment: <p className="table_green">Online</p>,
      time: "1/17/2022  8:20 PM",
      amount: "$5.00",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item">View</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: "1",
      vendorName: "John Doe",
      customerName: "John",
      type: <p className="table_blurblue">Delivery</p>,
      drivername: "John Doe",
      payment: <p className="table_green">Online</p>,
      time: "1/17/2022  8:20 PM",
      amount: "$5.00",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item">View</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: "1",
      vendorName: "John Doe",
      customerName: "John",
      type: <p className="table_blurblue">Delivery</p>,
      drivername: "John Doe",
      payment: <p className="table_green">Online</p>,
      time: "1/17/2022  8:20 PM",
      amount: "$5.00",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item">View</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: "1",
      vendorName: "John Doe",
      customerName: "John",
      type: <p className="table_blurblue">Delivery</p>,
      drivername: "John Doe",
      payment: <p className="table_green">Online</p>,
      time: "1/17/2022  8:20 PM",
      amount: "$5.00",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item">View</a>
            </li>
          </ul>
        </div>
      ),
    },
  ];
  const abandonecolumns = [
    { dataField: "id", text: "Order Id" },
    { dataField: "vendorName", text: "Vendor Name", sort: true },
    { dataField: "customerName", text: "Customer Name", sort: true },
    { dataField: "type", text: "Type", sort: true },
    {
      dataField: "status",
      text: (
        <>
          Status <img src={require("../assests/images/roundedi.png")} />
        </>
      ),
      sort: true,
    },
    { dataField: "payment", text: "Payment", sort: true },
    { dataField: "time", text: "Schedule Time", sort: true },
    { dataField: "amount", text: "Amount", sort: true },
    { dataField: "action", text: "Action" },
  ];

  const abandondata = [
    {
      id: "1",
      vendorName: "John Doe",
      customerName: "John",
      type: <p className="table_blurblue">Delivery</p>,
      status: "Pending ",
      payment: <p className="table_green">Online</p>,
      time: "1/17/2022  8:20 PM",
      amount: "$5.00",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item">View</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: "1",
      vendorName: "John Doe",
      customerName: "John",
      type: <p className="table_blurblue">Delivery</p>,
      status: "Pending ",
      payment: <p className="table_green">Online</p>,
      time: "1/17/2022  8:20 PM",
      amount: "$5.00",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item">View</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: "1",
      vendorName: "John Doe",
      customerName: "John",
      type: <p className="table_blurblue">Delivery</p>,
      status: "Pending ",
      payment: <p className="table_green">Online</p>,
      time: "1/17/2022  8:20 PM",
      amount: "$5.00",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item">View</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: "1",
      vendorName: "John Doe",
      customerName: "John",
      type: <p className="table_blurblue">Delivery</p>,
      status: "Pending ",
      payment: <p className="table_green">Online</p>,
      time: "1/17/2022  8:20 PM",
      amount: "$5.00",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item">View</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: "1",
      vendorName: "John Doe",
      customerName: "John",
      type: <p className="table_blurblue">Delivery</p>,
      status: "John Doe",
      payment: <p className="table_green">Online</p>,
      time: "1/17/2022  8:20 PM",
      amount: "$5.00",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item">View</a>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#F5F6F7"
        : isSelected
        ? "#F5F6F7"
        : isHovered
        ? "red"
        : "#F5F6F7",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#a71d24"
          : isSelected
          ? "#a71d24"
          : isFocused
          ? "#a71d24"

          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 20px",
      backgroundColor: "#fff ",
      border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 10,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#6C6A81",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const [sellerdata, setSellerdata] = useState([
    {
      seller_img: require("../assests/images/seller_image.png"),
      seller_name: "MC Donaldas",
      seller_desc: "Manhattan",
      rating: "4.5",
      ratingcount: "1692 Sales",
    },
    {
      seller_img: require("../assests/images/seller_image.png"),
      seller_name: "MC Donaldas",
      seller_desc: "Manhattan",
      rating: "4.5",
      ratingcount: "1692 Sales",
    },
    {
      seller_img: require("../assests/images/seller_image.png"),
      seller_name: "MC Donaldas",
      seller_desc: "Manhattan",
      rating: "4.5",
      ratingcount: "1692 Sales",
    },

    {
      seller_img: require("../assests/images/seller_image.png"),
      seller_name: "MC Donaldas",
      seller_desc: "Manhattan",
      rating: "4.5",
      ratingcount: "1692 Sales",
    },
  ]);
  //   const data = {
  //     series: [{
  //       name: "orders",
  //       data: [10, 41, 25, 41, 29, 42, 39]
  //   }],
  //   options: {
  //     chart: {
  //       height: 350,

  //       type: 'line',
  //       zoom: {
  //         enabled: false
  //       },

  //     },
  //     dataLabels: {
  //       enabled: false
  //     },

  //     stroke: {
  //       curve: 'smooth',
  //       width:2,
  //     },
  //     // title: {
  //     //   text: 'Product Trends by Month',
  //     //   align: 'left'
  //     // },
  //     grid: {
  //       row: {
  //         colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
  //         opacity: 0.5
  //       },
  //     },
  //     xaxis: {
  //       categories: ['11', '12', '13', '14', '15', '16', '17'],
  //     },
  //     yaxis: {
  //       type: 'Lists',
  //       categories: ["Jan", "Feb","Mar","Apr", "May","Jun", "July","Aug", "Sep"]
  //     },

  //   },

  //   };
  const datas = {
    series: [70, 20, 10],

    options: {
      labels: ["Delivery", "Pickup", "Cancel"],
      colors: ["#06038D", "#EE9D26", "#EB5757"],
      chart: {
        type: "donut",
        height: 150,
        width: "100%",
      },
      legend: {
        // show: false,
        position: "bottom",
      },
      fill: {
        colors: ["#06038D", "#EE9D26", "#EB5757"],
      },

      //  dataLabels: {
      //         enabled: false,},

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },

            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };
  const datas1 = {
    series: [
      {
        name: "Orders",
        data: [44, 55, 57, 56, 61, 58, 63],
      },
      {
        name: "Views",
        data: [76, 85, 101, 98, 87, 105, 91],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        width: "100%",
      },
      colors: ["#06038D", "#EE9D26", "#EB5757"],
      legend: {
        position: "top",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "20%",
          endingShape: "rounded",
          borderRadius: 2,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ],
      },
      yaxis: {},
      fill: {
        opacity: 1,
      },
      fill: {
        colors: ["#06038D", "#EE9D26", "#EB5757"],
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands";
          },
        },
      },
    },
  };

  return (
    <div>
      <div className="innerpages">
        <div className="dashboard_pages">
          <Headers />
          <div className="container custom_container px-lg-0">
            <div className="row mx-auto">
              <div className="innerpages_wid px-0">
                <Sidebar />
                <div
                  className={
                    toggle
                      ? "content-sec-inner right_widthset content_without_sidebar"
                      : "content-sec-inner  content_with_sidebar"
                  }
                >
                  <section id="content-wrapper">
                    <div class="row">
                      <div className="top_title_sec mt-4">
                        <div>
                          <p className="page_title">Dashboard</p>
                        </div>
                        {/* <div className='rightsec'>
    <p>Delivery Services 
    <div className='togswit'>
<input type="checkbox" id="toggle-btn"/>
<label for="toggle-btn"></label>
</div> 
    </p>
  </div>*/}
                      </div>
                    </div>

                    <div className="sec_border">
                      <div className="row">
                        <div className="col-xxl-4 col-xl-4 col-lg-3 col-md-3 col-sm-12 col-12">
                          <div className="whole_searchbar">
                            <img
                              className="search_glass"
                              src={require("../assests/images/Search.png")}
                            />
                            <input placeholder="Search term" />
                          </div>
                        </div>

                        <div className="col-xxl-8 col-xl-8 col-lg-9 col-md-9 col-sm-12 col-12">
                          <div className="rightside">
                            <div className="rightside_select">
                              <Select
                                options={options}
                                defaultValue={selectedOption}
                                styles={stylesgraybg}
                                onChange={setSelectedOption}
                                className="border_blue_select"
                                classNamePrefix="react-select"
                                placeholder="Type"
                              />
                            </div>

                            <div className="order_calendar_div">
                              <p
                                className={
                                  opacity == "true" ? "hiding_caltxt" : ""
                                }
                              >
                                Start Date
                              </p>
                              <img
                                className="order_calendar_ic"
                                src={require("../assests/images/calendar.png")}
                              />
                              <Datetime
                                onChange={() => {
                                  setOpacity("true");
                                }}
                                className="order_datepick"
                              />
                            </div>

                            <p className="order_totxt">To</p>
                            <div className="order_calendar_div">
                              <p
                                className={
                                  opacityEnd == "true" ? "hiding_calendtxt" : ""
                                }
                              >
                                End Date
                              </p>
                              <img
                                className="order_calendar_ic"
                                src={require("../assests/images/calendar.png")}
                              />
                              <Datetime
                                onChange={() => {
                                  setEndOpacity("true");
                                }}
                                className="order_datepick"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="orders_tabs">
                        <Tab.Container
                          id="left-tabs-example"
                          defaultActiveKey="first"
                        >
                          <Row>
                            <Col sm={12}>
                              <Nav variant="pills" className="flex orders_tabs">
                                <Nav.Item>
                                  <Nav.Link
                                    className="order_orangetab"
                                    eventKey="first"
                                  >
                                    New orders{" "}
                                    <span className="orders_tabpop orange_tabbg">
                                      5
                                    </span>
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link
                                    className="order_violettab"
                                    eventKey="second"
                                  >
                                    Scheduled{" "}
                                    <span className="orders_tabpop violet_tabbg">
                                      5
                                    </span>
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link
                                    className="order_bluetab"
                                    eventKey="third"
                                  >
                                    Accepted{" "}
                                    <span className="orders_tabpop blue_tabbg">
                                      5
                                    </span>
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link
                                    className="order_blurbluetab"
                                    eventKey="fourth"
                                  >
                                    Order is ready{" "}
                                    <span className="orders_tabpop blurblue_tabbg">
                                      5
                                    </span>{" "}
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link
                                    className="order_lightorangetab"
                                    eventKey="fifth"
                                  >
                                    In transit{" "}
                                    <span className="orders_tabpop lightorange_tabbg">
                                      5
                                    </span>
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link
                                    className="order_greentab"
                                    eventKey="sixth"
                                  >
                                    Completed{" "}
                                    <span className="orders_tabpop green_tabbg">
                                      5
                                    </span>
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link
                                    className="order_redtab"
                                    eventKey="seventh"
                                  >
                                    Cancel{" "}
                                    <span className="orders_tabpop red_tabbg">
                                      5
                                    </span>
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link
                                    className="order_skybluetab"
                                    eventKey="eight"
                                  >
                                    Abandoned{" "}
                                    <span className="orders_tabpop skyblue_tabbg">
                                      5
                                    </span>
                                  </Nav.Link>
                                </Nav.Item>
                              </Nav>
                            </Col>
                            <Col sm={12}>
                              <Tab.Content>
                                <Tab.Pane eventKey="first">
                                  <div className="ffd_table">
                                    <BootstrapTable
                                      bootstrap4
                                      keyField="id"
                                      columns={columns}
                                      data={data}
                                      pagination={pagination}
                                    />
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                  <div className="ffd_table">
                                    <BootstrapTable
                                      bootstrap4
                                      keyField="id"
                                      columns={schedulecolumns}
                                      data={data}
                                      pagination={pagination}
                                    />
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                  <div className="ffd_table">
                                    <BootstrapTable
                                      bootstrap4
                                      keyField="id"
                                      columns={schedulecolumns}
                                      data={data}
                                      pagination={pagination}
                                    />
                                  </div>
                                </Tab.Pane>

                                <Tab.Pane eventKey="fourth">
                                  <div className="ffd_table">
                                    <BootstrapTable
                                      bootstrap4
                                      keyField="id"
                                      columns={orderreadyecolumns}
                                      data={orderreadydata}
                                      pagination={pagination}
                                    />
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="fifth">
                                  <div className="ffd_table">
                                    <BootstrapTable
                                      bootstrap4
                                      keyField="id"
                                      columns={orderreadyecolumns}
                                      data={orderreadydata}
                                      pagination={pagination}
                                    />
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="sixth">
                                  <div className="ffd_table">
                                    <BootstrapTable
                                      bootstrap4
                                      keyField="id"
                                      columns={orderreadyecolumns}
                                      data={orderreadydata}
                                      pagination={pagination}
                                    />
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="seventh">
                                  <div className="ffd_table">
                                    <BootstrapTable
                                      bootstrap4
                                      keyField="id"
                                      columns={columns}
                                      data={data}
                                      pagination={pagination}
                                    />
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="eight">
                                  <div className="ffd_table">
                                    <BootstrapTable
                                      bootstrap4
                                      keyField="id"
                                      columns={abandonecolumns}
                                      data={abandondata}
                                      pagination={pagination}
                                    />
                                  </div>
                                </Tab.Pane>
                              </Tab.Content>
                            </Col>
                          </Row>
                        </Tab.Container>
                      </div>

                      {/* <div className='row top_filter_sec'>

          <div className='whole_searchbar'>
     <img className='search_glass' src={require('../assests/images/Search.png')}/>
    <input placeholder="Search term"/>
     </div>
          <div className='rightside'>
          <Select
        defaultValue={selectedOption}
        onChange={setSelectedOption}
        options={options}
        classNamePrefix="react-select"
      />
       <LocalizationProvider dateAdapter={AdapterDayjs} classNamePrefix="date_picker">
      <DemoContainer components={['DatePicker']}>
        <DatePicker label="start date" />
      </DemoContainer>
    </LocalizationProvider>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker label="End date" />
      </DemoContainer>
    </LocalizationProvider>
          </div>
        </div> */}
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardOverviewNew;
