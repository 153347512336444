import React, { useEffect, useState } from "react";
import Sidebar from "../../layouts/sidebar";
import Headers from "../../layouts/header";
import { useSelector } from "react-redux";
function AdminDiagnosis() {
    const { toggle } = useSelector((state) => state.admin_panel.User);
  return (
    <>
     <div>
        <div className="innerpages">
          <div className="orders_pages">
            <Headers />
            <div className="container custom_container">
              <div className="row">
                <div className="innerpages_wid">
                  <Sidebar />
                  <div
                    className={
                      toggle
                        ? "content-sec-inner right_widthset"
                        : "content-sec-inner"
                    }
                  >
                    <section id="content-wrapper">
                      <div class="row">
                       
                      </div>
                     
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminDiagnosis