import React, { useEffect, useState } from "react";
import Sidebar from "../../../layouts/sidebar";
import Headers from "../../../layouts/header";
import Select from "react-select";
import Datetime from "react-datetime";
import moment from "moment";
import "react-datetime/css/react-datetime.css";
import { Nav, Tab, Row, Col } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import useAxiosFile from "../../../actions/useaxios";
import { EncryptData, isEmpty } from "../../../actions/common";
import { Link } from "react-router-dom";
import Exportcsv from "./Exportcsv";
function ManagementDriver() {
  const { toggle } = useSelector((state) => state.admin_panel.User);

  const [selectedOption, setSelectedOption] = useState(null);
  const [rightside, setRightSide] = useState(null);
  const [exportdata, setExportdata] = useState([]);
  const axiosFile = useAxiosFile();

  const [Drivers, SetDrivers] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    GetDrivers();
  }, []);

  const GetDrivers = async () => {
    let Resp = await axiosFile.GetDriver();

    if (Resp?.data?.length > 0) {
      // Resp?.data?.map((val,ind)=>{
      //   val.hash = ind+1;
      //   val.action =
      //   <div class="dropdown table_viewdropdown">
      //   <button class="btn table_viewdrop dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
      //   <i class="fa-solid fa-ellipsis"/>
      //   </button>
      //   <ul class="dropdown-menu">

      //     <li><Link class="dropdown-item table_viewborbot" to={`/managementdriverview/?Id=${EncryptData(val._id)}`}>View</Link></li>
      //     {/* <li><a class="dropdown-item table_viewborbot">Edit</a></li>
      //     <li><a class="dropdown-item">Delete</a></li> */}

      //   </ul>
      // </div>
      // })
      var ddd = Resp?.data?.map(({ _id, _v, ...rest }) => {
        return rest;
      });
      setExportdata(ddd);

      SetDrivers(Resp.data);
    }
  };

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const driveroptions = [
    { value: "idle", label: "Idle" },
    { value: "intransit", label: "In Transit" },
    { value: "offline", label: "Offline" },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {},
    onSizePerPageChange: function (page, sizePerPage) {},
  });

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#F5F6F7"
        : isSelected
        ? "#F5F6F7"
        : isHovered
        ? "red"
        : "#F5F6F7",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#a71d24"
          : isSelected
          ? "#a71d24"
          : isFocused
          ? "#a71d24"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 20px",
      backgroundColor: "#fff ",
      border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 10,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#6C6A81",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const columns = [
    {
      dataField: "hash",
      text: "#",
      formatter: (cell, row, rowind) => <p>{rowind + 1}</p>,
    },
    {
      dataField: "FirstName",
      text: "Driver Name",
      sort: true,
      formatter: (cell, row) => (
        <p>
          {row?.FirstName} {row?.LastName}
        </p>
      ),
    },
    { dataField: "EmailId", text: "Email Id", sort: true },
    { dataField: "Mobile", text: "Phone Number", sort: true },
    // {dataField:"DeliveryZones",text:"Delivery Zone",sort:true},
    // {dataField:"orders",text:"Orders",sort:true},
    { dataField: "verificationstatus", text: "Approve Status", sort: true },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <Link
                class="dropdown-item table_viewborbot"
                to={`/managementdriverview/?Id=${EncryptData(row._id)}`}
              >
                View
              </Link>
            </li>
            {/* <li><a class="dropdown-item table_viewborbot">Edit</a></li>
            <li><a class="dropdown-item">Delete</a></li> */}
          </ul>
        </div>
      ),
    },
  ];

  const data = [
    {
      hash: (
        <img
          className="tab_dummyimg"
          src={require("../../../assests/images/dummyimg.png")}
        />
      ),
      driverName: "John Doe",
      emailId: "johndoe@gmail.com ",
      phoneNo: 1234567890,
      deliverZone: "Pasadena",
      orders: 2,
      status: <button className="table_grnavtivebtn">Active</button>,
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementdriverview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      hash: (
        <img
          className="tab_dummyimg"
          src={require("../../../assests/images/dummyimg.png")}
        />
      ),
      driverName: "John Doe",
      emailId: "johndoe@gmail.com ",
      phoneNo: 1234567890,
      deliverZone: "Pasadena",
      orders: 2,
      status: <button className="table_grnavtivebtn">Active</button>,
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementdriverview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      hash: (
        <img
          className="tab_dummyimg"
          src={require("../../../assests/images/dummyimg.png")}
        />
      ),
      driverName: "John Doe",
      emailId: "johndoe@gmail.com ",
      phoneNo: 1234567890,
      deliverZone: "Pasadena",
      orders: 2,
      status: <button className="table_grnavtivebtn">Active</button>,
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementdriverview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      hash: (
        <img
          className="tab_dummyimg"
          src={require("../../../assests/images/dummyimg.png")}
        />
      ),
      driverName: "John Doe",
      emailId: "johndoe@gmail.com ",
      phoneNo: 1234567890,
      deliverZone: "Pasadena",
      orders: 2,
      status: <button className="table_grnavtivebtn">Active</button>,
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementdriverview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      hash: (
        <img
          className="tab_dummyimg"
          src={require("../../../assests/images/dummyimg.png")}
        />
      ),
      driverName: "John Doe",
      emailId: "johndoe@gmail.com ",
      phoneNo: 1234567890,
      deliverZone: "Pasadena",
      orders: 2,
      status: <button className="table_grnavtivebtn">Active</button>,
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementdriverview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  return (
    <>
      <div>
        <div className="innerpages">
          <div className="orders_pages">
            <Headers />
            <div className="container custom_container px-lg-0">
              <div className="row mx-auto">
                <div className="innerpages_wid px-0">
                  <Sidebar />
                  <div
                    className={
                      toggle
                        ? "content-sec-inner right_widthset content_without_sidebar"
                        : "content-sec-inner  content_with_sidebar"
                    }
                  >
                    <section id="content-wrapper">
                      <div class="row">
                        <div className="top_title_sec">
                          <div>
                            <p className="page_title">Delivery Person</p>
                          </div>
                          <div className="rightside">
                            <button
                              type="button"
                              className="blue_btn add_deliveryprsn dp_btn_hover"
                              onClick={() => navigate("/adddriver")}
                            >
                              Add Delivery Person
                            </button>
                            {/* <button type="button" className='btn primary-style export_csv'>
      Export to CSV
     </button> */}
                            {/* <button type="button" className='btn primary-style export_csv' > */}
                            <Exportcsv
                              className="cmn_exportcsv"
                              csvData={exportdata}
                              fileName={"drivers"}
                            />
                            {/* </button> */}
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4 mx-auto">
                        <div className="sec_border">
                          <div className="row">
                            {/* <div className='col-xxl-4 col-xl-4 col-lg-3 col-md-3 col-sm-12 col-12'>
        <div className='whole_searchbar white_bg'>
   <img className='search_glass' src={require('../../../assests/images/Search.png')}/>
  <input placeholder="Search term"/>
   </div>
        </div> */}

                            {/* <div className='col-xxl-8 col-xl-8 col-lg-9 col-md-9 col-sm-12 col-12'>
        <div className='rightside'>

<div className='rightside_select'>
        <Select
                            options={options}
                            defaultValue={selectedOption}
                            styles={stylesgraybg}
                            onChange={setSelectedOption}
                            className="border_blue_select"
                            classNamePrefix="react-select"
                            placeholder='Type'
                       
                          />
                          </div>

                          <div className='rightside_select'>
        <Select
                            options={driveroptions}
                            defaultValue={selectedOption}
                            styles={stylesgraybg}
                            onChange={setSelectedOption}
                            className="border_blue_select"
                            classNamePrefix="react-select"
                            placeholder='Status'
                       
                          />
                          </div>

                        
  
        </div>

       
        </div> */}
                            <div className="ffd_table managedriver_table mar_bot">
                              <BootstrapTable
                                bootstrap4
                                keyField="id"
                                columns={columns}
                                data={Drivers}
                                pagination={pagination}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManagementDriver;
