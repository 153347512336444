import React, { useState } from "react";
import Sidebar from "../layouts/sidebar";
import Headers from "../layouts/header";
import { useEffect } from "react";
import useAxiosFile from "../actions/useaxios";
import { useSelector } from "react-redux";
import { NumberOnly } from "../actions/common";
import { isEmpty } from "../actions/common";
import { toast } from "react-toastify";

function DeliveryTimeCharge() {
  const { toggle, payload } = useSelector((state) => state.admin_panel.User);

  const [DayTimings, setDayTimings] = useState({
    Sunday: [
      {
        AfterPreparation: "",
        ChargeAmount: "",
      },
    ],
    Monday: [
      {
        AfterPreparation: "",
        ChargeAmount: "",
      },
    ],
    Tuesday: [
      {
        AfterPreparation: "",
        ChargeAmount: "",
      },
    ],
    Wednesday: [
      {
        AfterPreparation: "",
        ChargeAmount: "",
      },
    ],
    Thursday: [
      {
        AfterPreparation: "",
        ChargeAmount: "",
      },
    ],
    Friday: [
      {
        AfterPreparation: "",
        ChargeAmount: "",
      },
    ],
    Saturday: [
      {
        AfterPreparation: "",
        ChargeAmount: "",
      },
    ],
    DistanceCharge: "",
    DefaultCharge: ""
  });
  const [Errors, SetErrors] = useState({});

  const axiosFile = useAxiosFile();

  useEffect(() => {
    GetDeliveryTimeCharge();
  }, [payload]);

  const GetDeliveryTimeCharge = async () => {
    let Resp = await axiosFile.DeliveryTimeChargeAction({ action: "get" });
    console.log("lksdjfsdfsfs", Resp);
    setDayTimings({
      ...DayTimings,
      ...Resp.data,
    });
  };

  const OnChange = (e, key) => {
    SetErrors({});
    let { name, value, id } = e.target;
    name =Number(name)
    if (id == "DistanceCharge"||id=="DefaultCharge") {
      setDayTimings({ ...DayTimings, ...{ [id]: e.target.value, } });
    } else {
      setDayTimings({
        ...DayTimings,
        ...{
          [id]: [
            ...DayTimings[id].slice(0, name),
            ...[
              {
                ...DayTimings[id][name],
                ...{
                  [key]: value,
                },
              },
            ],
            ...DayTimings[id].slice(name + 1, DayTimings[id].length),
          ],
        },
      });
    }
  };

  const Chargeaddremove = (type, ind, day) => {
    SetErrors({});
    if (type == "add") {
      setDayTimings({
        ...DayTimings,
        ...{
          [day]: [
            ...DayTimings[day],
            ...[
              {
                AfterPreparation: "",
                ChargeAmount: "",
              },
            ],
          ],
        },
      });
    } else if (type == "remove") {
      setDayTimings({
        ...DayTimings,
        ...{
          [day]: [
            ...DayTimings[day].slice(0, ind),
            ...DayTimings[day].slice(ind + 1, DayTimings[day].length),
          ],
        },
      });
    }
  };

  const Validation = () => {
    let error = {};
    Object.keys(DayTimings).map((val) => {
      if (val.toLocaleLowerCase()?.includes("day")) {
        DayTimings[val]?.map((data, ind) => {
          if (isEmpty(data?.AfterPreparation)) {
            error[val + ind + "preparation"] = "Enter Hours";
          } else if (Number(data?.AfterPreparation) <= 0) {
            error[val + ind + "preparation"] = "Enter Valid Hours";
          }
          if (isEmpty(data?.ChargeAmount)) {
            error[val + ind + "charge"] = "Enter Charge";
          } else if (Number(data?.ChargeAmount) <= 0) {
            error[val + ind + "charge"] = "Enter Valid Charge Price";
          }
        });
      }
      else if(val.includes('Charge')&&val!="DistanceCharge"){
        if(isEmpty(DayTimings?.[val])){
          error[val] = "Enter Price"
        }
        else if(Number(DayTimings?.[val])<=0){
          error[val] = "Enter Valid Price";
        }
      }
      // else if(val == 'DistanceCharge'){
      //   if(isEmpty(DayTimings.DistanceCharge)){
      //     error.DistanceCharge = "Enter Price"
      //   }
      //   else if(Number(DayTimings.DistanceCharge)<=0){
      //     error.DistanceCharge = "Enter Valid Price";
      //   }
      // }

    });
    return error;
  };

  const Submit = async () => {
    let error = Validation();
    console.log("lsjdksljdlksf", error);
    if (isEmpty(error)) {
      let Resp = await axiosFile.DeliveryTimeChargeAction({
        action: "edit",
        days: DayTimings,
        id: DayTimings._id,
      });
      console.log("sjdsfjksfs", Resp);
      if (Resp?.status) {
        toast.success("Updated Succesfully");
      } else {
        toast.error("Try-Again");
      }
    } else {
      toast.error("Fix all Validations");
      SetErrors(error);
    }
  };

  return (
    <>
      {console.log("jkdhfjshfs", DayTimings)}
      <div>
        <div className="innerpages">
          <div className="dashboard_pages">
            <Headers />
            <div className="container custom_container px-lg-0">
              <div className="row mx-auto">
                <div className="innerpages_wid px-0">
                  <Sidebar />
                  <div
                    className={
                      toggle
                        ? "content-sec-inner right_widthset content_without_sidebar"
                        : "content-sec-inner  content_with_sidebar"
                    }
                  >
                    <section id="content-wrapper">
                      <p className="page_title">Delivery Time Charge</p>
                      <div class="row justify-content-center">
                        {/* <div className='col-sm-6 d-flex justify-content-center'>
     
    <div>
   
      <p className="subact_tabled_ttl">Enter Price Per K.M</p>
      <input
        className="sigin_inputs timing_inputs"
        type="number"
        id='DistanceCharge'
        max={24}
        placeholder="1,2..."
        onChange={(e) => OnChange(e)}
        value={DayTimings?.DistanceCharge}
      />
      
  </div>
  
  </div>
  {Errors?.DistanceCharge && 
        <span className='error-msg'>{Errors?.DistanceCharge}</span>} */}
                        <div className="top_title_sec">
                          <div className="ffd_table ">
                            <div className="subact_tabled_whole">
                              <div className="subact_delivery_whole">
                                <div className="subact_deliver_singles">
                                  <p className="subact_tabled_ttl">Day</p>
                                </div>
                                {/* <div className="subact_deliver_singles">
                                  <p className="subact_tabled_ttl">Status</p>
                                </div> */}
                                {/* <div className="subact_deliver_singles">
                                  <p className="subact_tabled_ttl">24 Hours</p>
                                </div> */}
                                <div className="subact_deliver_singles">
                                  <p className="subact_tabled_ttl">
                                    Time in hours
                                  </p>
                                </div>
                                <div className="subact_deliver_singles">
                                  <p className="subact_tabled_ttl">Charge</p>
                                </div>
                                <div className="subact_deliver_singles">
                                  <p className="subact_tabled_ttl"> Action</p>
                                </div>
                              </div>
                              {Object.keys(DayTimings).map((val) => {
                                return (
                                  val.toLocaleLowerCase()?.includes("day") && (
                                    <div className="subact_delivery_whole">
                                      <div className="subact_deliver_singles">
                                        <p>{val}</p>
                                      </div>
                                      {console.log(
                                        "dkjkfdjfd",
                                        DayTimings[val]
                                      )}

                                      <>
                                        <div className="subact_deliver_singles">
                                          {DayTimings[val]?.map((data, ind) => {
                                            return (
                                              <>
                                                <div className="storetiming_flexing mt-2">
                                                  {
                                                    <>
                                                      <input
                                                        className="sigin_inputs timing_inputs"
                                                        type="number"
                                                        max={24}
                                                        name={ind}
                                                        placeholder="1,2..."
                                                        id={val}
                                                        onChange={(e) =>
                                                          OnChange(
                                                            e,
                                                            "AfterPreparation"
                                                          )
                                                        }
                                                        value={
                                                          data?.AfterPreparation
                                                        }
                                                      />
                                                    </>
                                                  }
                                                </div>
                                                {Errors?.[
                                                  val + ind + "preparation"
                                                ] && (
                                                  <span className="error-msg">
                                                    {
                                                      Errors?.[
                                                        val +
                                                          ind +
                                                          "preparation"
                                                      ]
                                                    }
                                                  </span>
                                                )}
                                              </>
                                            );
                                          })}
                                        </div>
                                        <div className="subact_deliver_singles">
                                          {DayTimings[val]?.map((data, ind) => {
                                            return (
                                              <>
                                                <div className="storetiming_flexing mt-2">
                                                  {
                                                    <>
                                                      <input
                                                        className="sigin_inputs timing_inputs"
                                                        type="number"
                                                        value={
                                                          data?.ChargeAmount ||
                                                          ""
                                                        }
                                                        name={ind}
                                                        placeholder="1,2..."
                                                        id={val}
                                                        onChange={(e) =>
                                                          OnChange(
                                                            e,
                                                            "ChargeAmount"
                                                          )
                                                        }
                                                      />
                                                    </>
                                                  }
                                                </div>
                                                {Errors?.[
                                                  val + ind + "charge"
                                                ] && (
                                                  <span className="error-msg">
                                                    {
                                                      Errors?.[
                                                        val + ind + "charge"
                                                      ]
                                                    }
                                                  </span>
                                                )}
                                              </>
                                            );
                                          })}
                                        </div>

                                        <div className="subact_deliver_singles">
                                          {DayTimings[val]?.map((data, ind) => {
                                            return (
                                              <>
                                                <button
                                                  className="mmv_tabgn_plusbtn mt-2"
                                                  onClick={() =>
                                                    Chargeaddremove(
                                                      "add",
                                                      ind,
                                                      val
                                                    )
                                                  }
                                                >
                                                  <img
                                                    src={require("../assests/images/plusround.png")}
                                                  />
                                                </button>

                                                {ind > 0 && (
                                                  <button
                                                    className="mmv_tabgn_plusbtn ms-2"
                                                    onClick={() =>
                                                      Chargeaddremove(
                                                        "remove",
                                                        ind,
                                                        val
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      className=""
                                                      src={require("../assests/images/minusround.png")}
                                                    />
                                                  </button>
                                                )}

                                                <br />
                                              </>
                                            );
                                          })}
                                        </div>
                                      </>
                                    </div>
                                  )
                                );
                              })}
                            </div>
                            <div className="subact_deliver_singles">
                              <p>Flat Price (schedule for later)</p>
                            </div>
                            <div className="subact_deliver_singles">
                            <div className="storetiming_flexing mt-2">
                              <input
                                className="sigin_inputs timing_inputs"
                                type="number"
                                value={
                                  DayTimings?.DefaultCharge
                                }
                                name="DefaultCharge"
                                placeholder="e.x. 1"
                                id="DefaultCharge"
                                onChange={(e) => OnChange(e)}
                              />
                              </div>
                              <div className="subact_deliver_singles">
                                <p>Notes: when delivery time charge is not available this will be taken.</p>
                              </div>
                              { Errors?.DefaultCharge &&
                            <span className="error-msg">
                              {Errors?.DefaultCharge}
                            </span>}
                            </div>
                            
                            <button
                              className="blue_btn mdv_update mt-5"
                              onClick={() => Submit()}
                              type="button"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DeliveryTimeCharge;
