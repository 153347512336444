import React, { useState } from 'react'
// import { Link } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import { Link, useNavigate } from 'react-router-dom';
import { useParams, useLocation } from "react-router-dom";
import useAxiosFile from '../../actions/useaxios';
import { NumberOnly, isEmpty } from '../../actions/common';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
export default function Otpverfication() {
  const axiosFile = useAxiosFile()
  const [otp, setOtp] = useState();
  const navigate = useNavigate();
  

  const { state } = useLocation();
  
  const { payload, token } = useSelector(state => state.admin_panel.User);
  
  const [error, SetError] = useState({});
  
  const Validate = () => {
    
    let Error = {};
    if (isEmpty(otp)) {
      Error.OTP = "OTP Required"
    }
    return Error;
  }
  // const Verify = async () => {
  //   if (otp.length == 6) {
  //     // let senddata = {
  //     //   Type: "newmail",
  //     //   otp: otp,
  //     //   from: "buyer",
  //     // };
  //     let senddata = {};
  //     senddata.EmailId = state.newmail;
  //     senddata.Mobile = state?.Mobile;
  //     senddata._id = state?._id;
  //     senddata.Type = "newmail";
  //     senddata.from = "buyer";

  //       let Resp = await axiosFile.LoginReg(senddata);
  //       
  //       if (Resp.success == "success") {
  //         toast.success("OTP verified successfully");
  //         if(state.from == "email-change"){

  //           navigate('/orderdetail',{state:{from:'otp'}})
  //         }
  //         else  SetOtpVerified(true);
  //       } else {
  //         toast.error(Resp.msg);
  //         SetError({ OTP: Resp.msg });
  //       }
  //    }
  //     else {
  //     SetError({ OTP: "OTP Must Contain 6 Numbers" });
  //   }
  // };
  const Submit = async () => {
    
    let errorvalid = Validate(otp);
    if (isEmpty(errorvalid)) {
    if (otp?.length == 6) {
      
      
        let senddata = {
          otp: otp,
          Type: 'OTP'
        }
        let Resp = await axiosFile.LoginReg(senddata)
        
        if (Resp?.data == "verified") {
          toast.success("OTP verified successfully");
          navigate("/Changepassword");
        }
      }
    else {
      SetError({ OTP: "OTP Must Contain 6 Numbers" })
      
    }
  }
  else{
    SetError(errorvalid)
  }

  }

  const OnChange = (e) => {
    SetError({})
    setOtp(NumberOnly(e))
  }

  return (
    <div>
      <div className='login_pages'>
        <div className='container custom_container'>
          <div className='row aligns_itm login_row'>
            <div className='col-lg-6 leftsec'>
              <img src={require("../../assests/images/loginleft.png")} className='img-fluid login_img' />
            </div>
            <div className='col-lg-6 rightsec'>
              <div className='row logosec mb-3 mb-md-5'>
                <img src={require("../../assests/images/logo.png")} className='img-fluid logo_img' />
              </div>

              <div className='row form_sec'>
                <p className='log_title'>Verification</p>
                <p className='log_desc'>Enter the OTP that we sent you on</p>
                <Link className="link_tag blue" to="/"> {state?.EmailId}</Link>
                <div className="otp-inputs">
                  <OtpInput

                    value={otp}
                    onChange={(e)=>OnChange(e)}
                    numInputs={6}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}

                  />
                </div>

                {error?.OTP && <span className='error-msg'>{error.OTP}</span>}
                <button type="submit" className="btn btn-primary" onClick={() => Submit()}>Send Reset Link</button>

              </div>


              <div className='rem_log'>
                <p>Want to change mail address?  <Link className="link_tag blue" to="/forgot"> Back</Link></p>
              </div>

            </div>

          </div>
        </div>

      </div>













    </div>
  )
}




