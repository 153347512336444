
var EnvName = "demoserver";


var key = {};
key.KEY = "CardBo@rD1290%6Fine3";
key.ONEDAYINSECONDS = 0;
key.BLOCKS_PER_YEAR = 0;
key.RPAD_ADDRESS = "";
key.ROUTER = "";
key.EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
key.MOBILE = /^\d{10}$/;
key.NumOnly = /^\d+$/;
key.PASSWORD =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
key.OnlyAlbhabets = /^(?:[A-Za-z0-9]+)(?:[A-Za-z0-9 _]*)$/;
key.notSpecil = /^[a-zA-Z0-9]+$/;
key.OnlyAlphSpecial = /^[A-Za-z_@.#&+-]*$/;
key.Alpabetsalone = /^[A-Za-z\s]*$/;

key.limit = 50;
key.NumDigitOnly = /[^0-9\.]/g;
key.NumberOnly = /[^0-9]/g;

if (EnvName === "local") {
  key.FRONT_URL = "http://localhost:3001/";
  key.BACK_URL = "http://localhost:2000/v1";
  key.IMG_URL = "http://localhost:2000";
  // key.ADMIN_URL       =   'http://localhost:2000/'
}

if (EnvName === "demo") {
  key.FRONT_URL = "http://nftdemo.bimaticz.com/jamskeadmin";
  key.BACK_URL = "https://backend-jamske.maticz.in/v1";
  key.IMG_URL = "https://backend-jamske.maticz.in";
  // key.ADMIN_URL       =   'http://localhost:2000/'
}

if (EnvName === "demossl") {
  key.FRONT_URL = "https://jamske.maticz.in/jamskeadmin";
  key.BACK_URL = "https://backend-jamske.maticz.in/v1";
  key.IMG_URL = "https://backend-jamske.maticz.in";
  // key.ADMIN_URL       =   'http://localhost:2000/'
}
if (EnvName === "demoserver") {
  key.FRONT_URL = "https://jamskedemoadmin.maticz.in";
  key.BACK_URL = "https://jamskedemoapi.maticz.in/v1";
  key.IMG_URL = "https://jamskedemoapi.maticz.in";
  // key.ADMIN_URL       =   'http://localhost:2000/'
}

if (EnvName === "production") {
  key.Seller = "https://seller-panel.delpick.com";
  // key.Deliver = "https://jamske.maticz.in/Deliver";
  key.FRONT_URL = "https://user.delpick.com/";
  key.BACK_URL = "https://api.delpick.com/v1";
  key.IMG_URL = "https://api.delpick.com";
}

key.shopimg = `${key.IMG_URL}/shop`;
key.Keys = process.env.Decryptkey;
key.register = "XyZ";
key.login = "XYZ";
key.AccountImage = `${key.IMG_URL}/`;
key.AddressType = [
  { label: "Home", value: "Home" },
  { label: "Office", value: "Office" },
  { label: "Other", value: "Other" },
];

export default key;
