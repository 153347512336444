import React  , { useState }from 'react'
import { toast } from "react-toastify";
import { Link, useNavigate } from 'react-router-dom';
import useAxiosFile from "../../actions/useaxios";
import { isEmpty } from "../../actions/common";
import config from "../../actions/config";
export default function password() {
  const axiosFile = useAxiosFile();
  const [form, setForm] = useState({
    Password: "",
    ConfirmPassword: "",
  });
  const navigate = useNavigate();
  

  const [error, SetError] = useState({});

  const OnChange = (e) => {
    const { id, value } = e.target;
    SetError({})
    setForm({
      ...form,...{[id]: value},
    });
  };

  const Validate = () => {
    let Error = {};
    
    if (isEmpty(form.Password)) {
      Error.Password = "Enter Password";
    } else if (!config.PASSWORD.test(form.Password)) {
      Error.Password =
        "Password must contain 1 small letter, 1 capital letter, 1 special character, 1 number.";
    }
    if (form.Password !== form.ConfirmPassword) {
      Error.ConfirmPassword = "Password and Confirm Password must be same";
    }
    return Error;
  };

  const ChangePassword = async () => {
    let check = Validate();
    
    if (isEmpty(check)) {
      let senddata = {
        Type: "changepassword",
        Password: form.Password,
      };
      let Resp = await axiosFile.LoginReg(senddata);
      
      if (Resp.data.success == "success") {
        toast.success("Password Changed Sucessfully");
        navigate("/");
      } 
    } else {
      SetError(check);
    }
  };

  
  




  return (
    <div>
       <div className='login_pages'>
        <div className='container custom_container'>
            <div className='row aligns_itm login_row'>
                <div className='col-lg-6 leftsec'>
                     <img src={require("../../assests/images/loginleft.png")}  className='img-fluid login_img'/>
                </div>
                <div className='col-lg-6 rightsec'>
                    <div className='row logosec mb-3 mb-md-5'>
                    <img src={require("../../assests/images/logo.png")}  className='img-fluid logo_img'/>
                    </div>

                    <div className='row form_sec'>
                        <p className='log_title'>Choose a new password</p>
                        <p className='log_desc'>Choose a new password for your store account and continue selling with us.</p>
                        <form>
  <div className="form-group mb-4">
    <label for="exampleInputEmail1">Passworda</label>
    <input type="password" className="form-control"  id="Password" aria-describedby="emailHelp" placeholder="Enter Password" onChange={(e) => OnChange(e)}/>
    {error?.Password && <span className='error-msg'>{error.Password}</span>}
  </div>
  <div class="form-group mb-4">
    <label for="exampleInputPassword1">Confirm Password</label>
    <input type="password" className="form-control" id="ConfirmPassword" placeholder="Confirm Password" onChange={(e) => OnChange(e)}/>
    {error?.ConfirmPassword && (
                            <span className='error-msg'>{error.ConfirmPassword}</span>
                          )}
  </div>
 
  <Link  className="btn btn-primary" onClick={() => ChangePassword()}>Save</Link>
</form>
                    </div>

                </div>

            </div>
        </div>

       </div>













    </div>
  )
}
