import React, { useEffect, useState, useCallback } from "react";
import Sidebar from "../../../layouts/sidebar";
import Headers from "../../../layouts/header";
import Select from "react-select";
import Datetime from "react-datetime";
import moment from "moment";
import { Nav, Tab, Row, Col } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-datetime/css/react-datetime.css";
import useAxiosFile from "../../../actions/useaxios";
import { useDispatch, useSelector } from "react-redux";
// import useAxiosFile from '../../../actions/useaxios';
import { useParams, useLocation } from "react-router-dom";
import { isEmpty } from "../../../actions/common";
import config from "../../../actions/config";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  DecryptData,
  EncryptData,
  LoadToast,
  updateToast,
} from "../../../actions/common";

function subcategoryAdd() {
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const [selectedOption, setSelectedOption] = useState(null);
  const { toggle } = useSelector((state) => state.admin_panel.User);

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#F5F6F7"
        : isSelected
        ? "#F5F6F7"
        : isHovered
        ? "red"
        : "#F5F6F7",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#a71d24"
          : isSelected
          ? "#a71d24"
          : isFocused
          ? "#a71d24"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 20px",
      backgroundColor: "#fff ",
      border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 10,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#6C6A81",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const axiosFile = useAxiosFile();
  const navigate = useNavigate();
  const initialState = {
    Categoryname: "",
    CategoryImage: "",
    CategoryId: "",
    Category: "",
  };
  const [formValue, setFormValue] = useState(initialState);
  const [ValidateError, SetValidateError] = useState("");

  const [imageVal, setImageVal] = useState("");
  const [onchangeimg, setOnchangeimg] = useState("");
  const [Categorys, setsubcat] = useState("");
  // const { Id } = useParams()
  const [Id, Setid] = useState();
  const location = useLocation();
  console.log("ggg", location);
  console.log("xcvxcvxcv", Id);
  const { Categoryname, CategoryImage } = formValue;
  console.log("vbcvg", formValue);

  const getcatList = async () => {
    var resp = await axiosFile.getcatList();

    var cat = resp.data.map((item) => {
      return { label: item.Categoryname, value: item._id };
    });
    console.log("knsjdfn", cat);

    setsubcat(cat);
  };

  const geteditcat = async () => {
    var sendata = {
      id: isEmpty(DecryptData(Id)) ? Id : DecryptData(Id),
    };
    var resp = await axiosFile.getsubcat(sendata);
    console.log("lksjldjsad", resp);
    setSelectedOption({
      label: resp?.data?.CategoryId?.Categoryname,
      value: resp?.data?.CategoryId?._id,
    });
    setFormValue({ ...formValue, ...resp.data });
  };
  useEffect(() => {
    if (Id) {
      geteditcat();
    } else {
      getcatList();
    }
  }, [Id]);

  const onChange = (e) => {
    SetValidateError({});
    const { value, id } = e.target;
    var formData = { ...formValue, ...{ [id]: value } };
    setFormValue(formData);
  };
  const Onchangecategory = (e) => {
    console.log("hzhh", e, formValue);
    // formValue.CategoryId = e.value
    // formValue.Category = e.label
    setFormValue({
      ...formValue,
      ...{ ["CategoryId"]: e.value, Category: e.label },
    });
    setSelectedOption({
      label: e.label,
      value: e.value,
    });
    // setFormValue({
    //     ...formValue,
    //     ...{ ["Category"]: e.label },
    // })
  };
  useEffect(() => {
    console.log("kjsfsjfs", location.search.split("?Id="));
    if (location?.search) {
      let paramdata = location.search.split("?Id=")[1];
      console.log("llsflklfk", DecryptData(paramdata));
      Setid(
        isEmpty(DecryptData(paramdata)) ? paramdata : DecryptData(paramdata)
      );
    }
  }, [location?.search]);

  const Validation = useCallback(
    (data) => {
      // console("validation Checking", data)

      //
      let ValidateError = {};
      if (!data.CategoryImage)
        ValidateError.CategoryImage = "Category Image  Required";
      if (!data.Categoryname)
        ValidateError.Categoryname = "Category Name Required";
      if (isEmpty(data.CategoryId)) {
        ValidateError.Category = "Category Required";
      }
      SetValidateError(ValidateError);
      return ValidateError;
    },
    [ValidateError]
  );

  const AddSubCategory = async () => {
    console.log("rtdegyergertg", formValue);
    let error = Validation(formValue);
    // let id = LoadToast()

    // updateToast(id, "fix all validation", "error")

    if (isEmpty(error)) {
      if (Id) {
        var SendData = {
          Categoryname: formValue.Categoryname,
          CategoryImage: formValue.CategoryImage,
          CategoryId: formValue.CategoryId,
          Category: formValue.Category,
          id: Id,
          action: "edit",
        };
        var resp = await axiosFile.AddSubCategory(SendData);

        if (resp) {
          navigate("/subcategorylist");
          toast.success("Sub category updated successfully");
        }
      } else {
        var SendData = {
          Categoryname: formValue.Categoryname,
          CategoryImage: formValue.CategoryImage,
          CategoryId: formValue.CategoryId,
          Category: formValue.Category,
          action: "add",
        };
        var resp = await axiosFile.AddSubCategory(SendData);
        console.log("lkdjfkldsjfsd", resp);
        if (resp.success == "success") {
          // updateToast(id,resp.msg, resp.success)
          toast.success("Sub category added successfully");

          navigate("/subcategorylist");
        } else {
          SetValidateError(error);
          toast.error("Fix all Validations", 1000);
        }
      }
    } else {
      SetValidateError(error);
      toast.error("Fix all Validations", 1000);
    }
  };

  const handleFile = (event) => {
    event.preventDefault();
    var reader = new FileReader();

    const { id, files } = event.target;
    if (files[0].type.includes("image")) {
      if (event.target.files && event.target.files[0]) {
        var file = event.target.files[0];

        setImageVal(file);
        var url = reader.readAsDataURL(file);
        reader.onloadend = function (e) {
          if (reader.result) {
            setOnchangeimg(reader.result);
          }
        };
      }
      let formData = { ...formValue, ...{ [id]: files[0] } };
      setFormValue(formData);
    } else {
      toast.error("Upload Images Only", 1000);
    }
  };

  return (
    <>
      <div>
        <div className="innerpages">
          <div className="orders_pages">
            <Headers />
            <div className="container custom_container px-lg-0">
              <div className="row mx-auto">
                <div className="innerpages_wid px-0">
                  <Sidebar />
                  <div
                    className={
                      toggle
                        ? "content-sec-inner right_widthset content_without_sidebar"
                        : "content-sec-inner  content_with_sidebar"
                    }
                  >
                    <section id="content-wrapper">
                      <div className="top_title_sec">
                        <div>
                          <p className="page_title">SubCategory</p>
                        </div>
                        {/* <div className='rightsec'>
  

     <button type="button" className='btn primary-style export_csv'>
     <Exportcsv csvData={exportdata} fileName={"Category"}/>
     </button>



    </div> */}
                      </div>
                      <div className="sec_border">
                        <Row className="mdv_inputrow">
                          <Col
                            xxl={6}
                            xl={6}
                            lg={6}
                            md={6}
                            sm={6}
                            xs={12}
                            className="mb-3"
                          >
                            <p className="mdv_inplabel"> SubCategory Name</p>
                            <input
                              className="mdv_cmninput"
                              id="Categoryname"
                              value={Categoryname}
                              type="text"
                              placeholder="John"
                              onChange={onChange}
                            />
                            {ValidateError && ValidateError.Categoryname && (
                              <span className="error-msg">
                                {ValidateError.Categoryname}
                              </span>
                            )}
                          </Col>
                          {/* <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12} className="mb-3">
                    <p className='mdv_inplabel'>CategoryImage</p>
                    {onchangeimg ?
                        <img className='img-fluid' src={onchangeimg ? onchangeimg : require('../../../assests/images/cloud.png')} /> :

                        <img className='img-fluid' src={CategoryImage ? `${config.IMG_URL}/admin/subCategory/${CategoryImage}` : require('../../../assests/images/cloud.png')} />}
                    <input type='file' id="CategoryImage" className='' onChange={(e) => handleFile(e)} />
                    {ValidateError && ValidateError.CategoryImage && <span className="error_msg">{ValidateError.CategoryImage}</span>}
                </Col> */}

                          <Col
                            xxl={6}
                            xl={6}
                            lg={6}
                            md={6}
                            sm={6}
                            xs={12}
                            className="mb-3"
                          >
                            <p className="mdv_inplabel"> Category</p>
                            {console.log("ksfkslffs", selectedOption)}

                            <Select
                              options={Categorys}
                              // value={{label:formValue?.Category,
                              //     value:formValue?.Category}}
                              // defaultValue={selectedOption}
                              value={selectedOption}
                              styles={stylesgraybg}
                              onChange={(e) => Onchangecategory(e)}
                              className="border_blue_select"
                              classNamePrefix="react-select"
                              placeholder="Type"
                            />
                            {ValidateError && ValidateError.Category && (
                              <span className="error-msg">
                                {ValidateError.Category}
                              </span>
                            )}
                          </Col>

                          <Col
                            xxl={6}
                            xl={6}
                            lg={6}
                            md={6}
                            sm={6}
                            xs={12}
                            className="mb-3"
                          >
                            <p className="mdv_inplabel">Category Image</p>

                            <div className="flat_upload_img">
                              <img
                                className="flat_cloudimg"
                                src={require("../../../assests/images/cloud.png")}
                              />
                              <p className="flat_uploadtxt">Upload Image</p>
                              <input
                                className="flat_uploadfile"
                                id="CategoryImage"
                                onChange={(e) => handleFile(e)}
                                type="file"
                              />
                            </div>
                            {/* <p className='mdv_inplabel'>Upload Images Only</p> */}
                          </Col>

                          <Col
                            xxl={6}
                            xl={6}
                            lg={6}
                            md={6}
                            sm={6}
                            xs={12}
                            className="mb-3"
                          >
                            {onchangeimg ? (
                              <img
                                className="flat_uploadedimg"
                                src={
                                  onchangeimg
                                    ? onchangeimg
                                    : require("../../../assests/images/cloud.png")
                                }
                              />
                            ) : (
                              <img
                                className="flat_uploadedimg"
                                src={
                                  CategoryImage
                                    ? `${config.IMG_URL}/admin/subCategory/${CategoryImage}`
                                    : require("../../../assests/images/cloud.png")
                                }
                              />
                            )}

                            {ValidateError && ValidateError.CategoryImage && (
                              <span className="error-msg">
                                {ValidateError.CategoryImage}
                              </span>
                            )}

                            {/* <img className='flat_uploadedimg' src={require('../../../assests/images/cloud.png')}/> */}
                          </Col>
                        </Row>
                        <div className="mdv_update_dualbtn mt-3">
                          <button
                            className="blue_btn mdv_update"
                            onClick={() => AddSubCategory()}
                          >
                            save
                          </button>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default subcategoryAdd;
