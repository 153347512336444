import React, { useState } from "react";
import Sidebar from "../layouts/sidebar";
import Headers from "../layouts/header";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link, NavLink } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import Dropdown from "react-bootstrap/Dropdown";
import orders_img from "../assests/images/shopping_bag.png";
import dots from "../assests/images/threedots.png";
import preview from "../assests/images/preview.png";
// import seller_img from "../assests/images/seller_image.png";
import star_img from "../assests/images/star.png";

const columns = [
  {
    dataField: "id",
    text: "Product ID",
  },
  {
    dataField: "name",
    text: "Product Name",
  },
  {
    dataField: "price",
    text: "Product Price",
  },
];

function createData(orderid, name, type, date, price) {
  return { orderid, name, type, date, price };
}

const latestorders = [
  createData("15464", "Joh Doe", "#1100", "1/17/2022", "$230.67"),
  createData("15464", "Joh Doe", "#1100", "1/17/2022", "$23.67"),
  createData("15464", "Joh Doe", "#1100", "1/17/2022", "$23.67"),
  createData("15464", "Joh Doe", "#1100", "1/17/2022", "$23.67"),
  createData("15464", "Joh Doe", "#1100", "1/17/2022", "$23.67"),
];
function createData1(duration, order, amount, status, action) {
  return { duration, order, amount, status, action };
}

const lastsettle = [
  createData1("01st April 22 - 7th April 22", "20", "$230.67", "Pending", ""),
  createData1("01st April 22 - 7th April 22", "20", "$230.67", "Paid", ""),
  createData1("01st April 22 - 7th April 22", "20", "$230.67", "Paid", ""),
  createData1("01st April 22 - 7th April 22", "20", "$230.67", "Paid", ""),
  createData1("01st April 22 - 7th April 22", "20", "$230.67", "Paid", ""),
];
function createData2(
  month,
  order,
  subtotal,
  taxes,
  client,
  discount,
  amount,
  paid,
  earning,
  action
) {
  return {
    month,
    order,
    subtotal,
    taxes,
    client,
    discount,
    amount,
    paid,
    earning,
    action,
  };
}

const monthlyreports = [
  createData2(
    "January 2022",
    "1968",
    "$10,000",
    "$1,100",
    "$100",
    "$500",
    "$10,950",
    "$1095",
    "$10,105"
  ),
  createData2(
    "December 2021",
    "1968",
    "$10,000",
    "$1,100",
    "$100",
    "$500",
    "$10,950",
    "$1095",
    "$10,105"
  ),
  createData2(
    "January 2022",
    "1968",
    "$10,000",
    "$1,100",
    "$100",
    "$500",
    "$10,950",
    "$1095",
    "$10,105"
  ),
  createData2(
    "January 2022",
    "1968",
    "$10,000",
    "$1,100",
    "$100",
    "$500",
    "$10,950",
    "$1095",
    "$10,105"
  ),
  createData2(
    "January 2022",
    "1968",
    "$10,000",
    "$1,100",
    "$100",
    "$500",
    "$10,950",
    "$1095",
    "$10,105"
  ),
  createData2(
    "January 2022",
    "1968",
    "$10,000",
    "$1,100",
    "$100",
    "$500",
    "$10,950",
    "$1095",
    "$10,105"
  ),
  createData2(
    "January 2022",
    "1968",
    "$10,000",
    "$1,100",
    "$100",
    "$500",
    "$10,950",
    "$1095",
    "$10,105"
  ),
];

export default function Vendors() {
  const [opacity, setOpacity] = useState("false");
  const [opacityEnd, setEndOpacity] = useState("false");

  const [sellerdata, setSellerdata] = useState([
    {
      seller_img: require("../assests/images/seller_image.png"),
      seller_name: "MC Donaldas",
      seller_desc: "Manhattan",
      rating: "4.5",
      ratingcount: "1692 Sales",
    },
    {
      seller_img: require("../assests/images/seller_image.png"),
      seller_name: "MC Donaldas",
      seller_desc: "Manhattan",
      rating: "4.5",
      ratingcount: "1692 Sales",
    },
    {
      seller_img: require("../assests/images/seller_image.png"),
      seller_name: "MC Donaldas",
      seller_desc: "Manhattan",
      rating: "4.5",
      ratingcount: "1692 Sales",
    },

    {
      seller_img: require("../assests/images/seller_image.png"),
      seller_name: "MC Donaldas",
      seller_desc: "Manhattan",
      rating: "4.5",
      ratingcount: "1692 Sales",
    },
  ]);
  const data = {
    series: [
      {
        name: "orders",
        data: [10, 41, 25, 41, 29, 42, 39],
      },
    ],
    options: {
      chart: {
        height: 350,

        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },

      stroke: {
        curve: "smooth",
        width: 2,
      },
      // title: {
      //   text: 'Product Trends by Month',
      //   align: 'left'
      // },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: ["11", "12", "13", "14", "15", "16", "17"],
      },
      yaxis: {
        type: "Lists",
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "July",
          "Aug",
          "Sep",
        ],
      },
    },
  };
  const datas = {
    series: [70, 20, 10],

    options: {
      labels: ["Earnings", "Commission", "Sales Tax"],
      colors: ["#06038D", "#EE9D26", "#EB5757"],
      chart: {
        type: "donut",
        height: 150,
        width: "100%",
      },
      legend: {
        // show: false,
        position: "bottom",
      },
      fill: {
        colors: ["#06038D", "#EE9D26", "#EB5757"],
      },

      //  dataLabels: {
      //         enabled: false,},

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },

            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  return (
    <div>
      <div className="innerpages">
        <div className="dashboard_pages salespages">
          <Headers />
          <div className="container custom_container">
            <div className="row">
              <div className="innerpages_wid">
                <Sidebar />
                <div className="content-sec-inner">
                  <section id="content-wrapper">
                    <div class="row mb-4">
                      <div className="top_title_sec">
                        <div>
                          <p className="page_title">Monthly Report</p>
                        </div>
                        <div className="rightsec">
                          <div className="order_calendar_div">
                            <p
                              className={
                                opacity == "true" ? "hiding_caltxt" : ""
                              }
                            >
                              Start Date
                            </p>
                            <img
                              className="order_calendar_ic"
                              src={require("../assests/images/calendar.png")}
                            />
                            <Datetime
                              onChange={() => {
                                setOpacity("true");
                              }}
                              className="order_datepick"
                            />
                          </div>

                          <p className="order_totxt">To</p>
                          <div className="order_calendar_div">
                            <p
                              className={
                                opacityEnd == "true" ? "hiding_calendtxt" : ""
                              }
                            >
                              End Date
                            </p>
                            <img
                              className="order_calendar_ic"
                              src={require("../assests/images/calendar.png")}
                            />
                            <Datetime
                              onChange={() => {
                                setEndOpacity("true");
                              }}
                              className="order_datepick"
                            />
                          </div>
                          <Exportcsv
                            className="cmn_exportcsv"
                            csvData={exportdata}
                            fileName={"drivers"}
                          />
                        </div>
                      </div>
                    </div>

                    {/* <BootstrapTable keyField='id' data={ products } columns={ columns } pagination={ paginationFactory() } /> */}
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
