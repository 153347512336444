import React, { useState } from "react";
import Sidebar from "../layouts/sidebar";
import Headers from "../layouts/header";
import { Link, NavLink } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import Dropdown from "react-bootstrap/Dropdown";
import orders_img from "../assests/images/shopping_bag.png";
import dots from "../assests/images/threedots.png";
import preview from "../assests/images/preview.png";
// import seller_img from "../assests/images/seller_image.png";
import star_img from "../assests/images/star.png";

function createData(orderid, name, type, date, price) {
  return { orderid, name, type, date, price };
}

const latestorders = [
  createData("15464", "Joh Doe", "#1100", "1/17/2022", "$230.67"),
  createData("15464", "Joh Doe", "#1100", "1/17/2022", "$23.67"),
  createData("15464", "Joh Doe", "#1100", "1/17/2022", "$23.67"),
  createData("15464", "Joh Doe", "#1100", "1/17/2022", "$23.67"),
  createData("15464", "Joh Doe", "#1100", "1/17/2022", "$23.67"),
];
function createData1(duration, order, amount, status, action) {
  return { duration, order, amount, status, action };
}

const lastsettle = [
  createData1("01st April 22 - 7th April 22", "20", "$230.67", "Pending", ""),
  createData1("01st April 22 - 7th April 22", "20", "$230.67", "Paid", ""),
  createData1("01st April 22 - 7th April 22", "20", "$230.67", "Paid", ""),
  createData1("01st April 22 - 7th April 22", "20", "$230.67", "Paid", ""),
  createData1("01st April 22 - 7th April 22", "20", "$230.67", "Paid", ""),
];
function createData2(
  month,
  order,
  subtotal,
  taxes,
  client,
  discount,
  amount,
  paid,
  earning,
  action
) {
  return {
    month,
    order,
    subtotal,
    taxes,
    client,
    discount,
    amount,
    paid,
    earning,
    action,
  };
}

const monthlyreports = [
  createData2(
    "January 2022",
    "1968",
    "$10,000",
    "$1,100",
    "$100",
    "$500",
    "$10,950",
    "$1095",
    "$10,105"
  ),
  createData2(
    "December 2021",
    "1968",
    "$10,000",
    "$1,100",
    "$100",
    "$500",
    "$10,950",
    "$1095",
    "$10,105"
  ),
  createData2(
    "January 2022",
    "1968",
    "$10,000",
    "$1,100",
    "$100",
    "$500",
    "$10,950",
    "$1095",
    "$10,105"
  ),
  createData2(
    "January 2022",
    "1968",
    "$10,000",
    "$1,100",
    "$100",
    "$500",
    "$10,950",
    "$1095",
    "$10,105"
  ),
  createData2(
    "January 2022",
    "1968",
    "$10,000",
    "$1,100",
    "$100",
    "$500",
    "$10,950",
    "$1095",
    "$10,105"
  ),
  createData2(
    "January 2022",
    "1968",
    "$10,000",
    "$1,100",
    "$100",
    "$500",
    "$10,950",
    "$1095",
    "$10,105"
  ),
  createData2(
    "January 2022",
    "1968",
    "$10,000",
    "$1,100",
    "$100",
    "$500",
    "$10,950",
    "$1095",
    "$10,105"
  ),
];

export default function Sales() {
  const [opacity, setOpacity] = useState("false");
  const [opacityEnd, setEndOpacity] = useState("false");

  const [sellerdata, setSellerdata] = useState([
    {
      seller_img: require("../assests/images/seller_image.png"),
      seller_name: "MC Donaldas",
      seller_desc: "Manhattan",
      rating: "4.5",
      ratingcount: "1692 Sales",
    },
    {
      seller_img: require("../assests/images/seller_image.png"),
      seller_name: "MC Donaldas",
      seller_desc: "Manhattan",
      rating: "4.5",
      ratingcount: "1692 Sales",
    },
    {
      seller_img: require("../assests/images/seller_image.png"),
      seller_name: "MC Donaldas",
      seller_desc: "Manhattan",
      rating: "4.5",
      ratingcount: "1692 Sales",
    },

    {
      seller_img: require("../assests/images/seller_image.png"),
      seller_name: "MC Donaldas",
      seller_desc: "Manhattan",
      rating: "4.5",
      ratingcount: "1692 Sales",
    },
  ]);
  const data = {
    series: [
      {
        name: "orders",
        data: [10, 41, 25, 41, 29, 42, 39],
      },
    ],
    options: {
      chart: {
        height: 350,

        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },

      stroke: {
        curve: "smooth",
        width: 2,
      },
      // title: {
      //   text: 'Product Trends by Month',
      //   align: 'left'
      // },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: ["11", "12", "13", "14", "15", "16", "17"],
      },
      yaxis: {
        type: "Lists",
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "July",
          "Aug",
          "Sep",
        ],
      },
    },
  };
  const datas = {
    series: [70, 20, 10],

    options: {
      labels: ["Earnings", "Commission", "Sales Tax"],
      colors: ["#06038D", "#EE9D26", "#EB5757"],
      chart: {
        type: "donut",
        height: 150,
        width: "100%",
      },
      legend: {
        // show: false,
        position: "bottom",
      },
      fill: {
        colors: ["#06038D", "#EE9D26", "#EB5757"],
      },

      //  dataLabels: {
      //         enabled: false,},

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },

            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  return (
    <div>
      <div className="innerpages">
        <div className="dashboard_pages salespages">
          <Headers />
          <div className="container custom_container">
            <div className="row">
              <div className="innerpages_wid">
                <Sidebar />
                <div className="content-sec-inner">
                  <section id="content-wrapper">
                    <div class="row mb-4">
                      <div className="top_title_sec">
                        <div>
                          <p className="page_title">Finance</p>
                        </div>
                        <div className="rightsec">
                          <div className="order_calendar_div">
                            <p>Start Date</p>
                            <img
                              className="order_calendar_ic"
                              src={require("../assests/images/calendar.png")}
                            />
                            <Datetime className="order_datepick" />
                          </div>

                          <p className="order_totxt">To</p>
                          <div className="order_calendar_div">
                            <p>End Date</p>
                            <img
                              className="order_calendar_ic"
                              src={require("../assests/images/calendar.png")}
                            />
                            <Datetime className="order_datepick" />
                          </div>
                          <Exportcsv
                            className="cmn_exportcsv"
                            csvData={exportdata}
                            fileName={"drivers"}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="overall_card_sec">
                        <div className="card overall_card">
                          <img
                            src={orders_img}
                            className="img-fluid"
                            width="40"
                            height="40"
                            alt="order_img"
                          />
                          <p className="count">$15659.90</p>
                          <p className="count_name">Total Sales</p>
                        </div>
                        <div className="card overall_card">
                          <img
                            src={orders_img}
                            className="img-fluid"
                            width="40"
                            height="40"
                            alt="order_img"
                          />
                          <p className="count">25960</p>
                          <p className="count_name">Total Earnings</p>
                        </div>
                        <div className="card overall_card">
                          <img
                            src={orders_img}
                            className="img-fluid"
                            width="40"
                            height="40"
                            alt="order_img"
                          />
                          <p className="count">25960</p>
                          <p className="count_name">Admin Commission</p>
                        </div>
                        <div className="card overall_card">
                          <img
                            src={orders_img}
                            className="img-fluid"
                            width="40"
                            height="40"
                            alt="order_img"
                          />
                          <p className="count">25960</p>
                          <p className="count_name">Total Sales Tax</p>
                        </div>
                        <div className="card overall_card">
                          <img
                            src={orders_img}
                            className="img-fluid"
                            width="40"
                            height="40"
                            alt="order_img"
                          />
                          <p className="count">25960</p>
                          <p className="count_name">Pending Settlement</p>
                        </div>
                      </div>
                    </div>
                    <div className="mid_sec_graph mt-4">
                      <div className="row">
                        <div className="col-lg-3 mb-4">
                          <div className="graph_card">
                            <div className="top_title">
                              <p className="orders_name">Sales</p>
                              <p className="orders_count">25290</p>
                            </div>
                            <div id="chart1">
                              <ReactApexChart
                                options={datas.options}
                                series={datas.series}
                                type="donut"
                                height={450}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-9 mb-4">
                          <div className="graph_card ">
                            <div className="top_title">
                              <p className="orders_name">Sale's Overview</p>
                              {/* <p className='orders_count'>25290</p> */}
                            </div>
                            <div id="chart">
                              <ReactApexChart
                                options={data.options}
                                series={data.series}
                                type="line"
                                height={280}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="bottom_sec mt-4">
                      <div className="row">
                        <div className="col-12 mb-4">
                          <div className="dash_table">
                            <div className="top_section">
                              <div>
                                <p className="title">Monthly Report</p>
                              </div>
                              <div>
                                <Link className="table_link" to="">
                                  See All
                                </Link>
                              </div>
                            </div>
                            <div>
                              <TableContainer component={Paper}>
                                <Table
                                  sx={{ minWidth: 650 }}
                                  aria-label="simple table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      {/* <TableCell>No.</TableCell> */}
                                      <TableCell align="left">Month</TableCell>
                                      <TableCell align="left">Orders</TableCell>
                                      <TableCell align="left">
                                        Subtotal
                                      </TableCell>
                                      <TableCell align="left">Taxes</TableCell>
                                      <TableCell align="left">
                                        Clients Tips
                                      </TableCell>
                                      <TableCell align="left">
                                        Discount
                                      </TableCell>
                                      <TableCell align="left">
                                        Total Amount
                                      </TableCell>
                                      <TableCell align="left">
                                        Commission Paid
                                      </TableCell>
                                      <TableCell align="left">
                                        Ally Earning
                                      </TableCell>
                                      <TableCell align="left">Action</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {monthlyreports.map((monthlyreports) => (
                                      <TableRow
                                        key={latestorders.name}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell component="th" scope="row">
                                          {monthlyreports.month}
                                        </TableCell>
                                        <TableCell align="left">
                                          {monthlyreports.order}
                                        </TableCell>
                                        <TableCell align="left">
                                          {monthlyreports.subtotal}
                                        </TableCell>
                                        <TableCell align="left">
                                          {monthlyreports.taxes}
                                        </TableCell>
                                        <TableCell align="left">
                                          {monthlyreports.client}
                                        </TableCell>
                                        <TableCell
                                          className="price_clr"
                                          align="left"
                                        >
                                          {monthlyreports.discount}{" "}
                                        </TableCell>
                                        <TableCell align="left">
                                          {monthlyreports.amount}
                                        </TableCell>
                                        <TableCell align="left">
                                          {monthlyreports.paid}
                                        </TableCell>
                                        <TableCell align="left">
                                          {monthlyreports.earning}
                                        </TableCell>
                                        <TableCell align="left">
                                          <Link to="">
                                            {" "}
                                            <img
                                              src={preview}
                                              alt="preview"
                                              width={20}
                                            />
                                          </Link>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="dash_table">
                            <div className="top_section">
                              <div>
                                <p className="title">Top Seller</p>
                              </div>
                              <div>
                                <Link className="table_link" to="">
                                  See All
                                </Link>
                              </div>
                            </div>
                            <div>
                              <TableContainer component={Paper}>
                                <Table
                                  sx={{ minWidth: 650 }}
                                  aria-label="simple table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      {/* <TableCell>No.</TableCell> */}
                                      <TableCell align="left">
                                        Invoice ID.
                                      </TableCell>
                                      <TableCell align="left">
                                        Order Name
                                      </TableCell>
                                      <TableCell align="left">
                                        Order ID
                                      </TableCell>
                                      <TableCell align="left">
                                        Invoice Date{" "}
                                      </TableCell>
                                      <TableCell align="left">Price</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {latestorders.map((latestorders) => (
                                      <TableRow
                                        key={latestorders.name}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        {/* <TableCell component="th" scope="row">
                {latestorders.no}
              </TableCell> */}
                                        <TableCell align="left">
                                          {latestorders.orderid}
                                        </TableCell>
                                        <TableCell align="left">
                                          {latestorders.name}
                                        </TableCell>
                                        <TableCell align="left">
                                          {latestorders.type}
                                        </TableCell>
                                        <TableCell align="left">
                                          {latestorders.date}
                                        </TableCell>
                                        <TableCell
                                          className="price_clr"
                                          align="left"
                                        >
                                          {latestorders.price}{" "}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="dash_table">
                            <div className="top_section">
                              <div>
                                <p className="title">Last Settlements</p>
                              </div>
                              <div>
                                <Link className="table_link" to="">
                                  See All
                                </Link>
                              </div>
                            </div>
                            <div>
                              <TableContainer component={Paper}>
                                <Table
                                  sx={{ minWidth: 650 }}
                                  aria-label="simple table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      {/* <TableCell>No.</TableCell> */}
                                      <TableCell align="left">
                                        Duration
                                      </TableCell>
                                      <TableCell align="left">Orders</TableCell>
                                      <TableCell align="left">
                                        Total Amount{" "}
                                      </TableCell>
                                      <TableCell align="left">Status</TableCell>
                                      <TableCell align="left">Action</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {lastsettle.map((lastsettle) => (
                                      <TableRow
                                        key={lastsettle.name}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        {/* <TableCell component="th" scope="row">
                {lastsettle.no}
              </TableCell> */}
                                        <TableCell align="left">
                                          {lastsettle.duration}
                                        </TableCell>
                                        <TableCell align="left">
                                          {lastsettle.order}
                                        </TableCell>
                                        <TableCell
                                          className="price_clr"
                                          align="left"
                                        >
                                          {lastsettle.amount}
                                        </TableCell>
                                        <TableCell align="left">
                                          <p className="status_clr green">
                                            {lastsettle.status}
                                          </p>
                                        </TableCell>
                                        <TableCell align="left">
                                          {" "}
                                          <Dropdown className="table_drop">
                                            <Dropdown.Toggle
                                              variant="success"
                                              id="dropdown-basic"
                                            >
                                              <img
                                                src={dots}
                                                alt="dots"
                                                className="img-fluid"
                                                width={20}
                                              />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item href="#/action-1">
                                                Action
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-2">
                                                Another action
                                              </Dropdown.Item>
                                              <Dropdown.Item href="#/action-3">
                                                Something else
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>{" "}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
