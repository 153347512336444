import React, { useEffect, useState } from "react";
import Sidebar from "../../../layouts/sidebar";
import Headers from "../../../layouts/header";
import Select from "react-select";
import Datetime from "react-datetime";
import moment from "moment";
import "react-datetime/css/react-datetime.css";
import { Nav, Tab, Row, Col } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import useAxiosFile from "../../../actions/useaxios";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Exportcsv from "./Exportcsv";
import { DecryptData, EncryptData, isEmpty } from "../../../actions/common";
function ManagementUsers() {
  const axiosFile = useAxiosFile();

  const { toggle } = useSelector((state) => state.admin_panel.User);

  const [user, setuser] = useState();
  const [exportdata, setExportdata] = useState([]);

  useEffect(() => {
    getuserList();
  }, []);

  const getuserList = async () => {
    var sendata = {
      Type: "buyer",
    };
    var resp = await axiosFile.getusers(sendata);

    // resp?.data?.map((val,idx)=>{

    // val.action=
    // <div class="dropdown table_viewdropdown">
    // <button class="btn table_viewdrop dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
    //   <i class="fa-solid fa-ellipsis" />
    // </button>
    // <ul class="dropdown-menu">
    //   <li><Link class="dropdown-item table_viewborbot" to= {`/managementusersview/?Id=${String(EncryptData(val._id))}`}>View</Link></li>
    //   {/* <li><a class="dropdown-item table_viewborbot">Edit</a></li>
    //   <li><a class="dropdown-item">Delete</a></li> */}
    // </ul>
    // </div>
    // val.hash=idx+1

    // })

    var ddd = resp?.data?.map(({ _id, _v, ...rest }) => {
      return rest;
    });
    setExportdata(ddd);

    setuser(resp?.data);
  };

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {},
    onSizePerPageChange: function (page, sizePerPage) {},
  });
  const columns = [
    {
      dataField: "hash",
      text: "#",
      formatter: (cell, row, rowindex) => <p>{rowindex + 1}</p>,
    },
    { dataField: "FirstName", text: "Username", sort: true },
    { dataField: "Mobile", text: "Phone", sort: true },
    { dataField: "EmailId", text: "Email", sort: true },
    // { dataField: "balance", text: "Wallet Balance", sort: true },
    {
      dataField: "createdAt",
      text: "Joining Date",
      sort: true,
      formatter: (cell, row) => (
        <p>{moment(row?.createdAt).format("DD:MM:YYYY")}</p>
      ),
    },
    {
      dataField: "action",
      text: "action",
      formatter: (cell, val) => (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <Link
                class="dropdown-item table_viewborbot"
                to={`/managementusersview/?Id=${String(EncryptData(val._id))}`}
              >
                View
              </Link>
            </li>
            {/* <li><a class="dropdown-item table_viewborbot">Edit</a></li>
  <li><a class="dropdown-item">Delete</a></li> */}
          </ul>
        </div>
      ),
    },
  ];

  const data = [
    {
      hash: 1,

      username: "John Doe",

      mailphone: (
        <div className="mu_mailphone">
          <p>johndoe@gmail.com</p>
          <p>9876543210</p>
        </div>
      ),

      address: "354 Allen Ave, Pasadena, CA 91106, USA",

      balance: "$5,412",
      joindate: "	07 Oct, 2019",

      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementusersview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      hash: 1,
      username: "John Doe",
      mailphone: (
        <div className="mu_mailphone">
          <p>johndoe@gmail.com</p>
          <p>9876543210</p>
        </div>
      ),
      address: "354 Allen Ave, Pasadena, CA 91106, USA",
      balance: "$5,412",
      joindate: "	07 Oct, 2019",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementusersview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      hash: 1,
      username: "John Doe",
      mailphone: (
        <div className="mu_mailphone">
          <p>johndoe@gmail.com</p>
          <p>9876543210</p>
        </div>
      ),
      address: "354 Allen Ave, Pasadena, CA 91106, USA",
      balance: "$5,412",
      joindate: "	07 Oct, 2019",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementusersview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      hash: 1,
      username: "John Doe",
      mailphone: (
        <div className="mu_mailphone">
          <p>johndoe@gmail.com</p>
          <p>9876543210</p>
        </div>
      ),
      address: "354 Allen Ave, Pasadena, CA 91106, USA",
      balance: "$5,412",
      joindate: "	07 Oct, 2019",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementusersview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      hash: 1,
      username: "John Doe",
      mailphone: (
        <div className="mu_mailphone">
          <p>johndoe@gmail.com</p>
          <p>9876543210</p>
        </div>
      ),
      address: "354 Allen Ave, Pasadena, CA 91106, USA",
      balance: "$5,412",
      joindate: "	07 Oct, 2019",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementusersview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },
  ];
  return (
    <>
      <div>
        <div className="innerpages">
          <div className="orders_pages">
            <Headers />
            <div className="container custom_container px-lg-0">
              <div className="row mx-auto">
                <div className="innerpages_wid px-0">
                  <Sidebar />
                  <div
                    className={
                      toggle
                        ? "content-sec-inner right_widthset content_without_sidebar"
                        : "content-sec-inner  content_with_sidebar"
                    }
                  >
                    <section id="content-wrapper">
                      <div class="row">
                        <div className="top_title_sec">
                          <div>
                            <p className="page_title">Users</p>
                          </div>
                          <div className="rightsec">
                            {/* <button type="button" className='blue_btn add_deliveryprsn'>
      Add Delivery Person
     </button> */}

                            <Exportcsv
                              csvData={exportdata}
                              fileName={"users"}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4 mx-auto">
                        <div className="sec_border">
                          <div className="row">
                            {/* <div className="col-xxl-4 col-xl-4 col-lg-3 col-md-3 col-sm-12 col-12">
                              <div className="whole_searchbar white_bg">
                                <img
                                  className="search_glass"
                                  src={require("../../../assests/images/Search.png")}
                                />
                                <input placeholder="Search term" />
                              </div>
                            </div> */}

                            <div className="col-xxl-8 col-xl-8 col-lg-9 col-md-9 col-sm-12 col-12">
                              <div className="rightside"></div>
                            </div>
                            {user?.length > 0 && user && (
                              <div className="ffd_table manage_usertale mar_bot">
                                <BootstrapTable
                                  bootstrap4
                                  keyField="id"
                                  columns={columns}
                                  data={user}
                                  pagination={pagination}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManagementUsers;
