import React, { useEffect, useState } from "react";
import Sidebar from "../../../layouts/sidebar";
import Headers from "../../../layouts/header";
import Select from "react-select";
import Datetime from "react-datetime";
import moment from "moment";
import "react-datetime/css/react-datetime.css";
import { Nav, Tab, Row, Col } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import useAxiosFile from "../../../actions/useaxios";
import config from "../../../actions/config";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Exportcsv from "./Exportcsv";
import { useNavigate } from "react-router";
import { DecryptData, EncryptData } from "../../../actions/common";

function Cmslist() {
  const [selectedOption, setSelectedOption] = useState(null);
  const [exportdata, setExportdata] = useState([]);
  const navigate = useNavigate();
  const axiosFile = useAxiosFile();

  const [cat, setcat] = useState();

  useEffect(() => {
    getcatList();
  }, []);

  const getcatList = async () => {
    var resp = await axiosFile.getcatList();

    resp?.data?.map((val, idx) => {
      val.hash = (
        <img
          className="tab_dummyimg"
          src={
            val?.CategoryImage
              ? `${config.IMG_URL}/admin/Category/${val.CategoryImage}`
              : require("../../../assests/images/dummyimg.png")
          }
        />
      );
      val.action = (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            {/* <li><a class="dropdown-item table_viewborbot" >View</a></li> */}
            <li>
              <Link class="dropdown-item table_viewborbot" to="/cmseditpage">
                Edit
              </Link>
            </li>
            <li>
              <a
                class="dropdown-item"
                onClick={() =>
                  hideShowCategory(
                    val.hideShow == "visible" ? "hidden" : "visible",
                    val.Categoryname
                  )
                }
              >
                {val.hideShow == "hidden" ? "SHOW" : "HIDE"}
              </a>
            </li>
          </ul>
        </div>
      );
    });
    var ddd = resp?.data?.map(({ _id, _v, ...rest }) => {
      return rest;
    });
    setExportdata(ddd);
    setcat(resp?.data);
  };

  const hideShowCategory = async (data, name) => {
    var payload = { hideShow: data, catName: name };
    var resp = await axiosFile.hideShowsubCat(payload);

    if (resp?.status) {
      // toast.success(resp.msg)
      getcatList();
    } else toast.error(resp.msg);
  };

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {},
    onSizePerPageChange: function (page, sizePerPage) {},
  });

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const driveroptions = [
    { value: "idle", label: "Idle" },
    { value: "intransit", label: "In Transit" },
    { value: "offline", label: "Offline" },
  ];

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#F5F6F7"
        : isSelected
        ? "#F5F6F7"
        : isHovered
        ? "red"
        : "#F5F6F7",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#a71d24"
          : isSelected
          ? "#a71d24"
          : isFocused
          ? "#a71d24"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 20px",
      backgroundColor: "#fff ",
      border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 10,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#6C6A81",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  // const hideShowCategory = async(data,name)=>{
  //   var payload = {hideShow:data,catName:name}
  //   var resp = await tokenFunctions.hideShowCat(payload);
  //   if(resp?.status){
  //     toast.success(resp.msg)
  //     setTimeout(() => {
  //       window.location.reload();
  //     },2000);
  //   }
  //   else toast.error(resp.msg)

  // }

  const columns = [
    { dataField: "hash", text: "CategoryImage" },

    { dataField: "Categoryname", text: "Categoryname", sort: true },
    // {dataField:"Address",text:"Location"},
    // {dataField:"Category",text:"Category",sort:true},
    // {dataField:"status",text:"Status",sort:true},
    { dataField: "action", text: "Action" },
  ];

  const data = [
    {
      hash: (
        <img
          className="tab_dummyimg"
          src={require("../../../assests/images/dummyimg.png")}
        />
      ),
      vendorname: "John Doe",
      location: "354 Allen Ave, Pasadena, CA 91106, USA",
      category: "Resturant, Italian",
      status: <button className="table_grnavtivebtn">Available</button>,

      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementusersview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot drpdw_greentxt">
                Active
              </a>
            </li>
            <li>
              <a class="dropdown-item">Settlement</a>
            </li>
          </ul>
        </div>
      ),
    },

    {
      hash: (
        <img
          className="tab_dummyimg"
          src={require("../../../assests/images/dummyimg.png")}
        />
      ),
      vendorname: "John Doe",
      vendorId: "#1562",
      location: "354 Allen Ave, Pasadena, CA 91106, USA",
      category: "Resturant, Italian",
      status: (
        <button className="table_sandalactbtn">Waiting for approval</button>
      ),
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementusersview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot drpdw_greentxt">
                Active
              </a>
            </li>
            <li>
              <a class="dropdown-item drpdw_redtxt">Inactive</a>
            </li>
          </ul>
        </div>
      ),
    },

    {
      hash: (
        <img
          className="tab_dummyimg"
          src={require("../../../assests/images/dummyimg.png")}
        />
      ),
      vendorname: "John Doe",
      vendorId: "#1562",
      location: "354 Allen Ave, Pasadena, CA 91106, USA",
      category: "Resturant, Italian",
      status: <button className="table_grnavtivebtn">Active</button>,
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementvendorsview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot drpdw_redtxt">
                Inactive
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Order Aceepting Off</a>
            </li>
            <li>
              <a class="dropdown-item">Settlement</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      hash: (
        <img
          className="tab_dummyimg"
          src={require("../../../assests/images/dummyimg.png")}
        />
      ),
      vendorname: "John Doe",
      vendorId: "#1562",
      location: "354 Allen Ave, Pasadena, CA 91106, USA",
      category: "Resturant, Italian",
      status: <button className="table_grnavtivebtn">Active</button>,
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementvendorsview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot drpdw_redtxt">
                Inactive
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Order Aceepting Off</a>
            </li>
            <li>
              <a class="dropdown-item">Settlement</a>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="innerpages">
        <div className="orders_pages">
          <Headers />
          <div className="container custom_container">
            <div className="row">
              <div className="innerpages_wid">
                <Sidebar />
                <div className="content-sec-inner">
                  <section id="content-wrapper">
                    <div class="row">
                      <div className="top_title_sec">
                        <div>
                          <p className="page_title">Categorylist</p>
                        </div>
                        <div className="rightsec">
                          {/* <button type="button" className='blue_btn add_deliveryprsn'>
      Add Vendors
     </button> */}

                          <button
                            type="button"
                            className="blue_btn add_deliveryprsn dp_btn_hover"
                            onClick={() => navigate("/Category")}
                          >
                            Add Category
                          </button>

                          <button
                            type="button"
                            className="btn primary-style export_csv"
                          >
                            <Exportcsv
                              csvData={exportdata}
                              fileName={"Category"}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="sec_border">
                        <div className="row">
                          <div className="col-xxl-4 col-xl-4 col-lg-3 col-md-3 col-sm-12 col-12">
                            <div className="whole_searchbar white_bg">
                              <img
                                className="search_glass"
                                src={require("../../../assests/images/Search.png")}
                              />
                              <input placeholder="Search term" />
                            </div>
                          </div>

                          <div className="col-xxl-8 col-xl-8 col-lg-9 col-md-9 col-sm-12 col-12">
                            <div className="rightside">
                              <div className="rightside_select">
                                <Select
                                  options={options}
                                  defaultValue={selectedOption}
                                  styles={stylesgraybg}
                                  onChange={setSelectedOption}
                                  className="border_blue_select"
                                  classNamePrefix="react-select"
                                  placeholder="Type"
                                />
                              </div>

                              <div className="rightside_select">
                                <Select
                                  options={driveroptions}
                                  defaultValue={selectedOption}
                                  styles={stylesgraybg}
                                  onChange={setSelectedOption}
                                  className="border_blue_select"
                                  classNamePrefix="react-select"
                                  placeholder="Status"
                                />
                              </div>
                            </div>
                          </div>

                          {cat && (
                            <div className="ffd_table">
                              <BootstrapTable
                                bootstrap4
                                keyField="id"
                                columns={columns}
                                data={cat}
                                pagination={pagination}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Cmslist;
