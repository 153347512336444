import React, { useEffect, useState } from "react";
import Sidebar from "../../layouts/sidebar";
import Headers from "../../layouts/header";
import { Link, NavLink, useNavigate } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import Select from "react-select";
import Datetime from "react-datetime";

import orders_img from "../../assests/images/shopping_bag.png";
// import seller_img from "../assests/images/seller_image.png";
import { useSelector } from "react-redux";

import star_img from "../../assests/images/star.png";
// import useAxiosFile from "../actions/useaxios";
import useAxiosFile from "../../actions/useaxios";
// import { isEmpty } from "../actions/common";
import { isEmpty } from "../../actions/common";
// import config from "../actions/config";
import config from "../../actions/config";
// import Exportcsv from "../loginpages/Managementpages/Exportcsv";
import Exportcsv from ".././loginpages/Managementpages/Exportcsv";

function createData(no, orderid, name, type, date, price) {
  return { no, orderid, name, type, date, price };
}

const latestorders = [
  createData(1, "#1593195485", "Joh Doe", "Pickup", "1/17/2022", "$23.67"),
  createData(2, "#1593195486", "Joh Doe", "Delivery", "1/17/2022", "$23.67"),
  createData(3, "#1593195487", "Joh Doe", "Pickup", "1/17/2022", "$23.67"),
  createData(4, "#1593195488", "Joh Doe", "Pickup", "1/17/2022", "$23.67"),
  createData(5, "#1593195489", "Joh Doe", "Pickup", "1/17/2022", "$23.67"),
];

export default function SalesFinance() {
  const axiosFile = useAxiosFile();

  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState(null);
  const [chartdata, setChartData] = useState({});
  const [newdata, setnew] = useState();
  const [opacity, setOpacity] = useState("false");
  const [exportdata, setExportdata] = useState([]);
  const { toggle, payload } = useSelector((state) => state.admin_panel.User);

  useEffect(() => {
    GetDashBoardDatas();
  }, []);

  const GetDashBoardDatas = async () => {
    let Resp = await axiosFile.GetDashBoardDatas();
    console.log("sfjskjfsfs", Resp);
    if (!isEmpty(Resp?.data)) {
      let datas = Resp?.data;
      let ordergraph = [
        datas?.TotalDelivery,
        datas?.TotalPickup,
        datas?.TotalCancel,
      ];
      let userordergraph = [
        { name: "Orders", data: datas.OrderOnDays },
        { name: "Views", data: datas.ViewOnDays },
      ];

      let overviewgraphtime = [];
      let overviewgraphorders = { name: "Orders", data: [] };
      for (let i = 0; i <= 6; i++) {
        let sum = 0;
        if (i > 0) {
          for (let j = i == 1 ? 1 : i * 4 - 4 + 1; j <= i * 4; j++) {
            sum =
              sum +
              (Object.keys(datas?.Ordertoday)?.some((val) => {
                return val == j;
              })
                ? datas.Ordertoday[j].orders
                : 0);
          }
        }
        overviewgraphorders.data.push(sum / 4);
        overviewgraphtime.push(String(i * 4));
      }
      // overviewgraphorders.data.slice(1,7)

      let currdata = data;
      currdata.options.xaxis.categories = overviewgraphtime;
      setChartData({
        Ordergraph: ordergraph,
        TotalOrders: datas.TotalOrders,
        UserOrders: userordergraph,
        OverviewGraphtime: overviewgraphtime,
        OverViewGraphOrder: [overviewgraphorders],
        TopShops: datas.TopShops,
        LatestOrders: datas.LatestOrders,
      });
      SetData({ ...data, ...currdata });
      setnew(Resp.data);
    }
  };

  const [sellerdata, setSellerdata] = useState([
    {
      seller_img: require("../../assests/images/seller_image.png"),
      seller_name: "MC Donaldas",
      seller_desc: "Manhattan",
      rating: "4.5",
      ratingcount: "1692 Sales",
    },
    {
      seller_img: require("../../assests/images/seller_image.png"),
      seller_name: "MC Donaldas",
      seller_desc: "Manhattan",
      rating: "4.5",
      ratingcount: "1692 Sales",
    },
    {
      seller_img: require("../../assests/images/seller_image.png"),
      seller_name: "MC Donaldas",
      seller_desc: "Manhattan",
      rating: "4.5",
      ratingcount: "1692 Sales",
    },

    {
      seller_img: require("../../assests/images/seller_image.png"),
      seller_name: "MC Donaldas",
      seller_desc: "Manhattan",
      rating: "4.5",
      ratingcount: "1692 Sales",
    },
  ]);
  var [data, SetData] = useState({
    series: [
      {
        name: "orders",
        data: [
          10, 41, 25, 41, 29, 42, 39, 2, 3, 2, 2, 1, 10, 41, 25, 41, 29, 42, 39,
          2, 3, 2, 2, 1,
        ],
      },
    ],
    options: {
      chart: {
        height: 350,

        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },

      stroke: {
        curve: "smooth",
        width: 2,
      },
      // title: {
      //   text: 'Product Trends by Month',
      //   align: 'left'
      // },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [
          "0",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
        ],
      },
      yaxis: {
        type: "Lists",
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "July",
          "Aug",
          "Sep",
        ],
      },
    },
  });
  const driveroptions = [
    { value: "idle", label: "Idle" },
    { value: "intransit", label: "In Transit" },
    { value: "offline", label: "Offline" },
  ];
  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#F5F6F7"
        : isSelected
        ? "#F5F6F7"
        : isHovered
        ? "red"
        : "#F5F6F7",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#a71d24"
          : isSelected
          ? "#a71d24"
          : isFocused
          ? "#a71d24"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 20px",
      backgroundColor: "#fff ",
      border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 10,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#6C6A81",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };
  const datas = {
    series: [70, 20, 10],

    options: {
      labels: ["Earning", "Commission", "Sales Tax"],
      colors: ["#06038D", "#EE9D26", "#EB5757"],
      chart: {
        type: "donut",
        height: 150,
        width: "100%",
      },
      legend: {
        // show: false,
        position: "bottom",
      },
      fill: {
        colors: ["#06038D", "#EE9D26", "#EB5757"],
      },

      //  dataLabels: {
      //         enabled: false,},

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },

            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };
  const datas1 = {
    series: [
      {
        name: "Orders",
        data: [44, 55, 57, 56, 61, 58, 63],
      },
      {
        name: "Views",
        data: [76, 85, 101, 98, 87, 105, 91],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        width: "100%",
      },
      colors: ["#06038D", "#EE9D26", "#EB5757"],
      legend: {
        position: "top",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "20%",
          endingShape: "rounded",
          borderRadius: 2,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ],
      },
      yaxis: {},
      fill: {
        opacity: 1,
      },
      fill: {
        colors: ["#06038D", "#EE9D26", "#EB5757"],
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "" + val + " ";
            // return "$ " + val + " thousands"
          },
        },
      },
    },
  };

  return (
    <div>
      <div className="innerpages">
        <div className="dashboard_pages">
          <Headers />
          <div className="container custom_container px-lg-0">
            <div className="row mx-auto">
              <div className="innerpages_wid px-0">
                <Sidebar />
                <div
                  className={
                    toggle
                      ? "content-sec-inner right_widthset content_without_sidebar"
                      : "content-sec-inner  content_with_sidebar"
                  }
                >
                  <section id="content-wrapper">
                    <div class="row">
                      <div className="col-12 col-md-3 d-flex align-items-center">
                        <p className="page_title mb-0">Finance</p>
                      </div>
                      <div className="col-12 col-md-9  mt-sm-4 mt-md-0">
                        {" "}
                        <div className="rightside align_cent">
                          {/* <button
                              type="button"
                              className="blue_btn add_deliveryprsn"
                              onClick={() => navigate("/addseller")}
                            >
                              Add Locations
                            </button> */}
                          {/* <div className="rightside_select">
                            <Select
                              options={driveroptions}
                              defaultValue={selectedOption}
                              styles={stylesgraybg}
                              onChange={setSelectedOption}
                              className="border_blue_select"
                              classNamePrefix="react-select"
                              placeholder="Month"
                            />
                          </div> */}

                          <div className="order_calendar_div">
                            <p
                              className={
                                opacity == "true" ? "hiding_caltxt" : ""
                              }
                            >
                              Start Date
                            </p>
                            <img
                              className="order_calendar_ic"
                              src={require("../../assests/images/calendar.png")}
                            />
                            <Datetime
                              onChange={() => {
                                setOpacity("true");
                              }}
                              className="order_datepick"
                            />
                          </div>
                          <p className="mb-0">To</p>
                          <div className="order_calendar_div">
                            <p
                              className={
                                opacity == "true" ? "hiding_caltxt" : ""
                              }
                            >
                              End Date
                            </p>
                            <img
                              className="order_calendar_ic"
                              src={require("../../assests/images/calendar.png")}
                            />
                            <Datetime
                              onChange={() => {
                                setOpacity("true");
                              }}
                              className="order_datepick"
                            />
                          </div>
                          {exportdata && (
                            <Exportcsv
                              csvData={exportdata}
                              fileName={"users"}
                            />
                          )}

                          {/* <CSVLink
                              className="downloadbtn"
                              filename="my-file.csv"
                              data={csvData}
                            >
                              Export to CSV
                            </CSVLink> */}
                        </div>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="overall_card_sec">
                        <div className="card overall_card">
                          <img
                            src={orders_img}
                            className="img-fluid"
                            width="40"
                            height="40"
                            alt="order_img"
                          />
                          <p className="count">{chartdata?.TotalOrders}</p>
                          <p className="count_name">Total Sales</p>
                        </div>
                        {/* {console.log('kjdhfdkjfkhds',newdata)} */}
                        <div className="card overall_card">
                          <img
                            src={orders_img}
                            className="img-fluid"
                            width="40"
                            height="40"
                            alt="order_img"
                          />
                          <p className="count">
                            {newdata?.Earnings?.TotalSales
                              ? newdata?.Earnings?.TotalSales
                              : 0}
                          </p>
                          <p className="count_name">Total Earnings</p>
                        </div>
                        <div className="card overall_card">
                          <img
                            src={orders_img}
                            className="img-fluid"
                            width="40"
                            height="40"
                            alt="order_img"
                          />
                          {console.log("ldsjflksjflsfs", newdata)}
                          <p className="count">
                            {newdata?.Earnings?.TotalEarnings
                              ? newdata?.Earnings?.TotalEarnings
                              : 0}
                          </p>
                          <p className="count_name">Adming Commission</p>
                        </div>
                        <div className="card overall_card">
                          <img
                            src={orders_img}
                            className="img-fluid"
                            width="40"
                            height="40"
                            alt="order_img"
                          />
                          <p className="count">{newdata?.TotalVendors}</p>
                          <p className="count_name">Total Sales Tax</p>
                        </div>
                        <div className="card overall_card">
                          <img
                            src={orders_img}
                            className="img-fluid"
                            width="40"
                            height="40"
                            alt="order_img"
                          />
                          <p className="count">{newdata?.TotalDrivers}</p>
                          <p className="count_name">Pending Settlement</p>
                        </div>
                      </div>
                    </div>
                    <div className="mid_sec_graph mt-4">
                      <div className="row">
                        <div className="col-sm-5 col-xl-3  mb-4">
                          <div className="graph_card">
                            <div className="top_title">
                              <p className="orders_name">Sales</p>
                              <p className="orders_count">
                                {chartdata?.TotalOrders}
                              </p>
                            </div>
                            <div id="chart1">
                              <ReactApexChart
                                options={datas.options}
                                series={
                                  chartdata?.Ordergraph
                                    ? chartdata.Ordergraph
                                    : datas.series
                                }
                                type="donut"
                                height={450}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-7 col-xl-9 mb-4">
                          <div className="graph_card ">
                            <div className="top_title">
                              <p className="orders_name">Sales Overview</p>
                              {/* <p className='orders_count'>25290</p> */}
                            </div>
                            <div id="chart">
                              {chartdata?.OverViewGraphOrder && (
                                <ReactApexChart
                                  options={data.options}
                                  series={
                                    chartdata?.OverViewGraphOrder
                                      ? chartdata?.OverViewGraphOrder
                                      : data.series
                                  }
                                  type="line"
                                  height={280}
                                />
                              )}
                              {/* <ReactApexChart options={data.options} series={data.series} type="line" height={280} /> */}
                            </div>
                            {console.log("ksdghjsa", chartdata, datas)}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="bottom_sec mt-4">
                      <div className="row">
                        <div className="col mt-5 mt-lg-0">
                          <div className="dash_table">
                            <div className="top_section">
                              <div>
                                <p className="title">Monthly Report</p>
                              </div>
                              <div>
                                <Link className="table_link" to="/orders">
                                  See All
                                </Link>
                              </div>
                            </div>
                            <div>
                              <TableContainer component={Paper}>
                                <Table
                                  sx={{ minWidth: 650 }}
                                  aria-label="simple table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Month</TableCell>
                                      <TableCell align="center">
                                        Orders
                                      </TableCell>
                                      <TableCell align="center">
                                        Subtotal
                                      </TableCell>
                                      <TableCell align="center">
                                        Taxes
                                      </TableCell>
                                      <TableCell align="center">
                                        Clients Tips
                                      </TableCell>
                                      <TableCell align="center">
                                        Discount
                                      </TableCell>
                                      <TableCell align="center">
                                        Total Amount
                                      </TableCell>
                                      <TableCell align="center">
                                        Commission Paid
                                      </TableCell>
                                      <TableCell align="center">
                                        Ally Earning
                                      </TableCell>
                                      <TableCell align="center">
                                        Action
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {chartdata?.LatestOrders?.length > 0 &&
                                      chartdata?.LatestOrders?.map(
                                        (latestorders, ind) => (
                                          <TableRow
                                            key={latestorders.name}
                                            sx={{
                                              "&:last-child td, &:last-child th":
                                                { border: 0 },
                                            }}
                                          >
                                            <TableCell
                                              component="th"
                                              scope="row"
                                              align="center"
                                            >
                                              {ind + 1}
                                            </TableCell>
                                            <TableCell align="center">
                                              <p className="gry_txt mb-0">
                                                {latestorders.OrderNumber}
                                              </p>
                                            </TableCell>
                                            <TableCell align="center">
                                              <p className="dark_gry mb-0">
                                                {
                                                  latestorders?.UserId
                                                    ?.FirstName
                                                }
                                              </p>
                                            </TableCell>
                                            <TableCell align="center">
                                              <p className="gry_txt mb-0">
                                                {latestorders.OrderType}
                                              </p>
                                            </TableCell>
                                            <TableCell align="center">
                                              <p className="gry_txt mb-0">
                                                $100
                                              </p>
                                            </TableCell>
                                            <TableCell align="center">
                                              <p className="gry_txt mb-0">
                                                $500
                                              </p>
                                            </TableCell>
                                            <TableCell align="center">
                                              <p className="blue_txt mb-0">
                                                $10,950
                                              </p>
                                            </TableCell>
                                            <TableCell align="center">
                                              <p className="gry_txt mb-0">
                                                $1095
                                              </p>
                                            </TableCell>
                                            <TableCell align="center">
                                              <p className="blue_txt mb-0">
                                                $10,950
                                              </p>
                                            </TableCell>

                                            <TableCell
                                              className="price_clr"
                                              align="center"
                                            >
                                              {" "}
                                              <i
                                                class="fa-solid fa-eye curs_poin fs-5 i_icn"
                                                aria-hidden="true"
                                              ></i>
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bottom_sec mt-4">
                      <div className="row">
                        <div className="col-lg-6 mt-5 mt-lg-0">
                          <div className="dash_table">
                            <div className="top_section">
                              <div>
                                <p className="title">Latest Invoices</p>
                              </div>
                              <div>
                                <Link className="table_link" to="/orders">
                                  See All
                                </Link>
                              </div>
                            </div>
                            <div>
                              <TableContainer component={Paper}>
                                <Table
                                  sx={{ minWidth: 650 }}
                                  aria-label="simple table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>
                                        <p className="mb-0 txt_lt_gray text-center">
                                          Invoice ID
                                        </p>
                                      </TableCell>
                                      <TableCell align="center">
                                        <p className="mb-0 txt_lt_gray text-center">
                                          Order Name
                                        </p>
                                      </TableCell>
                                      <TableCell align="center">
                                        <p className="mb-0 txt_lt_gray text-center">
                                          Order ID
                                        </p>
                                      </TableCell>
                                      <TableCell align="center">
                                        <p className="mb-0 txt_lt_gray text-center">
                                          Invoice Date
                                        </p>
                                      </TableCell>
                                      <TableCell align="center">
                                        <p className="mb-0 txt_lt_gray text-center">
                                          Price
                                        </p>
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {chartdata?.LatestOrders?.length > 0 &&
                                      chartdata?.LatestOrders?.map(
                                        (latestorders, ind) => (
                                          <TableRow
                                            key={latestorders.name}
                                            sx={{
                                              "&:last-child td, &:last-child th":
                                                { border: 0 },
                                            }}
                                          >
                                            <TableCell scope="row">
                                              <p className="dark_gry mb-0 text-center">
                                                15464
                                              </p>
                                            </TableCell>
                                            <TableCell align="center">
                                              <p className="dark_gry mb-0">
                                                Joh Doe
                                              </p>
                                            </TableCell>
                                            <TableCell align="center">
                                              <p className="dark_gry mb-0">
                                                #1100
                                              </p>
                                            </TableCell>
                                            <TableCell align="center">
                                              <p className="dark_gry mb-0">
                                                4/17/2023
                                              </p>
                                            </TableCell>
                                            <TableCell align="center">
                                              <p className="blue_txt  mb-0">
                                                $230.67
                                              </p>
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 mt-5 mt-lg-0">
                          <div className="dash_table">
                            <div className="top_section">
                              <div>
                                <p className="title">Latest Statment</p>
                              </div>
                              <div>
                                <Link className="table_link" to="/orders">
                                  See All
                                </Link>
                              </div>
                            </div>
                            <div>
                              <TableContainer component={Paper}>
                                <Table
                                  sx={{ minWidth: 650 }}
                                  aria-label="simple table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>
                                        <p className="mb-0 txt_lt_gray text-center">
                                          Duration
                                        </p>
                                      </TableCell>
                                      <TableCell align="center">
                                        <p className="mb-0 txt_lt_gray text-center">
                                          Orders
                                        </p>
                                      </TableCell>
                                      <TableCell align="center">
                                        <p className="mb-0 txt_lt_gray text-center">
                                          Total Amount
                                        </p>
                                      </TableCell>
                                      <TableCell align="center">
                                        <p className="mb-0 txt_lt_gray text-center">
                                          Status
                                        </p>
                                      </TableCell>
                                      <TableCell align="center">
                                        <p className="mb-0 txt_lt_gray text-center">
                                          Action
                                        </p>
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {chartdata?.LatestOrders?.length > 0 &&
                                      chartdata?.LatestOrders?.map(
                                        (latestorders, ind) => (
                                          <TableRow
                                            key={latestorders.name}
                                            sx={{
                                              "&:last-child td, &:last-child th":
                                                { border: 0 },
                                            }}
                                          >
                                            <TableCell scope="row">
                                              <p className="dark_gry mb-0 text-center">
                                                01st April 22 - 7th April 22
                                              </p>
                                            </TableCell>
                                            <TableCell align="center">
                                              <p className="dark_gry mb-0">
                                                20
                                              </p>
                                            </TableCell>
                                            <TableCell align="center">
                                              <p className="blue_txt mb-0">
                                                $230.67
                                              </p>
                                            </TableCell>
                                            <TableCell align="center">
                                              {/* <p className="pending_sts mb-0 px-2 py-1">
                                                Pending
                                              </p> */}
                                              {/* <p className="paid_sts mb-0 px-2 py-1">
                                                Paid
                                              </p> */}
                                              <p className="cancel_sts mb-0 px-2 py-1">
                                                Cancel
                                              </p>
                                            </TableCell>
                                            <TableCell align="center">
                                              <p className="blue_txt  mb-0">
                                                $230.67
                                              </p>
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
