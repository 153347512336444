import React, { useEffect, useState } from "react";
import Sidebar from "../layouts/sidebar";
import Headers from "../layouts/header";

import "react-datetime/css/react-datetime.css";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

function Notification() {
  const { toggle } = useSelector((state) => state.admin_panel.User);

  const todayNotification = [
    {
      id: 1,
      hours: "2",
      type: "Activity",
      message:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
      id: 2,
      hours: "8",
      type: "System Alert",
      message:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
  ];

  const yesterdayNotification = [
    {
      id: 1,
      time: "11:30",
      hourType: "PM",
      type: "Activity",
      message:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
      id: 2,
      time: "06:10",
      hourType: "PM",
      type: "Activity",
      message:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
      id: 3,
      time: "05:30",
      hourType: "PM",
      type: "Activity",
      message:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
      id: 4,
      time: "01:30",
      hourType: "PM",
      type: "Activity",
      message:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
  ];

  return (
    <>
      <div>
        <div className="innerpages">
          <div className="orders_pages">
            <Headers />
            <div className="container custom_container px-lg-0">
              <div className="row mx-auto">
                <div className="innerpages_wid px-0">
                  <Sidebar />
                  <div
                    className={
                      toggle
                        ? "content-sec-inner right_widthset content_without_sidebar"
                        : "content-sec-inner  content_with_sidebar"
                    }
                  >
                    <section id="content-wrapper">
                      <div class="row">
                        <div className="top_title_sec">
                          <div>
                            <p className="page_title">Notification</p>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4 mx-auto">
                        <div className="col-xl-10 px-0">
                          <div className="sec_border p-lg-4">
                            <div className="row mx-auto ">
                              <div className="today_notification px-0 ">
                                <h6 className="notification_day">Today</h6>

                                {todayNotification.map((value) => (
                                  <div
                                    className="row align-items-start mt-2 act_not_top py-3"
                                    id={value.id}
                                  >
                                    <div className="col-5 col-sm-3 col-xxl-2  tod_time">
                                      <p className="hrs_txt">
                                        {value.hours} Hours ago
                                      </p>
                                    </div>
                                    <div className="col-7 col-sm-9 col-xxl-10 ">
                                      <p className="fw_600">{value.type}</p>
                                      <p className="notification_msg">
                                        {value.message}
                                      </p>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              <div className="yesterday_notification px-0 pt-4">
                                <h6 className="notification_day">Yesterday</h6>
                                {yesterdayNotification.map((value) => (
                                  <div
                                    className="row align-items-start mt-2 act_not_top py-3"
                                    id={value.id}
                                  >
                                    <div className="col-5 col-sm-3 col-xxl-2  tod_time">
                                      <p className="hrs_txt">
                                        {value.time} {value.hourType}
                                      </p>
                                    </div>
                                    <div className="col-7 col-sm-9 col-xxl-10 ">
                                      <p className="fw_600">{value.type}</p>
                                      <p className="notification_msg">
                                        {value.message}
                                      </p>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Notification;
