import React from "react";
import Sidebar from "../layouts/sidebar";
import Headers from "../layouts/header";
import { Col, Container, Row } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
// import Pagination from '@mui/material/Pagination';
// import PaginationItem from '@mui/material/PaginationItem';
// import {
//   DataGrid,
//   gridPageCountSelector,
//   gridPageSelector,
//   useGridApiContext,
//   useGridSelector,
// } from '@mui/x-data-grid';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import OrderStatus from "../layouts/OrderStatus";
import { useLocation } from "react-router";
import useAxiosFile from "../actions/useaxios";
import { useEffect } from "react";
import { useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { DateTimeForm, DecryptData, isEmpty } from "../actions/common";

import delpick from "../assests/images/delpick.png";

// function CustomPagination() {
//     const apiRef = useGridApiContext();
//     const page = useGridSelector(apiRef, gridPageSelector);
//     const pageCount = useGridSelector(apiRef, gridPageCountSelector);

//     return (

//       <Pagination
//         color="primary"
//         shape="rounded"
//         page={page + 1}
//         count={pageCount}
//         renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
//         onChange={(event, value) => apiRef.current.setPage(value - 1)}
//       />
//     );
//   }

function OrdersView() {
  const { payload, token } = useSelector((state) => state.admin_panel.User);
  const location = useLocation();
  const { toggle } = useSelector((state) => state.admin_panel.User);
  const axiosfile = useAxiosFile();

  const [Id, Setid] = useState("");
  const [Detail, SetDetail] = useState({});
  const [Drivers, SetDrivers] = useState([]);
  const [SelectedDriver, SetSelectedDriver] = useState({});

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#F5F6F7"
        : isSelected
        ? "#F5F6F7"
        : isHovered
        ? "red"
        : "#F5F6F7",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#a71d24"
          : isSelected
          ? "#a71d24"
          : isFocused
          ? "#a71d24"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 20px",
      backgroundColor: "#fff ",
      border: "1px solid lightgray",
      borderRadius: 10,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#6C6A81",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  function createData(no, orderid, name, type, date) {
    return { no, orderid, name, type, date };
  }

  const latestorders = [
    createData(1, "Coco Strawberry Pancake", "$9.49", "1", "$9.49"),
    createData(2, "Coco Strawberry Pancake", "$2.50", "2", "$5.00"),
    createData(3, "Coco Strawberry Pancake", "$2.50", "2", "$5.00"),
  ];
  // const columns = [
  //     { field: 'id', headerName: 'No', width: 90 },
  //     {
  //       field: 'product',
  //       headerName: 'Product',
  //       width: 140,
  //       editable: true,
  //     },
  //     {
  //       field: 'rate',
  //       headerName: 'Rate',
  //       width: 140,
  //       editable: true,
  //     },
  //     {
  //       field: 'quantity',
  //       headerName: 'Quantity',

  //       width: 140,
  //       editable: true,
  //     },
  //     {
  //       field: 'totals',
  //       headerName: 'Totals',
  //       width: 140,
  //       editable: true,
  //     },

  //   ];
  //   const rows = [
  //     { id: '1', product: 'Snow', customerName: 'Jon', type: 'Delivery',payment:'Online',time:'1/17/2022  8:20 PM',amount:'$5.00',action: <i class="fa-solid fa-ellipsis"/> },
  //     { id: '1', product: 'Snow', customerName: 'Jon', type: 'Delivery',payment:'Online',time:'1/17/2022  8:20 PM',amount:'$5.00',action:<i class="fa-solid fa-ellipsis"></i> },
  //     { id: '1', product: 'Snow', customerName: 'Jon', type: 'Delivery',payment:'Online',time:'1/17/2022  8:20 PM',amount:'$5.00',action:<i class="fa-solid fa-ellipsis"></i> },
  //     { id: '1', product: 'Snow', customerName: 'Jon', type: 'Delivery',payment:'Online',time:'1/17/2022  8:20 PM',amount:'$5.00',action:<i class="fa-solid fa-ellipsis"></i> },
  //     { id: '1', product: 'Snow', customerName: 'Jon', type: 'Delivery',payment:'Online',time:'1/17/2022  8:20 PM',amount:'$5.00',action:<i class="fa-solid fa-ellipsis"></i> },
  //     { id: '1', product: 'Snow', customerName: 'Jon', type: 'Delivery',payment:'Online',time:'1/17/2022  8:20 PM',amount:'$5.00',action:<i class="fa-solid fa-ellipsis"></i> },
  //     { id: '1', product: 'Snow', customerName: 'Jon', type: 'Delivery',payment:'Online',time:'1/17/2022  8:20 PM',amount:'$5.00',action:<i class="fa-solid fa-ellipsis"></i> },
  //     { id: '1', product: 'Snow', customerName: 'Jon', type: 'Delivery',payment:'Online',time:'1/17/2022  8:20 PM',amount:'$5.00',action:<i class="fa-solid fa-ellipsis"></i> },
  //     { id: '1', product: 'Snow', customerName: 'Jon', type: 'Delivery',payment:'Online',time:'1/17/2022  8:20 PM',amount:'$5.00',action:<i class="fa-solid fa-ellipsis"></i> },
  //     { id: '1', product: 'Snow', customerName: 'Jon', type: 'Delivery',payment:'Online',time:'1/17/2022  8:20 PM',amount:'$5.00',action:<i class="fa-solid fa-ellipsis"></i> },
  //   ];

  // const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  //     border: 0,
  //     color:
  //       theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
  //     fontFamily: [
  //       '-apple-system',
  //       'BlinkMacSystemFont',
  //       '"Segoe UI"',
  //       'Roboto',
  //       '"Helvetica Neue"',
  //       'Arial',
  //       'sans-serif',
  //       '"Apple Color Emoji"',
  //       '"Segoe UI Emoji"',
  //       '"Segoe UI Symbol"',
  //     ].join(','),
  //     WebkitFontSmoothing: 'auto',
  //     letterSpacing: 'normal',
  //     '& .MuiDataGrid-columnsContainer': {
  //       backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
  //     },
  //     '& .MuiDataGrid-iconSeparator': {
  //       display: 'none',
  //     },
  //     // '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
  //     //   borderRight: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
  //     //     }`,
  //     // },
  //     // '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
  //     //   borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
  //     //     }`,
  //     // },
  //     '& .MuiDataGrid-cell': {
  //       color:
  //         theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
  //         cursor:'default'
  //     },
  //     '& .MuiPaginationItem-root': {
  //       borderRadius: 0,
  //     },
  //     '& .MuiDataGrid-footerContainer': {
  //       display: "flex",
  //       justifyContent: "center"
  //     },

  //     ...customCheckbox(theme),
  //   }));

  //   function customCheckbox(theme) {
  //     return {
  //       '& .MuiCheckbox-root svg': {
  //         width: 16,
  //         height: 16,
  //         backgroundColor: 'transparent',
  //         border: `1px solid ${theme.palette.mode === 'light' ? '#d9d9d9' : 'rgb(67, 67, 67)'
  //           }`,
  //         borderRadius: 2,
  //       },
  //       '& .MuiCheckbox-root svg path': {
  //         display: 'none',
  //       },
  //       '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
  //         backgroundColor: '#e50914',
  //         borderColor: '#e50914',
  //       },
  //       '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
  //         position: 'absolute',
  //         display: 'table',
  //         border: '2px solid #fff',
  //         borderTop: 0,
  //         borderLeft: 0,
  //         transform: 'rotate(45deg) translate(-50%,-50%)',
  //         opacity: 1,
  //         transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
  //         content: '""',
  //         top: '50%',
  //         left: '39%',
  //         width: 5.71428571,
  //         height: 9.14285714,
  //       },
  //       '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
  //         width: 8,
  //         height: 8,
  //         backgroundColor: '#62626214',
  //         transform: 'none',
  //         top: '39%',
  //         border: 0,
  //       },

  //     };
  //   }

  useEffect(() => {
    console.log("kjsfsjfs", location.search.split("?id="));
    if (location?.search) {
      let paramdata = location.search.split("?id=")[1];
      console.log("llsflklfk", DecryptData(paramdata));
      Setid(
        isEmpty(DecryptData(paramdata)) ? paramdata : DecryptData(paramdata)
      );
    }
  }, [location.search]);

  useEffect(() => {
    console.log("ksjksjkf", Id);
    if (Id) {
      GetOrderDetail();
    }
  }, [Id, payload]);

  const GetOrderDetail = async () => {
    let Resp = await axiosfile.OrderDetail({ id: Id });
    console.log("kdjklsklj", Resp);
    SetDetail(Resp?.data?.Order);
    if (Resp?.data?.Order?.Driver) {
      Resp.data.Order.Driver.label = Resp?.data?.Order?.Driver?.FirstName;
      Resp.data.Order.Driver.value = Resp?.data?.Order?.Driver?.FirstName;
      SetSelectedDriver(Resp?.data?.Order?.Driver);
    }
  };

  const OrderAction = async (action) => {
    console.log("kfsjgdflgdsf", action);
    let senddata = {
      OrderId: Detail._id,
      Status: action,
    };
    let Resp = await axiosfile.OrderStatusUpdate(senddata);
    console.log("kjjfdjfkjskj", Resp, senddata);
    if (Resp?.success == "success") {
      toast.success("Status Updated Successfully");
    } else {
      toast.error(Resp?.msg);
    }
    GetOrderDetail();
    console.log("ljglkdfgldfgfds", Resp);
  };

  useEffect(() => {
    GetDrivers();
  }, []);

  const GetDrivers = async () => {
    let Resp = await axiosfile.GetDriver({ status: true });
    if (Resp?.data?.length > 0) {
      SetDrivers(Resp.data);
    }
  };

  const ChooseDriver = async () => {
    if (SelectedDriver?._id) {
      let Resp = await axiosfile.AssignDriver({
        Order: Id,
        Driver: SelectedDriver?._id,
      });
      if (!isEmpty(Resp?.data)) {
        toast.success("Driver Assigned");
      } else {
        toast.error("Error Occured");
      }
    } else {
      toast.error("Choose Driver");
    }
  };

  return (
    <div>
      <div className="innerpages">
        <div className="orders_pages">
          <Headers />
          <div className="container custom_container px-lg-0">
            <div className="row mx-auto">
              <div className="innerpages_wid px-0">
                <Sidebar />
                <div
                  className={
                    toggle
                      ? "content-sec-inner right_widthset content_without_sidebar"
                      : "content-sec-inner  content_with_sidebar"
                  }
                >
                  <section id="content-wrapper">
                    <Row className="mx-auto">
                      <Col xxl={9} xl={9} lg={8} md={12} sm={12} xs={12}>
                        <div className="rv_rowborder">
                          <div className="logo_invoice d_bloc">
                            {/* <img
                              className="mainlogo_al"
                              src={require("../assests/images/jamske.png")}
                            /> */}
                            <img
                              className="mainlogo_al wd_ht_unst"
                              src={delpick}
                            />
                            <p className="invoice_txt mt-3 mt-sm-0">
                              invoice <b>#{Detail?.OrderNumber}</b>
                            </p>
                          </div>
                          <div className="ov_adrsdate_dtls d_bloc">
                            {
                              isEmpty(Detail?.ShopId?.Address?.FullAddress)
                              ?
                              <p className="ov_address">
                                {Detail?.ShopId?.Address?.FullAddress}
                              </p>
                              :
                              <p className="ov_address">
                              {Detail?.ShopId?.Address?.StreetNumber
                                ? Detail?.ShopId?.Address?.StreetNumber + ","
                                : ""}
                              {Detail?.ShopId?.Address?.StreetName
                                ? Detail?.ShopId?.Address?.StreetName + ","
                                : ""}
                              {Detail?.ShopId?.Address?.City
                                ? Detail?.ShopId?.Address?.City + ","
                                : ""}
                              {Detail?.ShopId?.Address?.Country
                                ? Detail?.ShopId?.Address?.Country + "-"
                                : ""}
                              {Detail?.ShopId?.Address?.Zipcode
                                ? Detail?.ShopId?.Address?.Zipcode
                                : ""}
                              <br />
                              {Detail?.ShopId?.Mobile}
                              <br />
                              {Detail?.ShopId?.EmailId}
                            </p>
                            }
                            
                            <div className="ov_adrsdate_right mt-4 mt-sm-0">
                              <div>
                                <p className="ov_address">Date Issued : </p>
                                <p className="ov_address mt-2">Time : </p>
                              </div>
                              <div>
                                <p className="value_datetime">
                                  {DateTimeForm(Detail?.createdAt, true)}
                                </p>
                                <p className="value_datetime mt-2">
                                  {DateTimeForm(
                                    Detail?.createdAt,
                                    undefined,
                                    true,
                                    true
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="ov_invoicepayment_dtls d_bloc_lg">
                          {Detail?.OrderType?.toLocaleLowerCase() == "delivery" &&
                            <div className="ov_invoicepayment">
                              <p>Invoice to</p>
                              <p className="ov_invoicename mt-2">
                                {Detail?.DeliveryAddress?.FullName}
                              </p>
                              <p className="mt-2">
                                {
                                isEmpty(Detail?.Address?.FullAddress) ?
                                  <>{Detail?.Address?.FullAddress}</>

                                
                                :(isEmpty(Detail?.Address) ? (
                                  <>
                                    {Detail?.DeliveryAddress?.Doornumber
                                      ? Detail?.DeliveryAddress?.Doornumber +
                                        ","
                                      : ""}
                                    {Detail?.DeliveryAddress?.Street
                                      ? Detail?.DeliveryAddress?.Street + ","
                                      : ""}
                                    {Detail?.DeliveryAddress?.Street2
                                      ? Detail?.DeliveryAddress?.Street2 + ","
                                      : ""}
                                    <br />
                                    {Detail?.DeliveryAddress?.City
                                      ? Detail?.DeliveryAddress?.City + ","
                                      : ""}
                                    {Detail?.DeliveryAddress?.Area
                                      ? Detail?.DeliveryAddress?.Area + ","
                                      : ""}
                                    -
                                    {Detail?.DeliveryAddress?.Zipcode
                                      ? Detail?.DeliveryAddress?.Zipcode + ","
                                      : ""}
                                    <br />
                                    {Detail?.DeliveryAddress?.Mobile}
                                    <br />
                                    {Detail?.DeliveryAddress?.EmailId}
                                  </>
                                ) : (
                                  <>
                                    {Detail?.Address?.Doornumber
                                      ? Detail?.Address?.Doornumber + ","
                                      : ""}
                                    {Detail?.Address?.Street
                                      ? Detail?.Address?.Street + ","
                                      : ""}
                                    {Detail?.Address?.Street2
                                      ? Detail?.Address?.Street2 + ","
                                      : ""}
                                    <br />
                                    {Detail?.Address?.City
                                      ? Detail?.Address?.City + ","
                                      : ""}
                                    {Detail?.Address?.Area
                                      ? Detail?.Address?.Area + ","
                                      : ""}
                                    -
                                    {Detail?.Address?.Zipcode
                                      ? Detail?.Address?.Zipcode + ","
                                      : ""}
                                    <br />
                                    {Detail?.Address?.Mobile}
                                    <br />
                                    {Detail?.Address?.EmailId}
                                  </>
                                ))
                                }
                              </p>
                            </div>}
                            <div>
                              <p className="ov_paymentdtl_title mar_tp_lg">
                                Payment Details
                              </p>
                              <div className="ov_paymentdtls payment_width">
                                <div>
                                  <p className="ov_address wht_spc_nowrap">
                                    Total Amount:
                                  </p>
                                  <p className="ov_address mt-2 wht_spc_nowrap">
                                    Status:
                                  </p>
                                  {Detail?.Time?.Paid && (
                                    <p className="ov_address mt-2 wht_spc_nowrap">
                                      Transaction ID:
                                    </p>
                                  )}
                                </div>
                                <div>
                                  <p className="value_datetime">
                                    {Detail?.ShopId?.CurrencySymbol}
                                    {Detail?.Price?.PayAmount}
                                  </p>
                                  <p className="value_datetime greentxt mt-2">
                                    {isEmpty(Detail?.Time?.Paid)
                                      ? "Pending"
                                      : "Paid"}
                                  </p>
                                  {Detail?.Time?.Paid && (
                                    <p className="value_datetime mt-2 ms-1">
                                      #{Detail?.TransactionHistory?.object?.id}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* <div className='order_tables_cmn'>
                  <StyledDataGrid
                    
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    rows={rows}
                    columns={columns}
                    components={{
                      Pagination: CustomPagination,
                    }}
                    className='campaign_muitbl'
                   

                  />


                </div> */}

                          <TableContainer component={Paper}>
                            <Table
                              sx={{ minWidth: 650 }}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow className="ov_tablehead">
                                  <TableCell>No</TableCell>
                                  <TableCell align="left">Product</TableCell>
                                  <TableCell align="left">Rate</TableCell>
                                  <TableCell align="left">Quantity</TableCell>
                                  <TableCell align="left">Totals</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {Detail?.CartId?.length > 0 &&
                                  Detail?.CartId?.map((latestorders, ind) => (
                                    <TableRow
                                      className="ov_tabledtls"
                                      key={latestorders._id}
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell component="th" scope="row">
                                        {ind + 1}
                                      </TableCell>
                                      <TableCell align="left">
                                        {latestorders?.ProductId?.ProductName}
                                      </TableCell>
                                      <TableCell align="left">
                                        {Detail?.ShopId?.CurrencySymbol}{" "}
                                        {latestorders?.ProductId?.Price}
                                      </TableCell>
                                      <TableCell align="left">
                                        {latestorders.Quantity}
                                      </TableCell>
                                      <TableCell align="left">
                                        {Detail?.ShopId?.CurrencySymbol}{" "}
                                        {Number(latestorders.Quantity) *
                                          Number(
                                            latestorders?.ProductId?.Price
                                          )}
                                      </TableCell>
                                      {/* <TableCell className='price_clr' align="center">{latestorders.price} <i class="fa fa-angle-right" aria-hidden="true"></i></TableCell> */}
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>

                          <div className="ov_totalamnt_full">
                            <div className="ov_bottomsug">
                              {Detail?.DeliveryAddress?.Instructions && (
                                <>
                                  <p className="ov_totalamnt_left mt-0 left_tetx_ins">
                                    Instructions for delivery person:
                                  </p>
                                  <p className="ov_address left_tetx_ins tetx_earp_add">
                                    {Detail?.DeliveryAddress?.Instructions}
                                  </p>
                                </>
                              )}
                              {Detail?.DeliveryAddress?.Addresslabel && (
                                <>
                                  <p className="ov_totalamnt_left left_tetx_ins">
                                    Address Label:
                                  </p>
                                  <p className="ov_address left_tetx_ins tetx_earp_add">
                                    {Detail?.DeliveryAddress?.Addresslabel}
                                  </p>
                                </>
                              )}
                            </div>
                            <div className="ov_totalamnt_whole">
                              <div className="ov_totalamnt_top">
                                <div>
                                  <p className="ov_address">Subtotal</p>
                                  <p className="ov_address mt-2">Taxes</p>
                                  <p className="ov_address mt-2">Commission</p>
                                  {/* <p className='ov_address mt-2'>Discount</p> */}
                                  <p className="ov_address mt-2">Seller Tip</p>
                                  {Detail?.Price?.DriverTipsInValue && (
                                    <p className="ov_address mt-2">
                                      Driver Tip
                                    </p>
                                  )}
                                </div>
                                <div>
                                  <p className="value_datetime">
                                    {Detail?.ShopId?.CurrencySymbol}{" "}
                                    {Number(Detail?.Price?.CartValue).toFixed(
                                      2
                                    )}
                                  </p>
                                  <p className="value_datetime mt-2">
                                    {Detail?.ShopId?.CurrencySymbol}{" "}
                                    {Number(Detail?.Price?.Taxes).toFixed(2)}
                                  </p>
                                  <p className="value_datetime mt-2">
                                    {Detail?.ShopId?.CurrencySymbol}{" "}
                                    {Detail?.Price?.DeliveryTimeCharges}
                                  </p>
                                  {/* <p className='value_datetime mt-2'>$0.49</p> */}

                                  <p className="value_datetime mt-2">
                                    {Detail?.ShopId?.CurrencySymbol}{" "}
                                    {Number(
                                      Detail?.Price?.SellerTipsInValue
                                    ).toFixed(2)}
                                  </p>
                                  {Detail?.Price?.DriverTipsInValue && (
                                    <p className="value_datetime mt-2">
                                      {Detail?.ShopId?.CurrencySymbol}{" "}
                                      {Number(
                                        Detail?.Price?.DriverTipsInValue
                                      ).toFixed(2)}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="ov_totalamnt_bottom">
                                <p className="ov_totalamnt_left">
                                  Total Amount
                                </p>
                                <p className="ov_totalamnt_right">
                                  {Detail?.ShopId?.CurrencySymbol}{" "}
                                  {Detail?.Price?.PayAmount}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        {Detail?.OrderType == "Delivery" && (
                          <div className="rv_rowborder mt-3">
                            {SelectedDriver?.FirstName && (
                              <p className="ov_paymentdtl_title mar_tp_lg">
                                Choose Driver For Pickup
                              </p>
                            )}
                            <div className="mt-3">
                              <Select
                                value={SelectedDriver}
                                options={Drivers}
                                styles={stylesgraybg}
                                onChange={(e) => SetSelectedDriver(e)}
                                isDisabled={
                                  Detail?.Time?.Pickup ||
                                  Detail?.Status == "completed"
                                    ? true
                                    : false
                                }
                              />
                            </div>

                            <div className="ov_paymentdtls payment_width">
                              <div>
                                <p className="ov_address wht_spc_nowrap">
                                  Driver Name
                                </p>
                                <p className="ov_address mt-2 wht_spc_nowrap">
                                  Email-Id
                                </p>
                                <p className="ov_address mt-2 wht_spc_nowrap">
                                  Mobile
                                </p>
                              </div>
                              <div>
                                <p className="value_datetime no_wrap_tx_rig">
                                  : {SelectedDriver?.FirstName}{" "}
                                  {SelectedDriver?.LastName}
                                </p>
                                <p className="value_datetime greentxt mt-2 no_wrap_tx_rig">
                                  : {SelectedDriver?.EmailId}
                                </p>
                                <p className="value_datetime mt-2 no_wrap_tx_rig">
                                  : {SelectedDriver?.Mobile}
                                </p>
                              </div>
                            </div>

                            {isEmpty(Detail?.Time?.Pickup) && (
                              <button
                                className="blue_btn add_deliveryprsn mt-4 dp_btn_hover"
                                onClick={() => ChooseDriver()}
                                type="button"
                              >
                                Select Driver
                              </button>
                            )}
                          </div>
                        )}
                      </Col>
                      <Col
                        xxl={3}
                        xl={3}
                        lg={4}
                        md={12}
                        sm={12}
                        xs={12}
                        className="ov_reducepad pe-0"
                      >
                        <OrderStatus
                          Orderdata={Detail}
                          OrderAction={OrderAction}
                        />
                      </Col>
                    </Row>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrdersView;
