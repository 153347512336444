import React, { useState } from 'react'
import { Link,useNavigate } from 'react-router-dom';
import { isEmpty } from '../../actions/common';
// import {  useNavigate } from 'react-router-dom';
import useAxiosFile from '../../actions/useaxios';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
export default function forgot() {


  const axiosFile = useAxiosFile()
  const navigate =useNavigate();
  const initdata = {
    EmailId: '',
  }
  const [formdata, SetFormdata] = useState(initdata);
  const [error, SetError] = useState({});

  
  const OnChange = (e) => {
    const { id, value } = e.target;
    SetFormdata({ ...formdata, ...{ [id]: value } });
  }

  const formvalidation = async (data) => {
    

    var validationErr = {};
    let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([A-Za-zA-Z\-0-9]+\.)+[A-Za-zA-Z]{2,}))$/;

    if (data.EmailId == "") { validationErr.EmailId = "Email cannot be empty" }
    else if (data.EmailId != "") {
      if (!emailRegex.test(data.EmailId)) { validationErr.EmailId = "Enter valid email" }
    }
    return validationErr;

  }

  const Submit = async () => {
    var resp = await formvalidation(formdata);

    if (resp) {
      SetError(resp)
      
    }

 if(isEmpty(resp)) {
    
    let senddata = formdata;
    senddata.Type = 'forgot'
    let Resp = await axiosFile.LoginReg(formdata)
    
    if(Resp.success=='success'){
       navigate("/otpverfication",{state:{EmailId:formdata?.EmailId}})  
       toast.success(Resp?.data)
    }
    else{
      toast.error(Resp?.msg)
      SetError(Resp?.error)
    }
  }  


  }

  return (
    <div>
      <div className='login_pages'>
        <div className='container custom_container'>
          <div className='row aligns_itm login_row'>
            <div className='col-lg-6 leftsec'>
              <img src={require("../../assests/images/loginleft.png")} className='img-fluid login_img' />
            </div>
            <div className='col-lg-6 rightsec'>
              <div className='row logosec mb-3 mb-md-5'>
                <img src={require("../../assests/images/logo.png")} className='img-fluid logo_img' />
              </div>

              <div className='row form_sec'>
                <p className='log_title'>Forgot your password?</p>
                <form>
                  <div className="form-group mb-4">
                    <label for="exampleInputEmail1">Email Address</label>
                    <input type="email" className="form-control" id="EmailId" aria-describedby="emailHelp" placeholder="Ex. Johndeo@gmail.com" onChange={(e) => OnChange(e)} />
                    {error?.EmailId && <span className='error-msg'>{error?.EmailId}</span>}
                  </div>

                  <button type="button" className="btn btn-primary" onClick={() => Submit()}>Send OTP</button>
                </form>
              </div>

              <div className='rem_log'>
                <p>Remember it?  <Link className="link_tag" to="/"> Log In</Link></p>
              </div>

            </div>

          </div>
        </div>

      </div>













    </div>
  )
}
