import React, { useState } from "react";
import Sidebar from "../layouts/sidebar";
import Headers from "../layouts/header";
import Select from "react-select";
import Datetime from "react-datetime";
import moment from "moment";
import { Nav, Tab, Row, Col } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-datetime/css/react-datetime.css";
import { easing } from "jquery";
import {
  LettersOnly,
  MinuteHourFormat,
  isEmpty,
  LoadToast,
  updateToast,
} from "../actions/common";
import config from "../actions/config";
import useAxiosFile from "../actions/useaxios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

const initform = {
  FirstName: "",
  LastName: "",
  EmailId: "",
  Mobile: "",
  Password: "",
  Gender: "",
  // ConfirmPassword: '',
  Address: {
    FullName: "",
    EmailId: "",
    Mobile: "",
    Street: "",
    Street2: "",
    Area: "",
    City: "",
    State: "",
    Country: "",
    ZipCode: "",
  },
  BankDetails: {
    BankName: "",
    AccountType: "",
    AccountHolderName: "",
    AccountNumber: "",
    RoutingNumber: "",
  },
  VehicleType: "",
  DeliveryZones: [],
  VehicleCount: "",
};
const initname = {
  FirstName: "First Name",
  LastName: "Last Name",
  EmailId: "Email-Id",
  Mobile: "Mobile Number",
  Password: "Password",
  ConfirmPassword: "Confirm-Password",
  VehicleType: "Vehicle Type",
  VehicleCount: "Vehicle Count",
  DeliveryZones: "Delivery Zone",
  Street: "Street Number",
  Street2: "Street Name",
  Street: "Street Number",
  ZipCode: "Zip Code",
  BankName: "Bank Name",
  AccountType: "Account Type",
  AccountHolderName: "Account Holder Name",
  AccountNumber: "Bank Account Number",
  RoutingNumber: "Routing Number",
};

const initfile = {
  License: {},
  Profile: "",
};

const gender = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Others", label: "Others" },
];

const city = [
  { value: "Chicago", label: "Chicago" },
  { value: "Austin", label: "Austin" },
  { value: "Seattle", label: "Seattle" },
  { value: "San Francisco", label: "San Francisco" },
  { value: "Portland", label: "Portland" },
  { value: "New York", label: "New York" },
  { value: "Denver", label: "Denver" },
];

const state = [
  { value: "California", label: "California" },
  { value: "Texas", label: "Texas" },
  { value: "Florida", label: "Florida" },
  { value: "Ohio", label: "Ohio" },
  { value: "Hawaii", label: "Hawaii" },
  { value: "Alaska", label: "Alaska" },
  { value: "New Jersey", label: "New Jersey" },
];

const country = [{ value: "USA", label: "USA" }];

const deliveryzones = [
  { value: "Chicago", label: "Chicago" },
  { value: "Austin", label: "Austin" },
  { value: "Seattle", label: "Seattle" },
  { value: "San Francisco", label: "San Francisco" },
  { value: "Portland", label: "Portland" },
  { value: "New York", label: "New York" },
  { value: "Denver", label: "Denver" },
];

const driveroptions = [
  { value: "idle", label: "Idle" },
  { value: "intransit", label: "In Transit" },
  { value: "offline", label: "Offline" },
  { value: "active", label: "Active" },
];
const vehicletype = [
  { value: "Two-Wheeler", label: "Two-Wheeler" },
  { value: "Four-Wheeler", label: "Four-Wheeler" },
];

const maxdate = new Date(new Date().setFullYear(new Date().getFullYear() - 18));

function AddDriver() {
  const navigate = useNavigate();
  const { toggle } = useSelector((state) => state.admin_panel.User);
  const axiosFile = useAxiosFile();

  const [passwordText, setPasswordText] = useState("password");

  const [formdata, SetFormdata] = useState(initform);
  const [displayname, SetDisplayName] = useState(initname);
  const [Error, SetError] = useState({});
  const [Files, SetFiles] = useState(initfile);
  const [DisplayDate, SetDisplayDate] = useState({
    display: "",
    max: `${MinuteHourFormat(maxdate.getFullYear())}-${MinuteHourFormat(
      maxdate.getMonth() + 1
    )}-${MinuteHourFormat(maxdate.getDate())}`,
  });

  const [selectedOption, setSelectedOption] = useState(null);

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#F5F6F7"
        : isSelected
        ? "#F5F6F7"
        : isHovered
        ? "red"
        : "#F5F6F7",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#a71d24"
          : isSelected
          ? "#a71d24"
          : isFocused
          ? "#a71d24"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 20px",
      backgroundColor: "#fff ",
      border: "1px solid lightgray",
      borderRadius: 10,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#6C6A81",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const stylegreendrop = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#F5F6F7"
        : isSelected
        ? "#F5F6F7"
        : isHovered
        ? "red"
        : "#F5F6F7",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#a71d24"
          : isSelected
          ? "#a71d24"
          : isFocused
          ? "#a71d24"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 20px",
      backgroundColor: "#fff ",
      border: "1px solid #00B167",
      borderRadius: 10,
      fontSize: "13px",
      color: "#00B167",
      fontWeight: 700,
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#00B167",
      fontWeight: 700,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#00B167",
      fontWeight: 700,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {},
    onSizePerPageChange: function (page, sizePerPage) {},
  });

  const OnChange = (e, type) => {
    console.log("dskjfjkshfjsh", e, e?.target);
    if (e?.target) {
      const { name, value, id, type } = e.target;

      if (!isEmpty(Error[id])) {
        SetError({ ...Error, ...{ [id]: "" } });
      }

      if (name == "Address" || name == "BankDetails") {
        console.log("ldmlfdsmgs", type, type);
        if (!isEmpty(Error[id])) {
          SetError({ ...Error, ...{ [id]: "" } });
        }
        if (type == "text" && id != "Street2") {
          if (config.Alpabetsalone.test(value)) {
            SetFormdata({
              ...formdata,
              ...{ [name]: { ...formdata[name], ...{ [id]: value } } },
            });
          }
        } else {
          SetFormdata({
            ...formdata,
            ...{ [name]: { ...formdata[name], ...{ [id]: value } } },
          });
        }
      } else {
        if (["FirstName", "LastName", "EmailId", "Mobile"].includes(id)) {
          SetFormdata({
            ...formdata,
            ...{
              [id]: value,
              ["Address"]: {
                ...formdata["Address"],
                ...{
                  [id == "LastName" || id == "FirstName" ? "FullName" : id]:
                    id == "LastName"
                      ? formdata.FirstName + " " + value
                      : id == "FirstName"
                      ? value + " " + formdata.LastName
                      : value,
                },
              },
            },
          });
        } else {
          SetFormdata({ ...formdata, ...{ [id]: value } });
          if (id == "DateofBirth") {
            let disp = new Date(value);
            SetDisplayDate({
              ...DisplayDate,
              ...{
                display: `${MinuteHourFormat(
                  disp.getFullYear()
                )}-${MinuteHourFormat(disp.getMonth() + 1)}-${MinuteHourFormat(
                  disp.getDate()
                )}`,
              },
            });
          }
        }
      }
    } else if (type == "Mobile") {
      SetFormdata({
        ...formdata,
        ...{
          Mobile: e,
          Address: {
            ...formdata["Address"],
            ...{
              Mobile: e,
            },
          },
        },
      });
    }
  };
  console.log("dskjfjkshfsf", formdata);

  const SelectChange = (e, name, id) => {
    const { value } = e;
    SetError({});
    if (name == "Address") {
      SetFormdata({
        ...formdata,
        ...{ [name]: { ...formdata[name], ...{ [id]: value } } },
      });
    } else if (name == "DeliveryZones") {
      if (formdata?.DeliveryZones?.length > 0) {
        if (!formdata?.DeliveryZones?.some((val) => val == value)) {
          SetFormdata({
            ...formdata,
            ...{ [name]: [...formdata[name], value] },
          });
        }
        return true;
      } else {
        SetFormdata({ ...formdata, ...{ [name]: [...formdata[name], value] } });
      }
    } else {
      SetFormdata({ ...formdata, ...{ [name]: value } });
    }
  };

  const OnFileChange = (e) => {
    const { id, files } = e.target;
    if (files[0].type.includes("image")) {
      if (!isEmpty(Error[id])) {
        SetError({ ...Error, ...{ [id]: "" } });
      }
      SetFiles({ ...Files, ...{ [id]: files[0] } });
    } else {
      toast.error("Upload Images Only", 1000);
    }
  };

  const Validation = () => {
    const {
      FirstName,
      LastName,
      EmailId,
      Mobile,
      Password,
      Gender,
      Address,
      BankDetails,
      VehicleType,
      DeliveryZones,
      VehicleCount,
    } = formdata;
    let errors = {};
    Object.keys(formdata).map((val) => {
      console.log("jksjdsadsa", val);
      if (val !== "License" && val !== "Profile") {
        console.log("jksjdsadsainnn", val);
        if (isEmpty(formdata[val])) {
          errors[val] =
            (displayname[val] ? displayname[val] : val) + " is Required";
        } else {
          if (val == "Address" || val == "BankDetails") {
            Object.keys(formdata[val]).map((inval) => {
              if (isEmpty(formdata[val][inval])) {
                errors[inval] =
                  (initname[inval] ? initname[inval] : inval) + " is Required";
              }
            });
          }
          if (val == "Mobile") {
            if (!isValidPhoneNumber(formdata[val])) {
              errors[val] = "Enter Valid Mobile Number";
            }
          }
          if (val == "EmailId") {
            if (!config.EMAIL.test(formdata[val])) {
              errors[val] = "Enter Valid EmailId";
            }
          }
          if (val == "Password") {
            if (!config.PASSWORD.test(formdata[val])) {
              errors[val] =
                "Password Must Contain one small letter, one capital letter, one number and one special character";
            }
          }
        }
      }
    });
    console.log("lkdjfkjdsfd", errors);
    Object.keys(Files).map((val) => {
      if (val !== "Profile") {
        if (isEmpty(Files[val]?.type)) {
          errors[val] = val + " is Required";
        }
      }
    });

    return errors;
    // if(isEmpty(FirstName)){
    //   errors.FirstName = "First Name Required"
    // }
    // if(isEmpty(LastName)){
    //   errors.FirstName = "Last Name Required"
    // }
    // if(isEmpty(EmailId)){
    //   errors.FirstName = "Email-Id Required"
    // }
    // else if(!config.EMAIL.test(EmailId)){
    //   errors.EmailId = "Enter Valid EmailId"
    // }
    // if(isEmpty(Mobile)){
    //   errors.Mobile = "Mobile Number Required"
    // }
    // else if(!config.NumOnly.test(Mobile)){
    //   errors.Mobile = "Mobile Number Must be a Number"
    // }
    // if(isEmpty(Password)){
    //   errors.Mobile = "Password Required"
    // }
    // else if(!config.PASSWORD.test(Password)){
    //   errors.Password = "Password Must Contain one small letter, one capital letter, one number and one special character"
    // }
    // if()
  };

  const Submit = async () => {
    let validate = Validation();
    // let id = LoadToast()

    console.log("ldjfjkjdlfsd", validate);

    if (isEmpty(validate)) {
      let senddata = formdata;
      senddata.DeliveryZones = senddata.DeliveryZones;
      Object.keys(Files).map((val) => {
        senddata[val] = Files[val];
      });
      senddata.from = "Admin";
      senddata.type = "save";

      let Resp = await axiosFile.DriverAction(senddata);
      // updateToast(id,Resp.msg, Resp.success)

      if (Resp?.success == "success") {
        navigate("/managementdriver");
        toast.success("Driver Added Successfully", 1000);
      } else {
        toast.error(Resp.msg, 1000);
      }
    } else {
      SetError(validate);
      toast.error("Fix all mistakes and try-again", 1000);
    }
  };

  const Removedeliveryzone = (index) => {
    let array = formdata.DeliveryZones;
    array.splice(index, 1);
    SetFormdata({ ...formdata, ...{ DeliveryZones: array } });
  };

  return (
    <>
      <div>
        <div className="innerpages">
          <div className="orders_pages">
            <Headers />
            <div className="container custom_container px-lg-0">
              <div className="row mx-auto">
                <div className="innerpages_wid px-0">
                  <Sidebar />
                  <div
                    className={
                      toggle
                        ? "content-sec-inner right_widthset content_without_sidebar"
                        : "content-sec-inner  content_with_sidebar"
                    }
                  >
                    <section id="content-wrapper">
                      <div class="row">
                        <div className="top_title_sec">
                          <div>
                            <p className="page_title">
                              {formdata?.FirstName && formdata?.LastName
                                ? formdata.FirstName + " " + formdata?.LastName
                                : "e.g. Rino Piccolo"}
                            </p>
                          </div>
                          {/* <div className='rightsec'>
        <p>Accepting Orders
        <div className='togswit'>
  <input type="checkbox" id="toggle-btn"/>
  <label for="toggle-btn"></label>
</div>
        </p>
      </div> */}
                        </div>
                      </div>
                      {/* profile details */}
                      <div className="mt-4">
                        <div className="mdvsec_border sec_border">
                          <div className="row mdv_driverdtls_whole">
                            <div className="col-xxl-4 col-xl-4 col-lg-3 col-md-3 col-sm-12 col-12">
                              <div className="mdv_driverdtls">
                                <img
                                  className="tab_dummyimg me-3"
                                  src={
                                    isEmpty(Files?.Profile?.type)
                                      ? require("../assests/images/dummyimg.png")
                                      : URL.createObjectURL(Files?.Profile)
                                  }
                                />
                                <div>
                                  <p className="mdv_drivername">
                                    {formdata?.FirstName && formdata?.LastName
                                      ? formdata.FirstName +
                                        " " +
                                        formdata?.LastName
                                      : " e.g. Rino Piccolo"}
                                  </p>
                                  <div className="mdv_locationtext">
                                    <img
                                      className="mdv_location"
                                      src={require("../assests/images/location.png")}
                                    />
                                    <p>
                                      {formdata?.Address?.State
                                        ? formdata?.Address?.State
                                        : "e.g. Pasadena"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* 

//need to work
        <div className='col-xxl-8 col-xl-8 col-lg-9 col-md-9 col-sm-12 col-12'>
        
        <div className='rightside'>
        <div className='rightside_select'>
        <Select
                            options={driveroptions}
                            defaultValue={selectedOption}
                            styles={stylegreendrop}
                            onChange={setSelectedOption}
                            className="border_blue_select"
                            classNamePrefix="react-select"
                            placeholder='Status'
                       
                          />
                          </div>


        </div> 

       
        </div> */}
                          </div>
                        </div>

                        {/* profile details */}

                        <>
                          <div className="mdv_profiledtls">
                            <div className="mdv_border_div">
                              <p className="mdv_profilettl">
                                Personal Information
                              </p>
                            </div>
                            <Row className="mdv_inputrow">
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">First Name</p>
                                <input
                                  className="mdv_cmninput"
                                  type="text"
                                  id="FirstName"
                                  placeholder="John"
                                  onChange={(e) => OnChange(e)}
                                />
                                {Error?.FirstName && (
                                  <span className="error-msg">
                                    {Error?.FirstName}
                                  </span>
                                )}
                              </Col>
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">Last Name</p>
                                <input
                                  className="mdv_cmninput"
                                  type="text"
                                  id="LastName"
                                  placeholder="Doe"
                                  onChange={(e) => OnChange(e)}
                                />
                                {Error?.LastName && (
                                  <span className="error-msg">
                                    {Error?.LastName}
                                  </span>
                                )}
                              </Col>
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">Email Address</p>
                                <input
                                  className="mdv_cmninput"
                                  type="email"
                                  id="EmailId"
                                  placeholder="john@jamkse.com"
                                  onChange={(e) => OnChange(e)}
                                />
                                {Error?.EmailId && (
                                  <span className="error-msg">
                                    {Error?.EmailId}
                                  </span>
                                )}
                              </Col>

                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">Phone Number</p>
                                <PhoneInput
                                  className="sigin_inputs new_rephoneinp"
                                  placeholder="Enter phone number"
                                  id="Mobile"
                                  onChange={(e) => OnChange(e, "Mobile")}
                                  defaultCountry="US"
                                  country="US"
                                  onCountryChange={(e) =>
                                    OnChange(e, "Mobile", "country")
                                  }
                                  countries={["US","IN"]}
                                />
                                {Error?.Mobile && (
                                  <span className="error-msg">
                                    {Error?.Mobile}
                                  </span>
                                )}
                              </Col>
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">Date Of Birth</p>
                                <input
                                  className="mdv_cmninput"
                                  type="date"
                                  placeholder="Doe"
                                  id="DateofBirth"
                                  max={DisplayDate?.max}
                                  value={DisplayDate?.display}
                                  onChange={(e) => OnChange(e)}
                                />
                                {Error?.DateofBirth && (
                                  <span className="error-msg">
                                    {Error?.DateofBirth}
                                  </span>
                                )}
                              </Col>
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">Gender</p>
                                {/* <input className='mdv_cmninput' type="email" placeholder="john@jamkse.com"/> */}

                                <Select
                                  options={gender}
                                  onChange={(e) => {
                                    SetError({});
                                    SetFormdata({
                                      ...formdata,
                                      ["Gender"]: e.value,
                                    });
                                  }}
                                  defaultValue={selectedOption}
                                  styles={stylesgraybg}
                                  className="border_blue_select"
                                  classNamePrefix="react-select"
                                  placeholder="Gender"
                                />
                                {Error?.Gender && (
                                  <span className="error-msg">
                                    {Error?.Gender}
                                  </span>
                                )}
                              </Col>
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">Choose Profile</p>
                                <input
                                  className="mdv_cmninput"
                                  type="file"
                                  placeholder="Doe"
                                  id="Profile"
                                  onChange={(e) => OnFileChange(e)}
                                />
                              </Col>
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">Enter Password</p>
                                <div className="reg_passwordinps adddriver_inps">
                                  <input
                                    className=""
                                    type={
                                      passwordText == "password"
                                        ? "password"
                                        : "text"
                                    }
                                    placeholder="Doe"
                                    id="Password"
                                    onChange={(e) => OnChange(e)}
                                  />
                                  <i
                                    onClick={() =>
                                      setPasswordText(
                                        passwordText == "text"
                                          ? "password"
                                          : "text"
                                      )
                                    }
                                    className={
                                      passwordText == "password"
                                        ? "fa-solid fa-eye"
                                        : "fa-solid fa-eye-slash"
                                    }
                                  ></i>
                                </div>
                                {Error?.Password && (
                                  <span className="error-msg">
                                    {Error?.Password}
                                  </span>
                                )}
                              </Col>
                            </Row>
                          </div>

                          <div className="mdv_profiledtls">
                            <div className="mdv_border_div">
                              <p className="mdv_profilettl">Address</p>
                            </div>
                            <Row className="mdv_inputrow">
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">Street Number</p>
                                <input
                                  className="mdv_cmninput"
                                  id="Street"
                                  type="number"
                                  name="Address"
                                  placeholder="485"
                                  onChange={(e) => OnChange(e)}
                                />
                                {Error?.Street && (
                                  <span className="error-msg">
                                    {Error?.Street}
                                  </span>
                                )}
                              </Col>
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">Street Name</p>
                                <input
                                  className="mdv_cmninput"
                                  type="text"
                                  id="Street2"
                                  name="Address"
                                  placeholder="6th avenue"
                                  onChange={(e) => OnChange(e)}
                                  value={formdata?.Address?.Street2}
                                />
                                {Error?.Street2 && (
                                  <span className="error-msg">
                                    {Error?.Street2}
                                  </span>
                                )}
                              </Col>
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">Area</p>
                                <input
                                  className="mdv_cmninput"
                                  type="text"
                                  name="Address"
                                  id="Area"
                                  placeholder="delpick"
                                  onChange={(e) => OnChange(e)}
                                  value={formdata?.Address?.Area}
                                />
                                {Error?.Area && (
                                  <span className="error-msg">
                                    {Error?.Area}
                                  </span>
                                )}
                              </Col>

                              <Col
                                xxl={3}
                                xl={3}
                                lg={3}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">City</p>

                                {/* <input className='mdv_cmninput' type="number" placeholder="+1 515 516 0624"/> */}
                                <Select
                                  options={city}
                                  defaultValue={selectedOption}
                                  styles={stylesgraybg}
                                  onChange={(e) => {
                                    SetError({});
                                    SelectChange(e, "Address", "City");
                                  }}
                                  className="border_blue_select"
                                  classNamePrefix="react-select"
                                  placeholder="Select City"
                                  id="City"
                                  name="Address"
                                />
                                {Error?.City && (
                                  <span className="error-msg">
                                    {Error?.City}
                                  </span>
                                )}
                              </Col>
                              <Col
                                xxl={3}
                                xl={3}
                                lg={3}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">State</p>
                                {/* <input className='mdv_cmninput' type="text" placeholder="Doe"/> */}

                                <Select
                                  options={state}
                                  defaultValue={selectedOption}
                                  styles={stylesgraybg}
                                  onChange={(e) => {
                                    SetError({});
                                    SelectChange(e, "Address", "State");
                                  }}
                                  className="border_blue_select"
                                  classNamePrefix="react-select"
                                  placeholder="Select State"
                                  id="State"
                                  name="Address"
                                />
                                {Error?.State && (
                                  <span className="error-msg">
                                    {Error?.State}
                                  </span>
                                )}
                              </Col>
                              <Col
                                xxl={3}
                                xl={3}
                                lg={3}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">Country</p>
                                {/* <input className='mdv_cmninput' type="text" placeholder="john@jamkse.com"/> */}

                                <Select
                                  options={country}
                                  defaultValue={selectedOption}
                                  styles={stylesgraybg}
                                  onChange={(e) => {
                                    SetError({});
                                    SelectChange(e, "Address", "Country");
                                  }}
                                  className="border_blue_select"
                                  classNamePrefix="react-select"
                                  placeholder="Select Country"
                                  id="Country"
                                  name="Address"
                                />
                                {Error?.Country && (
                                  <span className="error-msg">
                                    {Error?.Country}
                                  </span>
                                )}
                              </Col>
                              <Col
                                xxl={3}
                                xl={3}
                                lg={3}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">Zip Code</p>
                                <input
                                  className="mdv_cmninput"
                                  type="number"
                                  id="ZipCode"
                                  name="Address"
                                  placeholder="10101"
                                  onChange={(e) => OnChange(e)}
                                />
                                {Error?.ZipCode && (
                                  <span className="error-msg">
                                    {Error?.ZipCode}
                                  </span>
                                )}
                              </Col>
                            </Row>
                          </div>

                          <div className="mdv_profiledtls">
                            <div className="mdv_border_div">
                              <p className="mdv_profilettl">Bank Details</p>
                            </div>
                            <Row className="mdv_inputrow">
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">Bank Name</p>
                                <input
                                  className="mdv_cmninput"
                                  type="text"
                                  id="BankName"
                                  name="BankDetails"
                                  placeholder="Bank Of America"
                                  onChange={(e) => OnChange(e)}
                                  value={formdata?.BankDetails?.BankName}
                                />
                                {Error?.BankName && (
                                  <span className="error-msg">
                                    {Error?.BankName}
                                  </span>
                                )}
                              </Col>
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">Account Type</p>
                                <input
                                  className="mdv_cmninput"
                                  type="text"
                                  id="AccountType"
                                  placeholder="Business"
                                  name="BankDetails"
                                  onChange={(e) => OnChange(e)}
                                  value={formdata?.BankDetails?.AccountType}
                                />
                                {Error?.AccountType && (
                                  <span className="error-msg">
                                    {Error?.AccountType}
                                  </span>
                                )}
                              </Col>
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">
                                  Account Holder Name
                                </p>
                                <input
                                  className="mdv_cmninput"
                                  type="text"
                                  placeholder="Sweet green"
                                  id="AccountHolderName"
                                  name="BankDetails"
                                  onChange={(e) => OnChange(e)}
                                  value={
                                    formdata?.BankDetails?.AccountHolderName
                                  }
                                />
                                {Error?.AccountHolderName && (
                                  <span className="error-msg">
                                    {Error?.AccountHolderName}
                                  </span>
                                )}
                              </Col>
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">
                                  Bank Account Number
                                </p>
                                <input
                                  className="mdv_cmninput"
                                  type="number"
                                  placeholder="5894 58967 6984"
                                  id="AccountNumber"
                                  name="BankDetails"
                                  onChange={(e) => OnChange(e)}
                                  value={formdata?.BankDetails?.AccountNumber}
                                />
                                {Error?.AccountNumber && (
                                  <span className="error-msg">
                                    {Error?.AccountNumber}
                                  </span>
                                )}
                              </Col>
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <p className="mdv_inplabel">Routing Number</p>
                                <input
                                  className="mdv_cmninput"
                                  type="number"
                                  placeholder="1562 87952"
                                  id="RoutingNumber"
                                  name="BankDetails"
                                  onChange={(e) => OnChange(e)}
                                  value={formdata?.BankDetails?.RoutingNumber}
                                />
                                {Error?.RoutingNumber && (
                                  <span className="error-msg">
                                    {Error?.RoutingNumber}
                                  </span>
                                )}
                              </Col>
                            </Row>
                          </div>

                          <div className="mdv_profiledtls">
                            <div className="mdv_border_div">
                              <p className="mdv_profilettl">
                                Vehicle & Delivery Details
                              </p>
                            </div>
                            <Row className="mdv_inputrow">
                              <Col
                                xxl={6}
                                xl={6}
                                lg={6}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <Row>
                                  <Col
                                    xxl={6}
                                    xl={6}
                                    lg={6}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="mb-3"
                                  >
                                    <p className="mdv_inplabel">Vehicle Type</p>
                                    {/* <input className='mdv_cmninput' type="text" placeholder="Doe"/> */}
                                    <Select
                                      options={vehicletype}
                                      defaultValue={selectedOption}
                                      styles={stylesgraybg}
                                      onChange={(e) =>
                                        SelectChange(
                                          e,
                                          "VehicleType",
                                          undefined
                                        )
                                      }
                                      className="border_blue_select"
                                      classNamePrefix="react-select"
                                      placeholder="VehicleType"
                                    />
                                    {Error?.VehicleType && (
                                      <span className="error-msg">
                                        {Error?.VehicleType}
                                      </span>
                                    )}
                                  </Col>
                                  <Col
                                    xxl={6}
                                    xl={6}
                                    lg={6}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="mb-3"
                                  >
                                    <p className="mdv_inplabel">Vehicles</p>
                                    <input
                                      className="mdv_cmninput"
                                      type="text"
                                      placeholder="2"
                                      id="VehicleCount"
                                      onChange={(e) => OnChange(e)}
                                    />
                                    {Error?.VehicleCount && (
                                      <span className="error-msg">
                                        {Error?.VehicleCount}
                                      </span>
                                    )}
                                  </Col>
                                  <Col
                                    xxl={12}
                                    xl={12}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    className="mb-3"
                                  >
                                    <p className="mdv_inplabel">
                                      Delivery Zones
                                    </p>
                                    {/* { error?.Mobile && <span>{error?.Mobile}</span>} */}
                                    {/* <input className='mdv_cmninput' type="text" placeholder="2"/> */}

                                    <Select
                                      options={deliveryzones}
                                      defaultValue={selectedOption}
                                      styles={stylesgraybg}
                                      onChange={(e) =>
                                        SelectChange(
                                          e,
                                          "DeliveryZones",
                                          undefined
                                        )
                                      }
                                      className="border_blue_select"
                                      classNamePrefix="react-select"
                                      placeholder="Pasadena"
                                    />
                                    {Error?.DeliveryZones && (
                                      <span className="error-msg">
                                        {Error?.DeliveryZones}
                                      </span>
                                    )}
                                  </Col>
                                  {console.log("kdsjhfskfks", formdata)}
                                  {formdata.DeliveryZones?.length > 0 && (
                                    <Col
                                      xxl={12}
                                      xl={12}
                                      lg={12}
                                      md={12}
                                      sm={12}
                                      xs={12}
                                      className="mb-3"
                                    >
                                      <div className="mdv_locationlists">
                                        {formdata.DeliveryZones?.map(
                                          (val, ind) => (
                                            <div className="mdv_singleloclist">
                                              <p>{val}</p>
                                              <img
                                                src={require("../assests/images/close_small.png")}
                                                onClick={() =>
                                                  Removedeliveryzone(ind)
                                                }
                                              />
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </Col>
                                  )}
                                </Row>
                              </Col>

                              <Col
                                xxl={6}
                                xl={6}
                                lg={6}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <Row>
                                  <Col
                                    xxl={6}
                                    xl={6}
                                    lg={6}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="mb-3"
                                  >
                                    <p className="mdv_inplabel">
                                      National Identity Document
                                    </p>
                                    {isEmpty(Files?.License?.type) ? (
                                      <img
                                        className="mdv_license img-fluid"
                                        src={require("../assests/images/license.png")}
                                      />
                                    ) : (
                                      <img
                                        className="mdv_license img-fluid"
                                        src={URL.createObjectURL(Files.License)}
                                      />
                                    )}
                                  </Col>
                                  <Col
                                    xxl={6}
                                    xl={6}
                                    lg={6}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="mb-3"
                                  >
                                    <div className="mdv_uploadfile">
                                      <img
                                        className="mdv_license img-fluid"
                                        src={require("../assests/images/cloud.png")}
                                      />
                                      <p className="mdv_uploadfile_txt">
                                        Upload File
                                      </p>
                                      <input
                                        type="file"
                                        className="mdv_fileupload"
                                        id="License"
                                        onChange={(e) => OnFileChange(e)}
                                      />
                                    </div>
                                    {Error?.License && (
                                      <span className="error-msg">
                                        {Error?.License}
                                      </span>
                                    )}
                                  </Col>
                                </Row>
                              </Col>

                              {/* <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12} className="mb-3">
              <Row>
            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12} className="mb-3">
                <p className='mdv_inplabel'>Vehicle Insurance</p>
                <img className='mdv_license img-fluid' src={require('../assests/images/license.png')}/>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12} className="mb-3">
              <div className='mdv_uploadfile'>
              <img className='mdv_license img-fluid' src={require('../assests/images/cloud.png')}/>
              <p className='mdv_uploadfile_txt'>Upload File</p>
              <input type='file' className='mdv_fileupload' id='Insurance' onChange={(e)=>OnFileChange(e)}/>
              </div>
            </Col>
            </Row>
            </Col> */}

                              <div className="mdv_update_dualbtn">
                                <button
                                  className="blue_btn mdv_update"
                                  type="button"
                                  onClick={() => Submit()}
                                >
                                  Submit
                                </button>
                                <button className="seconday_btn">Cancel</button>
                              </div>
                            </Row>
                          </div>
                        </>

                        {/* end of profile details */}
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddDriver;
