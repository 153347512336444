import React, { useEffect, useState } from "react";
// import Sidebar from "../../../layouts/sidebar";
import Sidebar from "../../layouts/sidebar";
import Headers from "../../layouts/header";
import Select from "react-select";
import Datetime from "react-datetime";
import moment from "moment";
import "react-datetime/css/react-datetime.css";
import { Nav, Tab, Row, Col } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
// import useAxiosFile from "../../../actions/useaxios";
import useAxiosFile from "../../actions/useaxios";
// import config from "../../../actions/config";
import config from "../../actions/config";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
// import Exportcsv from "./Exportcsv";
import Exportcsv from "../loginpages/Managementpages/Exportcsv";
// import { DecryptData, EncryptData, isEmpty } from "../../../actions/common";
import { DecryptData, EncryptData, isEmpty } from "../../actions/common";

// form import react-bootstrap
import Form from "react-bootstrap/Form";

// table import
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

function UserAccessView() {
  const [selectedOption, setSelectedOption] = useState(null);
  const [chartdata, setChartData] = useState({});
  const { toggle } = useSelector((state) => state.admin_panel.User);

  const navigate = useNavigate();

  const axiosFile = useAxiosFile();

  const [seller, setseller] = useState();
  const [exportdata, setExportdata] = useState([]);

  useEffect(() => {
    getvendor();
  }, []);

  const getvendor = async () => {
    var resp = await axiosFile.getvendorList();
    console.log("dasdasdsadsa", resp);
    if (resp?.data?.length > 0) {
      // (resp?.data||[])?.map((val, idx) => {

      //   val.hash = <img className='tab_dummyimg' src={val.BrandImage ? `${config.IMG_URL}/shop/${val?.BrandImage}` : require('../../../assests/images/dummyimg.png')} />
      //   val.action = <div class="dropdown table_viewdropdown">
      //     <button class="btn table_viewdrop dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
      //       <i class="fa-solid fa-ellipsis" />
      //     </button>
      //     <ul class="dropdown-menu">
      //       <li><Link class="dropdown-item table_viewborbot" to={`/managementvendorsview/?Id=${String(EncryptData((val?.SellerId)))}`}>View</Link></li>
      //       {/* <li><a class="dropdown-item table_viewborbot">Edit</a></li>
      //       <li><a class="dropdown-item">Delete</a></li> */}
      //     </ul>
      //   </div>

      // })
      var ddd =
        resp.length > 0
          ? (resp || [])?.map(({ _id, _v, ...rest }) => {
              return rest;
            })
          : [];
      setExportdata(ddd);
    }

    setseller(resp?.data);
  };

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {},
    onSizePerPageChange: function (page, sizePerPage) {},
  });

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#F5F6F7"
        : isSelected
        ? "#F5F6F7"
        : isHovered
        ? "red"
        : "#F5F6F7",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#a71d24"
          : isSelected
          ? "#a71d24"
          : isFocused
          ? "#a71d24"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 20px",
      backgroundColor: "#fff ",
      border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 10,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "45px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#6C6A81",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  return (
    <>
      <div>
        <div className="innerpages user_accview">
          <div className="orders_pages">
            <Headers />
            <div className="container custom_container px-lg-0">
              <div className="row mx-auto">
                <div className="innerpages_wid px-0">
                  <Sidebar />
                  <div
                    className={
                      toggle
                        ? "content-sec-inner right_widthset content_without_sidebar"
                        : "content-sec-inner  content_with_sidebar"
                    }
                  >
                    <section id="content-wrapper">
                      {/* <div class="row">
                        <div className="top_title_sec">
                          <div>
                            <p className="page_title mb-0">Location</p>
                          </div>
                          <div className="rightside just_end ">
                            <button
                              type="button"
                              className="blue_btn add_deliveryprsn"
                              onClick={() => navigate("/addseller")}
                            >
                              Add Locations
                            </button>
                          </div>
                        </div>
                      </div> */}
                      <div className="row mt-4 mx-auto">
                        <div className="sec_border">
                          <div className="row ">
                            <div className="pfl_sec d-flex align-items-start justify-content-between">
                              <div className="pfl_dtl d-flex align-items-start">
                                <div className="pfl_img ">
                                  <img
                                    src={require("../../assests/images/profile.png")}
                                    alt="profile"
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="pfl_cont ms-4">
                                  <p className="pfl_nam">Jane Doe</p>
                                  <p className="pfl_rol">Sub Admin</p>
                                  <p className="pfl_mail ">
                                    janedoe@gmail.com{" "}
                                    <span className="pfl_phno ms-md-4 ms-sm-3 mt-3 mt-sm-0">
                                      +1 (515) 516 0624
                                    </span>
                                  </p>
                                  <div className="pfl-status d-block d-sm-none mt-3 text-start">
                                    <p class="table_grnavtivebtn cont_width">
                                      Active
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="pfl-status d-none d-sm-block">
                                <p class="table_grnavtivebtn cont_width mx-auto">
                                  Active
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-5 ">
                            <div className="col-12 col-md-6 col-lg-4">
                              <Form>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label className="frm_lbl">
                                    First Name
                                  </Form.Label>
                                  <Form.Control
                                    className="frm_ctrl"
                                    type="text"
                                    placeholder="Jone"
                                  />
                                </Form.Group>
                              </Form>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                              <Form>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label className="frm_lbl">
                                    Last Name
                                  </Form.Label>
                                  <Form.Control
                                    className="frm_ctrl"
                                    type="text"
                                    placeholder="Doe"
                                  />
                                </Form.Group>
                              </Form>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                              <Form>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label className="frm_lbl">
                                    Email
                                  </Form.Label>
                                  <Form.Control
                                    className="frm_ctrl"
                                    type="email"
                                    placeholder="jonedoe@gmail.com"
                                  />
                                </Form.Group>
                              </Form>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                              <Form>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label className="frm_lbl">
                                    User Role
                                  </Form.Label>
                                  <Select
                                    options={options}
                                    defaultValue={selectedOption}
                                    styles={stylesgraybg}
                                    onChange={setSelectedOption}
                                    className="border_blue_select pfl_sel"
                                    classNamePrefix="react-select"
                                    placeholder="Role"
                                    // menuIsOpen={true}
                                  />
                                </Form.Group>
                              </Form>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                              <Form>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label className="frm_lbl">
                                    Status
                                  </Form.Label>
                                  <Select
                                    options={options}
                                    defaultValue={selectedOption}
                                    styles={stylesgraybg}
                                    onChange={setSelectedOption}
                                    className="border_blue_select pfl_sel"
                                    classNamePrefix="react-select"
                                    placeholder="Active"
                                    // menuIsOpen={true}
                                  />
                                </Form.Group>
                              </Form>
                            </div>
                          </div>

                          <div className="mt-5">
                            <div className="dash_table">
                              <div className="top_section wht_background">
                                <div>
                                  <p className="title">Permissions</p>
                                </div>
                                {/* <div>
                                  <Link className="table_link" to="/orders">
                                    See All
                                  </Link>
                                </div> */}
                              </div>
                              <div>
                                <TableContainer component={Paper}>
                                  <Table
                                    sx={{ minWidth: 650 }}
                                    aria-label="simple table"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>Module</TableCell>
                                        <TableCell align="center">
                                          Create
                                        </TableCell>
                                        <TableCell align="center">
                                          Read
                                        </TableCell>
                                        <TableCell align="center">
                                          Update
                                        </TableCell>
                                        <TableCell align="center">
                                          Delete
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {/* {chartdata?.LatestOrders?.length > 0 &&
                                        chartdata?.LatestOrders?.map(
                                          (latestorders, ind) => ( */}
                                      <TableRow
                                        // key={latestorders.name}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell scope="row">
                                          {/* {ind + 1} */}Admin
                                        </TableCell>
                                        <TableCell align="center">
                                          {/* {latestorders.OrderNumber} */}
                                          <div className="modal_checkoptions ps_defadcheck blue_checkbox">
                                            <Form.Check type="checkbox">
                                              <Form.Check.Input
                                                type="checkbox"
                                                // checked={
                                                //   addressform?.defaultAddress
                                                // }
                                                // onChange={() =>
                                                //   SetAddressForm({
                                                //     ...addressform,
                                                //     ...{
                                                //       defaultAddress:
                                                //         !addressform.defaultAddress,
                                                //     },
                                                //   })
                                                // }
                                              />
                                              {/* <Form.Check.Label className="ps_defaultaddr">
                                                Default Address
                                              </Form.Check.Label> */}
                                            </Form.Check>
                                          </div>
                                        </TableCell>
                                        <TableCell align="center">
                                          {" "}
                                          <div className="modal_checkoptions ps_defadcheck blue_checkbox">
                                            <Form.Check type="checkbox">
                                              <Form.Check.Input type="checkbox" />
                                            </Form.Check>
                                          </div>
                                        </TableCell>
                                        <TableCell align="center">
                                          <div className="modal_checkoptions ps_defadcheck blue_checkbox">
                                            <Form.Check type="checkbox">
                                              <Form.Check.Input type="checkbox" />
                                            </Form.Check>
                                          </div>
                                        </TableCell>
                                        <TableCell align="center">
                                          {" "}
                                          <div className="modal_checkoptions ps_defadcheck blue_checkbox">
                                            <Form.Check type="checkbox">
                                              <Form.Check.Input type="checkbox" />
                                            </Form.Check>
                                          </div>
                                        </TableCell>
                                      </TableRow>
                                      {/* )
                                        )} */}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-4">
                            <div className="col ">
                              <div className="d-flex flex-column align-items-center flex-sm-row">
                                <button
                                  type="button"
                                  class="blue_btn  btn_min_wd"
                                >
                                  Save Changes
                                </button>
                                <button
                                  type="button"
                                  class="blue_btn  ms-sm-3  btn_min_wd rst_btn mt-3 mt-sm-0 "
                                >
                                  Reset
                                </button>
                                <button
                                  type="button"
                                  class="blue_btn  ms-sm-3 btn_min_wd blk_btn mt-3 mt-sm-0"
                                >
                                  Block User
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserAccessView;
