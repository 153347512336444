import { memo } from "react";
import { Route } from "react-router-dom";

import Login from "../pages/loginpages/signin";
import DashboardOverview from "../pages/dashboardOverview";
import Orders from "../pages/orders";
import OrdersView from "../pages/OrdersView";
import OrderDrivers from "../pages/OrderDrivers";
import ManagementDriver from "../pages/loginpages/Managementpages/ManagementDriver";
import ManagementDriverView from "../pages/loginpages/Managementpages/ManagementDriverView";
import Sales from "../pages/sales";
import Vendors from "../pages/vendors";
import Drivers from "../pages/Drivers";
import Profiledetail from "../pages/profiledetail";
import Protected from "./Protected";
import ManagementUserView from "../pages/loginpages/Managementpages/ManagementUserView";
import ManagementVendors from "../pages/loginpages/Managementpages/ManagementVendors";
import ManagementVendorsView from "../pages/loginpages/Managementpages/ManagementVendorsView";
import ManagementUsers from "../pages/loginpages/Managementpages/ManagementUsers";
import DashboardOverviewNew from "../pages/DashboardOverviewNew";
import AddDriver from "../pages/adddriver";
import Category from "../pages/loginpages/Managementpages/category";
import Categorylist from "../pages/loginpages/Managementpages/categorylist";
import SubCategorylist from "../pages/loginpages/Managementpages/Subcategorylist";
import Subcategory from "../pages/loginpages/Managementpages/SubCategory";

import CouponsList from "../pages/loginpages/Coupons/CouponsList.js";
import Coupons from "../pages/loginpages/Coupons/Coupons";
import Faq from "../pages/loginpages/Managementpages/Addfaq";
import FaqList from "../pages/loginpages/Managementpages/faqlist";
import Cms from "../pages/loginpages/Managementpages/Addcms";
import CmsList from "../pages/loginpages/Managementpages/cmslist";
import FinanceDrivers from "../pages/FinancePages/FinanceDrivers";
import FinanceReports from "../pages/FinancePages/FinanceReports";
import FinanceVendorsView from "../pages/FinancePages/FinanceVendorsView";
import FinanceSales from "../pages/FinancePages/FinanceSales";
import FaqEditPage from "../pages/loginpages/Managementpages/FaqEditPage";
import CmslistEdit from "../pages/loginpages/Managementpages/CmslistEdit";
import AdminDiagnosis from "../pages/loginpages/AdminDiagnosis";
// import FinanceVendor from "../pages/FinancePages/FinanceVendors";
import Add from "../pages/socialLinks/add";
import Lists from "../pages/socialLinks/lists";
import Detailadd from "../pages/Emailtemplate/detailadd";
import DataLists from "../pages/Emailtemplate/datalists";
//subscribe
import Subscribeadd from "../pages/Subscribe/subscribeadd";
import Subscribelists from "../pages/Subscribe/subscribelists";

import Cmsadd from "../pages/cms_modules/cmsadd";
import Listcms from "../pages/cms_modules/listcms";

import FaqVig from "../pages/loginpages/Managementpages/FAQ/Addfaq";
import FaqListVig from "../pages/loginpages/Managementpages/FAQ/faqlist";
import DeliveryTimeCharge from "../pages/DeliveryTimeCharge";
import MenuManager from "../pages/loginpages/Managementpages/MenuManager";

// Location

import Locations from "../pages/Location/Locations";
import DeliveryZone from "../pages/Location/DeliveryZone";
import UserAccessView from "../pages/settings/UserAccessView";
import Notification from "../pages/Notification.js";
import VendorFinance from "../pages/FinancePages/VendorFinance.js";
import DriverFinance from "../pages/FinancePages/DriverFinance.js";
import ReportFinance from "../pages/FinancePages/ReportFinance.js";
import SalesFinance from "../pages/FinancePages/SalesFinance.js";
import UserAccess from "../pages/settings/UserAccess.js";
import AddUser from "../pages/settings/AddUser.js";

const FinanceVendor = require("../pages/FinancePages/FinanceVendors").default;

export const LoginRoutes = (Auth) => {
  console.log("jkdhjkfsdf", Auth);
  // Auth = true
  return (
    <>
      <Route
        path="/dashboard"
        element={
          <Protected isSignedIn={Auth}>
            {" "}
            <DashboardOverview />
          </Protected>
        }
      />
      <Route
        path="/orders"
        element={
          <Protected isSignedIn={Auth}>
            {" "}
            <Orders />{" "}
          </Protected>
        }
      />
      <Route
        path="/ordersview"
        element={
          <Protected isSignedIn={Auth}>
            {" "}
            <OrdersView />{" "}
          </Protected>
        }
      />
      <Route
        path="/ordersdriver"
        element={
          <Protected isSignedIn={Auth}>
            {" "}
            <OrderDrivers />{" "}
          </Protected>
        }
      />
      <Route
        path="/managementdriver"
        element={
          <Protected isSignedIn={Auth}>
            {" "}
            <ManagementDriver />{" "}
          </Protected>
        }
      />
      <Route
        path="/managementdriverview"
        element={
          <Protected isSignedIn={Auth}>
            {" "}
            <ManagementDriverView />{" "}
          </Protected>
        }
      />
      <Route
        path="/managementusers"
        element={
          <Protected isSignedIn={Auth}>
            {" "}
            <ManagementUsers />{" "}
          </Protected>
        }
      />
      <Route
        path="/managementusersview"
        element={
          <Protected isSignedIn={Auth}>
            {" "}
            <ManagementUserView />{" "}
          </Protected>
        }
      />
      <Route
        path="/locations"
        element={
          <Protected isSignedIn={Auth}>
            {" "}
            <Locations />{" "}
          </Protected>
        }
      />
      <Route
        path="/deliveryzone"
        element={
          <Protected isSignedIn={Auth}>
            {" "}
            <DeliveryZone />{" "}
          </Protected>
        }
      />
      <Route
        path="/financevendor"
        element={
          <Protected isSignedIn={Auth}>
            {" "}
            <VendorFinance />{" "}
          </Protected>
        }
      />
      <Route
        path="/financedrivers"
        element={
          <Protected isSignedIn={Auth}>
            {" "}
            {/* <FinanceDrivers />{" "} */}
            <DriverFinance />{" "}
          </Protected>
        }
      />
      <Route
        path="/financereports"
        element={
          <Protected isSignedIn={Auth}>
            {" "}
            {/* <FinanceReports />{" "} */}
            <ReportFinance />{" "}
          </Protected>
        }
      />
      <Route
        path="/financesales"
        element={
          <Protected isSignedIn={Auth}>
            {" "}
            {/* <FinanceSales />{" "} */}
            <SalesFinance />{" "}
          </Protected>
        }
      />
      <Route
        path="/faqeditpage"
        element={
          <Protected isSignedIn={Auth}>
            {" "}
            <FaqEditPage />{" "}
          </Protected>
        }
      />
      <Route
        path="/cmseditpage"
        element={
          <Protected isSignedIn={Auth}>
            {" "}
            <CmslistEdit />{" "}
          </Protected>
        }
      />
      <Route
        path="/diagnosis"
        element={
          <Protected isSignedIn={Auth}>
            {" "}
            <AdminDiagnosis />{" "}
          </Protected>
        }
      />
      <Route
        path="/deliverytimecharge"
        element={
          <Protected isSignedIn={Auth}>
            {" "}
            <DeliveryTimeCharge />{" "}
          </Protected>
        }
      />
      <Route
        path="/financevendorview"
        element={
          <Protected isSignedIn={Auth}>
            {" "}
            <FinanceVendorsView />{" "}
          </Protected>
        }
      />
      <Route
        path="/managementvendors"
        element={
          <Protected isSignedIn={Auth}>
            {" "}
            <ManagementVendors />{" "}
          </Protected>
        }
      />
      <Route
        path="/managementvendorsview"
        element={
          <Protected isSignedIn={Auth}>
            {" "}
            <ManagementVendorsView />{" "}
          </Protected>
        }
      />
      <Route
        path="/managementvendorsview/:Id"
        element={
          <Protected isSignedIn={Auth}>
            {" "}
            <ManagementVendorsView />{" "}
          </Protected>
        }
      />
      <Route
        path="/addseller"
        element={
          <Protected isSignedIn={Auth}>
            {" "}
            <ManagementVendorsView />{" "}
          </Protected>
        }
      />
      <Route
        path="/sales"
        element={
          <Protected isSignedIn={Auth}>
            {" "}
            <Sales />{" "}
          </Protected>
        }
      />
      <Route
        path="/vendor"
        element={
          <Protected isSignedIn={Auth}>
            {" "}
            <Vendors />{" "}
          </Protected>
        }
      />
      <Route
        path="/drivers"
        element={
          <Protected isSignedIn={Auth}>
            {" "}
            <Drivers />{" "}
          </Protected>
        }
      />
      <Route
        path="/profiledetail"
        element={
          <Protected isSignedIn={Auth}>
            {" "}
            <Profiledetail />{" "}
          </Protected>
        }
      />
      <Route
        path="/newbb"
        element={
          <Protected isSignedIn={Auth}>
            {" "}
            <DashboardOverviewNew />{" "}
          </Protected>
        }
      />
      <Route
        path="/adddriver"
        element={
          <Protected isSignedIn={Auth}>
            <AddDriver />
          </Protected>
        }
      />
      <Route
        path="/Category"
        element={
          <Protected isSignedIn={Auth}>
            <Category />
          </Protected>
        }
      />
      <Route
        path="/Category/:Id"
        element={
          <Protected isSignedIn={Auth}>
            <Category />
          </Protected>
        }
      />
      <Route
        path="/Categorylist"
        element={
          <Protected isSignedIn={Auth}>
            <Categorylist />
          </Protected>
        }
      />
      <Route
        path="/subcategory"
        element={
          <Protected isSignedIn={Auth}>
            <Subcategory />
          </Protected>
        }
      />
      <Route
        path="/subcategory/:Id"
        element={
          <Protected isSignedIn={Auth}>
            <Subcategory />
          </Protected>
        }
      />
      <Route
        path="/subcategorylist"
        element={
          <Protected isSignedIn={Auth}>
            <SubCategorylist />
          </Protected>
        }
      />
      <Route
        path="/couponslist"
        element={
          <Protected isSignedIn={Auth}>
            <CouponsList />
          </Protected>
        }
      />
      <Route
        path="/coupons"
        element={
          <Protected isSignedIn={Auth}>
            <Coupons />
          </Protected>
        }
      />
      <Route
        path="/Faq/"
        element={
          <Protected isSignedIn={Auth}>
            <Faq />
          </Protected>
        }
      />

      {/* Setting page routes */}
      <Route
        path="/useraccess"
        element={
          <Protected isSignedIn={Auth}>
            <UserAccess />
          </Protected>
        }
      />
      <Route
        path="/useraccessview"
        element={
          <Protected isSignedIn={Auth}>
            <UserAccessView />
          </Protected>
        }
      />
      <Route
        path="/adduser"
        element={
          <Protected isSignedIn={Auth}>
            <AddUser />
          </Protected>
        }
      />
      <Route
        path="/Cms/"
        element={
          <Protected isSignedIn={Auth}>
            <Cms />
          </Protected>
        }
      />
      <Route
        path="/Cms/:Id"
        element={
          <Protected isSignedIn={Auth}>
            <Cms />
          </Protected>
        }
      />
      <Route
        path="/FaqList"
        element={
          <Protected isSignedIn={Auth}>
            <FaqList />
          </Protected>
        }
      />
      <Route
        path="/CmsList"
        element={
          <Protected isSignedIn={Auth}>
            <CmsList />
          </Protected>
        }
      />
      <Route
        path="/Faq"
        element={
          <Protected isSignedIn={Auth}>
            <Faq />
          </Protected>
        }
      />
      <Route
        path="/Faqdetail"
        element={
          <Protected isSignedIn={Auth}>
            <Faq />
          </Protected>
        }
      />
      <Route
        path="/add"
        element={
          <Protected isSignedIn={Auth}>
            <Add />
          </Protected>
        }
      />
      <Route
        path="/lists"
        element={
          <Protected isSignedIn={Auth}>
            <Lists />
          </Protected>
        }
      />
      <Route
        path="/detailadd"
        element={
          <Protected isSignedIn={Auth}>
            <Detailadd />
          </Protected>
        }
      />
      <Route
        path="/datalists"
        element={
          <Protected isSignedIn={Auth}>
            <DataLists />
          </Protected>
        }
      />
      {/* subscribe */}
      <Route
        path="/subscribeadd"
        element={
          <Protected isSignedIn={Auth}>
            <Subscribeadd />
          </Protected>
        }
      />
      <Route
        path="/subscribelists"
        element={
          <Protected isSignedIn={Auth}>
            <Subscribelists />
          </Protected>
        }
      />
      <Route
        path="/cmsadd"
        element={
          <Protected isSignedIn={Auth}>
            <Cmsadd />
          </Protected>
        }
      />
      <Route
        path="/listcms"
        element={
          <Protected isSignedIn={Auth}>
            <Listcms />
          </Protected>
        }
      />
      <Route
        path="/menumanager"
        element={
          <Protected isSignedIn={Auth}>
            <MenuManager />
          </Protected>
        }
      />
      <Route
        path="/notification"
        element={
          <Protected isSignedIn={Auth}>
            <Notification />
          </Protected>
        }
      />
    </>
  );
};
