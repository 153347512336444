import React, { useEffect, useState } from "react";
// import Sidebar from "../../../layouts/sidebar";
import Sidebar from "../../layouts/sidebar";
import Headers from "../../layouts/header";
import Select from "react-select";
import Datetime from "react-datetime";
import moment from "moment";
import "react-datetime/css/react-datetime.css";
import { Nav, Tab, Row, Col } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
// import useAxiosFile from "../../../actions/useaxios";
import useAxiosFile from "../../actions/useaxios";
// import config from "../../../actions/config";
import config from "../../actions/config";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
// import Exportcsv from "./Exportcsv";
import Exportcsv from "../loginpages/Managementpages/Exportcsv";
// import { DecryptData, EncryptData, isEmpty } from "../../../actions/common";
import { DecryptData, EncryptData, isEmpty } from "../../actions/common";

function UserAccess() {
  const [selectedOption, setSelectedOption] = useState(null);
  const { toggle } = useSelector((state) => state.admin_panel.User);
  const [opacity, setOpacity] = useState("false");

  const navigate = useNavigate();

  const axiosFile = useAxiosFile();

  const [seller, setseller] = useState();
  const [exportdata, setExportdata] = useState([]);

  const [viewOpen, setViewOpen] = useState(false);

  useEffect(() => {
    getvendor();
  }, []);

  const getvendor = async () => {
    var resp = await axiosFile.getvendorList();
    console.log("dasdasdsadsa", resp);
    if (resp?.data?.length > 0) {
      // (resp?.data||[])?.map((val, idx) => {

      //   val.hash = <img className='tab_dummyimg' src={val.BrandImage ? `${config.IMG_URL}/shop/${val?.BrandImage}` : require('../../../assests/images/dummyimg.png')} />
      //   val.action = <div class="dropdown table_viewdropdown">
      //     <button class="btn table_viewdrop dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
      //       <i class="fa-solid fa-ellipsis" />
      //     </button>
      //     <ul class="dropdown-menu">
      //       <li><Link class="dropdown-item table_viewborbot" to={`/managementvendorsview/?Id=${String(EncryptData((val?.SellerId)))}`}>View</Link></li>
      //       {/* <li><a class="dropdown-item table_viewborbot">Edit</a></li>
      //       <li><a class="dropdown-item">Delete</a></li> */}
      //     </ul>
      //   </div>

      // })
      var ddd =
        resp.length > 0
          ? (resp || [])?.map(({ _id, _v, ...rest }) => {
              return rest;
            })
          : [];
      setExportdata(ddd);
    }

    setseller(resp?.data);
  };

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {},
    onSizePerPageChange: function (page, sizePerPage) {},
  });

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const driveroptions = [
    { value: "idle", label: "Idle" },
    { value: "intransit", label: "In Transit" },
    { value: "offline", label: "Offline" },
  ];

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#F5F6F7"
        : isSelected
        ? "#F5F6F7"
        : isHovered
        ? "red"
        : "#F5F6F7",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#a71d24"
          : isSelected
          ? "#a71d24"
          : isFocused
          ? "#a71d24"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 20px",
      backgroundColor: "#fff ",
      border: "1px solid rgba(34, 34, 34, 0.32)",
      borderRadius: 10,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#6C6A81",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const columns = [
    {
      dataField: "no",
      text: "No",
      sort: true,
      formatter: (cell, row, rowindex) => (
        <>
          <p style={{ fontSize: "13px", fontWeight: "700" }}>1</p>
        </>
      ),
    },
    {
      dataField: "username",
      text: "Username",
      sort: true,
      formatter: (cell, row, rowindex) => (
        <>
          <p className="dark_gry">Joe Doe</p>
        </>
      ),
    },
    {
      dataField: "emailAddress",
      text: "Email Address",
      // sort: true,
      formatter: (cell, row, rowindex) => (
        <>
          <p className="gry_txt">johndoe@gmail.com</p>
        </>
      ),
    },
    {
      dataField: "role",
      text: "Role",
      // sort: true,
      formatter: (cell, row, rowindex) => (
        <>
          <p className="blue_txt">Admin</p>
        </>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      // sort: true,
      formatter: (cell, row, rowindex) => (
        <>
          {/* <p className="cancel_sts mb-0 px-3 py-1 mx-auto">Block</p> */}
          <p className="paid_sts mb-0 px-3 py-1 mx-auto">Active</p>
          {/* <p className="pending_sts mb-0 px-3 py-1 mx-auto">Inactive</p> */}
        </>
      ),
    },

    {
      dataField: "action",
      text: "Action",
      // sort: true,
      formatter: (cell, row, rowindex) => (
        <>
          {/* <i class="fa-solid fa-eye curs_poin" aria-hidden="true"></i> */}
          {/* <div className="position-relative">
            <i class="fa-solid fa-ellipsis curs_poin"></i>
            <div className="position-absolute view_opn py-1 px-2">View</div>
          </div> */}
          <div class="dropdown table_viewdropdown">
            <button
              class="btn table_viewdrop dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="fa-solid fa-ellipsis" />
            </button>
            <ul class="dropdown-menu">
              <li>
                <Link
                  class="dropdown-item table_viewborbot"
                  // to={`/managementvendorsview/?Id=${String(
                  //   EncryptData(val?._id)
                  // )}`}
                  to="/useraccessview"
                >
                  View
                </Link>
              </li>
              {/* <li><a class="dropdown-item table_viewborbot">Edit</a></li>
             <li><a class="dropdown-item">Delete</a></li> */}
            </ul>
          </div>
        </>
      ),
    },
  ];

  const data = [
    {
      hash: (
        // <img
        //   className="tab_dummyimg"
        //   src={require("../../../assests/images/dummyimg.png")}
        // />
        <img
          className="tab_dummyimg"
          src={require("../../assests/images/dummyimg.png")}
        />
      ),
      vendorname: "John Doe",
      location: "354 Allen Ave, Pasadena, CA 91106, USA",
      category: "Resturant, Italian",
      status: <button className="table_grnavtivebtn">Available</button>,

      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementvendorsview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot drpdw_redtxt">
                Inactive
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Order Aceepting Off</a>
            </li>
            <li>
              <a class="dropdown-item">Settlement</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      hash: (
        <img
          className="tab_dummyimg"
          src={require("../../assests/images/dummyimg.png")}
        />
      ),
      vendorname: "John Doe",
      location: "354 Allen Ave, Pasadena, CA 91106, USA",
      category: "Resturant, Italian",
      status: <button className="table_redinactbtn">Not Available</button>,
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementusersview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot drpdw_greentxt">
                Active
              </a>
            </li>
            <li>
              <a class="dropdown-item">Settlement</a>
            </li>
          </ul>
        </div>
      ),
    },

    {
      hash: (
        <img
          className="tab_dummyimg"
          src={require("../../assests/images/dummyimg.png")}
        />
      ),
      vendorname: "John Doe",
      vendorId: "#1562",
      location: "354 Allen Ave, Pasadena, CA 91106, USA",
      category: "Resturant, Italian",
      status: (
        <button className="table_sandalactbtn">Waiting for approval</button>
      ),
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementusersview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot drpdw_greentxt">
                Active
              </a>
            </li>
            <li>
              <a class="dropdown-item drpdw_redtxt">Inactive</a>
            </li>
          </ul>
        </div>
      ),
    },

    {
      hash: (
        <img
          className="tab_dummyimg"
          src={require("../../assests/images/dummyimg.png")}
        />
      ),
      vendorname: "John Doe",
      vendorId: "#1562",
      location: "354 Allen Ave, Pasadena, CA 91106, USA",
      category: "Resturant, Italian",
      status: <button className="table_grnavtivebtn">Active</button>,
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementvendorsview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot drpdw_redtxt">
                Inactive
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Order Aceepting Off</a>
            </li>
            <li>
              <a class="dropdown-item">Settlement</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      hash: (
        <img
          className="tab_dummyimg"
          src={require("../../assests/images/dummyimg.png")}
        />
      ),
      vendorname: "John Doe",
      vendorId: "#1562",
      location: "354 Allen Ave, Pasadena, CA 91106, USA",
      category: "Resturant, Italian",
      status: <button className="table_grnavtivebtn">Active</button>,
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementvendorsview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot drpdw_redtxt">
                Inactive
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Order Aceepting Off</a>
            </li>
            <li>
              <a class="dropdown-item">Settlement</a>
            </li>
          </ul>
        </div>
      ),
    },
  ];
  // var header=csvData.map((item)=>
  //     [JSON.stringify(item)]
  //     )

  // const csvReport = {
  //     data: csvData,
  //     filename: fileName
  //   };

  return (
    <>
      <div>
        <div className="innerpages">
          <div className="orders_pages">
            <Headers />
            <div className="container custom_container px-lg-0">
              <div className="row mx-auto">
                <div className="innerpages_wid px-0">
                  <Sidebar />
                  <div
                    className={
                      toggle
                        ? "content-sec-inner right_widthset content_without_sidebar"
                        : "content-sec-inner  content_with_sidebar"
                    }
                  >
                    <section id="content-wrapper">
                      <div class="row">
                        <div className="col-12 col-sm-4 d-flex align-items-center">
                          <p className="page_title mb-0">User & Access</p>
                        </div>
                        <div className="col-12 col-sm-8  ">
                          {" "}
                          <div className="rightside">
                            {/* <button
                              type="button"
                              className="blue_btn add_deliveryprsn"
                              onClick={() => navigate("/addseller")}
                            >
                              Add Locations
                            </button> */}
                            <div className="rightside_select">
                              <Select
                                options={driveroptions}
                                defaultValue={selectedOption}
                                styles={stylesgraybg}
                                onChange={setSelectedOption}
                                className="border_blue_select"
                                classNamePrefix="react-select"
                                placeholder="Status"
                              />
                            </div>

                            {/* <div className="order_calendar_div">
                              <p
                                className={
                                  opacity == "true" ? "hiding_caltxt" : ""
                                }
                              >
                                Start Date
                              </p>
                              <img
                                className="order_calendar_ic"
                                src={require("../../assests/images/calendar.png")}
                              />
                              <Datetime
                                onChange={() => {
                                  setOpacity("true");
                                }}
                                className="order_datepick"
                              />
                            </div> */}
                            {/* {exportdata && (
                              <Exportcsv
                                csvData={exportdata}
                                fileName={"users"}
                              />
                            )} */}
                            <button
                              type="button"
                              className="blue_btn add_deliveryprsn btn_min_ht dp_btn_hover"
                              onClick={() => navigate("/adduser")}
                            >
                              + Add User
                            </button>

                            {/* <CSVLink
                              className="downloadbtn"
                              filename="my-file.csv"
                              data={csvData}
                            >
                              Export to CSV
                            </CSVLink> */}
                          </div>
                        </div>
                      </div>
                      <div className="row mx-auto">
                        {console.log("ldjkfdljfdsfa", seller)}
                        {seller && (
                          <div className="ffd_table mar_bot min_ht_none px-0 table_bor">
                            <BootstrapTable
                              bootstrap4
                              keyField="id"
                              columns={columns}
                              data={seller}
                              pagination={pagination}
                            />
                          </div>
                        )}
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserAccess;
