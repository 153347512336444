import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";

import useAxiosFile from "../../actions/useaxios";
import {
  DecryptData,
  LoadToast,
  isEmpty,
  updateToast,
} from "../../actions/common";
import Sidebar from "../../layouts/sidebar";
import Header from "../../layouts/header";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";

function add() {
  let data = useLocation();
  console.log("fffffff", data);
  const search = data.search.split("=")[1];
  const { toggle } = useSelector((state) => state.admin_panel.User);
  console.log("axiosFile", data.search.split("=")[1]);

  const axiosFile = useAxiosFile();
  const navigate = useNavigate();

  const [userdata, setUserdata] = useState({});
  const [errors, setError] = useState({});
  console.log("uuuuu", userdata);
  console.log("input_data", userdata.icon, userdata.website, userdata.link);
  useEffect(() => {
    alldata(search);
  }, []);

  const alldata = async (search) => {
    console.log("search", search !== "" || search !== undefined);
    if (search !== "" && search !== undefined) {
      var senddata = {
        _id: search,
      };

      var data = await axiosFile.alldata(senddata);
      console.log("yyyyyyy", data);
      if (data?.success == true) {
        setUserdata({
          icon: data?.data?.icon,
          website: data?.data?.website,
          link: data?.data?.link,
        });
        console.log("wefwera", data?.success);
      }
    }
  };
  const changefn = (e) => {
    setError("");

    console.log("sadfgjhadfasdf", e.target.value);
    var { name, value } = e.target;
    console.log("nv", name, value);
    setUserdata({ ...userdata, ...{ [e.target.name]: e.target.value } });
  };

  const clickSubmit = async (e) => {
    let id = LoadToast();

    var value = addvalidation();

    if (!isEmpty(value)) {
      console.log("value", value);
      setError(value);
      updateToast(id, "fix all validation", "error");
    } else {
      console.log("search_data", search);
      let senddata;
      if (search != undefined) {
        senddata = { ...userdata, ...{ _id: search, action: "update" } };
        // _id: search,
        // icon: userdata.icon,
        // website: userdata.website,
        // link: userdata.link,
        // action: "update"
      } else {
        senddata = { ...userdata, ...{ action: "add" } };

        // senddata = {
        //   icon: userdata.icon,
        //   website: userdata.website,
        //   link: userdata.link,
        //   action: "add"
        // }
      }
      var data = await axiosFile.addinsert(senddata);
      console.log("dddd", data);
      updateToast(id, data.msg, data.success);
      if (data.success == "success") {
        console.log("statusdata", data.success);
        navigate("/lists");
      }
    }
  };

  const addvalidation = () => {
    console.log(
      "dbhjbnjhbasjhd",
      userdata.icon,
      userdata?.website,
      userdata?.link
    );
    const link =
      /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
    let errors = {};
    if (isEmpty(userdata?.icon)) {
      errors.icon = "icon field is required";
    }
    if (isEmpty(userdata?.website)) {
      errors.website = "website field is required";
    }
    if (isEmpty(userdata?.link)) {
      errors.link = "link field is required";
    } else if (!link.test(userdata?.link)) {
      errors.link = "please valid link";
    }
    return errors;
  };

  console.log("userdata", userdata);
  return (
    <>
      <div>
        <div className="innerpages">
          <div className="orders_pages">
            <Header />
            <div className="container custom_container px-lg-0">
              <div className="row">
                <div className="innerpages_wid px-0">
                  <Sidebar />
                  <div
                    className={
                      toggle
                        ? "content-sec-inner right_widthset content_without_sidebar"
                        : "content-sec-inner  content_with_sidebar"
                    }
                  >
                    <section id="content-wrapper">
                      <div className="top_title_sec">
                        <div>
                          <p className="page_title">
                            {search !== undefined ? "Edit links" : "Add links"}
                          </p>
                        </div>
                      </div>
                      <label>
                        Icon
                        <input
                          className="mdv_cmninput mb-3"
                          name="icon"
                          type="text"
                          id="icon"
                          placeholder="Enter the icon"
                          value={userdata?.icon}
                          onChange={(e) => changefn(e)}
                        />
                        <span id="icon-error" className="error-msg">
                          {errors && errors.icon}
                        </span>
                      </label>
                      <br />
                      <label>
                        Website
                        <input
                          className="mdv_cmninput mb-3"
                          name="website"
                          type="text"
                          id="website"
                          placeholder="Enter the website"
                          value={userdata?.website}
                          onChange={(e) => changefn(e)}
                        />
                        <span id="website-error" className="error-msg">
                          {errors && errors.website}
                        </span>
                      </label>
                      <br />

                      <label>
                        Link
                        <input
                          className="mdv_cmninput mb-3"
                          name="link"
                          type="text"
                          id="link"
                          placeholder="Enter the link"
                          value={userdata?.link}
                          onChange={(e) => changefn(e)}
                        />
                        <span id="link-error" className="error-msg">
                          {errors && errors.link}
                        </span>
                      </label>
                      <br />
                      {/* <Row className="mt-4">
                          <Col md={6}>
                            <div className="addfaq_sep_inpfile">
                              <p>Choose File...</p>
                            <input type="file" className="addfaq_sep_input" id="image" onChange={handleFile} />
                            </div>
                          </Col>
                          <Col md={6}>
                          {
                        onchangeimg ? <img src={onchangeimg ? onchangeimg : require('../../../assests/images/cloud.png')} width={50} /> :

                          <img src={img ? `${config.IMG_URL}/Faq/${img}` : require('../../../assests/images/cloud.png')} />
                      }
                          </Col>
                        </Row> */}

                      <button
                        className="blue_btn add_deliveryprsn dp_btn_hover"
                        onClick={() => clickSubmit()}
                      >
                        {search !== undefined ? "update" : "add"}
                      </button>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default add;
