import { memo } from "react";
import { Route, Navigate } from "react-router-dom";

import Login from "../pages/loginpages/signin";
import Forgot from "../pages/loginpages/forgot";
import Password from "../pages/loginpages/password";
import Otpverfication from "../pages/loginpages/otpverfication";

// import Dashboard from "../src/pages/dashboard";
import Orders from "../pages/orders";
import Sales from "../pages/sales";
import Vendor from "../pages/vendors";
import DashboardOverview from "../pages/dashboardOverview";
import OrdersView from "../pages/OrdersView";
import OrderDrivers from "../pages/OrderDrivers";
import ManagementDriver from "../pages/loginpages/Managementpages/ManagementDriver";
import ManagementDriverView from "../pages/loginpages/Managementpages/ManagementDriverView";
import Protected from "./Protected";
import Drivers from "../pages/Drivers";
import Profiledetail from "../pages/profiledetail";
import DashboardOverviewNew from "../pages/DashboardOverviewNew";
import { isEmpty } from "../actions/common";
export const PublicRoutes = (Auths, Token) => {
  const Auth = Auths && isEmpty(Token) ? true : false;
  const Auth_sign = Token && Auths ? false : true;
  console.log("Authsssss", Auth_sign, Auths, Token);
  return (
    <>
      <Route path="/" element={<Navigate to={"/signin"} replace />} />
      <Route
        path="/signin"
        element={
          <Protected isSignedIn={true}>
            <Login />
          </Protected>
        }
      />
      <Route
        path="/forgot"
        element={
          <Protected isSignedIn={true}>
            <Forgot />
          </Protected>
        }
      />
      <Route
        path="/Changepassword"
        element={
          <Protected isSignedIn={true}>
            <Password />
          </Protected>
        }
      />
      <Route
        path="/otpverfication"
        element={
          <Protected isSignedIn={true}>
            {" "}
            <Otpverfication />
          </Protected>
        }
      />
      {/* <Route exact path="/" element={<Login />}/>
        <Route path="/forgot" element={<Forgot />}/>
        <Route path="/password" element={<Password />}/>
        <Route path="/otpverfication" element={<Otpverfication />}/> */}
    </>
  );
};
