import React from "react";
import { CSVLink } from "react-csv";

const Exportcsv = ({ csvData, fileName }) => {
  const csvReport = {
    data: csvData || [],
    filename: fileName,
  };

  return (
    <div classname="App export_min_ht">
      <CSVLink
        className="btn bottom_btn_csv export_min_ht d-flex align-items-center"
        {...csvReport}
      >
        Export to CSV
      </CSVLink>
    </div>
  );
};
export default Exportcsv;
