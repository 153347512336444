import React, { useEffect, useState } from "react";
import Sidebar from "../../../layouts/sidebar";
import Headers from "../../../layouts/header";
import Select from "react-select";
import Datetime from "react-datetime";
import moment from "moment";
import { Nav, Tab, Row, Col, Container, Form } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-datetime/css/react-datetime.css";
import Accordion from "react-bootstrap/Accordion";
import { useParams, useHistory } from "react-router-dom";
import useAxiosFile from "../../../actions/useaxios";
import config from "../../../actions/config";
import {
  DecryptData,
  EncryptData,
  MinuteHourFormat,
  isEmpty,
} from "../../../actions/common";
import { useLocation, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { GetLatLngBasedOnAddress } from "../../Map/geocode";

import deletSvg from "../../../assests/svg/delete.svg";

const initdaytiming = {
  Sunday: {
    day: "Sunday",
    Timing: {
      day: "Sunday",
      Status: false,
      hours: false,
      error: "",
      timing: [],
    },
    Delivery: {
      day: "Sunday",
      Status: false,
      hours: false,
      error: "",
      timing: [],
    },
    Pickup: {
      day: "Sunday",
      Status: false,
      hours: false,
      error: "",
      timing: [],
    },
  },
  Monday: {
    day: "Monday",
    Timing: {
      day: "Monday",
      Status: false,
      hours: false,
      error: "",
      timing: [],
    },
    Delivery: {
      day: "Monday",
      Status: false,
      hours: false,
      error: "",
      timing: [],
    },
    Pickup: {
      day: "Monday",
      Status: false,
      hours: false,
      error: "",
      timing: [],
    },
  },
  Tuesday: {
    day: "Tuesday",
    Timing: {
      day: "Tuesday",
      Status: false,
      hours: false,
      error: "",
      timing: [],
    },
    Delivery: {
      day: "Tuesday",
      Status: false,
      hours: false,
      error: "",
      timing: [],
    },
    Pickup: {
      day: "Tuesday",
      Status: false,
      hours: false,
      error: "",
      timing: [],
    },
  },
  Wednesday: {
    day: "Wednesday",
    Timing: {
      day: "Wednesday",
      Status: false,
      hours: false,
      error: "",
      timing: [],
    },
    Delivery: {
      day: "Wednesday",
      Status: false,
      hours: false,
      error: "",
      timing: [],
    },
    Pickup: {
      day: "Wednesday",
      Status: false,
      hours: false,
      error: "",
      timing: [],
    },
  },
  Thursday: {
    day: "Thursday",
    Timing: {
      day: "Thursday",
      Status: false,
      hours: false,
      error: "",
      timing: [],
    },
    Delivery: {
      day: "Thursday",
      Status: false,
      hours: false,
      error: "",
      timing: [],
    },
    Pickup: {
      day: "Thursday",
      Status: false,
      hours: false,
      error: "",
      timing: [],
    },
  },
  Friday: {
    day: "Friday",
    Timing: {
      day: "Friday",
      Status: false,
      hours: false,
      error: "",
      timing: [],
    },
    Delivery: {
      day: "Friday",
      Status: false,
      hours: false,
      error: "",
      timing: [],
    },
    Pickup: {
      day: "Friday",
      Status: false,
      hours: false,
      error: "",
      timing: [],
    },
  },
  Saturday: {
    day: "Saturday",
    Timing: {
      day: "Saturday",
      Status: false,
      hours: false,
      error: "",
      timing: [],
    },
    Delivery: {
      day: "Saturday",
      Status: false,
      hours: false,
      error: "",
      timing: [],
    },
    Pickup: {
      day: "Saturday",
      Status: false,
      hours: false,
      error: "",
      timing: [],
    },
  },
};

function ManagementVendorsView() {
  const initdata = {
    FirstName: "",
    LastName: "",
    EmailId: "",
    Mobile: "",
    DateofBirth: "",
    Gender: "",
    Profile: "",
  };

  const { token, payload } = useSelector((state) => state.admin_panel.User);
  const { toggle } = useSelector((state) => state.admin_panel.User);
  const location = useLocation();
  const { pathname, search, state } = useLocation();
  console.log("dfgd", token);

  const navigate = useNavigate();
  let id = DecryptData(location?.search.split("?Id=")[1]);

  const axiosFile = useAxiosFile();

  const [selectedOption, setSelectedOption] = useState(null);
  const [activeTab, setActiveTab] = useState("profile");
  const [subactiveTab, setSubActiveTab] = useState("delivery");
  const [formValue, setFormValue] = useState(initdata);
  const [Datas, SetDatas] = useState({ profile: {}, shop: {} });
  const [ShopList, SetShopList] = useState({});
  const [SelectedShop, SetSelectedShop] = useState({});
  const [Error, SetError] = useState({});
  const [newshopdata, SetNewShopData] = useState({});
  const [shoptab, SetShopTab] = useState("");
  const [HourMinuteDisplay, SetHourMinuteDisplay] = useState({
    Hours: [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23,
    ],
    Minutes: [1, 2, 3, 4],
  });
  const [DayTimings, setDayTimings] = useState(initdaytiming);
  const [Categorys, setsubcat] = useState("");
  const [SubCategorys, setSubCategory] = useState([]);
  const [SelectedTime, SetSelectedTime] = useState({
    Timing: [],
    Pickup: [],
    Delivery: [],
  });
  const [Currencies, SetCurrencies] = useState([
    { label: "USD", value: "$" },
    { label: "EURO", value: "€" },
  ]);

  const [Id, Setid] = useState();
  const [passwordText, setPasswordText] = useState("password");
  var [selectCheck, SetSelectCheck] = useState(true);

  // const { Id } = useParams()
  const [vendor, setvendor] = useState();
  const [disabledstatus,SetDisabledstatus] = useState({});

  console.log("sdfasdf", vendor);
  const getvendorview = async (e) => {
    var SendData = {
      Id: isEmpty(DecryptData(Id)) ? Id : DecryptData(Id),
      TabName: activeTab.includes("shop") ? "shop" : activeTab,
      ShopId: e?.value
        ? e?.value
        : isEmpty(SelectedShop?.value)
        ? undefined
        : SelectedShop?.value,
    };
    var resp = await axiosFile.getvendorListview(SendData);
    console.log(
      "dddddddddddd",
      resp,
      e,
      SendData,
      SelectedShop,
      isEmpty(SelectedShop)
    );
    if (activeTab == "profile") {
      setFormValue(resp?.data);
      SetDatas({
        ...Datas,
        ...{ [activeTab]: resp?.data },
      });
    }
    setvendor(resp.data);
    SetShopList(resp?.ShopList);

    console.log("ljdlksjdkassda", resp?.data);
    if (activeTab.includes("shop")) {
      // if(formValue?.CreatedBy == "admin"){
      SetNewShopData(resp?.data);
      // }
      if (activeTab == "addshop") {
        SetSelectedShop({});
      } else {
        if (resp?.data?.DayTimings) {
          let instobj = {
            Timing: [],
            Pickup: [],
            Delivery: [],
          };
          let respdaytiming = resp?.data?.DayTimings;
          let days = Object.keys(respdaytiming);
          Object.values(respdaytiming).map((val, ind) => {
            Object.keys(val).map((type) => {
              if (
                (type == "Delivery" || type == "Timing" || type == "Pickup") &&
                val?.[type]?.Status
              ) {
                if (
                  instobj?.[type]?.find(
                    (findcheck) =>
                      findcheck?.EndTime == val?.[type]?.timing?.[0]?.EndTime &&
                      findcheck?.StartTime ==
                        val?.[type]?.timing?.[0]?.StartTime
                  )
                ) {
                  let findindex = instobj[type].findIndex(
                    (findcheck) =>
                      findcheck?.EndTime == val?.[type]?.timing?.[0]?.EndTime &&
                      findcheck?.StartTime ==
                        val?.[type]?.timing?.[0]?.StartTime &&
                      !isEmpty(findcheck?.EndTime) &&
                      !isEmpty(findcheck?.StartTime)
                  );
                  instobj[type] = [
                    ...instobj[type].slice(0, findindex),
                    ...[
                      {
                        ...instobj[type]?.[findindex],
                        ...{
                          [days[ind]]: true,
                        },
                      },
                    ],
                    ...instobj[type].slice(findindex + 1, instobj[type].length),
                  ];
                } else {
                  instobj[type].push({
                    StartTime: val?.[type]?.timing?.[0]?.StartTime,
                    StartTimeshow: HourMinutetoTime(
                      val?.[type]?.timing?.[0]?.StartHour,
                      val?.[type]?.timing?.[0]?.StartMinute
                    ),
                    EndTime: val?.[type]?.timing?.[0]?.EndTime,
                    EndTimeshow: HourMinutetoTime(
                      val?.[type]?.timing?.[0]?.EndHour,
                      val?.[type]?.timing?.[0]?.EndMinute
                    ),
                    [days[ind]]: true,
                  });
                }
              }
            });
          });
          setDayTimings(respdaytiming);
          SetSelectedTime({ ...instobj });
        }
        SetDatas({
          ...Datas,
          ...{ [activeTab]: resp?.data },
        });
        SetSelectedShop({
          label: resp?.data?.BrandName,
          value: resp?.data?._id,
        });
      }
    }
    if (shoptab == "addshop") {
      SetShopTab("");
    }
  };

  useEffect(() => {
    if (Id) getvendorview();
  }, [Id, activeTab]);

  const [onchangeimg, setOnchangeimg] = useState("");
  const [imageVal, setImageVal] = useState("");
  console.log("hdhsssg", imageVal, location?.pathname);

  useEffect(() => {
    console.log("kjsfsjfs", location.search.split("?Id="));
    if (location?.search) {
      let paramdata = location.search.split("?Id=")[1];
      Setid(
        isEmpty(DecryptData(paramdata)) ? paramdata : DecryptData(paramdata)
      );
    }
  }, [location.search]);

  // console.log("fgghdhg",Gender);

  const onChange = (e) => {
    const { value, id } = e.target;
    var formData = { ...formValue, ...{ [id]: value } };
    setFormValue(formData);
  };
  //   const handleFile = (event) => {
  //     event.preventDefault();
  //     console.log('dsugfdsk',event)
  //     var reader = new FileReader()
  //    console.log("reader",reader);
  //     const { id, files } = event.target;
  //     console.log("profile id",id)
  //     if (event.target.files && event.target.files[0]) {
  //         var file = event.target.files[0];
  //         console.log("imgssss",file);
  //         setImageVal(file)
  //         var url = reader.readAsDataURL(file);
  //         reader.onloadend = function (e) {
  //             console.log('hiiiiiiii')
  //             if (reader.result) {
  //                 setOnchangeimg(reader.result);
  //             }
  //         }
  //     }
  // }

  const columns = [
    { dataField: "productid", text: "Product ID" },
    { dataField: "productName", text: "Product Name", sort: true },
    { dataField: "variant", text: "Variant", sort: true },
    { dataField: "status", text: "Status", sort: true },
    { dataField: "price", text: "Price", sort: true },
    { dataField: "action", text: "Action" },
  ];

  const data = [
    {
      productid: "#1569",
      productName: (
        <div className="mvv_catelog_tabimgdtls">
          <img src={require("../../../assests/images/food.png")} />
          <p>Spicy Sandwich</p>
        </div>
      ),
      variant: "2",
      status: <button className="table_grnavtivebtn">Active</button>,
      price: "$20.00",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementdriverview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },

    {
      productid: "#1569",
      productName: (
        <div className="mvv_catelog_tabimgdtls">
          <img src={require("../../../assests/images/food.png")} />
          <p>Spicy Sandwich</p>
        </div>
      ),
      variant: "2",
      status: <button className="table_redinactbtn">Inactive</button>,
      price: "$20.00",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementdriverview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },

    {
      productid: "#1569",
      productName: (
        <div className="mvv_catelog_tabimgdtls">
          <img src={require("../../../assests/images/food.png")} />
          <p>Spicy Sandwich</p>
        </div>
      ),
      variant: "2",
      status: <button className="table_grnavtivebtn">Active</button>,
      price: "$20.00",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementdriverview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },

    {
      productid: "#1569",
      productName: (
        <div className="mvv_catelog_tabimgdtls">
          <img src={require("../../../assests/images/food.png")} />
          <p>Spicy Sandwich</p>
        </div>
      ),
      variant: "2",
      status: <button className="table_grnavtivebtn">Active</button>,
      price: "$20.00",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementdriverview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },

    {
      productid: "#1569",
      productName: (
        <div className="mvv_catelog_tabimgdtls">
          <img src={require("../../../assests/images/food.png")} />
          <p>Spicy Sandwich</p>
        </div>
      ),
      variant: "2",
      status: <button className="table_grnavtivebtn">Active</button>,
      price: "$20.00",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementdriverview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  const optimecolumns = [
    { dataField: "day", text: "Day" },
    { dataField: "status", text: "Status" },
    { dataField: "hours", text: "24 Hours" },
    { dataField: "opentime", text: "Open Time" },
    { dataField: "closetime", text: "Close Time" },
    { dataField: "action", text: "Action" },
  ];

  const optimedata = [
    {
      day: "Sunday",
      status: (
        <div className="rightsec secondary_togtab">
          <div className="togswit togswit_redgreen">
            <input type="checkbox" id="toggle-btn3" />
            <label for="toggle-btn3"></label>
          </div>
          <p className="mvv_acceptorder">On</p>
        </div>
      ),
      hours: (
        <div className="rightsec secondary_togtab">
          <div className="togswit togswit_redgreen">
            <input type="checkbox" id="toggle-btn4" />
            <label for="toggle-btn4"></label>
          </div>
          <p className="mvv_acceptorder">On</p>
        </div>
      ),
      opentime: <input type="date" className="table_dateinps" />,
      closetime: <input type="date" className="table_dateinps" />,
      action: (
        <button className="mmv_tabgn_plusbtn">
          <img src={require("../../../assests/images/plusround.png")} />
        </button>
      ),
    },

    {
      day: "Monday",
      status: (
        <div className="rightsec secondary_togtab">
          <div className="togswit togswit_redgreen">
            <input type="checkbox" id="toggle-btn5" />
            <label for="toggle-btn5"></label>
          </div>
          <p className="mvv_acceptorder">On</p>
        </div>
      ),
      hours: (
        <div className="rightsec secondary_togtab">
          <div className="togswit togswit_redgreen">
            <input type="checkbox" id="toggle-btn6" />
            <label for="toggle-btn6"></label>
          </div>
          <p className="mvv_acceptorder">On</p>
        </div>
      ),
      opentime: <input type="date" className="table_dateinps" />,
      closetime: <input type="date" className="table_dateinps" />,
      action: (
        <button className="mmv_tabred_minusbtn">
          <img src={require("../../../assests/images/roundminus.png")} />
        </button>
      ),
    },

    {
      day: "Tuesday",
      status: (
        <div className="rightsec secondary_togtab">
          <div className="togswit togswit_redgreen">
            <input type="checkbox" id="toggle-btn7" />
            <label for="toggle-btn7"></label>
          </div>
          <p className="mvv_acceptorder">On</p>
        </div>
      ),
    },

    {
      day: "Wednesday",
      status: (
        <div className="rightsec secondary_togtab">
          <div className="togswit togswit_redgreen">
            <input type="checkbox" id="toggle-btn8" />
            <label for="toggle-btn8"></label>
          </div>
          <p className="mvv_acceptorder">On</p>
        </div>
      ),
      hours: (
        <div className="rightsec secondary_togtab">
          <div className="togswit togswit_redgreen">
            <input type="checkbox" id="toggle-btn9" />
            <label for="toggle-btn9"></label>
          </div>
          <p className="mvv_acceptorder">On</p>
        </div>
      ),
      opentime: <input type="date" className="table_dateinps" />,
      closetime: <input type="date" className="table_dateinps" />,
      action: (
        <button className="mmv_tabgn_plusbtn">
          <img src={require("../../../assests/images/plusround.png")} />
        </button>
      ),
    },

    {
      day: "Thursday",
      status: (
        <div className="rightsec secondary_togtab">
          <div className="togswit togswit_redgreen">
            <input type="checkbox" id="toggle-btn10" />
            <label for="toggle-btn10"></label>
          </div>
          <p className="mvv_acceptorder">On</p>
        </div>
      ),
      hours: (
        <div className="rightsec secondary_togtab">
          <div className="togswit togswit_redgreen">
            <input type="checkbox" id="toggle-btn11" />
            <label for="toggle-btn11"></label>
          </div>
          <p className="mvv_acceptorder">On</p>
        </div>
      ),
      opentime: <input type="date" className="table_dateinps" />,
      closetime: <input type="date" className="table_dateinps" />,
      action: (
        <button className="mmv_tabgn_plusbtn">
          <img src={require("../../../assests/images/plusround.png")} />
        </button>
      ),
    },
    {
      day: "Friday",
      status: (
        <div className="rightsec secondary_togtab">
          <div className="togswit togswit_redgreen">
            <input type="checkbox" id="toggle-btn12" />
            <label for="toggle-btn12"></label>
          </div>
          <p className="mvv_acceptorder">On</p>
        </div>
      ),
      hours: (
        <div className="rightsec secondary_togtab">
          <div className="togswit togswit_redgreen">
            <input type="checkbox" id="toggle-btn13" />
            <label for="toggle-btn13"></label>
          </div>
          <p className="mvv_acceptorder">On</p>
        </div>
      ),
      opentime: <input type="date" className="table_dateinps" />,
      closetime: <input type="date" className="table_dateinps" />,
      action: (
        <button className="mmv_tabgn_plusbtn">
          <img src={require("../../../assests/images/plusround.png")} />
        </button>
      ),
    },

    {
      day: "Saturday",
      status: (
        <div className="rightsec secondary_togtab">
          <div className="togswit togswit_redgreen">
            <input type="checkbox" id="toggle-btn14" />
            <label for="toggle-btn14"></label>
          </div>
          <p className="mvv_acceptorder">On</p>
        </div>
      ),
      hours: (
        <div className="rightsec secondary_togtab">
          <div className="togswit togswit_redgreen">
            <input type="checkbox" id="toggle-btn15" />
            <label for="toggle-btn15"></label>
          </div>
          <p className="mvv_acceptorder">On</p>
        </div>
      ),
      opentime: <input type="date" className="table_dateinps" />,
      closetime: <input type="date" className="table_dateinps" />,
      action: (
        <button className="mmv_tabgn_plusbtn">
          <img src={require("../../../assests/images/plusround.png")} />
        </button>
      ),
    },
  ];

  const reviewcolumns = [
    { dataField: "orderid", text: "Order ID" },
    { dataField: "customerId", text: "Customer Id", sort: true },
    { dataField: "customerName", text: "Customer Name", sort: true },
    { dataField: "mobileno", text: "Mobile Number", sort: true },
    { dataField: "rating", text: "Rating", sort: true },
    { dataField: "review", text: "Review", sort: true },
    { dataField: "date", text: "Date", sort: true },
    { dataField: "action", text: "Action" },
  ];

  const reviewdata = [
    {
      orderid: "#1569",
      customerId: "10",
      customerName: "Venturino Piccolo",
      mobileno: "515 516 0624",
      rating: (
        <div className="table_startxt">
          <p className="table_blurblue">2</p>
          <img src={require("../../../assests/images/Union.png")} />
        </div>
      ),
      review: "Ipsum is simply dummy text of the printing industry. ",
      date: "10/10/2022",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementdriverview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },

    {
      orderid: "#1569",
      customerId: "10",
      customerName: "Venturino Piccolo",
      mobileno: "515 516 0624",
      rating: (
        <div className="table_startxt">
          <p className="table_blurblue">2</p>
          <img src={require("../../../assests/images/Union.png")} />
        </div>
      ),
      review: "Ipsum is simply dummy text of the printing industry. ",
      date: "10/10/2022",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementdriverview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },

    {
      orderid: "#1569",
      customerId: "10",
      customerName: "Venturino Piccolo",
      mobileno: "515 516 0624",
      rating: (
        <div className="table_startxt">
          <p className="table_blurblue">2</p>
          <img src={require("../../../assests/images/Union.png")} />
        </div>
      ),
      review: "Ipsum is simply dummy text of the printing industry. ",
      date: "10/10/2022",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementdriverview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },

    {
      orderid: "#1569",
      customerId: "10",
      customerName: "Venturino Piccolo",
      mobileno: "515 516 0624",
      rating: (
        <div className="table_startxt">
          <p className="table_blurblue">2</p>
          <img src={require("../../../assests/images/Union.png")} />
        </div>
      ),
      review: "Ipsum is simply dummy text of the printing industry. ",
      date: "10/10/2022",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementdriverview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },

    {
      orderid: "#1569",
      customerId: "10",
      customerName: "Venturino Piccolo",
      mobileno: "515 516 0624",
      rating: (
        <div className="table_startxt">
          <p className="table_blurblue">2</p>
          <img src={require("../../../assests/images/Union.png")} />
        </div>
      ),
      review: "Ipsum is simply dummy text of the printing industry. ",
      date: "10/10/2022",
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementdriverview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  const discountcolumns = [
    { dataField: "no", text: "No", sort: true },
    { dataField: "title", text: "Title" },
    { dataField: "promocode", text: "Promo Code", sort: true },
    { dataField: "discount", text: "Discount(%)", sort: true },
    { dataField: "flatdiscount", text: "Flat Discount", sort: true },
    { dataField: "startdate", text: "Start Date", sort: true },
    { dataField: "enddate", text: "End Date", sort: true },
    { dataField: "status", text: "Status", sort: true },
    { dataField: "action", text: "Action" },
  ];

  const discountdata = [
    {
      no: "#1",
      title: "Get 20% on first 5 orders",
      promocode: "WELCOME20",
      discount: "20 %",
      flatdiscount: "$ 0",
      startdate: "10/10/2022",
      enddate: "20/10/2022",
      status: <button className="table_grnavtivebtn">Active</button>,
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementdriverview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },

    {
      no: "#1",
      title: "Get 20% on first 5 orders",
      promocode: "WELCOME20",
      discount: "20 %",
      flatdiscount: "$ 0",
      startdate: "10/10/2022",
      enddate: "20/10/2022",
      status: <button className="table_redinactbtn">Inactive</button>,
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementdriverview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      no: "#1",
      title: "Get 20% on first 5 orders",
      promocode: "WELCOME20",
      discount: "20 %",
      flatdiscount: "$ 0",
      startdate: "10/10/2022",
      enddate: "20/10/2022",
      status: <button className="table_grnavtivebtn">Active</button>,
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementdriverview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      no: "#1",
      title: "Get 20% on first 5 orders",
      promocode: "WELCOME20",
      discount: "20 %",
      flatdiscount: "$ 0",
      startdate: "10/10/2022",
      enddate: "20/10/2022",
      status: <button className="table_grnavtivebtn">Active</button>,
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementdriverview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },
    {
      no: "#1",
      title: "Get 20% on first 5 orders",
      promocode: "WELCOME20",
      discount: "20 %",
      flatdiscount: "$ 0",
      startdate: "10/10/2022",
      enddate: "20/10/2022",
      status: <button className="table_redinactbtn">Inactive</button>,
      action: (
        <div class="dropdown table_viewdropdown">
          <button
            class="btn table_viewdrop dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="fa-solid fa-ellipsis" />
          </button>
          <ul class="dropdown-menu">
            <li>
              <a
                class="dropdown-item table_viewborbot"
                href="/managementdriverview"
              >
                View
              </a>
            </li>
            <li>
              <a class="dropdown-item table_viewborbot">Edit</a>
            </li>
            <li>
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  const gender = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Others", label: "Others" },
  ];

  const driveroptions = [
    { value: "idle", label: "Idle" },
    { value: "intransit", label: "In Transit" },
    { value: "offline", label: "Offline" },
    { value: "active", label: "Active" },
  ];

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#F5F6F7"
        : isSelected
        ? "#F5F6F7"
        : isHovered
        ? "red"
        : "#F5F6F7",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#a71d24"
          : isSelected
          ? "#a71d24"
          : isFocused
          ? "#a71d24"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 20px",
      backgroundColor: "#fff ",
      border: "1px solid lightgray",
      borderRadius: 10,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#6C6A81",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#6C6A81",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const stylegreendrop = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#6C6A81",
      background: isFocused
        ? "#F5F6F7"
        : isSelected
        ? "#F5F6F7"
        : isHovered
        ? "red"
        : "#F5F6F7",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#a71d24"
          : isSelected
          ? "#a71d24"
          : isFocused
          ? "#a71d24"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : "",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 20px",
      backgroundColor: "#fff ",
      border: "1px solid #00B167",
      borderRadius: 10,
      fontSize: "13px",
      color: "#00B167",
      fontWeight: 700,
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#00B167",
      fontWeight: 700,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#00B167",
      fontWeight: 700,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
    }),
  };

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log("page", page);
      console.log("sizePerPage", sizePerPage);
    },
  });

  const OnChange = (e, type) => {
    console.log("skfskfsgsg", e);
    if (e?.target) {
      const { value, id, files } = e.target;
      if (!isEmpty(Error[id])) {
        SetError({ ...Error, ...{ [id]: "" } });
      } else if (files) {
      }
      setFormValue({ ...formValue, ...{ [id]: value } });
    } else if (e?.value) {
      if (!isEmpty(Error?.[type])) {
        SetError({ ...Error, ...{ [type]: "" } });
      }
      setFormValue({ ...formValue, ...{ [type]: e.value } });
    } else if (e) {
      setFormValue({ ...formValue, ...{ [type]: e } });
    }
  };
  console.log("skjhjskfsfsf", newshopdata);

  useEffect(() => {
    if (selectCheck == true) {
      SetSelectCheck(false);
    }
  }, [newshopdata?._id]);

  useEffect(() => {
    if (selectCheck == false) {
      SetSelectCheck(true);
    }
  }, [selectCheck]);

  const ShopOnChange = (e, type) => {
    console.log("kdsjdsjkfs", e, e?.target, e?.target?.name, e?.target?.value);
    if (e?.target) {
      const { value, id, name, files } = e.target;
      if (!isEmpty(Error[id])) {
        SetError({ ...Error, ...{ [id]: "" } });
      }
      if (name == "BankDetails" || name == "Address") {
        SetNewShopData({
          ...newshopdata,
          ...{
            [name]: {
              ...newshopdata?.[name],
              ...{ [id]: value },
            },
          },
        });
      } else {
        if (
          id == "StoreImages" &&
          files?.length > 0 &&
          Object.values(files).some((it) => !it.type.includes("image"))
        ) {
          toast("Except Image other formats are eliminated automatically", {
            type: toast.TYPE.ERROR,
            autoClose: true,
          });
        }
        if (
          id == "BrandImage" &&
          files?.length > 0 &&
          Object.values(files).some((it) => !it.type.includes("image"))
        ) {
          toast("Image Only Allowed", {
            type: toast.TYPE.ERROR,
            autoClose: true,
          });
        }
        SetNewShopData({
          ...newshopdata,
          ...{
            [id]:
              files?.length > 0
                ? id == "StoreImages"
                  ? Object.values(files).filter((it) =>
                      it.type.includes("image")
                    )
                  : Object.values(files).filter((it) =>
                      it.type.includes("image")
                    )[0]
                : value,
          },
        });
      }
    } else if (type == "Mobile") {
      if (!isEmpty(Error[type])) {
        SetError({ ...Error, ...{ [type]: "" } });
      }
      SetNewShopData({ ...newshopdata, ...{ [type]: e } });
    } else if (type == "Category") {
      if (!isEmpty(Error[type])) {
        SetError({ ...Error, ...{ ["Category"]: "" } });
      }
      SetNewShopData({
        ...newshopdata,
        ...{
          ["Category"]: e.value,
          ["SubCategory"]: "",
        },
      });
      setSubCategory(e.SubCategory);
    } else if (type == "SubCategory") {
      if (!isEmpty(Error[type])) {
        SetError({ ...Error, ...{ ["SubCategory"]: "" } });
      }
      SetNewShopData({
        ...newshopdata,
        ...{
          SubCategory: e?.value,
        },
      });
    } else if (type == "Currency") {
      if (!isEmpty(Error[type])) {
        SetError({ ...Error, ...{ ["CurrencyName"]: "" } });
      }
      SetNewShopData({
        ...newshopdata,
        ...{
          ["CurrencyName"]: e.label,
          ["CurrencySymbol"]: e.value,
        },
      });
    }
  };

  console.log("ksljskfjsfs", formValue, Datas, SelectedShop);

  const FileChange = (e) => {
    const { id, files } = e.target;
    if (files[0].type.includes("image")) {
      setFormValue({ ...formValue, ...{ [id]: files[0] } });
    } else {
      toast.error("Upload Images Only", 1000);
    }
  };

  const Validate = () => {
    let error = {};
    if (
      location?.pathname == "/addseller" ||
      (activeTab == "profile" && formValue?.CreatedBy == "admin")
    ) {
      if (isEmpty(formValue?.FirstName)) {
        error.FirstName = "Enter First Name";
      }
      if (isEmpty(formValue?.LastName)) {
        error.LastName = "Enter Last Name";
      }
      if (isEmpty(formValue?.EmailId)) {
        error.EmailId = "Enter Email-Id";
      } else if (!config.EMAIL.test(formValue?.EmailId)) {
        error.EmailId = "Enter Valid Email-Id";
      }
      if (isEmpty(formValue?.Mobile)) {
        error.Mobile = "Enter Mobile";
      } else if (!isValidPhoneNumber(formValue?.Mobile)) {
        error.Mobile = "Enter Valid Mobile";
      }
      if (isEmpty(formValue?.DateofBirth)) {
        error.DateofBirth = "Enter Date-of-Birth";
      }
      if (isEmpty(formValue?.Gender)) {
        error.Gender = "Enter Gender";
      }
      if (location?.pathname == "/addseller" && isEmpty(formValue?.Password)) {
        if (isEmpty(formValue?.Password)) error.Password = "Enter Password";
      } else if (!isEmpty(formValue?.Password)) {
        if (!config.PASSWORD.test(formValue?.Password)) {
          error.Password =
            "Password must contain Alphanumeric and between 8-15 characters";
        }
      }
    } else {
      if (isEmpty(newshopdata?.BrandName)) {
        error.BrandName = "Enter Brand Name";
      }
      if (isEmpty(newshopdata?.EmailId)) {
        error.EmailId = "Enter Email-Id";
      } else if (!config.EMAIL.test(newshopdata?.EmailId)) {
        error.EmailId = "Enter Valid Email-Id";
      }
      if (isEmpty(newshopdata?.Mobile)) {
        error.Mobile = "Enter Mobile";
      } else if (!isValidPhoneNumber(newshopdata?.Mobile)) {
        error.Mobile = "Enter Valid Mobile";
      }
      if (isEmpty(newshopdata?.Address?.StreetName)) {
        error.StreetName = "Enter Street Name";
      }
      if (isEmpty(newshopdata?.Address?.City)) {
        error.City = "Enter City";
      }
      if (isEmpty(newshopdata?.Address?.State)) {
        error.State = "Enter State";
      }
      if (isEmpty(newshopdata?.Address?.Country)) {
        error.Country = "Enter Country";
      }
      if (isEmpty(newshopdata?.Address?.Zipcode)) {
        error.Zipcode = "Enter Zipcode";
      }
      if (isEmpty(newshopdata?.BankDetails?.BankName)) {
        error.BankName = "Enter BankName";
      }
      if (isEmpty(newshopdata?.BankDetails?.AccountType)) {
        error.AccountType = "Enter Account-Type";
      }
      if (isEmpty(newshopdata?.BankDetails?.AccountHolderName)) {
        error.AccountHolderName = "Enter Account Holder Name";
      }
      if (isEmpty(newshopdata?.BankDetails?.AccountNumber)) {
        error.AccountNumber = "Enter AccountNumber";
      }
      if (isEmpty(newshopdata?.BankDetails?.RoutingNumber)) {
        error.RoutingNumber = "Enter Routing Number";
      }
      if (isEmpty(newshopdata?.Category)) {
        error.Category = "Choose Category";
      }
      if (isEmpty(newshopdata?.SubCategory)) {
        error.SubCategory = "Choose Sub-Category";
      }
      if (isEmpty(error)) {
        console.log("sjdksjdksfs", error);
        error.Timing = false;
        Object.values(DayTimings).map((instval) => {
          if (instval?.Timing?.timing?.length > 0) {
            error.Timing = true;
          }
        });
        if (error?.Timing) {
          let curdaytiming = DayTimings;
          let currTiming = SelectedTime?.Timing;
          Object.keys(SelectedTime).map((timetype) => {
            if (SelectedTime[timetype]?.length > 0) {
              SelectedTime[timetype]?.map((val, ind) => {
                if (isEmpty(val?.StartTime)) {
                  error[timetype + "StartTime" + ind] = "Enter Start-Time";
                }
                if (isEmpty(val?.EndTime)) {
                  error[timetype + "EndTime" + ind] = "Enter Start-Time";
                }
                if (timetype != "Timing") {
                  // let error = true;
                  Object.keys(val).map((keys) => {
                    if (
                      keys != "EndTime" &&
                      keys != "EndTimeshow" &&
                      keys != "StartTime" &&
                      keys != "StartTimeshow" &&
                      val[keys]
                    ) {
                      curdaytiming?.[keys]?.Timing?.timing?.map((checktime) => {
                        // if( error && (HourMinutetoTime(checktime?.EndHour,checktime?.EndMinute)>=HourMinutetoTime(val?.EndTime.split(':')[0],val?.EndTime.split(':')[1])) && (HourMinutetoTime(val?.StartHour,val?.StartMinute)>=HourMinutetoTime(checktime?.StartHour,curdaytiming?.StartMinute))){
                        //   error = false;
                        // }
                      });
                    }
                  });
                }
              });
            }
          });
          if (isEmpty(error) || error.Timing) {
            error = { Delivery: false, Timing: false, Pickup: false };
            Object.values(curdaytiming).map((it, ind) => {
              console.log("dkjjsfsklflkjklfl", it);
              Object.keys(it).map((type) => {
                console.log("sljfksjflsfs", type);
                if (
                  type == "Delivery" ||
                  type == "Timing" ||
                  type == "Pickup"
                ) {
                  if (it?.[type]?.timing?.length > 1) {
                    it?.[type]?.timing?.map((checkdata, checkdataind) => {
                      it?.[type]?.timing?.map((checkdata2, checkdata2ind) => {
                        if (checkdata2ind != checkdataind) {
                          if (
                            HourMinutetoTime(
                              checkdata?.StartHour,
                              checkdata?.StartMinute
                            ) <
                              HourMinutetoTime(
                                checkdata2?.StartHour,
                                checkdata2?.StartMinute
                              ) &&
                            HourMinutetoTime(
                              checkdata?.EndHour,
                              checkdata?.EndMinute
                            ) <
                              HourMinutetoTime(
                                checkdata2?.EndHour,
                                checkdata2?.EndMinute
                              ) &&
                            HourMinutetoTime(
                              checkdata2?.StartHour,
                              checkdata2?.StartMinute
                            ) <
                              HourMinutetoTime(
                                checkdata?.EndHour,
                                checkdata?.EndMinute
                              )
                          ) {
                            let newstarttime =
                              HourMinutetoTime(
                                checkdata?.StartHour,
                                checkdata?.StartMinute
                              ) <
                              HourMinutetoTime(
                                checkdata2?.StartHour,
                                checkdata2?.StartMinute
                              )
                                ? checkdata?.StartTime
                                : checkdata2?.StartTime;
                            let newendtime =
                              HourMinutetoTime(
                                checkdata?.EndHour,
                                checkdata?.EndMinute
                              ) <
                              HourMinutetoTime(
                                checkdata2?.EndHour,
                                checkdata2?.EndMinute
                              )
                                ? checkdata2?.EndTime
                                : checkdata2?.EndTime;
                            curdaytiming = {
                              ...curdaytiming,
                              ...{
                                [it.day]: {
                                  ...curdaytiming[it.day],
                                  ...{
                                    [type]: {
                                      ...curdaytiming[it.day][type],
                                      ...{
                                        timing: [
                                          ...curdaytiming[it.day][
                                            type
                                          ]?.timing?.slice(
                                            0,
                                            checkdataind < checkdata2ind
                                              ? checkdataind
                                              : checkdata2ind
                                          ),
                                          ...[
                                            {
                                              ...curdaytiming[it.day][type]
                                                ?.timing?.[
                                                checkdataind < checkdata2ind
                                                  ? checkdataind
                                                  : checkdata2ind
                                              ],
                                              ...{
                                                StartHour:
                                                  newstarttime.split(":")[0],
                                                StartMinute:
                                                  newstarttime.split(":")[1],
                                                StartTime: newstarttime,
                                                EndHour:
                                                  newendtime.split(":")[0],
                                                EndMinute:
                                                  newendtime.split(":")[1],
                                                EndTime: newendtime,
                                              },
                                            },
                                          ],
                                          ...curdaytiming[it.day][
                                            type
                                          ]?.timing?.slice(
                                            (checkdataind < checkdata2ind
                                              ? checkdataind
                                              : checkdata2ind) + 1,
                                            checkdataind > checkdata2ind
                                              ? checkdataind
                                              : checkdata2ind
                                          ),
                                          ...curdaytiming[it.day][
                                            type
                                          ]?.timing?.slice(
                                            (checkdataind > checkdata2ind
                                              ? checkdataind
                                              : checkdata2ind) + 1,
                                            curdaytiming[it.day][type]?.timing
                                              ?.length
                                          ),
                                        ],
                                      },
                                    },
                                  },
                                },
                              },
                            };
                          } else if (
                            HourMinutetoTime(
                              checkdata2?.StartHour,
                              checkdata2?.StartMinute
                            ) <
                              HourMinutetoTime(
                                checkdata?.StartHour,
                                checkdata?.StartMinute
                              ) &&
                            HourMinutetoTime(
                              checkdata?.StartHour,
                              checkdata?.StartMinute
                            ) <
                              HourMinutetoTime(
                                checkdata2?.EndHour,
                                checkdata2?.EndMinute
                              )
                          ) {
                            let newstarttime =
                              HourMinutetoTime(
                                checkdata?.StartHour,
                                checkdata?.StartMinute
                              ) <
                              HourMinutetoTime(
                                checkdata2?.StartHour,
                                checkdata2?.StartMinute
                              )
                                ? checkdata?.StartTime
                                : checkdata2?.StartTime;
                            let newendtime =
                              HourMinutetoTime(
                                checkdata?.EndHour,
                                checkdata?.EndMinute
                              ) <
                              HourMinutetoTime(
                                checkdata2?.EndHour,
                                checkdata2?.EndMinute
                              )
                                ? checkdata2?.EndTime
                                : checkdata2?.EndTime;
                            curdaytiming = {
                              ...curdaytiming,
                              ...{
                                [it.day]: {
                                  ...curdaytiming[it.day],
                                  ...{
                                    [type]: {
                                      ...curdaytiming[it.day][type],
                                      ...{
                                        timing: [
                                          ...curdaytiming[it.day][
                                            type
                                          ]?.timing?.slice(
                                            0,
                                            checkdataind < checkdata2ind
                                              ? checkdataind
                                              : checkdata2ind
                                          ),
                                          ...[
                                            {
                                              ...curdaytiming[it.day][type]
                                                ?.timing?.[
                                                checkdataind < checkdata2ind
                                                  ? checkdataind
                                                  : checkdata2ind
                                              ],
                                              ...{
                                                StartHour:
                                                  newstarttime.split(":")[0],
                                                StartMinute:
                                                  newstarttime.split(":")[1],
                                                StartTime: newstarttime,
                                                EndHour:
                                                  newendtime.split(":")[0],
                                                EndMinute:
                                                  newendtime.split(":")[1],
                                                EndTime: newendtime,
                                              },
                                            },
                                          ],
                                          ...curdaytiming[it.day][
                                            type
                                          ]?.timing?.slice(
                                            (checkdataind < checkdata2ind
                                              ? checkdataind
                                              : checkdata2ind) + 1,
                                            checkdataind > checkdata2ind
                                              ? checkdataind
                                              : checkdata2ind
                                          ),
                                          ...curdaytiming[it.day][
                                            type
                                          ]?.timing?.slice(
                                            (checkdataind > checkdata2ind
                                              ? checkdataind
                                              : checkdata2ind) + 1,
                                            curdaytiming[it.day][type]?.timing
                                              ?.length
                                          ),
                                        ],
                                      },
                                    },
                                  },
                                },
                              },
                            };
                          }
                          // else if()
                        }
                      });
                    });
                  }
                  if (curdaytiming[it.day][type]?.timing?.length == 0) {
                    curdaytiming = {
                      ...curdaytiming,
                      ...{
                        [it.day]: {
                          ...curdaytiming[it.day],
                          ...{
                            [type]: {
                              ...curdaytiming[it.day][type],
                              ...{
                                Status: false,
                              },
                            },
                          },
                        },
                      },
                    };
                  }
                  if (curdaytiming[it.day][type]?.timing?.length > 0) {
                    error[type] = true;
                  }
                }
              });
            });
            return { ...{ newdaytiming: curdaytiming }, ...error };
          }
        }
      }
    }
    return error;
  };

  const Submit = async () => {
    SetDisabledstatus({Submit:true});
    const toastid = toast.loading("Validating")
    let errorcheck = Validate();
    console.log("dkjsjkjsfsinnnn", errorcheck);
    if (isEmpty(errorcheck) || errorcheck?.Timing) {
      let senddata = {};
      if (location?.pathname == "/addseller" || activeTab == "profile") {
        senddata = formValue;
        senddata.action =
          location?.pathname == "/addseller" ? "register" : "profile_add";
        senddata.Type = "seller";
        senddata.CreatedBy = "admin";
      } else {
        if (newshopdata.Address?.City) {
          var address = `${newshopdata.Address.StreetName},${newshopdata.Address?.City},${newshopdata.Address?.Country},${newshopdata.Address?.State},${newshopdata.Address?.Zipcode}`;
          var latlng = await GetLatLngBasedOnAddress(address);
        }
        senddata = {
          action: "profile_add",
          ...newshopdata,
          SellerId: Datas?.profile?._id,
          ...{
            latlng,
            Delivery: errorcheck?.Delivery ? true : false,
            Pickup: errorcheck?.Pickup ? true : false,
          },
          ...{
            DayTimings: errorcheck.newdaytiming,
            AcceptingOrders: true,
            SubCategory: JSON.stringify(newshopdata.SubCategory),
          },
          ...(location?.pathname == "/addseller" ? { CreatedBy: "admin" } : {}),
        };
        if (senddata?.SellerCategory && senddata?.SellerCategory?.length > 0) {
          senddata.SellerCategory = JSON.stringify(senddata?.SellerCategory);
        }
      }
      // let senddata = formValue;
      // senddata.action = "register";
      // senddata.Type = "seller";
      // senddata.CreatedBy = "admin";
      let Resp = await axiosFile?.[
        location?.pathname == "/addseller" || activeTab == "profile"
          ? "SellerAction"
          : "ShopAction"
      ](senddata);
      console.log("skjdhsfsfs", Resp, senddata);
      if (Resp?.success == "success") {
        
        toast.update(toastid,{type:"success",render:Resp?.msg,isLoading:false,autoClose:1000})
        // toast.success(Resp?.msg, 1000);
        navigate("/managementvendors");
      } 
      else {
        toast.update(toastid,{type:"error",render:Resp?.msg,isLoading:false,autoClose:1000})
        // toast.error(Resp?.msg, 1000);
        SetError(Resp?.error);
      }
    } else {
      if (errorcheck?.Timing == false) {
        SetDisabledstatus({Submit:false})
        return toast.update(toastid,{type:"error",render:"Choose Time",isLoading:false,autoClose:1000})
        // return toast.error("Choose Time", 1000);
      }
      toast.update(toastid,{type:"error",render:"Fix all Validations",isLoading:false,autoClose:1000})
      // toast.error("Fix all Validations", 1000);
      SetError(errorcheck);
    }
    SetDisabledstatus({Submit:false})
  };

  const OnChangeTime = (e, index, day, type, timingtype) => {
    try {
      let setvalue = {};
      if (type == "checkbox") {
        setvalue = {
          ...SelectedTime,
          ...{
            [timingtype]: [
              ...SelectedTime?.[timingtype]?.slice(0, index),
              ...[
                {
                  ...SelectedTime?.[timingtype][index],
                  ...{
                    [day]: e?.target?.checked,
                  },
                },
              ],
              ...SelectedTime?.[timingtype]?.slice(
                index + 1,
                SelectedTime?.[timingtype]?.length
              ),
            ],
          },
        };
        // SetSelectedTime(setvalue)
      } 
      else if (type == "time") {
        e._d = new Date(e._d.setSeconds(0, 0));
        console.log(
          "lksjkjsfsfs",
          e._d,
          SelectedTime?.[timingtype]?.[index]?.StartTimeshow,
          SelectedTime?.[timingtype]?.[index]?.EndTimeshow
        );
        if (day == "EndTime") {
          if (SelectedTime?.[timingtype]?.[index]?.StartTimeshow >= e._d) {
            toast.error("Time Choosen Must be Greater than Start Time");
            SetSelectedTime({ ...SelectedTime });
            setDayTimings({ ...DayTimings });
            return false;
          }
        } else if (day == "StartTime") {
          if (SelectedTime?.[timingtype]?.[index]?.EndTimeshow <= e._d) {
            toast.error("Time Choosen Must be Less than End Time");
            SetSelectedTime({ ...SelectedTime });
            setDayTimings({ ...DayTimings });
            return false;
          }
        }
        setvalue = {
          ...SelectedTime,
          ...{
            [timingtype]: [
              ...SelectedTime?.[timingtype]?.slice(0, index),
              ...[
                {
                  ...SelectedTime?.[timingtype]?.[index],
                  ...{
                    [day]:
                      MinuteHourFormat(e._d.getHours()) +
                      ":" +
                      MinuteHourFormat(e._d.getMinutes()),
                    [day + "show"]: e._d,
                  },
                },
              ],
              ...SelectedTime?.[timingtype]?.slice(
                index + 1,
                SelectedTime?.[timingtype]?.length
              ),
            ],
          },
        };
      }
      let instdaytiming = DayTimings;
      let Time = {
        StartTime: HourMinutetoTime(
          setvalue?.[timingtype]?.[index]?.StartTime.split(":")[0],
          setvalue?.[timingtype]?.[index]?.StartTime.split(":")[1]
        ),
        EndTime: HourMinutetoTime(
          setvalue?.[timingtype]?.[index]?.EndTime.split(":")[0],
          setvalue?.[timingtype]?.[index]?.EndTime.split(":")[1]
        ),
      };

      if ((type == "checkbox" && e?.target?.checked) || type == "time") {
        Object.keys(setvalue?.[timingtype]?.[index])?.map((val) => {
          if (
            val != "StartTime" &&
            val != "StartTimeshow" &&
            val != "EndTime" &&
            val != "EndTimeshow"
          ) {
            if (DayTimings?.[val] && setvalue?.[timingtype]?.[index]?.[val]) {
              let curdaytimeind = DayTimings?.[val]?.[
                timingtype
              ]?.timing?.findIndex(
                (findindexval) =>
                  findindexval?.StartTime ==
                    SelectedTime?.[timingtype]?.[index]?.StartTime &&
                  findindexval?.EndTime ==
                    SelectedTime?.[timingtype]?.[index]?.EndTime
              );
              if (curdaytimeind != -1) {
                instdaytiming = {
                  ...instdaytiming,
                  ...{
                    [val]: {
                      ...instdaytiming?.[val],
                      ...{
                        [timingtype]: {
                          ...instdaytiming?.[val]?.[timingtype],
                          ...{
                            Status: true,
                            timing: [
                              ...instdaytiming?.[val]?.[
                                timingtype
                              ]?.timing?.slice(0, curdaytimeind),
                              ...[
                                {
                                  ...instdaytiming[val]?.[timingtype]?.timing?.[
                                    curdaytimeind
                                  ],
                                  ...{
                                    StartTime:
                                      setvalue?.[timingtype]?.[index]
                                        ?.StartTime,
                                    StartHour:
                                      setvalue?.[timingtype]?.[
                                        index
                                      ]?.StartTime?.split(":")[0],
                                    StartMinute:
                                      setvalue?.[timingtype]?.[
                                        index
                                      ]?.StartTime?.split(":")[1],
                                    EndTime:
                                      setvalue?.[timingtype]?.[index]?.EndTime,
                                    EndHour:
                                      setvalue?.[timingtype]?.[
                                        index
                                      ]?.EndTime?.split(":")[0],
                                    EndMinute:
                                      setvalue?.[timingtype]?.[
                                        index
                                      ]?.EndTime?.split(":")[1],
                                  },
                                },
                              ],
                              ...instdaytiming[val]?.[
                                timingtype
                              ]?.timing?.slice(
                                curdaytimeind + 1,
                                instdaytiming[val]?.[timingtype]?.timing?.length
                              ),
                            ],
                          },
                        },
                      },
                    },
                  },
                };
                Object.keys(instdaytiming?.[val])?.map((insttimingtype) => {
                  if (insttimingtype != "Timing" && insttimingtype != "day") {
                    if (
                      instdaytiming?.[val]?.[insttimingtype]?.Status == false ||
                      instdaytiming?.[val]?.[instdaytiming]?.timing?.length == 0
                    ) {
                      if (insttimingtype != "DeliveryTimeCharge") {
                        instdaytiming = {
                          ...instdaytiming,
                          ...{
                            [val]: {
                              ...instdaytiming[val],
                              ...{
                                [insttimingtype]: {
                                  ...instdaytiming[val][insttimingtype],
                                  ...{
                                    Status: true,
                                    AfterPreparation: [],
                                  },
                                },
                              },
                            },
                          },
                        };
                      } else {
                        instdaytiming = {
                          ...instdaytiming,
                          ...{
                            [val]: {
                              ...instdaytiming[val],
                              ...{
                                [insttimingtype]: {
                                  ...instdaytiming[val][insttimingtype],
                                  ...{
                                    StartHour: "",
                                    StartMinute: "",
                                    StartTime: "",
                                    EndHour: "",
                                    EndMinute: "",
                                    EndTime: "",
                                    Status: true,
                                    timing: [],
                                  },
                                },
                              },
                            },
                          },
                        };
                      }
                    }
                  }
                });
              } 
              else {
                instdaytiming = {
                  ...instdaytiming,
                  ...{
                    [val]: {
                      ...instdaytiming[val],
                      ...{
                        [timingtype]: {
                          ...instdaytiming[val][timingtype],
                          ...{
                            Status: true,
                            timing: [
                              ...instdaytiming[val]?.[timingtype]?.timing,
                              ...[
                                {
                                  StartTime:
                                    setvalue?.[timingtype]?.[index]?.StartTime,
                                  StartHour:
                                    setvalue?.[timingtype]?.[
                                      index
                                    ]?.StartTime?.split(":")[0],
                                  StartMinute:
                                    setvalue?.[timingtype]?.[
                                      index
                                    ]?.StartTime?.split(":")[1],
                                  EndTime:
                                    setvalue?.[timingtype]?.[index]?.EndTime,
                                  EndHour:
                                    setvalue?.[timingtype]?.[
                                      index
                                    ]?.EndTime?.split(":")[0],
                                  EndMinute:
                                    setvalue?.[timingtype]?.[
                                      index
                                    ]?.EndTime?.split(":")[1],
                                },
                              ],
                            ],
                          },
                        },
                      },
                    },
                  },
                };
              }
            }
          }
        });
      } else if (type == "checkbox" && !e?.target?.checked) {
        let timingindex = instdaytiming?.[day]?.[timingtype]?.timing?.findIndex(
          (daytimeindex) =>
            daytimeindex?.StartTime ==
              SelectedTime?.[timingtype]?.[index]?.StartTime &&
            daytimeindex?.EndTime ==
              SelectedTime?.[timingtype]?.[index]?.EndTime
        );
        if (timingindex != -1) {
          let newtiming = {
            StartHour: "",
            StartMinute: "",
            StartTime: "",
            StartTimeshow: "",
            EndHour: "",
            EndMinute: "",
            EndTime: "",
            EndTimeshow: "",
          };
          instdaytiming = {
            ...instdaytiming,
            ...{
              [day]: {
                ...instdaytiming[day],
                ...{
                  [timingtype]: {
                    ...instdaytiming[day][timingtype],
                    ...{
                      timing: [
                        ...instdaytiming[day]?.[timingtype]?.timing?.slice(
                          0,
                          timingindex
                        ),
                        ...instdaytiming[day]?.[timingtype]?.timing?.slice(
                          timingindex + 1,
                          instdaytiming[day]?.[timingtype]?.timing?.length
                        ),
                      ],
                    },
                  },
                },
              },
            },
          };
          if (timingtype == "Timing") {
            Object.keys(instdaytiming).map((days) => {
              if (
                instdaytiming?.[days]?.Timing?.Status &&
                instdaytiming?.[days]?.Timing?.timing?.length == 0
              ) {
                instdaytiming = {
                  ...instdaytiming,
                  ...{
                    [days]: {
                      ...instdaytiming[days],
                      ...{
                        [timingtype]: {
                          ...instdaytiming[days][timingtype],
                          ...{
                            StartHour: "",
                            StartMinute: "",
                            StartTime: "",
                            EndHour: "",
                            EndMinute: "",
                            EndTime: "",
                            Status: false,
                          },
                        },
                      },
                    },
                  },
                };
                Object.keys(instdaytiming?.[days])?.map((insttimingtype) => {
                  if (insttimingtype != "Timing" && insttimingtype != "day") {
                    if (
                      instdaytiming?.[days]?.[insttimingtype]?.Status ||
                      instdaytiming?.[days]?.[instdaytiming]?.timing?.length > 0
                    ) {
                      if (insttimingtype != "DeliveryTimeCharge") {
                        instdaytiming = {
                          ...instdaytiming,
                          ...{
                            [days]: {
                              ...instdaytiming[days],
                              ...{
                                [insttimingtype]: {
                                  ...instdaytiming[days][insttimingtype],
                                  ...{
                                    Status: false,
                                    AfterPreparation: [],
                                  },
                                },
                              },
                            },
                          },
                        };
                      } else {
                        instdaytiming = {
                          ...instdaytiming,
                          ...{
                            [days]: {
                              ...instdaytiming[days],
                              ...{
                                [insttimingtype]: {
                                  ...instdaytiming[days][insttimingtype],
                                  ...{
                                    StartHour: "",
                                    StartMinute: "",
                                    StartTime: "",
                                    EndHour: "",
                                    EndMinute: "",
                                    EndTime: "",
                                    Status: false,
                                    timing: [],
                                  },
                                },
                              },
                            },
                          },
                        };
                      }
                    }
                  }
                });
              }
            });
          }
        }
      }
      Object.keys(instdaytiming).map((days) => {
        if (instdaytiming[days][timingtype]?.Status) {
          let maxtime = GetMaxendMinstart(instdaytiming, days, timingtype);
          instdaytiming = {
            ...instdaytiming,
            ...{
              [days]: {
                ...instdaytiming[days],
                ...{
                  [timingtype]: {
                    ...instdaytiming[days][timingtype],
                    ...maxtime,
                  },
                },
              },
            },
          };
        }
      });

      SetSelectedTime({ ...setvalue });
      setDayTimings({ ...instdaytiming });
    } catch (err) {
      console.log("OnChangeTime Error", err);
    }
  };

  const HourMinutetoTime = (hour, minute, from) => {
    if (isEmpty(hour) && isEmpty(minute) && hour !== 0 && minute !== 0) {
      if (from == "end") {
        return new Date(new Date().setHours(0, 0));
      }
      else if(from == 'start'){
        return new Date(new Date().setHours(0, 0, 0));  
      }
      return new Date(new Date().setHours(23, 59, 59));
    }
    if (0 == Number(hour) && 0 == Number(minute)) {
      return new Date(new Date().setHours(0, 0, 0, 0));
    }
    return new Date(new Date().setHours((hour>=24)?23:hour, (hour>=24)?59:minute, (minute == 59 || hour>=24) ? 59 : 0));
  };

  const AddNewTiming = (type) => {
    SetSelectedTime({
      ...SelectedTime,
      ...{
        [type]: [
          ...SelectedTime?.[type],
          ...[
            {
              StartTime: "00:00",
              StartTimeshow: HourMinutetoTime(0, 0),
              EndTime: "24:00",
              EndTimeshow: HourMinutetoTime(23, 59),
            },
          ],
        ],
      },
    });
  };

  const RemoveTime = (index, type) => {
    let insttimings = DayTimings;
    Object.keys(SelectedTime?.[type]?.[index]).map((val) => {
      if (
        val != "EndTime" &&
        val != "EndTimeshow" &&
        val != "StartTime" &&
        val != "StartTimeshow"
      ) {
        let maxtime = {
          StartTime: "",
          StartTimeshow: "",
          StartHour: "",
          StartMinute: "",
          EndTime: "",
          EndTimeshow: "",
          EndHour: "",
          EndMinute: "",
        };
        let findtimingindex = insttimings?.[val]?.[type]?.timing?.findIndex(
          (findinst) =>
            findinst?.StartTime == SelectedTime?.[type]?.[index]?.StartTime &&
            findinst?.EndTime == SelectedTime?.[type]?.[index]?.EndTime
        );
        if (findtimingindex != -1) {
          insttimings = {
            ...insttimings,
            ...{
              [val]: {
                ...insttimings?.[val],
                ...{
                  [type]: {
                    ...insttimings?.[val]?.[type],
                    ...{
                      timing: [
                        ...insttimings?.[val]?.[type]?.timing?.slice(
                          0,
                          findtimingindex
                        ),
                        ...insttimings?.[val]?.[type]?.timing?.slice(
                          findtimingindex + 1,
                          insttimings?.[val]?.[type]?.timing?.length
                        ),
                      ],
                    },
                  },
                },
              },
            },
          };
        }
      }
    });
    setDayTimings({ ...insttimings });
    SetSelectedTime({
      ...SelectedTime,
      ...{
        [type]: [
          ...SelectedTime?.[type]?.slice(0, index),
          ...SelectedTime?.[type]?.slice(
            index + 1,
            SelectedTime?.[type]?.length
          ),
        ],
      },
    });
  };

  const CopyTimings = (fromtype, totype) => {
    let currdaytiming = DayTimings;
    let currselectedtime = SelectedTime;
    let display = "Copied to ";
    if (Array.isArray(totype)) {
      if (
        totype.every(
          (check) =>
            check == "Timing" || check == "Pickup" || check == "Delivery"
        ) &&
        (fromtype == "Timing" || fromtype == "Pickup" || fromtype == "Delivery")
      ) {
        totype.map((type, ind) => {
          Object.keys(currdaytiming).map((days) => {
            currdaytiming[days][type] = currdaytiming[days][fromtype];
          });
          currselectedtime[type] = currselectedtime[fromtype];
          display =
            display +
            (ind == totype.length - 1 ? type + " Successfully." : type + " ,");
        });
      } else {
        console.log("fromtype,totype invalid");
        return false;
      }
    } else {
      if (
        (fromtype == "Timing" ||
          fromtype == "Pickup" ||
          fromtype == "Delivery") &&
        (totype == "Timing" || totype == "Pickup" || totype == "Delivery")
      ) {
        Object.keys(currdaytiming).map((days) => {
          currdaytiming[days][totype] = currdaytiming[days][fromtype];
        });
        currselectedtime[totype] = currselectedtime[fromtype];
        display = display + totype + " Successfully.";
      } else {
        console.log("fromtype,totype invalid");
        return false;
      }
    }
    toast.success(display, 1000);
    setDayTimings({ ...currdaytiming });
    SetSelectedTime({ ...currselectedtime });
  };

  const GetMaxendMinstart = (instdaytiming, days, timingtype) => {
    let minstart = "";
    let maxend = "";
    instdaytiming?.[days]?.[timingtype]?.timing?.map((timings) => {
      if (
        minstart == "" ||
        HourMinutetoTime(timings?.StartHour, timings?.StartMinute) <
          HourMinutetoTime(minstart.split(":")[0], minstart.split(":")[1])
      ) {
        minstart = timings?.StartTime;
      }
      if (
        maxend == "" ||
        HourMinutetoTime(timings?.EndHour, timings?.EndMinute) <
          HourMinutetoTime(maxend.split(":")[0], maxend.split(":")[1])
      ) {
        maxend = timings?.EndTime;
      }
    });
    return {
      StartHour: minstart ? minstart.split(":")[0] : "",
      StartMinute: minstart ? minstart.split(":")[1] : "",
      StartTime: minstart,
      EndHour: maxend ? maxend.split(":")[0] : "",
      EndMinute: maxend ? maxend.split(":")[1] : "",
      EndTime: maxend,
    };
  };

  console.log("slfjsjfksjf", SelectedShop, formValue, Datas);

  useEffect(() => {
    console.log("++++++++++++++", pathname, payload?._id);
    if (id && payload?._id) {
      SetNewShopData({
        ...newshopdata,
        ...{ SellerId: formValue },
      });
      getcat();
    }
  }, [id, newshopdata?.Category]);

  useEffect(() => {
    getcat();
  }, [newshopdata?.Category]);

  const getcat = async () => {
    var resp = await axiosFile.getcatList({ from: "seller" });
    console.log("bdhsbdh", resp, SelectedShop);
    setsubcat(resp?.data);
    setSubCategory(
      resp?.data?.filter((it) => it?.value == newshopdata?.Category).length > 0
        ? resp?.data?.filter((it) => it?.value == newshopdata?.Category)?.[0]
            ?.SubCategory
        : []
    );
  };

  const AddShopClick = () => {
    SetShopTab("addshop");
    SetDatas({ ...Datas, ...{ shop: {} } });
    SetSelectedShop({});
    SetNewShopData({});
    setDayTimings({ ...initdaytiming });
    SetSelectedTime({ Timing: [], Pickup: [], Delivery: [] });
  };

  const DisableCondition = () => {
    // return (shoptab == 'addshop' || Datas?.profile?.CreatedBy == 'admin') ? false : true
    return false;
  };

  return (
    <>
      <div>
        <div className="innerpages">
          <div className="orders_pages">
            <Headers />
            <div className="container custom_container px-lg-0">
              <div className="row mx-auto">
                <div className="innerpages_wid px-0">
                  <Sidebar />
                  <div
                    className={
                      toggle
                        ? "content-sec-inner right_widthset content_without_sidebar"
                        : "content-sec-inner  content_with_sidebar"
                    }
                  >
                    <section id="content-wrapper">
                      <div class="row">
                        <div className="top_title_sec">
                          <div>
                            <p className="page_title">{formValue?.FirstName}</p>
                          </div>
                          {/* <div className='rightsec'>
        <p>Accepting Orders
        <div className='togswit'>
  <input type="checkbox" id="toggle-btn"/>
  <label for="toggle-btn"></label>
</div>
        </p>
      </div> */}
                        </div>
                      </div>
                      {/* profile details */}
                      <div className=" mt-4">
                        <div className="mdvsec_border sec_border">
                          <div className="row mdv_driverdtls_whole">
                            <div className="col-xxl-4 col-xl-4 col-lg-3 col-md-3 col-sm-12 col-12">
                              <div className="mdv_driverdtls">
                                <img
                                  className="tab_dummyimg me-3"
                                  src={
                                    Datas?.[activeTab]?.BrandImage
                                      ? `${config.IMG_URL}/shop/${Datas?.[activeTab]?.BrandImage}`
                                      : Datas?.[activeTab]?.Profile
                                      ? `${config.IMG_URL}/user/${Datas?.[activeTab]?._id}/profile/${Datas?.[activeTab]?.Profile}`
                                      : typeof formValue?.Profile !==
                                          "string" && formValue?.Profile
                                      ? URL.createObjectURL(formValue?.Profile)
                                      : typeof newshopdata?.BrandImage !==
                                          "string" && newshopdata?.BrandImage
                                      ? URL.createObjectURL(
                                          newshopdata?.BrandImage
                                        )
                                      : require("../../../assests/images/dummyimg.png")
                                  }
                                />
                                <div>
                                  <p className="mdv_drivername">
                                    {Datas?.[activeTab]?.FirstName
                                      ? Datas?.[activeTab]?.FirstName
                                      : Datas?.[activeTab]?.BrandName}
                                  </p>
                                  {/* <div className='mdv_locationtext'>
                <img className='mdv_location' src={require('../../../assests/images/location.png')}/>
                <p>Pasadena</p>
                </div> */}
                                </div>
                              </div>
                            </div>

                            {/* <div className='col-xxl-8 col-xl-8 col-lg-9 col-md-9 col-sm-12 col-12'>
        {(activeTab == "rating" || activeTab == "catelog" || activeTab == "operationtime" || activeTab == "rating" || activeTab == "accountsettings") ? 
            <div className='rightside'>
            <div className='whole_searchbar white_bg'>
   <img className='search_glass' src={require('../../../assests/images/Search.png')}/>
  <input placeholder="Search..."/>
   </div>
            </div>:<></>}

            {activeTab == "discount" ? 
            <div className='rightside'>
            <div className='whole_searchbar white_bg'>
   <img className='search_glass' src={require('../../../assests/images/Search.png')}/>
  <input placeholder="Search..."/>
   </div>
   <div className='rightside_select'>
        <Select
                            options={driveroptions}
                            defaultValue={selectedOption}
                            styles={stylesgraybg}
                            onChange={setSelectedOption}
                            className="border_blue_select"
                            classNamePrefix="react-select"
                            placeholder='Status'
                       
                          />
                          </div>
                          <button type="button" className='blue_btn add_deliveryprsn'>
      Create a discount
     </button>
            </div>:<></>}

            {activeTab == "profile" ? 
        <div className='rightside'>
        <div className='whole_searchbar white_bg'>
   <img className='search_glass' src={require('../../../assests/images/Search.png')}/>
  <input placeholder="Search..."/>
   </div>
        <div className='rightside_select'>
        <Select
                            options={driveroptions}
                            defaultValue={selectedOption}
                            styles={stylegreendrop}
                            onChange={setSelectedOption}
                            className="border_blue_select"
                            classNamePrefix="react-select"
                            placeholder='Status'
                       
                          />
                          </div>


        </div> : <></>}

        </div> */}
                          </div>
                          <div className="mdv_drivertab jus_start">
                            <p
                              className={
                                activeTab == "profile"
                                  ? "mdv_tabtext active"
                                  : "mdv_tabtext"
                              }
                              onClick={() => setActiveTab("profile")}
                            >
                              Profile
                            </p>
                            {location?.pathname != "/addseller" && (
                              <p
                                className={
                                  activeTab == "shop"
                                    ? "mdv_tabtext active"
                                    : "mdv_tabtext"
                                }
                                onClick={() => setActiveTab("shop")}
                              >
                                Shops
                              </p>
                            )}
                            {/* <p className={activeTab == "catelog" ? 'mdv_tabtext active' : 'mdv_tabtext'} onClick={() => setActiveTab("catelog")}>Catelogs</p>
        <p className={activeTab == "operationtime" ? 'mdv_tabtext active' : 'mdv_tabtext'} onClick={() => setActiveTab("operationtime")}>Operation Time</p>
        <p className={activeTab == "rating" ? 'mdv_tabtext active' : 'mdv_tabtext'} onClick={() => setActiveTab("rating")}>Ratings & Reviews</p>
        <p className={activeTab == "discount" ? 'mdv_tabtext active' : 'mdv_tabtext'} onClick={() => setActiveTab("discount")}>Discount</p>
        <p className={activeTab == "accountsettings" ? 'mdv_tabtext active' : 'mdv_tabtext'} onClick={() => setActiveTab("accountsettings")}>Account Settings</p> */}
                          </div>
                        </div>

                        {/* profile details */}
                        {activeTab == "profile" && (
                          <>
                            <div className="mdv_profiledtls">
                              <div className="mdv_border_div">
                                <p className="mdv_profilettl">
                                  Personal Information
                                </p>
                              </div>
                              {console.log("dsfdsasfa", formValue)}
                              <Row className="mdv_inputrow">
                                <Col
                                  xxl={4}
                                  xl={4}
                                  lg={4}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <p className="mdv_inplabel">First Name</p>
                                  <input
                                    className="mdv_cmninput"
                                    type="text"
                                    id="FirstName"
                                    placeholder=""
                                    value={formValue?.FirstName}
                                    disabled={
                                      location?.pathname == "/addseller" ||
                                      formValue?.CreatedBy == "admin"
                                        ? false
                                        : true
                                    }
                                    onChange={(e) => OnChange(e)}
                                  />
                                  {Error?.FirstName && (
                                    <span className="error-msg">
                                      {Error?.FirstName}
                                    </span>
                                  )}
                                </Col>
                                <Col
                                  xxl={4}
                                  xl={4}
                                  lg={4}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <p className="mdv_inplabel">Last Name</p>
                                  <input
                                    className="mdv_cmninput"
                                    type="text"
                                    id="LastName"
                                    placeholder=""
                                    value={formValue?.LastName}
                                    disabled={
                                      location?.pathname == "/addseller" ||
                                      formValue?.CreatedBy == "admin"
                                        ? false
                                        : true
                                    }
                                    onChange={(e) => OnChange(e)}
                                  />
                                  {Error?.LastName && (
                                    <span className="error-msg">
                                      {Error?.LastName}
                                    </span>
                                  )}
                                </Col>
                                <Col
                                  xxl={4}
                                  xl={4}
                                  lg={4}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <p className="mdv_inplabel">Email Address</p>
                                  <input
                                    className="mdv_cmninput"
                                    type="email"
                                    id="EmailId"
                                    placeholder=""
                                    value={formValue?.EmailId}
                                    disabled={
                                      location?.pathname == "/addseller" ||
                                      formValue?.CreatedBy == "admin"
                                        ? false
                                        : true
                                    }
                                    onChange={(e) => OnChange(e)}
                                  />
                                  {Error?.EmailId && (
                                    <span className="error-msg">
                                      {Error?.EmailId}
                                    </span>
                                  )}
                                </Col>

                                <Col
                                  xxl={4}
                                  xl={4}
                                  lg={4}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="mb-3"
                                >
                                  <p className="mdv_inplabel">Phone Number</p>
                                  <PhoneInput
                                    className="new_rephoneinp"
                                    placeholder="Enter phone number"
                                    id="Mobile"
                                    onChange={(e) => OnChange(e, "Mobile")}
                                    value={formValue?.Mobile}
                                    defaultCountry="US"
                                    country="US"
                                    countries={["US","IN"]}
                                  />
                                  {/* <input className='mdv_cmninput' type="text" id="Mobile" placeholder="" value={formValue?.Mobile} disabled={location?.pathname == '/addseller' ? false : true} onChange={(e)=>OnChange(e)}/> */}
                                  {Error?.Mobile && (
                                    <span className="error-msg">
                                      {Error?.Mobile}
                                    </span>
                                  )}
                                </Col>

                                {(formValue?.DateofBirth ||
                                  location?.pathname == "/addseller") && (
                                  <Col
                                    xxl={4}
                                    xl={4}
                                    lg={4}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="mb-3"
                                  >
                                    <p className="mdv_inplabel">
                                      Date Of Birth
                                    </p>
                                    <input
                                      className="mdv_cmninput"
                                      type={
                                        location?.pathname == "/addseller" ||
                                        formValue?.CreatedBy == "admin"
                                          ? "date"
                                          : "text"
                                      }
                                      id="DateofBirth"
                                      placeholder=""
                                      value={formValue?.DateofBirth}
                                      disabled={
                                        location?.pathname == "/addseller" ||
                                        formValue?.CreatedBy == "admin"
                                          ? false
                                          : true
                                      }
                                      onChange={(e) => OnChange(e)}
                                    />
                                    {Error?.DateofBirth && (
                                      <span className="error-msg">
                                        {Error?.DateofBirth}
                                      </span>
                                    )}
                                  </Col>
                                )}
                                {(location?.pathname == "/addseller" ||
                                  formValue?.CreatedBy == "admin") && (
                                  <Col
                                    xxl={4}
                                    xl={4}
                                    lg={4}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="mb-3"
                                  >
                                    <p className="mdv_inplabel">
                                      {formValue?.CreatedBy == "admin"
                                        ? "Enter Password (Only if Want To Update)"
                                        : "Enter Password"}
                                    </p>
                                    <div className="reg_passwordinps">
                                      <input
                                        className="mdv_cmninput"
                                        type={
                                          passwordText == "password"
                                            ? "password"
                                            : "text"
                                        }
                                        id="Password"
                                        placeholder=""
                                        disabled={
                                          location?.pathname == "/addseller" ||
                                          formValue?.CreatedBy == "admin"
                                            ? false
                                            : true
                                        }
                                        onChange={(e) => OnChange(e)}
                                      />
                                      <i
                                        onClick={() =>
                                          setPasswordText(
                                            passwordText == "text"
                                              ? "password"
                                              : "text"
                                          )
                                        }
                                        className={
                                          passwordText == "password"
                                            ? "fa-solid fa-eye-slash"
                                            : "fa-solid fa-eye"
                                        }
                                      ></i>
                                    </div>
                                    {Error?.Password && (
                                      <span className="error-msg">
                                        {Error?.Password}
                                      </span>
                                    )}
                                  </Col>
                                )}
                                {(formValue?.Gender ||
                                  location?.pathname == "/addseller") && (
                                  <Col
                                    xxl={4}
                                    xl={4}
                                    lg={4}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="mb-3"
                                  >
                                    <p className="mdv_inplabel">Gender</p>
                                    {location?.pathname == "/addseller" ||
                                    formValue?.CreatedBy == "admin" ? (
                                      <Select
                                        options={gender}
                                        defaultValue={selectedOption}
                                        value={{
                                          label: formValue?.Gender,
                                          value: formValue?.Gender,
                                        }}
                                        styles={stylesgraybg}
                                        className="border_blue_select"
                                        classNamePrefix="react-select"
                                        placeholder="Select Gender"
                                        onChange={(e) => OnChange(e, "Gender")}
                                      />
                                    ) : (
                                      <input
                                        className="mdv_cmninput"
                                        type="text"
                                        id="Gender"
                                        placeholder=""
                                        value={formValue?.Gender}
                                        disabled={
                                          location?.pathname == "/addseller" ||
                                          formValue?.CreatedBy == "admin"
                                            ? false
                                            : true
                                        }
                                      />
                                    )}
                                    {Error?.Gender && (
                                      <span className="error-msg">
                                        {Error?.Gender}
                                      </span>
                                    )}
                                  </Col>
                                )}
                                {location?.pathname == "/addseller" && (
                                  <Col
                                    xxl={4}
                                    xl={4}
                                    lg={4}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="mb-3"
                                  >
                                    <p className="mdv_inplabel">
                                      Choose Profile
                                    </p>
                                    <input
                                      className="mdv_cmninput"
                                      type="file"
                                      id="Profile"
                                      placeholder=""
                                      disabled={
                                        location?.pathname == "/addseller" ||
                                        formValue?.CreatedBy == "admin"
                                          ? false
                                          : true
                                      }
                                      onChange={(e) => FileChange(e)}
                                    />
                                    {Error?.Profile && (
                                      <span className="error-msg">
                                        {Error?.Profile}
                                      </span>
                                    )}
                                  </Col>
                                )}
                              </Row>
                              {(location?.pathname == "/addseller" ||
                                formValue?.CreatedBy == "admin") && (
                                <div className="mdv_update_dualbtn">
                                  <button
                                    className="blue_btn mdv_update"
                                    type="button"
                                    onClick={() => Submit()}
                                  >
                                    {location?.pathname == "/addseller"
                                      ? "Submit"
                                      : "Update"}
                                  </button>
                                </div>
                              )}
                            </div>

                            {/* })} */}
                            {/* <div className='mdv_update_dualbtn mt-3'>
              <button className='blue_btn mdv_update'>Update</button>
              <button className='seconday_btn'>Cacnel</button>
            </div> */}
                          </>
                        )}
                      </div>

                      {/* end of profile details */}

                      {/* delivered details */}
                      {activeTab == "catelog" && (
                        <>
                          <Accordion
                            className="mvv_accordion mt-3"
                            defaultActiveKey="0"
                            flush
                          >
                            <Accordion.Item className="mb-3" eventKey="0">
                              <Accordion.Header>Sandwich(10)</Accordion.Header>
                              <Accordion.Body>
                                <div className="ffd_table">
                                  <BootstrapTable
                                    bootstrap4
                                    keyField="id"
                                    columns={columns}
                                    data={data}
                                  />
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item className="mb-3" eventKey="1">
                              <Accordion.Header>Pizza(16)</Accordion.Header>
                              <Accordion.Body>
                                <div className="ffd_table">
                                  <BootstrapTable
                                    bootstrap4
                                    keyField="id"
                                    columns={columns}
                                    data={data}
                                  />
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item className="mb-3" eventKey="2">
                              <Accordion.Header>Burger(10)</Accordion.Header>
                              <Accordion.Body>
                                <div className="ffd_table">
                                  <BootstrapTable
                                    bootstrap4
                                    keyField="id"
                                    columns={columns}
                                    data={data}
                                  />
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>

                          {/* <div className='mdvsec_border '>

      <div className='mdv_driverdtls_whole'>
          
          </div>location?.pathname == '/addseller' || formValue?.CreatedBy == 'admin'
          </div> */}
                        </>
                      )}
                      {/* end of delivered details */}

                      {/* Shops */}

                      {activeTab.includes("shop") && (
                        <>
                          <div className="mdv_profiledtls">
                            <div className="mdv_border_div">
                              <p className="mdv_profilettl">Shop List</p>
                            </div>
                            <Row className="mdv_inputrow">
                              <Col
                                xxl={4}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                className="mb-3"
                              >
                                <Select
                                  options={ShopList}
                                  value={SelectedShop}
                                  onChange={(e) => {
                                    getvendorview(e);
                                  }}
                                  isSearchable={false}
                                  styles={stylesgraybg}
                                  // menuIsOpen={true}
                                />

                                {/* {
              formValue?.CreatedBy == "admin" && */}
                                <button
                                  type="button"
                                  className="blue_btn mangra_addtime mangra_addtime_new"
                                  onClick={() => AddShopClick()}
                                >
                                  Add Shop
                                </button>
                                {/* } */}
                              </Col>
                            </Row>
                          </div>
                          {console.log(
                            "dskhjfhskhfskfs",
                            newshopdata,
                            Categorys
                          )}
                          {ShopList?.length > 0 || shoptab == "addshop" ? (
                            <>
                              <div className="mdv_profiledtls">
                                <div className="mdv_border_div">
                                  <p className="mdv_profilettl">
                                    Shop Information
                                  </p>
                                </div>
                                {console.log("dsfdsasfa", formValue)}
                                <Row className="mdv_inputrow">
                                  <Col
                                    xxl={4}
                                    xl={4}
                                    lg={4}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="mb-3"
                                  >
                                    <p className="mdv_inplabel">
                                      Shop/Brand Name
                                    </p>
                                    <input
                                      className="mdv_cmninput"
                                      type="text"
                                      id="BrandName"
                                      placeholder=""
                                      value={
                                        newshopdata?.BrandName ||
                                        Datas?.shop?.BrandName ||
                                        ""
                                      }
                                      disabled={DisableCondition()}
                                      onChange={(e) => ShopOnChange(e)}
                                    />
                                    {Error?.BrandName && (
                                      <span className="error-msg">
                                        {Error?.BrandName}
                                      </span>
                                    )}
                                  </Col>
                                  {/* <Col xxl={4} xl={4} lg={4} md={6} sm={6} xs={12} className="mb-3">
                <p className='mdv_inplabel'>Last Name</p>
                <input className='mdv_cmninput' type="text" id="LastName" placeholder="Doe" value={formValue?.LastName} disabled={true}/>
            </Col> */}
                                  <Col
                                    xxl={4}
                                    xl={4}
                                    lg={4}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="mb-3"
                                  >
                                    <p className="mdv_inplabel">
                                      Brand Description
                                    </p>
                                    <input
                                      className="mdv_cmninput"
                                      type="text"
                                      id="BrandDescription"
                                      placeholder=""
                                      value={
                                        newshopdata?.BrandDescription ||
                                        Datas?.shop?.BrandDescription ||
                                        ""
                                      }
                                      disabled={DisableCondition()}
                                      onChange={(e) => ShopOnChange(e)}
                                    />
                                    {Error?.BrandDescription && (
                                      <span className="error-msg">
                                        {Error?.BrandDescription}
                                      </span>
                                    )}
                                  </Col>
                                  <Col
                                    xxl={4}
                                    xl={4}
                                    lg={4}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="mb-3"
                                  >
                                    <p className="mdv_inplabel">
                                      Email Address
                                    </p>
                                    <input
                                      className="mdv_cmninput"
                                      type="email"
                                      id="EmailId"
                                      placeholder=""
                                      value={
                                        newshopdata?.EmailId ||
                                        Datas?.shop?.EmailId ||
                                        ""
                                      }
                                      disabled={DisableCondition()}
                                      onChange={(e) => ShopOnChange(e)}
                                    />
                                    {Error?.EmailId && (
                                      <span className="error-msg">
                                        {Error?.EmailId}
                                      </span>
                                    )}
                                  </Col>

                                  <Col
                                    xxl={4}
                                    xl={4}
                                    lg={4}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="mb-3"
                                  >
                                    <p className="mdv_inplabel">Phone Number</p>
                                    {/* <input className='mdv_cmninput' type="number" id="Mobile" placeholder="" value={newshopdata?.Mobile||Datas?.shop?.Mobile} disabled={activeTab == 'addshop' ? false : true} onChange={(e)=>ShopOnChange(e)}/> */}
                                    <PhoneInput
                                      className="new_rephoneinp"
                                      placeholder="Enter phone number"
                                      id="Mobile"
                                      onChange={(e) =>
                                        ShopOnChange(e, "Mobile")
                                      }
                                      value={
                                        newshopdata?.Mobile ||
                                        Datas?.shop?.Mobile
                                      }
                                      disabled={DisableCondition()}
                                      country="US"
                                      defaultCountry="US"
                                      countries={["US","IN"]}
                                    />
                                    {Error?.Mobile && (
                                      <span className="error-msg">
                                        {Error?.Mobile}
                                      </span>
                                    )}
                                  </Col>
                                  <Col
                                    xxl={4}
                                    xl={4}
                                    lg={4}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="mb-3"
                                  >
                                    <p className="mdv_inplabel signin_labels">
                                      Category
                                    </p>
                                    {/* <input className='mdv_cmninput' autoComplete="off" type="email" placeholder="john@jamkse.com"/> */}
                                    {/* <label className='signin_labels' for="mobile"></label> */}
                                    {!isEmpty(Categorys) &&
                                      (id ? (
                                        <Select
                                          options={Categorys}
                                          value={{
                                            label:
                                              (Categorys || [])?.filter(
                                                (it) =>
                                                  it?.value ==
                                                  newshopdata?.Category
                                              )?.length > 0
                                                ? Categorys.filter(
                                                    (it) =>
                                                      it?.id ==
                                                      newshopdata?.Category
                                                  )[0]?.label
                                                : newshopdata?.Category,
                                            value: newshopdata?.Category,
                                          }}
                                          styles={stylesgraybg}
                                          onChange={(e) =>
                                            ShopOnChange(e, "Category")
                                          }
                                          className="border_blue_select"
                                          classNamePrefix="react-select"
                                          placeholder="Category"
                                        />
                                      ) : (
                                        <Select
                                          options={Categorys}
                                          styles={stylesgraybg}
                                          onChange={(e) =>
                                            ShopOnChange(e, "Category")
                                          }
                                          className="border_blue_select"
                                          classNamePrefix="react-select"
                                          placeholder="Category"
                                        />
                                      ))}

                                    {Error?.Category && (
                                      <span className="error-msg">
                                        {Error.Category}
                                      </span>
                                    )}
                                  </Col>
                                  <Col
                                    xxl={4}
                                    xl={4}
                                    lg={4}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="mb-3"
                                  >
                                    <p className="mdv_inplabel">Sub Category</p>
                                    {/* <input className='mdv_cmninput' autoComplete="off" type="email" placeholder="john@jamkse.com"/> */}
                                    {!isEmpty(newshopdata?.Category) &&
                                      (id ? (
                                        <Select
                                          isMulti
                                          closeMenuOnSelect={false}
                                          options={SubCategorys}
                                          value={
                                            SubCategorys?.length > 0 ?
                                            (newshopdata?.SubCategory || [])?.map((it) => {
                                            return {
                                              label:
                                                (SubCategorys || [])?.filter(
                                                  (its) => it == its?.value
                                                )?.length > 0
                                                  ? SubCategorys?.filter(
                                                      (its) => it == its?.value
                                                    )?.[0]?.label
                                                  : it,
                                              value: it,
                                            };
                                          })
                                          :
                                          {
                                            label: "All",
                                            value: "All",
                                          }
                                        }
                                          styles={stylesgraybg}
                                          onChange={(e) =>
                                            ShopOnChange(
                                              { value: e.map((e) => e.value) },
                                              "SubCategory"
                                            )
                                          }
                                          className="border_blue_select react_multi_slct"
                                          classNamePrefix="react-select"
                                          placeholder="SubCategory"
                                          isDisabled={SubCategorys?.length>0?false:true}
                                        />
                                      ) : (
                                        <Select
                                          isMulti
                                          options={SubCategorys}
                                          value={
                                            SubCategorys?.length > 0 ?
                                            (newshopdata.SubCategory || [])?.map((it) => {
                                            return {
                                              label: (
                                                SubCategorys || []
                                              )?.filter(
                                                (its) => it == its?.value
                                              )?.[0]?.label,
                                              value: it,
                                            }})
                                            :
                                            {
                                              label: "All",
                                              value: "All",
                                            }
                                          }
                                          styles={stylesgraybg}
                                          onChange={(e) =>
                                            ShopOnChange(
                                              { value: e.map((e) => e.value) },
                                              "SubCategory"
                                            )
                                          }
                                          className="border_blue_select"
                                          classNamePrefix="react-select"
                                          placeholder="SubCategory"
                                          isDisabled={SubCategorys?.length>0?false:true}
                                        />
                                      ))}

                                    {Error?.SubCategory && (
                                      <span className="error-msg">
                                        {Error.SubCategory}
                                      </span>
                                    )}
                                  </Col>
                                  {/* {(shoptab == 'addshop' || Datas?.profile?.CreatedBy == 'admin') && */}
                                  <Col
                                    xxl={12}
                                    xl={12}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    className="mb-3"
                                  >
                                    {/* <div className='mdv_profiledtls'> */}
                                    {/* <div className='mdv_border_div'> */}
                                    <p className="mdv_profilettl">Shop Image</p>
                                    {/* </div> */}
                                    <div className="mvv_griddtls">
                                      <div className="mvv_gridsingle">
                                        <div className="mvv_uploadfile_dtl">
                                          <img
                                            className="img-fluid"
                                            src={require("../../../assests/images/cloud.png")}
                                          />
                                          <p className="mvv_cloudtext">
                                            Upload File
                                          </p>
                                          <input
                                            type="file"
                                            className="mvv_fileupload"
                                            id="BrandImage"
                                            onChange={(e) =>
                                              ShopOnChange(e, "BrandImage")
                                            }
                                            multiple
                                          />
                                          <span className="error-msg">
                                            {/* {Error?.StoreImages} */}
                                          </span>
                                        </div>
                                      </div>

                                      <div className="mvv_gridsingle">
                                        {typeof newshopdata?.BrandImage !=
                                          "string" &&
                                          newshopdata?.BrandImage && (
                                            <img
                                              className="mvv_shopimg"
                                              src={URL.createObjectURL(
                                                newshopdata?.BrandImage
                                              )}
                                            />
                                          )}
                                      </div>
                                    </div>
                                    {Error?.EmailId && (
                                      <span className="error-msg">
                                        {Error?.EmailId}
                                      </span>
                                    )}
                                    {/* </div> */}
                                  </Col>
                                  {/* <Col xxl={4} xl={4} lg={4} md={6} sm={6} xs={12} className="mb-3">
                <p className='mdv_inplabel'>Date Of Birth</p>
                <input className='mdv_cmninput' type="text"  id="DateofBirth" placeholder="Doe" value={formValue?.DateofBirth} disabled={true}/>
            </Col>
            
            <Col xxl={4} xl={4} lg={4} md={6} sm={6} xs={12} className="mb-3">
                <p className='mdv_inplabel'>Gender</p>
                <input className='mdv_cmninput' type="text"  id="DateofBirth" placeholder="Doe" value={formValue?.Gender} disabled={true}/>
            </Col> */}
                                </Row>
                              </div>
                              {console.log("dkjsjkjsfs", Error)}
                              <div className="mdv_profiledtls">
                                <div className="mdv_border_div">
                                  <p className="mdv_profilettl">Address</p>
                                </div>
                                <Row className="mdv_inputrow">
                                  {/* <Col xxl={4} xl={4} lg={4} md={6} sm={6} xs={12} className="mb-3">
                <p className='mdv_inplabel'>Street Number</p>
                <input className='mdv_cmninput' type="number" id='StreetNumber' name='Address' placeholder="" value={newshopdata?.Address?.StreetNumber||Datas?.shop?.Address?.StreetNumber} disabled={activeTab == 'addshop' ? false : true} onChange={(e)=>ShopOnChange(e)}/>
                { Error?.StreetNumber && <span className='error-msg'>{Error?.StreetNumber}</span>}
            </Col> */}
                                  <Col
                                    xxl={4}
                                    xl={4}
                                    lg={4}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="mb-3"
                                  >
                                    <p className="mdv_inplabel">Street Name</p>
                                    <input
                                      className="mdv_cmninput"
                                      type="text"
                                      placeholder=""
                                      id="StreetName"
                                      name="Address"
                                      value={
                                        newshopdata?.Address?.StreetName ||
                                        Datas?.shop?.Address?.StreetName ||
                                        ""
                                      }
                                      disabled={DisableCondition()}
                                      onChange={(e) => ShopOnChange(e)}
                                    />
                                    {Error?.StreetName && (
                                      <span className="error-msg">
                                        {Error?.StreetName}
                                      </span>
                                    )}
                                  </Col>
                                  {/* <Col xxl={4} xl={4} lg={4} md={6} sm={6} xs={12} className="mb-3">
                <p className='mdv_inplabel'>Unit</p>
                <input className='mdv_cmninput' type="text" placeholder="" id='Unit' name='Address' value={newshopdata?.Address?.Unit||Datas?.shop?.Address?.Unit} disabled={activeTab == 'addshop' ? false : true} onChange={(e)=>ShopOnChange(e)}/>
                { Error?.Unit && <span className='error-msg'>{Error?.Unit}</span>}
            </Col> */}

                                  <Col
                                    xxl={4}
                                    xl={4}
                                    lg={4}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="mb-3"
                                  >
                                    <p className="mdv_inplabel">City</p>
                                    <input
                                      className="mdv_cmninput"
                                      type="text"
                                      placeholder=""
                                      id="City"
                                      name="Address"
                                      value={
                                        newshopdata?.Address?.City ||
                                        Datas?.shop?.Address?.City ||
                                        ""
                                      }
                                      disabled={DisableCondition()}
                                      onChange={(e) => ShopOnChange(e)}
                                    />
                                    {/* <input className='mdv_cmninput' type="number" placeholder="+1 515 516 0624"/> */}
                                    {/* <Select
                              options={gender}
                              defaultValue={selectedOption}
                              styles={stylesgraybg}
                              onChange={setSelectedOption}
                              className="border_blue_select"
                              classNamePrefix="react-select"
                              placeholder='Manhattan'
                         
                            /> */}
                                    {Error?.City && (
                                      <span className="error-msg">
                                        {Error?.City}
                                      </span>
                                    )}
                                  </Col>
                                  <Col
                                    xxl={4}
                                    xl={4}
                                    lg={4}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="mb-3"
                                  >
                                    <p className="mdv_inplabel">State</p>
                                    {/* <input className='mdv_cmninput' type="text" placeholder="Doe"/> */}
                                    <input
                                      className="mdv_cmninput"
                                      type="text"
                                      placeholder=""
                                      id="State"
                                      name="Address"
                                      value={
                                        newshopdata?.Address?.State ||
                                        Datas?.shop?.Address?.State ||
                                        ""
                                      }
                                      disabled={DisableCondition()}
                                      onChange={(e) => ShopOnChange(e)}
                                    />
                                    {/* <Select
                              options={gender}
                              defaultValue={selectedOption}
                              styles={stylesgraybg}
                              onChange={setSelectedOption}
                              className="border_blue_select"
                              classNamePrefix="react-select"
                              placeholder='New York'
                         
                            /> */}
                                    {Error?.State && (
                                      <span className="error-msg">
                                        {Error?.State}
                                      </span>
                                    )}
                                  </Col>
                                  <Col
                                    xxl={4}
                                    xl={4}
                                    lg={4}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="mb-3"
                                  >
                                    <p className="mdv_inplabel">Country</p>
                                    {/* <input className='mdv_cmninput' type="text" placeholder="john@jamkse.com"/> */}

                                    <input
                                      className="mdv_cmninput"
                                      type="text"
                                      placeholder=""
                                      id="Country"
                                      name="Address"
                                      value={
                                        newshopdata?.Address?.Country ||
                                        Datas?.shop?.Address?.Country ||
                                        ""
                                      }
                                      disabled={DisableCondition()}
                                      onChange={(e) => ShopOnChange(e)}
                                    />
                                    {Error?.Country && (
                                      <span className="error-msg">
                                        {Error?.Country}
                                      </span>
                                    )}
                                  </Col>
                                  <Col
                                    xxl={4}
                                    xl={4}
                                    lg={4}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="mb-3"
                                  >
                                    <p className="mdv_inplabel">Zip Code</p>
                                    <input
                                      className="mdv_cmninput"
                                      type="number"
                                      placeholder=""
                                      id="Zipcode"
                                      name="Address"
                                      value={
                                        newshopdata?.Address?.Zipcode ||
                                        Datas?.shop?.Address?.Zipcode ||
                                        ""
                                      }
                                      disabled={DisableCondition()}
                                      onChange={(e) => ShopOnChange(e)}
                                    />
                                    {Error?.Zipcode && (
                                      <span className="error-msg">
                                        {Error?.Zipcode}
                                      </span>
                                    )}
                                  </Col>
                                </Row>
                              </div>

                              <div className="mdv_profiledtls">
                                <div className="mdv_border_div">
                                  <p className="mdv_profilettl">Bank Details</p>
                                </div>
                                <Row className="mdv_inputrow">
                                  <Col
                                    xxl={4}
                                    xl={4}
                                    lg={4}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="mb-3"
                                  >
                                    <p className="mdv_inplabel">Bank Name</p>
                                    <input
                                      className="mdv_cmninput"
                                      type="text"
                                      placeholder=""
                                      id="BankName"
                                      name="BankDetails"
                                      value={
                                        newshopdata?.BankDetails?.BankName ||
                                        Datas?.shop?.BankDetails?.BankName ||
                                        ""
                                      }
                                      disabled={DisableCondition()}
                                      onChange={(e) => ShopOnChange(e)}
                                    />
                                    {Error?.BankName && (
                                      <span className="error-msg">
                                        {Error?.BankName}
                                      </span>
                                    )}
                                  </Col>
                                  <Col
                                    xxl={4}
                                    xl={4}
                                    lg={4}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="mb-3"
                                  >
                                    <p className="mdv_inplabel">Account Type</p>
                                    <input
                                      className="mdv_cmninput"
                                      type="text"
                                      placeholder=""
                                      id="AccountType"
                                      name="BankDetails"
                                      value={
                                        newshopdata?.BankDetails?.AccountType ||
                                        Datas?.shop?.BankDetails?.AccountType ||
                                        ""
                                      }
                                      disabled={DisableCondition()}
                                      onChange={(e) => ShopOnChange(e)}
                                    />
                                    {Error?.AccountType && (
                                      <span className="error-msg">
                                        {Error?.AccountType}
                                      </span>
                                    )}
                                  </Col>
                                  <Col
                                    xxl={4}
                                    xl={4}
                                    lg={4}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="mb-3"
                                  >
                                    <p className="mdv_inplabel">
                                      Account Holder Name
                                    </p>
                                    <input
                                      className="mdv_cmninput"
                                      type="text"
                                      placeholder=""
                                      id="AccountHolderName"
                                      name="BankDetails"
                                      value={
                                        newshopdata?.BankDetails
                                          ?.AccountHolderName ||
                                        Datas?.shop?.BankDetails
                                          ?.AccountHolderName ||
                                        ""
                                      }
                                      disabled={DisableCondition()}
                                      onChange={(e) => ShopOnChange(e)}
                                    />
                                    {Error?.AccountHolderName && (
                                      <span className="error-msg">
                                        {Error?.AccountHolderName}
                                      </span>
                                    )}
                                  </Col>
                                  <Col
                                    xxl={4}
                                    xl={4}
                                    lg={4}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="mb-3"
                                  >
                                    <p className="mdv_inplabel">
                                      Bank Account Number
                                    </p>
                                    <input
                                      className="mdv_cmninput"
                                      type="text"
                                      placeholder=""
                                      id="AccountNumber"
                                      name="BankDetails"
                                      value={
                                        newshopdata?.BankDetails
                                          ?.AccountNumber ||
                                        Datas?.shop?.BankDetails
                                          ?.AccountNumber ||
                                        ""
                                      }
                                      disabled={DisableCondition()}
                                      onChange={(e) => ShopOnChange(e)}
                                    />
                                    {Error?.AccountNumber && (
                                      <span className="error-msg">
                                        {Error?.AccountNumber}
                                      </span>
                                    )}
                                  </Col>
                                  <Col
                                    xxl={4}
                                    xl={4}
                                    lg={4}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="mb-3"
                                  >
                                    <p className="mdv_inplabel">
                                      Routing Number
                                    </p>
                                    <input
                                      className="mdv_cmninput"
                                      type="text"
                                      placeholder=""
                                      id="RoutingNumber"
                                      name="BankDetails"
                                      value={
                                        newshopdata?.BankDetails
                                          ?.RoutingNumber ||
                                        Datas?.shop?.BankDetails
                                          ?.RoutingNumber ||
                                        ""
                                      }
                                      disabled={DisableCondition()}
                                      onChange={(e) => ShopOnChange(e)}
                                    />
                                    {Error?.RoutingNumber && (
                                      <span className="error-msg">
                                        {Error?.RoutingNumber}
                                      </span>
                                    )}
                                  </Col>
                                  <Col
                                    xxl={4}
                                    xl={4}
                                    lg={4}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="mb-3"
                                  >
                                    <p className="mdv_inplabel">Currency</p>
                                    <Select
                                      options={Currencies}
                                      styles={stylesgraybg}
                                      onChange={(e) =>
                                        ShopOnChange(e, "Currency")
                                      }
                                      className="border_blue_select"
                                      classNamePrefix="react-select"
                                      placeholder="Choose Currency"
                                      value={
                                        newshopdata?.CurrencyName
                                          ? {
                                              label: newshopdata?.CurrencyName,
                                              value:
                                                newshopdata?.CurrencySymbol,
                                            }
                                          : {}
                                      }
                                      isSearchable={false}
                                    />
                                    {Error?.Currency && (
                                      <span className="error-msg">
                                        {Error.Currency}
                                      </span>
                                    )}
                                  </Col>
                                </Row>
                              </div>
                              {console.log(
                                "kdhfsgsgs",
                                Datas?.shop?.StoreImages,
                                newshopdata?.StoreImages
                              )}
                              {(Datas?.shop?.StoreImages?.length > 0 ||
                                shoptab == "addshop" ||
                                Datas?.profile?.CreatedBy == "admin") && (
                                <div className="mdv_profiledtls">
                                  <div className="mdv_border_div">
                                    <p className="mdv_profilettl">
                                      Store Images
                                    </p>
                                  </div>
                                  <div className="mvv_griddtls">
                                    {/* {(Datas?.shop?.StoreImages||[])?.map((item)=>{
        console.log("sjhsjhs",item);
        return(
        <div className='mvv_gridsingle'>
        <img className='mvv_shopimg' src={item?.Name ? `${config?.IMG_URL}/shop/${item?.Name}`:require('../../../assests/images/store.png')}/>
        </div>)})} */}

                                    {
                                      <>
                                        {(
                                          (newshopdata?.StoreImages?.length >
                                            0 &&
                                            newshopdata?.StoreImages) ||
                                          (Datas?.shop?.StoreImages?.length >
                                            0 &&
                                            Datas?.shop?.StoreImages) ||
                                          []
                                        )?.map((item) => {
                                          return (
                                            <div className="mvv_gridsingle">
                                              <img
                                                className="mvv_shopimg"
                                                src={
                                                  item?.size && item?.type
                                                    ? URL.createObjectURL(item)
                                                    : item?.Name
                                                    ? `${config?.IMG_URL}/shop/${item?.Name}`
                                                    : require("../../../assests/images/store.png")
                                                }
                                              />
                                            </div>
                                          );
                                        })}
                                        {/* {(shoptab == 'addshop' || Datas?.profile?.CreatedBy == 'admin') && */}
                                        <div className="mvv_gridsingle">
                                          <div className="mvv_uploadfile_dtl">
                                            <img
                                              className="img-fluid"
                                              src={require("../../../assests/images/cloud.png")}
                                            />
                                            <p className="mvv_cloudtext">
                                              Upload File
                                            </p>
                                            <input
                                              type="file"
                                              className="mvv_fileupload"
                                              id="StoreImages"
                                              onChange={(e) =>
                                                ShopOnChange(e, "StoreImages")
                                              }
                                              multiple
                                            />
                                            <span className="error-msg">
                                              {/* {Error?.StoreImages} */}
                                            </span>
                                          </div>
                                        </div>
                                      </>
                                    }
                                  </div>
                                </div>
                              )}
                              {/* {activeTab == 'addshop' 
        && */}
                              <>
                                {/* <h1>Shop Timing</h1> */}
                                <div className=" py-3 pb-3">
                                  <Container>
                                    <Row>
                                      <Col xl={12} lg={12} md={12} xs={12}>
                                        <div className="mangra_overflow_align mangra_overflow_align_new">
                                          <p className="mangra_whole_ttl">
                                            Business Hours Graph
                                          </p>
                                          <div className="manual_graph_whle">
                                            <div className="manualgra_xaxis_txt">
                                              <p>Day</p>
                                              <p>Midnight</p>
                                              <p>4AM</p>
                                              <p>8AM</p>
                                              <p>Lunch</p>
                                              <p>4PM</p>
                                              <p>8PM</p>
                                            </div>
                                            {console.log(
                                              "dskjhjsfjss",
                                              DayTimings
                                            )}

                                            {Object.keys(DayTimings)?.map(
                                              (val) => {
                                                return (
                                                  // DayTimings?.[val]?.Timing?.Status &&
                                                  <div className="manual_graph_single">
                                                    <p className="manualgra_yaxis_txt">
                                                      {val.substring(0, 3)}
                                                    </p>
                                                    {HourMinuteDisplay?.Hours?.map(
                                                      (hour) => {
                                                        return (
                                                          <div className="single_square">
                                                            {HourMinuteDisplay?.Minutes?.map(
                                                              (minute) => {
                                                                return (
                                                                  <div
                                                                    className={
                                                                      DayTimings?.[
                                                                        val
                                                                      ]?.Timing
                                                                        ?.timing
                                                                        ?.length >
                                                                      0
                                                                        ? DayTimings?.[
                                                                            val
                                                                          ]?.Timing?.timing?.some(
                                                                            (
                                                                              val
                                                                            ) =>
                                                                              HourMinutetoTime(
                                                                                hour,
                                                                                minute *
                                                                                  15
                                                                              ) >=
                                                                                HourMinutetoTime(
                                                                                  val?.StartHour,
                                                                                  val?.StartMinute,
                                                                                  "start"
                                                                                ) &&
                                                                              HourMinutetoTime(
                                                                                hour,
                                                                                minute *
                                                                                  15
                                                                              ) <=
                                                                                HourMinutetoTime(
                                                                                  val?.EndHour,
                                                                                  val?.EndMinute,
                                                                                  "end"
                                                                                ) &&
                                                                              (minute *
                                                                                15 !==
                                                                                60 ||
                                                                                (minute *
                                                                                  15 ==
                                                                                  60 &&
                                                                                  hour !=
                                                                                    Number(
                                                                                      val?.StartHour
                                                                                    ) -
                                                                                      1))
                                                                          )
                                                                          ? "timing_div"
                                                                          : "test111"
                                                                        : "test111"
                                                                    }
                                                                  >
                                                                  </div>
                                                                );
                                                              }
                                                            )}
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                          <Row className="mt-3">
                                            {selectCheck && (
                                              <Col xs={12}>
                                                <div className="cmn_ttl_aligns cmn_ttl_aligns_new">
                                                  <div className="title_percent_ttl title_percent_ttl_new">
                                                    <p className="checkbox_title checkbox_title_new">
                                                      Select day(s):
                                                    </p>
                                                  </div>
                                                  {/* {(shoptab == 'addshop' || Datas?.profile?.CreatedBy == 'admin') &&  */}
                                                  <div className="title_percent_ttl title_percent_ttl_new">
                                                    <p className="checkbox_title tet_lefvc checkbox_title_new ps-3">
                                                      Select times:
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="whole_check_datepick mt-3">
                                                  <div className="daysslct_ttl day_width_45">
                                                    {/* <div className="">

                                    <div className="daysslct_ttl"> */}
                                                    {Object.keys(
                                                      DayTimings
                                                    )?.map((val) => {
                                                      return (
                                                        // DayTimings?.[val]?.Timing?.Status &&
                                                        <p className="show_days_div">
                                                          {val?.substring(0, 1)}
                                                        </p>
                                                      );
                                                    })}
                                                    {/*  <p>M</p>
                                       <p>T</p>
                                       <p>W</p>
                                      <p>T</p>
                                      <p>F</p>
                                       <p>S</p> */}
                                                    {/* </div>
                                   
                                    </div> */}
                                                  </div>

                                                  {/* {
                                            (shoptab == 'addshop' || Datas?.profile?.CreatedBy == 'admin') &&  */}
                                                  <div className="dateselect_dtls">
                                                    <div className="daysslct_ttl days_new_widh">
                                                      <p>From</p>
                                                      <p>To</p>
                                                      <p className="hider_txt">
                                                        Delete
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                {console.log(
                                                  "sjhfjkhsfksfsfs",
                                                  SelectedTime
                                                )}
                                                {SelectedTime?.Timing?.length >
                                                  0 &&
                                                  SelectedTime?.Timing?.map(
                                                    (arrind, ind) => {
                                                      return (
                                                        <>
                                                          <div className="whole_check_datepick">
                                                            <div className="day_width_45">
                                                              {Object.keys(
                                                                DayTimings
                                                              )?.map((val) => {
                                                                return (
                                                                  // DayTimings?.[val]?.Timing?.Status &&
                                                                  <div className="linecheck_aligned linecheck_aligned_new">
                                                                    <div className="modal_checkoptions odp_check">
                                                                      <Form.Check type="checkbox">
                                                                        <Form.Check.Input
                                                                          type="checkbox"
                                                                          defaultChecked={
                                                                            SelectedTime
                                                                              ?.Timing?.[
                                                                              ind
                                                                            ]?.[
                                                                              val
                                                                            ]
                                                                          }
                                                                          isValid
                                                                          onChange={(
                                                                            e
                                                                          ) => {
                                                                            OnChangeTime(
                                                                              e,
                                                                              ind,
                                                                              val,
                                                                              "checkbox",
                                                                              "Timing"
                                                                            );
                                                                          }}
                                                                          disabled={DisableCondition()}
                                                                        />
                                                                      </Form.Check>
                                                                    </div>
                                                                  </div>
                                                                );
                                                              })}
                                                            </div>

                                                            {/* {(shoptab == 'addshop' || Datas?.profile?.CreatedBy == 'admin') &&  */}
                                                            <div className="dateselect_dtls">
                                                              <Datetime
                                                                viewMode={
                                                                  "time"
                                                                }
                                                                value={
                                                                  SelectedTime
                                                                    ?.Timing?.[
                                                                    ind
                                                                  ]
                                                                    ?.StartTimeshow
                                                                }
                                                                // onViewModeChange={'time'}
                                                                dateFormat={
                                                                  false
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  OnChangeTime(
                                                                    e,
                                                                    ind,
                                                                    "StartTime",
                                                                    "time",
                                                                    "Timing"
                                                                  );
                                                                }}
                                                                disabled={DisableCondition()}
                                                              />

                                                              <Datetime
                                                                viewMode={
                                                                  "time"
                                                                }
                                                                dateFormat={
                                                                  false
                                                                }
                                                                value={
                                                                  SelectedTime
                                                                    ?.Timing?.[
                                                                    ind
                                                                  ]?.EndTimeshow
                                                                }
                                                                onChange={(e) =>
                                                                  OnChangeTime(
                                                                    e,
                                                                    ind,
                                                                    "EndTime",
                                                                    "time",
                                                                    "Timing"
                                                                  )
                                                                }
                                                              />
                                                              {/* <img
                                                                className="order_trashed_ic"
                                                                src={require("../../../assests/images/trash.png")}
                                                                onClick={() =>
                                                                  RemoveTime(
                                                                    ind,
                                                                    "Timing"
                                                                  )
                                                                }
                                                              /> */}
                                                              <img
                                                                className="order_trashed_ic"
                                                                src={deletSvg}
                                                                onClick={() =>
                                                                  RemoveTime(
                                                                    ind,
                                                                    "Timing"
                                                                  )
                                                                }
                                                              />
                                                            </div>
                                                          </div>

                                                          <div></div>
                                                        </>
                                                      );
                                                    }
                                                  )}
                                                {/* {(shoptab == 'addshop' || Datas?.profile?.CreatedBy == 'admin') &&  */}
                                                <div className="triple_copybtns">
                                                  <button
                                                    className="blue_btn mangra_addtime mangra_addtime_new"
                                                    type="button"
                                                    onClick={() =>
                                                      AddNewTiming("Timing")
                                                    }
                                                  >
                                                    Add Time
                                                  </button>
                                                  <button
                                                    className="blue_btn mangra_addtime mangra_addtime_new"
                                                    type="button"
                                                    onClick={() =>
                                                      CopyTimings(
                                                        "Timing",
                                                        "Pickup"
                                                      )
                                                    }
                                                  >
                                                    Copy This to Pickup
                                                  </button>
                                                  <button
                                                    className="blue_btn mangra_addtime mangra_addtime_new"
                                                    type="button"
                                                    onClick={() =>
                                                      CopyTimings(
                                                        "Timing",
                                                        "Delivery"
                                                      )
                                                    }
                                                  >
                                                    Copy This to Delivery
                                                  </button>
                                                </div>
                                              </Col>
                                            )}
                                          </Row>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Container>
                                </div>
                                <div className=" py-3 pb-3">
                                  <Container>
                                    <Row>
                                      <Col xl={12} lg={12} md={12} xs={12}>
                                        <div className="mangra_overflow_align mangra_overflow_align_new">
                                          <p className="mangra_whole_ttl">
                                            Delivery Hours Graph
                                          </p>
                                          <div className="manual_graph_whle">
                                            <div className="manualgra_xaxis_txt">
                                              <p>Day</p>
                                              <p>Midnight</p>
                                              <p>4AM</p>
                                              <p>8AM</p>
                                              <p>Lunch</p>
                                              <p>4PM</p>
                                              <p>8PM</p>
                                            </div>

                                            {console.log(
                                              "kjkfsjlkfskjfhksxjcsdjkjd",
                                              DayTimings,
                                              SelectedTime
                                            )}

                                            {Object.keys(DayTimings)?.map(
                                              (val) => {
                                                return (
                                                  DayTimings?.[val]?.Timing
                                                    ?.Status && (
                                                    <div className="manual_graph_single">
                                                      <p className="manualgra_yaxis_txt">
                                                        {val.substring(0, 3)}
                                                      </p>
                                                      {HourMinuteDisplay?.Hours?.map(
                                                        (hour) => {
                                                          return (
                                                            <div className="single_square">
                                                              {HourMinuteDisplay?.Minutes?.map(
                                                                (minute) => {
                                                                  return (
                                                                    <div
                                                                      className={
                                                                        DayTimings?.[
                                                                          val
                                                                        ]
                                                                          ?.Delivery
                                                                          ?.timing
                                                                          ?.length >
                                                                        0
                                                                          ? DayTimings?.[
                                                                              val
                                                                            ]?.Delivery?.timing?.some(
                                                                              (
                                                                                val
                                                                              ) =>
                                                                                HourMinutetoTime(
                                                                                  hour,
                                                                                  minute *
                                                                                    15
                                                                                ) >=
                                                                                  HourMinutetoTime(
                                                                                    val?.StartHour,
                                                                                    val?.StartMinute,
                                                                                    "start"
                                                                                  ) &&
                                                                                HourMinutetoTime(
                                                                                  hour,
                                                                                  minute *
                                                                                    15
                                                                                ) <=
                                                                                  HourMinutetoTime(
                                                                                    val?.EndHour,
                                                                                    val?.EndMinute,
                                                                                    "end"
                                                                                  ) &&
                                                                                (minute *
                                                                                  15 !==
                                                                                  60 ||
                                                                                  (minute *
                                                                                    15 ==
                                                                                    60 &&
                                                                                    hour !=
                                                                                      Number(
                                                                                        val?.StartHour
                                                                                      ) -
                                                                                        1))
                                                                            )
                                                                            ? "timing_div"
                                                                            : "test111"
                                                                          : "test111"
                                                                      }
                                                                      // onClick={() => {
                                                                      //   TimeChoose(
                                                                      //     val,
                                                                      //     minute * 15 ==
                                                                      //       60
                                                                      //       ? hour + 1
                                                                      //       : hour,
                                                                      //     minute * 15 ==
                                                                      //       60
                                                                      //       ? 0
                                                                      //       : minute *
                                                                      //           15,
                                                                      //     "Delivery"
                                                                      //   );
                                                                      // }}
                                                                    ></div>
                                                                  );
                                                                }
                                                              )}
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  )
                                                );
                                              }
                                            )}
                                          </div>
                                          <Row className="mt-3">
                                            <Col xs={12}>
                                              <div className="cmn_ttl_aligns cmn_ttl_aligns_new">
                                                <div className="title_percent_ttl title_percent_ttl_new">
                                                  <p className="checkbox_title checkbox_title_new">
                                                    Select day(s):
                                                  </p>
                                                </div>
                                                {/* {(shoptab == 'addshop' || Datas?.profile?.CreatedBy == 'admin') &&  */}
                                                <div className="title_percent_ttl title_percent_ttl_new">
                                                  <p className="checkbox_title checkbox_title_new ps-3">
                                                    Select times:
                                                  </p>
                                                </div>
                                              </div>
                                              <div className="whole_check_datepick mt-3">
                                                <div className="daysslct_ttl day_width_45">
                                                  {/* <div className="daysslct_ttl"> */}
                                                  {Object.keys(DayTimings)?.map(
                                                    (val) => {
                                                      return (
                                                        DayTimings?.[val]
                                                          ?.Timing?.Status && (
                                                          <p className="show_days_div">
                                                            {val?.substring(
                                                              0,
                                                              1
                                                            )}
                                                          </p>
                                                        )
                                                      );
                                                    }
                                                  )}

                                                  {/*  <p>M</p>
                                       <p>T</p>
                                       <p>W</p>
                                      <p>T</p>
                                      <p>F</p>
                                       <p>S</p> */}
                                                  {/* </div> */}
                                                </div>
                                                {/* {(shoptab == 'addshop' || Datas?.profile?.CreatedBy == 'admin') &&  */}
                                                <div className="title_percent_ttl">
                                                  <div className="daysslct_ttl days_new_widh">
                                                    <p>From</p>
                                                    <p>To</p>
                                                    <p className="hider_txt">
                                                      Delete
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                              {console.log(
                                                "sjhfjkhsfksfsfs",
                                                SelectedTime
                                              )}
                                              {SelectedTime?.Delivery?.length >
                                                0 &&
                                                SelectedTime?.Delivery?.map(
                                                  (arrind, ind) => {
                                                    return (
                                                      <>
                                                        <div className="whole_check_datepick">
                                                          <div className="day_width_45">
                                                            {Object.keys(
                                                              DayTimings
                                                            )?.map((val) => {
                                                              return (
                                                                DayTimings?.[
                                                                  val
                                                                ]?.Timing
                                                                  ?.Status && (
                                                                  <div className="linecheck_aligned linecheck_aligned_new">
                                                                    <div className="modal_checkoptions odp_check">
                                                                      <Form.Check type="checkbox">
                                                                        <Form.Check.Input
                                                                          type="checkbox"
                                                                          defaultChecked={
                                                                            SelectedTime
                                                                              ?.Delivery?.[
                                                                              ind
                                                                            ]?.[
                                                                              val
                                                                            ]
                                                                          }
                                                                          isValid
                                                                          onChange={(
                                                                            e
                                                                          ) => {
                                                                            OnChangeTime(
                                                                              e,
                                                                              ind,
                                                                              val,
                                                                              "checkbox",
                                                                              "Delivery"
                                                                            );
                                                                          }}
                                                                          disabled={DisableCondition()}
                                                                        />
                                                                      </Form.Check>
                                                                    </div>
                                                                  </div>
                                                                )
                                                              );
                                                            })}
                                                          </div>
                                                          {/* {(shoptab == 'addshop' || Datas?.profile?.CreatedBy == 'admin') &&  */}
                                                          <div className="dateselect_dtls">
                                                            <Datetime
                                                              viewMode={"time"}
                                                              value={
                                                                SelectedTime
                                                                  ?.Delivery?.[
                                                                  ind
                                                                ]?.StartTimeshow
                                                              }
                                                              // onViewModeChange={'time'}
                                                              dateFormat={false}
                                                              onChange={(e) => {
                                                                console.log(
                                                                  "kdfjsfkdsfsd",
                                                                  e._d
                                                                );
                                                                OnChangeTime(
                                                                  e,
                                                                  ind,
                                                                  "StartTime",
                                                                  "time",
                                                                  "Delivery"
                                                                );
                                                              }}
                                                            />

                                                            <Datetime
                                                              viewMode={"time"}
                                                              dateFormat={false}
                                                              value={
                                                                SelectedTime
                                                                  ?.Delivery?.[
                                                                  ind
                                                                ]?.EndTimeshow
                                                              }
                                                              onChange={(e) =>
                                                                OnChangeTime(
                                                                  e,
                                                                  ind,
                                                                  "EndTime",
                                                                  "time",
                                                                  "Delivery"
                                                                )
                                                              }
                                                            />
                                                            {/* <img
                                                              className="order_trashed_ic"
                                                              src={require("../../../assests/images/trash.png")}
                                                              onClick={() =>
                                                                RemoveTime(
                                                                  ind,
                                                                  "Delivery"
                                                                )
                                                              }
                                                            /> */}
                                                            <img
                                                              className="order_trashed_ic"
                                                              src={deletSvg}
                                                              onClick={() =>
                                                                RemoveTime(
                                                                  ind,
                                                                  "Delivery"
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                          <span className="error-msg">
                                                            {
                                                              Error?.[
                                                                "SelectedTimeDelivery" +
                                                                  ind
                                                              ]
                                                            }
                                                          </span>
                                                        </div>

                                                        <div></div>
                                                      </>
                                                    );
                                                  }
                                                )}
                                              {/* {(shoptab == 'addshop' || Datas?.profile?.CreatedBy == 'admin') &&  */}
                                              <div className="triple_copybtns">
                                                <button
                                                  className="blue_btn mangra_addtime mangra_addtime_new"
                                                  type="button"
                                                  onClick={() =>
                                                    AddNewTiming("Delivery")
                                                  }
                                                >
                                                  Add Time
                                                </button>

                                                <button
                                                  className="blue_btn mangra_addtime mangra_addtime_new"
                                                  type="button"
                                                  onClick={() =>
                                                    CopyTimings(
                                                      "Delivery",
                                                      "Pickup"
                                                    )
                                                  }
                                                >
                                                  Copy This to Pickup
                                                </button>
                                                <button
                                                  className="blue_btn mangra_addtime mangra_addtime_new"
                                                  type="button"
                                                  onClick={() =>
                                                    CopyTimings(
                                                      "Delivery",
                                                      "Timing"
                                                    )
                                                  }
                                                >
                                                  Copy This to Timing
                                                </button>
                                              </div>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Col>
                                      <Col xl={12} lg={12} md={12} xs={12}>
                                        <div className="mangra_overflow_align mangra_overflow_align_new">
                                          <p className="mangra_whole_ttl">
                                            PickUp Hours Graph
                                          </p>
                                          <div className="manual_graph_whle">
                                            <div className="manualgra_xaxis_txt">
                                              <p>Day</p>
                                              <p>Midnight</p>
                                              <p>4AM</p>
                                              <p>8AM</p>
                                              <p>Lunch</p>
                                              <p>4PM</p>
                                              <p>8PM</p>
                                            </div>

                                            {Object.keys(DayTimings)?.map(
                                              (val) => {
                                                return (
                                                  DayTimings?.[val]?.Timing
                                                    ?.Status && (
                                                    <div className="manual_graph_single">
                                                      <p className="manualgra_yaxis_txt">
                                                        {val.substring(0, 3)}
                                                      </p>
                                                      {HourMinuteDisplay?.Hours?.map(
                                                        (hour) => {
                                                          return (
                                                            <div className="single_square">
                                                              {HourMinuteDisplay?.Minutes?.map(
                                                                (minute) => {
                                                                  return (
                                                                    <div
                                                                      className={
                                                                        DayTimings?.[
                                                                          val
                                                                        ]
                                                                          ?.Pickup
                                                                          ?.timing
                                                                          ?.length >
                                                                        0
                                                                          ? DayTimings?.[
                                                                              val
                                                                            ]?.Pickup?.timing?.some(
                                                                              (
                                                                                val
                                                                              ) =>
                                                                                HourMinutetoTime(
                                                                                  hour,
                                                                                  minute *
                                                                                    15
                                                                                ) >=
                                                                                  HourMinutetoTime(
                                                                                    val?.StartHour,
                                                                                    val?.StartMinute,
                                                                                    "start"
                                                                                  ) &&
                                                                                HourMinutetoTime(
                                                                                  hour,
                                                                                  minute *
                                                                                    15
                                                                                ) <=
                                                                                  HourMinutetoTime(
                                                                                    val?.EndHour,
                                                                                    val?.EndMinute,
                                                                                    "end"
                                                                                  ) &&
                                                                                (minute *
                                                                                  15 !==
                                                                                  60 ||
                                                                                  (minute *
                                                                                    15 ==
                                                                                    60 &&
                                                                                    hour !=
                                                                                      Number(
                                                                                        val?.StartHour
                                                                                      ) -
                                                                                        1))
                                                                            )
                                                                            ? "timing_div"
                                                                            : "test111"
                                                                          : "test111"
                                                                      }
                                                                      // onClick={() => {
                                                                      //   TimeChoose(
                                                                      //     val,
                                                                      //     minute * 15 ==
                                                                      //       60
                                                                      //       ? hour + 1
                                                                      //       : hour,
                                                                      //     minute * 15 ==
                                                                      //       60
                                                                      //       ? 0
                                                                      //       : minute *
                                                                      //           15,
                                                                      //     "Pickup"
                                                                      //   );
                                                                      // }}
                                                                    ></div>
                                                                  );
                                                                }
                                                              )}
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  )
                                                );
                                              }
                                            )}
                                          </div>
                                          <Row className="mt-3">
                                            <Col xs={12}>
                                              {/* {(shoptab == 'addshop' || Datas?.profile?.CreatedBy == 'admin') &&  */}
                                              <div className="cmn_ttl_aligns cmn_ttl_aligns_new">
                                                <div className="title_percent_ttl title_percent_ttl_new">
                                                  <p className="checkbox_title checkbox_title_new">
                                                    Select day(s):
                                                  </p>
                                                </div>
                                                <div className="title_percent_ttl title_percent_ttl_new">
                                                  <p className="checkbox_title checkbox_title_new ps-3">
                                                    Select times:
                                                  </p>
                                                </div>
                                              </div>
                                              <div className="whole_check_datepick mt-3">
                                                {/* <div className="title_percent_ttl"> */}

                                                <div className="daysslct_ttl day_width_45">
                                                  {Object.keys(DayTimings)?.map(
                                                    (val) => {
                                                      return (
                                                        DayTimings?.[val]
                                                          ?.Timing?.Status && (
                                                          <p className="show_days_div">
                                                            {val?.substring(
                                                              0,
                                                              1
                                                            )}
                                                          </p>
                                                        )
                                                      );
                                                    }
                                                  )}
                                                </div>

                                                {/* </div> */}
                                                {/* {(shoptab == 'addshop' || Datas?.profile?.CreatedBy == 'admin') &&  */}
                                                <div className="title_percent_ttl">
                                                  <div className="daysslct_ttl">
                                                    <p>From</p>
                                                    <p className="calendar_totxt">
                                                      To
                                                    </p>
                                                    <p className="hider_txt">
                                                      Delete
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                              {SelectedTime?.Pickup?.length >
                                                0 &&
                                                SelectedTime?.Pickup?.map(
                                                  (arrind, ind) => {
                                                    return (
                                                      <>
                                                        <div className="whole_check_datepick">
                                                          <div className="day_width_45">
                                                            {Object.keys(
                                                              DayTimings
                                                            )?.map((val) => {
                                                              return (
                                                                DayTimings?.[
                                                                  val
                                                                ]?.Timing
                                                                  ?.Status && (
                                                                  <div className="linecheck_aligned linecheck_aligned_new">
                                                                    <div className="modal_checkoptions odp_check">
                                                                      <Form.Check type="checkbox">
                                                                        <Form.Check.Input
                                                                          type="checkbox"
                                                                          defaultChecked={
                                                                            SelectedTime
                                                                              ?.Pickup?.[
                                                                              ind
                                                                            ]?.[
                                                                              val
                                                                            ]
                                                                          }
                                                                          isValid
                                                                          onChange={(
                                                                            e
                                                                          ) => {
                                                                            OnChangeTime(
                                                                              e,
                                                                              ind,
                                                                              val,
                                                                              "checkbox",
                                                                              "Pickup"
                                                                            );
                                                                          }}
                                                                          disabled={DisableCondition()}
                                                                        />
                                                                      </Form.Check>
                                                                    </div>
                                                                  </div>
                                                                )
                                                              );
                                                            })}
                                                          </div>

                                                          {/* {(shoptab == 'addshop' || Datas?.profile?.CreatedBy == 'admin') &&  */}
                                                          <div className="dateselect_dtls">
                                                            <Datetime
                                                              viewMode={"time"}
                                                              value={
                                                                SelectedTime
                                                                  ?.Pickup?.[
                                                                  ind
                                                                ]?.StartTimeshow
                                                              }
                                                              dateFormat={false}
                                                              // onViewModeChange={'time'}
                                                              onChange={(e) => {
                                                                console.log(
                                                                  "kdfjsfkdsfsd",
                                                                  e._d
                                                                );
                                                                OnChangeTime(
                                                                  e,
                                                                  ind,
                                                                  "StartTime",
                                                                  "time",
                                                                  "Pickup"
                                                                );
                                                              }}
                                                            />

                                                            <Datetime
                                                              viewMode={"time"}
                                                              dateFormat={false}
                                                              value={
                                                                SelectedTime
                                                                  ?.Pickup?.[
                                                                  ind
                                                                ]?.EndTimeshow
                                                              }
                                                              onChange={(e) =>
                                                                OnChangeTime(
                                                                  e,
                                                                  ind,
                                                                  "EndTime",
                                                                  "time",
                                                                  "Pickup"
                                                                )
                                                              }
                                                            />
                                                            {/* <img
                                                              className="order_trashed_ic"
                                                              src={require("../../../assests/images/trash.png")}
                                                              onClick={() =>
                                                                RemoveTime(
                                                                  ind,
                                                                  "Pickup"
                                                                )
                                                              }
                                                            /> */}
                                                            <img
                                                              className="order_trashed_ic"
                                                              src={deletSvg}
                                                              onClick={() =>
                                                                RemoveTime(
                                                                  ind,
                                                                  "Pickup"
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                          <span className="error-msg">
                                                            {
                                                              Error?.[
                                                                "SelectedTimePickup" +
                                                                  ind
                                                              ]
                                                            }
                                                          </span>
                                                        </div>

                                                        <div></div>
                                                      </>
                                                    );
                                                  }
                                                )}
                                              {/* {(shoptab == 'addshop' || Datas?.profile?.CreatedBy == 'admin') &&  */}
                                              <div className="triple_copybtns">
                                                <button
                                                  className="blue_btn mangra_addtime mangra_addtime_new"
                                                  type="button"
                                                  onClick={() =>
                                                    AddNewTiming("Pickup")
                                                  }
                                                >
                                                  Add Time
                                                </button>

                                                <button
                                                  className="blue_btn mangra_addtime mangra_addtime_new"
                                                  type="button"
                                                  onClick={() =>
                                                    CopyTimings(
                                                      "Pickup",
                                                      "Delivery"
                                                    )
                                                  }
                                                >
                                                  Copy This to Delivery
                                                </button>
                                                <button
                                                  className="blue_btn mangra_addtime mangra_addtime_new"
                                                  type="button"
                                                  onClick={() =>
                                                    CopyTimings(
                                                      "Pickup",
                                                      "Timing"
                                                    )
                                                  }
                                                >
                                                  Copy This to Timing
                                                </button>
                                              </div>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Container>
                                </div>
                                {/* {
          (shoptab == 'addshop' || (Datas?.profile?.CreatedBy == 'admin' && !isEmpty(SelectedShop))) &&  */}
                                <div className="mdv_update_dualbtn">
                                  <button
                                    className="blue_btn mdv_update"
                                    type="button"
                                    onClick={() => Submit()}
                                    disabled={disabledstatus?.Submit}
                                  >
                                    {shoptab == "addshop" ? "Submit" : "Update"}
                                  </button>
                                </div>
                              </>
                            </>
                          ) : (
                            <div className="mdv_profiledtls">
                              <p className="mdv_profilettl">
                                No Shop Available
                              </p>
                            </div>
                          )}
                        </>
                      )}
                      {/* Shops */}

                      {/* rejected details */}
                      {activeTab == "operationtime" && (
                        <div className="whole_operationtime_border">
                          <div className="mvv_catelog_timerow">
                            <div className="mvv_operationtime_tabs">
                              <p
                                className={
                                  subactiveTab == "delivery"
                                    ? "mvv_subtabs active"
                                    : "mvv_subtabs"
                                }
                                onClick={() => setSubActiveTab("delivery")}
                              >
                                Delivery
                              </p>
                              <p
                                className={
                                  subactiveTab == "pickup"
                                    ? "mvv_subtabs active"
                                    : "mvv_subtabs"
                                }
                                onClick={() => setSubActiveTab("pickup")}
                              >
                                Pickup
                              </p>
                            </div>

                            <div className="rightsec secondary_tog">
                              <p className="mvv_acceptorder">
                                Accepting Orders
                              </p>
                              <div className="togswit ">
                                <input type="checkbox" id="toggle-btn1" />
                                <label for="toggle-btn1"></label>
                              </div>
                            </div>
                          </div>
                          {subactiveTab == "delivery" ? (
                            <div className="ffd_table px-3 pb-3">
                              <BootstrapTable
                                bootstrap4
                                keyField="id"
                                columns={optimecolumns}
                                data={optimedata}
                              />
                              <button className="blue_btn mdv_update">
                                Update Time
                              </button>
                            </div>
                          ) : (
                            <></>
                          )}
                          {subactiveTab == "pickup" ? (
                            <div className="ffd_table px-3 pb-3">
                              <BootstrapTable
                                bootstrap4
                                keyField="id"
                                columns={optimecolumns}
                                data={optimedata}
                              />
                              <button className="blue_btn mdv_update">
                                Update Time
                              </button>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      )}
                      {/* end of rejected details */}

                      {/* ratings & reviews details */}

                      {activeTab == "rating" && (
                        <div className="row">
                          <div className="mdvsec_border ">
                            <div className="mdv_driverdtls_whole">
                              <div className="ffd_table">
                                <BootstrapTable
                                  bootstrap4
                                  keyField="id"
                                  columns={reviewcolumns}
                                  data={reviewdata}
                                  pagination={pagination}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* end of ratings & reviews details */}

                      {/* discount details */}

                      {activeTab == "discount" && (
                        <div className="row">
                          <div className="mdvsec_border ">
                            <div className="mdv_driverdtls_whole">
                              <div className="ffd_table">
                                <BootstrapTable
                                  bootstrap4
                                  keyField="id"
                                  columns={discountcolumns}
                                  data={discountdata}
                                  pagination={pagination}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* end of discount details */}

                      {/* change password details */}

                      {activeTab == "accountsettings" &&
                        {
                          /* <div className='mdv_profiledtls'>
        <div className='mdv_border_div'>
            <p className='mdv_profilettl'>Change Password</p>
        </div>
        <Row className='mdv_inputrow'>
            <Col xxl={4} xl={4} lg={4} md={6} sm={6} xs={12} className="mb-3">
                <p className='mdv_inplabel'>New Password</p>
                <input className='mdv_cmninput' type="password" placeholder="******"/>
            </Col>
            <Col xxl={4} xl={4} lg={4} md={6} sm={6} xs={12} className="mb-3">
                <p className='mdv_inplabel'>Confirm Password</p>
                <input className='mdv_cmninput' type="password" placeholder="******"/>
            </Col>

            <div className='mdv_update_dualbtn'>
              <button className='blue_btn mdv_update'>Update</button>
              <button className='seconday_btn'>Cacnel</button>
            </div>
           
            
        </Row>
      </div> */
                        }}

                      {/* end of change password details */}
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManagementVendorsView;
