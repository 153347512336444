import React, { useEffect, useState } from "react";
import Sidebar from "../../layouts/sidebar";
import Headers from "../../layouts/header";

import "react-datetime/css/react-datetime.css";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import useAxiosFile from "../../actions/useaxios";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { toast } from "react-toastify";

// import { LoadToast, updateToast } from "../../actions/common";
const datas = [];
function subscribelists() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  console.log("stateeeeee", state, location);
  const [sublists, setSublists] = useState([]);
  // const [subselect, setSubselect] = useState([])
  console.log("select", sublists);
  const { toggle } = useSelector((state) => state.admin_panel.User);

  // const [selectdata, setSelectData] = useState([]);
  // console.log('selectdata 111 juju', selectdata)
  const axiosFile = useAxiosFile();

  useEffect(() => {
    showinglists();
  }, []);

  const showinglists = async () => {
    console.log("ddddd");
    var data = await axiosFile.showinglists();
    console.log("vvvv", data);
    setSublists(data?.data);
    // setSubselect(data?.data)
  };

  const submitbtn = async (cell) => {
    console.log("rtg", cell);
    navigate(`/subscribeadd/?id=${cell._id}`);
  };

  // const Datas = [];
  // const subdetailbutton = (e) => {
  //   let cell = e?.target?.id
  //   console.log("uiouioui", cell);
  //   Datas.push(cell);
  //   setSelectData(call)
  //   console.log("fgsfrgtwrtwe", Datas);
  //   if (cell == Datas) {
  //     Datas.pop(cell)
  //   }
  // }
  console.log("dsijskhfsf", datas);
  const subdetailbutton = (e) => {
    let cell = {
      email: e?.target?.id,
    };
    console.log("subdetailbutton", e.target.checked);
    if (datas.length > 0) {
      if (e.target.checked) {
        datas.push(cell);
      } else {
        datas.map((item, i) => {
          console.log("item_datassssss", item, i);
          if (item.email == cell.email) {
            datas.splice(i, 1);
          }
        });
      }
    } else {
      datas.push(cell);
    }
    console.log("cell data", cell);
    // setSelectData(cell);

    console.log("selectdata", datas);
  };

  const emailsend = async () => {
    console.log("datsssss", datas);
    if (datas?.length > 0) {
      navigate("/subscribeadd", { state: { email: datas } });
    } else {
      toast.error("Choose Email-Id to send email");
    }
    console.log("eFCSEdvf", datas);
  };

  //   const clickbtn = async (cell) => {

  //     const id= LoadToast()
  //     console.log("ccc", cell);
  //     const updatedata = {
  //       _id: cell._id,
  //       action: "statusupdate",
  //       status: true
  //     }
  //     var data = await axiosFile.newinsert(updatedata)
  //     console.log("ttttttttrue", data);
  //     updateToast(id,data.msg,data.success)
  //     if(data?.success== "success"){
  //         showinglists()
  //     }
  //   }
  const columns = [
    {
      dataField: "S.No",
      text: "S.No",
      sort: true,
      formatter: (cell, row, rowindex) => <div> {rowindex + 1}</div>,
    },
    {
      dataField: "EmailId",
      text: "EmailId",
      sort: true,
      formatter: (cell, row) => (
        <div dangerouslySetInnerHTML={{ __html: cell }}></div>
      ),
    },

    {
      dataField: "EmailId",
      text: "select",
      sort: true,
      formatter: (cell, row, i) => (
        <>
          {" "}
          {console.log("row_dasta", cell, row)}{" "}
          {
            //
            <label>
              <input
                type="checkbox"
                name="select"
                id={cell}
                // value={subselect.includes(cell)?cell:''}
                // checked={subselect.includes(cell)?'checked':'unchecked'}
                onChange={(e) => {
                  subdetailbutton(e, i);
                }}
              />
            </label>
          }
        </>
      ),
    },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {},
    onSizePerPageChange: function (page, sizePerPage) {},
  });

  return (
    <div>
      <div className="innerpages">
        <div className="orders_pages">
          <Headers />
          <div className="container custom_container px-lg-0">
            <div className="row mx-auto">
              <div className="innerpages_wid px-0">
                <Sidebar />
                <div
                  className={
                    toggle
                      ? "content-sec-inner right_widthset content_without_sidebar"
                      : "content-sec-inner  content_with_sidebar"
                  }
                >
                  <section id="content-wrapper">
                    <div class="row">
                      <div className="top_title_sec">
                        <div>
                          <p className="page_title">subscribe List</p>
                        </div>
                        {/* <div className="rightsec">
                          <button
                            type="button"
                            className="blue_btn add_deliveryprsn"
                            onClick={() => navigate("/subscribeadd")}
                          >
                            Back
                          </button>

                        </div> */}
                      </div>
                    </div>
                    <div className="row mx-auto mt-4">
                      <div className="sec_border">
                        <div className="row">
                          {
                            <div className="ffd_table mar_bot">
                              <BootstrapTable
                                bootstrap4
                                keyField="id"
                                columns={columns}
                                data={sublists}
                                pagination={pagination}
                              />
                            </div>
                          }
                          {/* <div className="rightsec">
                           <button
                            type="button"
                            className="blue_btn add_deliveryprsn"
                            // onClick={() => navigate("/subscribeadd")}
                          >
                            send 
                          </button>
                          </div> */}
                          <div className="rightsec">
                            <button
                              type="button"
                              className="blue_btn add_deliveryprsn dp_btn_hover"
                              onClick={() => {
                                emailsend();
                              }}
                            >
                              send
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default subscribelists;
