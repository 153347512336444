import {
  setKey,
  setDefaults,
  setLanguage,
  setRegion,
  fromAddress,
  fromLatLng,
  fromPlaceId,
  setLocationType,
  geocode,
  RequestType,
} from "react-geocode";
import React from "react";

// export default function Geocode() {
  setDefaults({
    key: "AIzaSyDT9jQZ5BhdkR8dUwZFtdJKdi82PaBT_h8", // Your API key here.
    language: "en", // Default language for responses.
  });

 export const GetLatLngBasedOnAddress = async(address) => {

    // Get latitude & longitude from address.
  try{
    console.log("results",address)

   var {results}  = await fromAddress(address)
   console.log("results",results,results?.[0]?.geometry?.location)
    // coordinates: [ -73.97, 40.77 ] 
   return { type: "Point",coordinates:[results?.[0]?.geometry?.location?.lat,results?.[0]?.geometry?.location?.lng]}
    //   .then(({ results }) => {
    //     const { lat, lng } = results[0].geometry.location;
    //     console.log(lat, lng);
        
    //   })
    //   .catch(console.error);
 }
catch(e){ return { type: "Point",coordinates:[0,0]}}
};

//   return {
//     GetLatLngBasedOnAddress
// };
// }
