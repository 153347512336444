import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { CKEditor } from "ckeditor4-react";

import useAxiosFile from "../../actions/useaxios";
import {
  DecryptData,
  LoadToast,
  isEmpty,
  updateToast,
} from "../../actions/common";
import Sidebar from "../../layouts/sidebar";
import Header from "../../layouts/header";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";

function dtadd() {
  let data = useLocation();
  console.log("tyyyy", data);
  const search = data.search.split("=")[1];
  console.log("axios", data.search.split("=")[1]);
  const { toggle } = useSelector((state) => state.admin_panel.User);
  const axiosFile = useAxiosFile();
  const navigate = useNavigate();

  const [mydata, setMydata] = useState({});
  // const [disb, setDisb] = useState(false)

  const [edit, setEdit] = useState({});

  const [errors, setError] = useState({});
  // setDisb({search})
  console.log("dddd", mydata);
  console.log("fffff", mydata.type, mydata?.subject, mydata?.answer);
  useEffect(() => {
    viewdata(search);
  }, []);

  const viewdata = async (search) => {
    console.log("search", search !== "" || search !== undefined);
    if (search !== "" && search !== undefined) {
      var senddata = {
        _id: search,
      };

      var data = await axiosFile.viewdata(senddata);
      console.log("wwwwww", data);
      if (data?.success == true) {
        setMydata({
          type: data?.data?.type,
          subject: data?.data?.subject,
          answer: data?.data?.answer,
        });
        setEdit({ answer: data?.data?.answer });

        console.log("wefwera", data?.success);
      }
    }
  };
  const changefn = (e) => {
    setError("");

    console.log("sadfgjhadfasdf", e.target.value);
    var { name, value } = e.target;
    console.log("nv", name, value);

    setMydata({ ...mydata, ...{ [e.target.name]: e.target.value } });
  };

  const clickSubmit = async (e) => {
    let id = LoadToast();

    var value = addvalidation();

    if (!isEmpty(value)) {
      console.log("value", value);
      setError(value);
      updateToast(id, "fix all validation", "error");
    } else {
      console.log("search_data", search);
      let senddata;
      if (search != undefined) {
        senddata = { ...mydata, ...{ _id: search, action: "update" } };
      } else {
        senddata = { ...mydata, ...{ action: "add" } };
      }
      var data = await axiosFile.detailinsert(senddata);
      console.log("dddd", data);
      updateToast(id, data.msg, data.success);
      if (data.success == "success") {
        console.log("statusdata", data.success);
        navigate("/datalists");
      }
    }
  };
  const ckeditfn = (evt) => {
    console.log("event", evt);
    setError("");
    var edit = evt.editor.getData();
    var name = evt.editor.name;
    console.log("edit_data", name, edit);
    setMydata((editor) => ({ ...editor, [name]: edit }));
  };
  const addvalidation = () => {
    console.log("dbhjbnjhbasjhd", mydata.type, mydata?.subject, mydata?.answer);
    // const link = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
    let errors = {};
    if (isEmpty(mydata?.type)) {
      errors.type = "type field is required";
    }
    if (isEmpty(mydata?.subject)) {
      errors.subject = "subject field is required";
    }
    if (isEmpty(mydata?.answer)) {
      errors.answer = "edit field is required";
    }

    return errors;
  };

  console.log("mydata", mydata);
  return (
    <>
      <div>
        <div className="innerpages">
          <div className="orders_pages">
            <Header />
            <div className="container custom_container px-lg-0">
              <div className="row mx-auto">
                <div className="innerpages_wid px-0">
                  <Sidebar />
                  <div
                    className={
                      toggle
                        ? "content-sec-inner right_widthset content_without_sidebar"
                        : "content-sec-inner  content_with_sidebar"
                    }
                  >
                    <section id="content-wrapper">
                      <div className="top_title_sec">
                        <div>
                          <p className="page_title">
                            {search !== undefined
                              ? "Edit Template"
                              : "Add Template"}
                          </p>
                        </div>
                      </div>
                      <label>
                        type
                        <input
                          className="mdv_cmninput mb-3"
                          disabled={search !== undefined}
                          name="type"
                          type="text"
                          id="type"
                          placeholder="Enter the type"
                          value={mydata?.type}
                          onChange={(e) => changefn(e)}
                        />
                        <span id="icon-error" className="error-msg">
                          {errors && errors.type}
                        </span>
                      </label>
                      <br />
                      <label>
                        subject
                        <input
                          className="mdv_cmninput mb-3"
                          name="subject"
                          type="text"
                          id="subject"
                          placeholder="Enter the subject"
                          value={mydata?.subject}
                          onChange={(e) => changefn(e)}
                        />
                        <span id="website-error" className="error-msg">
                          {errors && errors.subject}
                        </span>
                      </label>
                      <br />
                      {search ? (
                        <>
                          {edit.answer && (
                            <CKEditor
                              name="answer"
                              initData={edit.answer}
                              onChange={(e) => ckeditfn(e)}
                            />
                          )}
                        </>
                      ) : (
                        <CKEditor
                          name="answer"
                          initData={[]}
                          onChange={(e) => ckeditfn(e)}
                        />
                      )}
                      <span id="answer-error" className="error-msg">
                        {errors && errors.answer}
                      </span>

                      <br />

                      <button
                        className="blue_btn add_deliveryprsn dp_btn_hover"
                        onClick={() => clickSubmit()}
                      >
                        {search !== undefined ? "update" : "add"}
                      </button>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default dtadd;
