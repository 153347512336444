import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { isEmpty } from "../../actions/common";
import useAxiosFile from "../../actions/useaxios";
import { useDispatch, useSelector } from "react-redux";
import { Login } from "../../redux/action";
import config from "../../actions/config";
import { useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import delpickLogo from "../../assests/images/delpick.png";

export default function signin() {
  const { token, payload } = useSelector((state) => state.admin_panel.User);

  const axiosFile = useAxiosFile();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [passwordText, setPasswordText] = useState("password");
  const initdata = {
    EmailId: "",
    Password: "",
    Rememberme: false,
  };
  const [formdata, SetFormdata] = useState(initdata);
  const [error, SetError] = useState({});

  const OnChange = (e) => {
    SetError({});

    const { id, value } = e.target;
    SetFormdata({ ...formdata, ...{ [id]: value } });
  };

  const formvalidation = async (data) => {
    var validationErr = {};
    let emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([A-Za-zA-Z\-0-9]+\.)+[A-Za-zA-Z]{2,}))$/;

    if (data.EmailId == "") {
      validationErr.EmailId = "Email cannot be empty";
    } else if (data.EmailId != "") {
      if (!emailRegex.test(data.EmailId)) {
        validationErr.EmailId = "Enter valid email";
      }
    }

    if (data?.Password == "") {
      validationErr.Password = "Enter Password";
    }
    return validationErr;
  };
  const Submit = async () => {
    let errorvalid = await formvalidation(formdata);
    const id = toast.loading("Validating");
    if (isEmpty(errorvalid)) {
      let senddata = formdata;
      senddata.Type = "login";
      let Resp = await axiosFile.LoginReg(formdata);

      if (Resp?.success == "success") {
        console.log("hasvdgh");

        localStorage.setItem("Login", true);
        toast.update(id, {
          type: "success",
          render: "Login Successfully",
          isLoading: false,
          autoClose: 1000,
        });
        navigate("/dashboard");
      } else {
        console.log("resppppp", Resp.msg);
        toast.update(id, {
          type: "error",
          render: Resp.msg,
          autoClose: 1000,
          isLoading: false,
        });
        SetError(Resp.error);
      }
    } else {
      SetError(errorvalid);
    }
  };
  return (
    <div>
      <div className="login_pages">
        <div className="container custom_container">
          <div className="row aligns_itm login_row">
            <div className="col-lg-6 leftsec">
              <img
                src={require("../../assests/images/loginleft.png")}
                className="img-fluid login_img"
              />
            </div>
            <div className="col-lg-6 rightsec">
              <div className="row logosec mb-3 mb-md-5">
                {/* <img
                  src={require("../../assests/images/logo.png")}
                  className="img-fluid logo_img"
                /> */}
                <img src={delpickLogo} className="img-fluid logo_img" />
              </div>

              <div className="row form_sec">
                <p className="log_title">Let's sign you in.</p>
                <form>
                  <div className="form-group mb-4">
                    <label for="exampleInputEmail1">Email Id</label>
                    <input
                      type="text"
                      className="form-control"
                      id="EmailId"
                      aria-describedby="emailHelp"
                      placeholder="Enter your EmailId"
                      max={10}
                      onChange={(e) => OnChange(e)}
                    />
                    {error?.EmailId && (
                      <span className="error-msg">{error?.EmailId}</span>
                    )}
                  </div>
                  <div class="form-group mb-4">
                    <label for="exampleInputPassword1">Password</label>
                    <div className="reg_passwordinps">
                      {console.log("lksfjksjfsfs", passwordText)}
                      <input
                        type={passwordText == "password" ? "password" : "text"}
                        className="form-control"
                        id="Password"
                        placeholder="Password"
                        onChange={(e) => OnChange(e)}
                      />
                      <i
                        onClick={() =>
                          setPasswordText(
                            passwordText == "text" ? "password" : "text"
                          )
                        }
                        className={
                          passwordText == "password"
                            ? "fa-solid fa-eye-slash"
                            : "fa-solid fa-eye"
                        }
                      ></i>
                    </div>
                    {error?.Password && (
                      <span className="error-msg">{error?.Password}</span>
                    )}
                  </div>
                  <div className="row chk_sec">
                    <div className="col-sm-6">
                      {/* <div className="form-check mb-2 mb-md-4">
    <input type="checkbox" className="form-check-input" id="Rememberme" name="Rememberme" checked={formdata?.Rememberme} onClick={()=>SetFormdata({...formdata,...{Rememberme:!formdata.Rememberme}})}/>
    <label class="form-check-label" for="exampleCheck1">Remember Me</label>
  </div> */}
                    </div>
                    <div className="col-sm-6 linksec">
                      <Link className="link_tag" to="/forgot">
                        {" "}
                        Forgot Password?
                      </Link>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => Submit()}
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
